import { ActiveTournamentPlan, Plan } from "../domain/interfaces/plan";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import {
  createFetchActiveTournamentPlanReducer,
  createFetchOrganizersReducer,
  createGivePlanReducer,
  createTopupBalanceReducer,
} from "./redusers";

import { Organizer } from "../domain/interfaces/Organizer";

export type OrganizersSliceState = {
  organizers: Organizer[]
  plans: {
    list: Plan[]
    byOwnerId: Record<number, ActiveTournamentPlan | null>
  }
  filter?: string
  sortByDate?: string
  filterBySportType?: string
  filterByNumber?: string

  isLoaded: boolean
  isLoading: boolean

  isProcessing: boolean
  isProcessed: boolean
  processName?: string
};

const initialState: OrganizersSliceState = {
  organizers: [],
  plans: {
    byOwnerId: {},
    list: [
      {
        id: 0,
        name: 'Бесплатный',
        price: 0,
      }, {
        id: -1,
        name: 'Базовый',
        price: 1500,
      }, {
        id: -2,
        name: 'Продвинутый',
        price: 2500,
      }, {
        id: 2,
        name: 'Профессиональный',
        price: 10000,
      },
    ]
  },
  filter: '',
  sortByDate: undefined,
  filterBySportType: undefined,
  filterByNumber: undefined,

  isLoaded: false,
  isLoading: false,

  isProcessing: false,
  isProcessed: false,
};

const slice = createSlice({
  name: 'organizers',
  initialState,
  extraReducers: (builder) => {
    createFetchOrganizersReducer(builder);
    createFetchActiveTournamentPlanReducer(builder);
    createTopupBalanceReducer(builder);
    createGivePlanReducer(builder);
  },
  reducers: {
    setOrganizers: (state, action: PayloadAction<Organizer[]>) => {
      state.organizers = action.payload;
    },
    setFilter: (state, action: PayloadAction<string | undefined>) => {
      state.filter = action.payload;
    },
    setSortByDate: (state, action: PayloadAction<string | undefined>) => {
      state.sortByDate = action.payload;
    },
    setFilterBySportType: (state, action: PayloadAction<string | undefined>) => {
      state.filterBySportType = action.payload;
    },
    setFilterByNumber: (state, action: PayloadAction<string | undefined>) => {
      state.filterByNumber = action.payload;
    },
    resetProcess: (state) => {
      state.isProcessing = false;
      state.isProcessed = false;
      state.processName = undefined;
    },
  },
});

const organizersReducer = slice.reducer;
export const {
  setOrganizers,
  setFilter,
  setSortByDate,
  setFilterBySportType,
  setFilterByNumber,
  resetProcess,
} = slice.actions;
export default organizersReducer;
