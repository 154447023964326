import styled from '@emotion/styled';

export const PaginationContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledPagination = styled.div`
  display: flex;
  flex-direction: row;
  &.disabled {
    pointer-events: none;
    opacity: 0.5;
  }
`;

export const PaginationItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 24px;
  min-width: 24px;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.colors.SurfaceCultured10};
  cursor: pointer;
  user-select: none;

  &:not(:last-child) {
    margin-right: 8px;
  }

  &.active {
    background-color: ${({ theme }) => theme.colors.AccentNaplesYellow};
    pointer-events: none;
    cursor: default;
  }
`;

export const PaginationArrow = styled(PaginationItem)`
  color: ${({ theme }) => theme.colors.SurfaceRaisinBlack50};

  &.disallow {
    color: ${({ theme }) => theme.colors.SurfaceRomanSilver30};
    pointer-events: none;
    cursor: none;
  }
`;
