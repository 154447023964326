import { Board, Template } from "../../../core/domain/interfaces/supabase/overlay/entity";
import { BoardProcess, TemplateProcess } from "../../domain/interface/processes";
import {
  CopyBoard,
  CreateBoard,
  CreateTemplate,
  GetOrCreateUserTemplate,
  UpdateBoard,
  UpdateTemplate,
  VisibilityEnum,
} from "../../../core/domain/interfaces/supabase/overlay/auxiliary-types";

import { Resolution } from "../../../ud-ui/components/fabric-canvas/types";
import { createAsyncThunk } from "@reduxjs/toolkit";
import supabase from "../../../core/supabase/supabase";

const PREFIX = 'templates';

export const fetchUserTemplates = createAsyncThunk<Template[] | null, GetOrCreateUserTemplate>(
  `${PREFIX}/fetchUserTemplates`,
  async (payload, { rejectWithValue }) => {
    try {
      return await supabase.overlay.getOrCreateUserTemplate(payload);
    } catch (error) {
      console.error(`${PREFIX}/fetchUserTemplates error:`, error);

      let message
      if (error instanceof Error) message = error.message
      else message = String(error)
      return rejectWithValue(message)
    }
  },
);


export const createTemplate = createAsyncThunk<Template, CreateTemplate>(
  `${PREFIX}/createTemplate`,
  async (payload, { rejectWithValue }) => {
    try {
      return await supabase.overlay.createTemplate(payload);
    } catch (error) {
      console.error(`${PREFIX}/createTemplate error:`, error);

      let message
      if (error instanceof Error) message = error.message
      else message = String(error)
      return rejectWithValue(message)
    }
  },
)

export const deleteTemplate = createAsyncThunk<{ id: number }, { id: number }>(
  `${PREFIX}/deleteTemplate`,
  async (payload, { rejectWithValue }) => {
    try {
      await supabase.overlay.deleteTemplate(payload.id);
      return payload
    } catch (error) {
      console.error(`${PREFIX}/deleteTemplate error:`, error);

      let message
      if (error instanceof Error) message = error.message
      else message = String(error)
      return rejectWithValue(message)
    }
  },
)

export const updateTemplate = createAsyncThunk<Template, UpdateTemplate>(
  `${PREFIX}/updateTemplate`,
  async (payload, { rejectWithValue }) => {
    try {
      return await supabase.overlay.updateTemplate(payload);
    } catch (error) {
      console.error(`${PREFIX}/updateTemplate error:`, error);

      let message
      if (error instanceof Error) message = error.message
      else message = String(error)
      return rejectWithValue(message)
    }
  },
)

export const createBoard = createAsyncThunk<Board, CreateBoard>(
  `${PREFIX}/createBoard`,
  async (payload, { rejectWithValue }) => {
    try {
      return await supabase.overlay.createBoard(payload);
    } catch (error) {
      console.error(`${PREFIX}/createBoard error:`, error);

      let message
      if (error instanceof Error) message = error.message
      else message = String(error)
      return rejectWithValue(message)
    }
  },
)

export const deleteBoard = createAsyncThunk<{ id: number }, { id: number }>(
  `${PREFIX}/deleteBoard`,
  async (payload, { rejectWithValue }) => {
    try {
      await supabase.overlay.deleteBoard(payload.id);
      return payload
    } catch (error) {
      console.error(`${PREFIX}/deleteBoard error:`, error);

      let message
      if (error instanceof Error) message = error.message
      else message = String(error)
      return rejectWithValue(message)
    }
  },
)

export const updateBoard = createAsyncThunk<Board, UpdateBoard>(
  `${PREFIX}/updateBoard`,
  async (payload, { rejectWithValue }) => {
    try {
      return await supabase.overlay.updateBoard(payload);
    } catch (error) {
      console.error(`${PREFIX}/updateBoard error:`, error);

      let message
      if (error instanceof Error) message = error.message
      else message = String(error)
      return rejectWithValue(message)
    }
  },
)

export const copyTemplate = createAsyncThunk<Template, {
  ownerId: number,
  templateId: number,
  name?: string,
  resolution?: Resolution
} & { process?: TemplateProcess }>(
  `${PREFIX}/copyTemplate`,
  async (payload, { rejectWithValue }) => {
    try {
      return await supabase.overlay.copyTemplate(payload);
    } catch (error) {
      console.error(`${PREFIX}/createTemplateCopy error:`, error);

      let message
      if (error instanceof Error) message = error.message
      else message = String(error)
      return rejectWithValue(message)
    }
  },
);

export const copyBoard = createAsyncThunk<Board, CopyBoard & { process?: BoardProcess }>(
  `${PREFIX}/copyBoard`,
  async (payload, { rejectWithValue }) => {
    try {
      return await supabase.overlay.copyBoard(payload);
    } catch (error) {
      console.error(`${PREFIX}/copyBoard error:`, error);

      let message
      if (error instanceof Error) message = error.message
      else message = String(error)
      return rejectWithValue(message)
    }
  },
);

export const fetchCatalogTemplates = createAsyncThunk<Template[] | null, { visibility: VisibilityEnum[] }>(
  `${PREFIX}/fetchCatalogTemplates`,
  async (payload, { rejectWithValue }) => {
    try {
      return await supabase.overlay.getTemplates({
        access: ["default", 'public'],
        visibility: payload.visibility,
      });
    } catch (error) {
      console.error(`${PREFIX}/fetchCatalogTemplates error:`, error);

      let message
      if (error instanceof Error) message = error.message
      else message = String(error)
      return rejectWithValue(message)
    }
  },
);

export const addLike = createAsyncThunk<Template, {
  templateId: number,
  userId: number
}>(
  `${PREFIX}/addLike`,
  async (payload, { rejectWithValue }) => {
    try {
      return await supabase.overlay.addLikeToTemplate(payload);
    } catch (error) {
      console.error(`${PREFIX}/addLike error:`, error);

      let message
      if (error instanceof Error) message = error.message
      else message = String(error)
      return rejectWithValue(message)
    }
  },
);

export const addView = createAsyncThunk<Template, {
  templateId: number,
  userId: number
}>(
  `${PREFIX}/addView`,
  async (payload, { rejectWithValue }) => {
    try {
      return await supabase.overlay.addViewToTemplate(payload);
    } catch (error) {
      console.error(`${PREFIX}/addView error:`, error);

      let message
      if (error instanceof Error) message = error.message
      else message = String(error)
      return rejectWithValue(message)
    }
  },
);

export const fetchBoardsByTemplateId = createAsyncThunk<Board[], { templateId: number }>(
  `${PREFIX}/fetchBoardsByTemplateId`,
  async (payload, { rejectWithValue }) => {
    try {
      return await supabase.overlay.getBoardsByTemplateId(payload.templateId);
    } catch (error) {
      console.error(`${PREFIX}/fetchBoardsByTemplateId error:`, error);
      let message;
      if (error instanceof Error) message = error.message;
      else message = String(error);
      return rejectWithValue(message);
    }
  },
);

export const fetchTemplateById = createAsyncThunk<Template, { templateId: number }>(
  `${PREFIX}/fetchTemplateById`,
  async (payload, { rejectWithValue }) => {
    try {
      return await supabase.overlay.getTemplateById(payload.templateId);
    } catch (error) {
      console.error(`${PREFIX}/fetchTemplateById error:`, error);

      let message
      if (error instanceof Error) message = error.message
      else message = String(error)
      return rejectWithValue(message)
    }
  },
);

export const fetchTemplateWithBoards = createAsyncThunk<{ template: Template, boards: Board[] }, { templateId: number }>(
  `${PREFIX}/fetchTemplateWithBoards`,
  async (payload, { rejectWithValue }) => {
    try {
      return await supabase.overlay.getTemplateWithBoards(payload.templateId);
    } catch (error) {
      console.error(`${PREFIX}/fetchTemplateWithBoards error:`, error);

      let message
      if (error instanceof Error) message = error.message
      else message = String(error)
      return rejectWithValue(message)
    }
  },
);
