import { useCallback, useEffect, useMemo } from "react";
import useUniversalModal, { IUNModalProps } from "../useUniversalModal";

import { AppDispatch } from "../../../../../../store/store";
import { EventType } from "../../../../../stream/domain/interfaces/events";
import boardNameValidatorOnEdit from "../../../../domain/validators/boardNameValidatorOnEdit";
import { copyBoard } from "../../../../store/templates/actions";
import { eventsOptions } from "../elements/events-selector/type";
import { resetPressedState } from "../../../../store/templates/templates";
import useBoards from "../../useBoards";
import { useDispatch } from "react-redux";

function useCopyModal() {
  const dispatch = useDispatch<AppDispatch>();
  const { activeBoard, boards, isProcessed, isFinished, process } = useBoards()

  const validator = useCallback((values: { [p: string]: string | { label: string, value: string } }) => {
    const errors: { [p: string]: string | undefined } = {}

    if (typeof values.name === 'string') {
      const nameError = boardNameValidatorOnEdit(
        values.name?.trim(),
        boards.map(board => board.name),
        activeBoard?.name,
      )
      if (nameError) { errors.name = nameError }
    }

    return errors
  }, [activeBoard?.name, boards]);

  const onSubmit = useCallback((values: {
    [p: string]: string | { label: string, value: string } | { label: string, value: string }[] | undefined
  }) => {
    if (!activeBoard) return

    if (typeof values.name !== 'string') {
      console.warn({ event: 'failed to create category: name is not string', values });
      return;
    }

    const { name } = values

    dispatch(copyBoard({
      boardId: activeBoard.id,
      name: name.trim(),
      templateId: activeBoard.templateId,
      process: 'copy board',
    }))
  }, [activeBoard, dispatch]);

  const events = useMemo(() => {
    if (!activeBoard) return undefined
    return activeBoard.events
      .map(event => eventsOptions.find(option => option.value === event))
      .filter(event => event !== undefined) as { label: string, value: EventType }[]
  }, [activeBoard]);

  const data: IUNModalProps = useMemo(() => {
    return {
      initialValue: {
        name: activeBoard?.name ? activeBoard?.name + ' (дубликат)' : '',
      },
      headerText: 'Укажите название табло',
      validator,
      buttonText: 'Дублировать',
      input: {
        placeholder: 'Название табло',
      },
      onSubmit,
      isProcessed,
    }
  }, [activeBoard?.name, isProcessed, onSubmit, validator]);

  const { modal, close, open } = useUniversalModal(data)

  useEffect(() => {
    if (!isFinished) { return; }
    if (process !== 'copy board') { return; }
    close()
    dispatch(resetPressedState())
    if (!activeBoard?.templateId) { return; }
  }, [activeBoard?.templateId, close, dispatch, isFinished, process]);

  return { modal, close, open }
}

export default useCopyModal;
