import { Route, Routes } from 'react-router-dom';

import { Championship } from 'modules/tourneys/domain/interfaces/Championship';
import ChampionshipApplicationsPage from '../../../../teams/ui/pages/championship-applications-page';
import { ChampionshipInfoForm } from 'features/championship';
import { ChampionshipStateData } from '../../../store/championship';
import ChampionshipSymbolicTeams from '../form-parts/symbolic-teams/symbolic-teams';
import TourneysComponentsFormRulesPart from '../form-parts/rules';
import TourneysComponentsStages from '../form-parts/stages/stages';
import { useOrganizer } from '../../../../organizer/ui/hooks/useOrganizer';

type Props = {
  tourney: ChampionshipStateData;
  onSubmit: (data: Championship) => any;
};

const TourneyComponentsEditForm = (props: Props) => {
  const { info } = useOrganizer();

  if (!info) {
    return null;
  }

  return (
    <Routes>
      <Route
        path='rules'
        element={<TourneysComponentsFormRulesPart tournamentId={info!.id} />}
      />
      <Route
        path='applications/*'
        element={<ChampionshipApplicationsPage championshipId={props.tourney.id} />}
      />
      <Route
        path='steps'
        element={<TourneysComponentsStages championship={props.tourney} />}
      />
      <Route
        path='symbolic-teams'
        element={
          <ChampionshipSymbolicTeams
            championshipId={props.tourney.id}
            items={props.tourney.symbolicTeams}
          />
        }
      />
      <Route
        path='*'
        element={<ChampionshipInfoForm tournamentId={info!.id} />}
      />
    </Routes>
  );
};

export default TourneyComponentsEditForm;
