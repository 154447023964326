import { Label } from './styles';
import React from 'react';
import classNames from 'classnames';

export type UDFormLabelProps = & React.DetailedHTMLProps<
  React.LabelHTMLAttributes<HTMLLabelElement>,
  HTMLLabelElement
>;

const UDFormLabel = (props: UDFormLabelProps) => {
  const { children, className, ...otherProps } = props;
  return (
    <Label {...otherProps} className={classNames('text callout', className)} style={{ fontFamily: 'SF Pro Text', fontWeight: 400 }}>
      {children}
    </Label>
  )
}

export default UDFormLabel;
