import styled from '@emotion/styled';

export const MenuSystemNavigation = styled.div`
  padding-top: 8px;
  border-top: ${({ theme }) => theme.colors.SurfaceSpaceCadet50};
  border-top-width: 1px;
  border-top-style: solid;

  & .link {
    &.active {
      color: ${({ theme }) => theme.colors.SurfaceRomanSilver30};
      background-color: ${({ theme }) => theme.colors.SurfaceSpaceCadet50};
    }
  }

  & .notification .count {
    padding: 1px 6px;
    border-radius: 4px;
    background-color: ${({ theme }) => theme.colors.SurfaceRaisinBlack50};
    color: ${({ theme }) => theme.colors.AccentNaplesYellow};
  }
`;
