import { useDispatch, useSelector } from "react-redux";

import { authStoreUserTournamentId } from "modules/auth/store/selectors";
import { fetchLocations } from './../../../locations/store/actions';
import { fetchOrganizer } from "modules/organizer/store/actions";
import { useEffect } from "react";
import { useOrganizer } from "./useOrganizer";

export const useFetchOrganizer = () => {
  const dispatch = useDispatch();
  const tournamentId = useSelector(authStoreUserTournamentId);
  const { isLoading, isLoaded, info } = useOrganizer();

  useEffect(() => {
    if (tournamentId && !isLoading && (!isLoaded || (info && info.id !== tournamentId))) {
      dispatch(fetchOrganizer({ tournamentId }));
      dispatch(fetchLocations({ tournamentId }));
    }
  }, [dispatch, info, isLoaded, isLoading, tournamentId]);
};
