import React, { useCallback } from 'react';

import { AppDispatch } from '../../../../../../../store/store';
import { BorderedInput } from 'modules/teams/ui/components/styles';
import { Formik } from 'formik';
import { FormikHelpers } from "formik/dist/types";
import { StageV2 } from '../../../../../domain/interfaces/Stage';
import UDButton from '../../../../../../ud-ui/components/button';
import UDFormInput from '../../../../../../ud-form/components/input';
import UDText from '../../../../../../ud-ui/components/text';
import { UpsertStageTeamGroupParams } from '../../../../../domain/repositories/tourneysRepository';
import styled from '@emotion/styled';
import { upsertStageTeamGroup } from '../../../../../store/stages/stages.actions';
import { useDispatch } from 'react-redux';

const CreateStageGroupFormWrapper = styled.div`
  position: sticky;
  top: 0;
  z-index: 1;
`;

const CreateStageGroupFormContainer = styled.div`
  padding: 16px 20px 24px 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 0 1px rgba(26, 26, 41, 0.08), 0 8px 16px rgba(26, 26, 41, 0.08);
  margin-bottom: 42px;
`;

const CreateStageGroupFormFields = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;

  > div {
    margin: 0 5px;
    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }
  }
`;

type CreateStageGroupFormProps = {
  stage: StageV2;
};

const CreateStageGroupForm = ({ stage }: CreateStageGroupFormProps) => {
  const dispatch = useDispatch<AppDispatch>();

  const initialValues: UpsertStageTeamGroupParams = {
    stageId: stage.id,
    name: '',
    teamIds: []
  };

  const handleSubmit = useCallback((
    values: UpsertStageTeamGroupParams,
    { resetForm }: FormikHelpers<UpsertStageTeamGroupParams>
  ) => {
    if (!values.name.length) {
      return;
    }

    dispatch(upsertStageTeamGroup(values))
      .then(() => {
        resetForm();
      });
  }, [dispatch]);

  return (
    <CreateStageGroupFormWrapper>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
      >
        {(formProps) => (
          <form onSubmit={formProps.handleSubmit}>
            <CreateStageGroupFormContainer>
              <UDText type="subhead" className="bold">Добавить новую группу</UDText>

              <CreateStageGroupFormFields>
                <UDFormInput
                  containerProps={{
                    className: 'w-90'
                  }}
                  name="name"
                  placeholder="Введите название"
                  inputComponent={BorderedInput}
                />

                <div>
                  <UDButton
                    disabled={!formProps.values.name.length}
                    type="submit"
                    variant="primary"
                    loading={formProps.isSubmitting}
                  >Добавить</UDButton>
                </div>
              </CreateStageGroupFormFields>
            </CreateStageGroupFormContainer>
          </form>
        )}
      </Formik>
    </CreateStageGroupFormWrapper>
  );
};

export default CreateStageGroupForm;
