import { GetTeamEntryRequestsResponseDto, GetTeamPlayersResponseDto } from '../dtos/GetTeamPlayersResponse.dto';
import graphqlResource, { GraphqlResource, } from '../../../core/graphqlResource';

import { AddToTeamInputDto } from '../dtos/AddToTeamInput.dto';
import { AddToTeamInputResponseDto } from '../dtos/AddToTeamInputResponse.dto';
import { DeleteTournamentApplicationUserResponseDto } from '../dtos/DeleteTournamentApplicationUserResponse.dto';
import { EntryRequest } from '../interfaces/EntryRequest';
import { EntryRequestStatus } from 'shared/types/entryRequestStatus';
import { GetUserResponseDto } from '../dtos/GetUserResponse.dto';
import { KickFromTeamInputDto } from '../dtos/KickFromTeamInput.dto';
import { TournamentUser } from '../interfaces/TournamentUser';
import { UpdateEntryRequestResponseDto } from 'modules/teams/domain/dtos/UpdateEntryRequestResponse.dto';
import { UpsertTournamentApplicationUserDto } from '../dtos/UpsertTournamentApplicationUser.dto';
import { UpsertTournamentApplicationUserResponseDto } from '../dtos/UpsertTournamentApplicationUserResponse.dto';

export class PlayersRepository {
  constructor(private readonly graphql: GraphqlResource) { }

  public async getTournamentUsers(params: number): Promise<TournamentUser[]> {
    const query = `#graphql
      query team($id: Int!) {
        team(id: $id) {
          members {
            id
            user {
              id
              name
              surname
              middleName
              birthDate
              height
              weight
              tournamentAvatars {
                id
                image {
                  id
                  externalUrl
                }
              }
              photo {
                id
                externalUrl
              }
              isReal
            }
            role
          }
        }
      }
    `;

    const response = await this.graphql.query<GetTeamPlayersResponseDto>(
      query,
      { id: params }
    );
    const { members } = response.data.data.team;
    return members.map((teamMember) => {
      return { ...teamMember.user, role: teamMember.role, isReal: teamMember.user?.isReal ?? undefined };
    });


  }

  public async getUser(params: number): Promise<TournamentUser> {
    const query = `
      query user($id: Int!) {
        user(id: $id) {
          id
          name
          surname
          middleName
          birthDate
          height
          weight
          tournamentAvatars {
            id
            image {
              id
              externalUrl
            }
          }
        }
      }
    `;
    const response = await this.graphql.query<GetUserResponseDto>(query, {
      id: params,
    });
    const { user } = response.data.data;

    return user;
  }

  public async upsertTournamentApplicationForUser(
    params: UpsertTournamentApplicationUserDto,
    tournamentId: number
  ): Promise<TournamentUser> {
    const query = `
      mutation upsertTournamentUser($input: CreateUserInput!, $tournamentId: Int!) {
        upsertTournamentUser(input: $input, tournamentId: $tournamentId) {
          id
          name
          surname
          middleName
          birthDate
          height
          weight
          createdAt
          tournamentAvatars {
            id
            image {
              id
              externalUrl
            }
          }
        }
      }
    `;

    const variables = {
      input: {
        ...params,
        // position: params.position
        //   ? params.position.toUpperCase()
        //   : params.position,
      },
      tournamentId,
    };
    const response =
      await this.graphql.query<UpsertTournamentApplicationUserResponseDto>(
        query,
        variables
      );
    const { upsertTournamentUser } = response.data.data;
    const {
      id,
      name,
      middleName,
      surname,
      birthDate,
      height,
      weight,
      // position,
      tournamentAvatars,
    } = upsertTournamentUser;

    return {
      id,
      name,
      middleName,
      surname,
      birthDate,
      height,
      weight,
      // position,
      tournamentAvatars,
      createdAt: new Date(upsertTournamentUser.createdAt),
    };
  }

  public async addUserToTeam(
    params: AddToTeamInputDto
  ): Promise<TournamentUser> {
    const query = `
      mutation addToTeam($input: AddToTeamInput!) {
        addToTeam(input: $input) {
          id
          name
          surname
          middleName
          birthDate
          photo {
            id
            externalUrl
          }
          height
          weight
          createdAt
          tournamentAvatars {
            id
            image {
              id
              externalUrl
            }
          }
        }
      }
    `;

    const variables = {
      input: {
        ...params,
        // position: params.position
        //   ? params.position.toUpperCase()
        //   : params.position,
      },
    };

    const response = await this.graphql.query<AddToTeamInputResponseDto>(
      query,
      variables
    );
    const { addToTeam } = response.data.data;
    const {
      id,
      name,
      middleName,
      surname,
      birthDate,
      height,
      weight,
      tournamentAvatars,
    } = addToTeam;

    return {
      id,
      name,
      middleName,
      surname,
      birthDate,
      height,
      weight,
      // position: params.position,
      tournamentAvatars,
      createdAt: new Date(addToTeam.createdAt),
    };
  }

  public async deleteTournamentApplicationUser(
    params: number
  ): Promise<string> {
    const query = `
      mutation deleteTournamentUser($userId: Int!) {
        deleteTournamentUser(userId: $userId) {
          status
        }
      }
    `;

    const response =
      await this.graphql.query<DeleteTournamentApplicationUserResponseDto>(
        query,
        { userId: params }
      );
    const { status } = response.data;

    return status;
  }

  public async kickUserFromTeam(
    params: KickFromTeamInputDto
  ): Promise<Boolean> {
    const query = `
    mutation kickFromTeam($teamId: Int!, $userId: Int!) {
      kickFromTeam(teamId: $teamId, userId: $userId)
    }
    `;

    const response = await this.graphql.query<Boolean>(query, params);
    return response.data;
  }

  public async suggestTournamentPlayers(tournamentId: number, nameFilter: string): Promise<TournamentUser[]> {
    const query = `
      query suggestTournamentPlayers($id: Int!, $nameFilter: String) {
        tournament(id: $id) {
          users(limit: 20, offset: 0, nameFilter: $nameFilter) {
              id
              isReal
              name
              surname
              middleName
              birthDate
              tournamentAvatars {
                id
                tournament {
                    id
                }
                image {
                    externalUrl
                }
              }
              photo {
                externalUrl
              }
          }
        }
      }
    `;

    const response = await this.graphql.query<{
      data: {
        tournament: {
          users: TournamentUser[];
        };
      };
    }>(query, {
      id: tournamentId,
      nameFilter,
    });

    return response.data.data.tournament.users.filter((user) => !user.isReal);
  }

  public async getTeamEntryRequests(teamId: number): Promise<EntryRequest[]> {
    const query = `#graphql
      query getTeamEntryRequests($teamId: Int!) {
        team(id: $teamId) {
          entryRequests {
            id
            status
            requestingUser {
              id
              name
              surname
              middleName
              birthDate
              height
              weight
              photo {
                id
                externalUrl
              }
            }
          }
        }
      }
    `;

    const response = await this.graphql.query<GetTeamEntryRequestsResponseDto>(query, { teamId });


    const { entryRequests } = response.data.data.team;
    return entryRequests.map((entryRequest) => {
      return {
        requestId: entryRequest.id,
        ...entryRequest.requestingUser,
        status: entryRequest.status,
      };
    });

  }

  public async changeEntryRequestStatus(entryRequestId: number, status: EntryRequestStatus): Promise<EntryRequest> {

    const query = `#graphql
      mutation changeEntryRequestStatus($entryRequestId: Int!, $status: String!) {
        updateEntryRequest(entryRequestId: $entryRequestId, status: $status) {
          id
          status
          requestingUser {
            id
            name
            surname
            middleName
            birthDate
            height
            weight
            photo {
              id
              externalUrl
            }
          }
        }
      }
    `;

    const response = await this.graphql.query<UpdateEntryRequestResponseDto>(query, { entryRequestId, status: status.toLowerCase() });
    const { updateEntryRequest } = response.data.data;

    return {
      requestId: updateEntryRequest.id,
      ...updateEntryRequest.requestingUser,
      status: updateEntryRequest.status,
    };

  }
}


const playersRepository = new PlayersRepository(graphqlResource);

export default playersRepository;
