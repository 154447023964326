import UDInput, { UDInputProps } from "../../../ud-form/components/input/component";

import { DivProps } from "../../../../typings";
import React from "react";

interface IProps extends Omit<DivProps, 'onChange'> {
  isLoading?: boolean
  value?: string
  onChange?: (value: string) => void
  placeholder?: string
  name?: string
  inputProps?: Omit<UDInputProps, 'ref'>
}

const UrlInput = React.forwardRef((props: IProps, ref: React.ForwardedRef<HTMLInputElement>) => {
  const { isLoading, value, onChange, placeholder, name, inputProps, ...rest } = props

  return (
    <div {...rest}>
      {isLoading ? (
        <div style={{ borderRadius: 8, height: 38 }} className='skeleton-block' />
      ) : (
        <UDInput
          ref={ref}
          name={name}
          placeholder={placeholder}
          value={value}
          onChange={event => onChange && onChange(event.target.value)}
          {...inputProps}
        />
      )}
    </div>
  );
})

export default UrlInput;
