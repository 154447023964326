import { PlayerEditFormPart } from "../../../players/domain/constants/player-card";
import { TeamEditFormPart } from '../../../teams/ui/components/team-view-tabs/constants';
import { TourneyEditFormPart } from '../../../tourneys/ui/components/edit-form/constants';

export const Pages = {
  SIGN_IN: '/sign-in',
  SIGN_UP: '/sign-up',
  SELECT_TOURNAMENT: '/select-tournament',
  INDEX: '/',
  ABOUT: '/about',
  POLICY: '/policy',
  TERMS: '/terms',
  SETTINGS: '/settings',
  ORGANIZER: {
    CREATE: '/organizer/create',
  },
  TEMPLATE: {
    INDEX: '/template/',
    BOARDS: '/template/:templateId/boards',
    EDITOR: '/template/:templateId/boards/:boardId',
    PREVIEW: '/template/:templateId/boards/:boardId/preview',
    builders: {
      boards: (templateId: number) => `/template/${templateId}/boards`,
      editor: (templateId: number, boardId: number) => `/template/${templateId}/boards/${boardId}`,
      preview: (templateId: number, boardId: number) => `/template/${templateId}/boards/${boardId}/preview`,
    },
    CATALOG: {
      INDEX: '/template/catalog',
      CARD: '/template/catalog/:id',
      builders: {
        card: (id: number) => `/template/catalog/${id}`,
      },
    },
  },
  NOTIFICATIONS: '/notifications',
  SUPPORT: '/support',
  TOURNEYS: {
    INDEX: '/tourneys',
    CREATE: '/tourneys/create/common',
    EDIT: '/tourneys/:id',
    builders: {
      edit(id: number, hash: string = TourneyEditFormPart.COMMON.hash) {
        return `/tourneys/${id}/${hash}`;
      },
    },
  },
  GAMES: 'games',
  TEAMS: {
    INDEX: '/teams/',
    CREATE: '/teams/create',
    EDIT: '/teams/:id',
    builders: {
      edit: (id: number, hash: string = TeamEditFormPart.COMMON.hash) => `/teams/${id}/${hash}`,
    },
  },
  OVERLAY: {
    INDEX: '/cover/:matchId',
    CONTROL_PANEL: '/control-panel/:matchId',
  },
  COVER: '/cover/:matchId',
  ORGANIZERS: {
    INDEX: '/organizers/',
    VIEW: '/organizers/:id',
    builders: {
      view: (id: number) => `/organizers/${id}`,
    },
  },
  SCHEDULE: {
    INDEX: '/schedule/',
    VIEW: '/schedule/:id',
    builders: {
      view: (id: number) => `/schedule/${id}`,
    },
  },
  PLAYERS: {
    INDEX: '/players/',
    VIEW: '/players/:id',
    CREATE: "/players/create/",
    builders: {
      view: (id: number, hash: string = PlayerEditFormPart.COMMON.hash) => `/players/${id}/${hash}`,
    },
  },
  MASTER_BOARDS: {
    INDEX: '/master-board',
    CONTROL_PANEL: '/master-board/control-panel/:id?',
    BOARD: '/master-board/board/:id?',
    builders: {
      controlPanel: (id: string) => `/master-board/control-panel${id ? `/${id}` : ''}`,
      board: (id: string) => `/master-board/board${id ? `/${id}` : ''}`,
    },
  },
  LOCATIONS: {
    INDEX: '/locations',
    CREATE: '/locations/create',
    VIEW: '/locations/:id',
    builders: {
      edit: (id: number) => `/locations/${id}`,
    },
  },
  APPLICATION_LINK: {
    INDEX: '/application-link',
  },
  CATEGORY_TEST: {
    INDEX: '/category-test',
    builders: {
      index: () => '/category-test',
    },
  },
}
