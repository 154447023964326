import pattern from "../../../../template-editor/domain/images/circlePattern.svg";
import styled from "@emotion/styled";

export const Container = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  gap: 16px;
`

export const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 284px;
`

export const RightContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  width: 100%;
  /* height: 100%; */
`

export const CanvasWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  /* min-height: 500px; */

  background: #ffffff url(${pattern}) no-repeat center;
  border-radius: 8px;
  background-size: cover;
  position: relative;
`
