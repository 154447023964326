/** @jsxImportSource @emotion/react */

import UDCheckbox, { UDCheckboxProps } from './component';

import React from 'react';
import { SerializedStyles } from '@emotion/react';
import { UDFormComponentProps } from '../types';
import UDFormFieldWrapper from '../../wrapper';
import classNames from 'classnames';

type Props = UDCheckboxProps & UDFormComponentProps & {
  css?: SerializedStyles;
};

const UDFormCheckbox = (props: Props) => {
  const {
    name,
    required,
    labelProps,
    label,
    containerProps,
    ...checkboxProps
  } = props;
  return (
    <UDFormFieldWrapper
      name={name}
      required={required}
      label={label}
      labelProps={{
        ...labelProps,
        style: { marginBottom: 0, ...labelProps?.style },
      }}
      containerProps={{
        ...containerProps,
        className: classNames(
          'd-flex flex-row-reverse',
          containerProps?.className,
        ),
      }}>
      {({ field }) => (
        <UDCheckbox {...checkboxProps} {...field} checked={field.value} />
      )}
    </UDFormFieldWrapper>
  );
};

export default UDFormCheckbox;
