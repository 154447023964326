import { TeamResponse, UserResponse } from '../../domain/dtos/UpsertChampionshipApplicationResponse.dto';
import { createAction, createAsyncThunk, unwrapResult } from '@reduxjs/toolkit';

import { AnnouncedTeamStatus } from '../../domain/enums/AnnouncedTeamStatus';
import { AnnouncedTeamsMap } from '../../domain/interfaces/AnnouncedTeamsMap';
import { ApplicationStatus } from '../../../applications/domain/enums/ApplicationStatus';
import { CreateUserInputDto } from 'modules/teams/domain/dtos/CreateUserInput.dto';
import { PlayerApplication } from '../../domain/interfaces/PlayerApplication';
import { PlayerPosition } from '../../domain/enums/PlayerPosition';
import { RootState } from '../../../../store/store';
import { Team } from '../../domain/interfaces/Team';
import { UpsertChampionshipApplicationDto } from 'modules/teams/domain/dtos/UpsertChampionshipApplication.dto';
import { UpsertChampionshipApplicationUserDto } from '../../domain/dtos/UpsertChampionshipApplicationUser.dto';
import { UpsertTournamentTeamDto } from '../../domain/dtos/UpsertTournamentTeam.dto';
import { addChampionshipTeam } from '../../../tourneys/store/championship/actions';
import { createTeam } from '../../../organizer/store/actions';
import teamsRepository from '../../domain/repositories/teams.repository';

const PREFIX = 'teams/championshipTeams';

export const createChampionshipTeam = createAsyncThunk<Team, UpsertTournamentTeamDto, { state: RootState; }>(
  `${PREFIX}/createChampionshipTeam`,
  async (payload, { dispatch }) => {
    try {
      const result = await dispatch(createTeam(payload));
      const team = unwrapResult(result);
      await dispatch(addChampionshipTeam(team.id));

      return team;
    } catch (err) {
      console.error(`${PREFIX}/createChampionshipTeam error:`, err);
      throw err;
    }
  },
);

export const getChampionshipAnnouncedTeams = createAsyncThunk<AnnouncedTeamsMap, number>(
  `${PREFIX}/getChampionshipAnnouncedTeams`,
  async (payload, { getState }) => {
    try {
      const result = await teamsRepository.getAnnouncedChampionshipTeams(payload);
      const state = getState() as RootState;
      const tournamentTeams = state.organizer.info?.teams || [];

      Object.keys(result).forEach((key) => {
        const teamId = parseInt(key);
        const championshipTeam = result[teamId];
        const tournamentTeam = tournamentTeams.find((team) => team.id === teamId);
        if (tournamentTeam) {
          const tournamentTeamApplications = tournamentTeam.players
            .filter((player) => !championshipTeam.applications.some((app) => app.player.id === player.userId))
            .map((player) => ({
              id: (new Date()).getTime(),
              championshipId: payload,
              teamId: tournamentTeam.id,
              status: ApplicationStatus.PENDING,
              createdAt: championshipTeam.applicationDate,
              position: player.position,
              number: player.number,
              player: {
                id: player.userId,
                firstName: player.firstName,
                lastName: player.lastName,
                middleName: player.middleName,
                birthDate: player.birthDate,
                logoUrl: player.avatar,
              }
            } as PlayerApplication));

          result[teamId].applications = [
            ...result[teamId].applications,
            ...tournamentTeamApplications,
          ];
        }
      });

      return result;
    } catch (err) {
      console.error(`${PREFIX}/getChampionshipAnnouncedTeams error:`, err);
      throw err;
    }
  },
);

export const upsertChampionshipPlayerApplication = createAsyncThunk<PlayerApplication, UpsertChampionshipApplicationUserDto>(
  `${PREFIX}/upsertChampionshipPlayerApplication`,
  async (payload, { dispatch }) => {
    try {
      const result = await teamsRepository.upsertChampionshipApplicationForUser(payload);
      await dispatch(getChampionshipAnnouncedTeams(payload.championshipId));

      return result;
    } catch (err) {
      console.error(`${PREFIX}/upsertChampionshipPlayerApplication error:`, err);
      throw err;
    }
  },
);

export const championshipPlayerApplicationChangeNumber = createAsyncThunk<PlayerApplication, Omit<UpsertChampionshipApplicationUserDto, 'id'> & { id: number }>(
  `${PREFIX}/championshipPlayerApplicationChangeNumber`,
  async (payload, { dispatch }) => {
    try {
      const result = await teamsRepository.upsertChampionshipApplicationForUser(payload);

      dispatch(playerChampionshipApplicationNumberChanged({
        id: payload.id,
        teamId: payload.teamId,
        number: payload.createUserOrUpdateApplication?.number,
      }));

      return result;
    } catch (err) {
      console.error(`${PREFIX}/championshipPlayerApplicationChangeNumber error:`, err);
      throw err;
    }
  },
);

export const championshipPlayerApplicationChangePosition = createAsyncThunk<PlayerApplication, Omit<UpsertChampionshipApplicationUserDto, 'id'> & { id: number }>(
  `${PREFIX}/championshipPlayerApplicationChangeNumber`,
  async (payload, { dispatch }) => {
    try {
      const result = await teamsRepository.upsertChampionshipApplicationForUser(payload);

      dispatch(playerChampionshipApplicationPositionChanged({
        id: payload.id,
        teamId: payload.teamId,
        position: payload.createUserOrUpdateApplication?.position as PlayerPosition,
      }));

      return result;
    } catch (err) {
      console.error(`${PREFIX}/championshipPlayerApplicationChangeNumber error:`, err);
      throw err;
    }
  },
);

export const upsertChampionshipTeamApplication = createAsyncThunk<{ teamId: number, status: AnnouncedTeamStatus, applicationStatus: ApplicationStatus } | null, { teamId: number, status: AnnouncedTeamStatus }>(
  `${PREFIX}/upsertChampionshipTeamApplication`,
  async (payload, { getState }) => {
    try {
      const state = getState() as RootState;
      const { data } = state.teams.championshipTeams;
      if (!data) {
        return null;
      }

      let applicationStatus: ApplicationStatus;
      switch (payload.status) {
        case AnnouncedTeamStatus.ACCEPTED:
          applicationStatus = ApplicationStatus.ACCEPTED;
          break;
        case AnnouncedTeamStatus.DECLINED:
          applicationStatus = ApplicationStatus.DECLINED;
          break;
        default:
          applicationStatus = ApplicationStatus.PENDING;
      }

      const apps: PlayerApplication[] = data[payload.teamId].applications;
      const createUserOrUpdateApplications: CreateUserInputDto[] = apps.map((app) => ({
        userId: app.player.id,
        name: app.player.firstName,
        surname: app.player.lastName,
        middleName: app.player.middleName,
        birthDate: app.player.birthDate,
        weight: app.player.weight,
        height: app.player.height,
        number: app.number,
        position: app.position ? app.position.toUpperCase() : app.position,
        state: applicationStatus.toUpperCase() as ApplicationStatus,
      }));

      const applicationPayload: UpsertChampionshipApplicationDto = {
        teamId: payload.teamId,
        championshipId: apps[0].championshipId,
        applications: createUserOrUpdateApplications,
      };

      await teamsRepository.upsertChampionshipApplication(applicationPayload);

      return {
        ...payload,
        applicationStatus,
      };
    } catch (err) {
      console.error(`${PREFIX}/upsertChampionshipTeamApplication error:`, err);
      throw err;
    }
  }
);

// export const announceNewTeam = createAction<Team>(`${PREFIX}/announceNewTeam`);
export const announceNewTeam = createAsyncThunk<Team, Team, { state: RootState; }>(
  `${PREFIX}/announceNewTeam`,
  async (team, { dispatch }) => {
    try {
      await dispatch(addChampionshipTeam(team.id));
      return team;
    } catch (err) {
      console.error(`${PREFIX}/announceNewTeam error:`, err);
      throw err;
    }
  },
);

export const playerChampionshipApplicationNumberChanged = createAction<{
  teamId: number;
  id: number;
  number?: number;
}>(`${PREFIX}/playerChampionshipApplicationNumberChanged`);

export const playerChampionshipApplicationPositionChanged = createAction<{
  teamId: number;
  id: number;
  position?: PlayerPosition;
}>(`${PREFIX}/playerChampionshipApplicationPositionChanged`);

export const upsertChampionshipApplicationUsers = createAsyncThunk<{ team: TeamResponse, applications: UserResponse[] } | null, UpsertChampionshipApplicationDto>(
  `${PREFIX}/upsertChampionshipApplicationUsers`,
  async (payload, { getState }) => {
    try {
      const state = getState() as RootState;
      const { data } = state.teams.championshipTeams;
      if (!data) {
        return null;
      }

      const applicationPayload: UpsertChampionshipApplicationDto = {
        teamId: payload.teamId,
        championshipId: payload.championshipId,
        applications: payload.applications,
      };

      const { team, users } = await teamsRepository.upsertChampionshipApplication(applicationPayload);

      return {
        team,
        applications: users
      };
    } catch (err) {
      console.error(`${PREFIX}/upsertChampionshipTeamApplication error:`, err);
      throw err;
    }
  }
);
