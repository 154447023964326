import { fetchBoardsByTemplateId, fetchUserTemplates } from "../../store/templates/actions";
import { setActiveBoard, setActiveTemplate, setTournamentId } from "../../store/templates/templates";
import { useEffect, useMemo } from "react";

import { AppDispatch } from "../../../../store/store";
import { Pages } from "../../../navigation/domain/enums/pages";
import { useAuth } from "../../../auth/ui/hooks/useAuth";
import { useDispatch } from "react-redux";
import { useMatch } from "react-router-dom";
import { useOrganizer } from "../../../organizer/ui/hooks/useOrganizer";
import useTemplates from "./useTemplates";

export function useLoadTemplates() {
  const dispatch = useDispatch<AppDispatch>()
  const { templateId } = useMatch(Pages.TEMPLATE.BOARDS + '/*')?.params ?? {}
  const { boardId } = useMatch(Pages.TEMPLATE.EDITOR + '/*')?.params ?? {}

  const { info } = useOrganizer()
  const { user } = useAuth()
  const { isLoaded: isTemplatesLoaded, tournamentId, loadedTemplates } = useTemplates()

  const isDev = process.env.REACT_APP_ENVIRONMENT !== 'production'

  const isAdmin = useMemo(() => {
    if (isDev) return true
    if (!user) return false
    return user.id === 20
  }, [isDev, user])


  useEffect(() => {
    if (!info) return
    if (isTemplatesLoaded && info.id === tournamentId) return
    dispatch(setTournamentId({ id: info.id }))
    dispatch(fetchUserTemplates({ ownerId: info.id, visibility: isAdmin ? ['superAdmin', 'all'] : ['all'] }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, info, isTemplatesLoaded, isAdmin, templateId])

  useEffect(() => {
    if (templateId && !loadedTemplates.includes(Number(templateId))) {
      dispatch(fetchBoardsByTemplateId({ templateId: Number(templateId) }))
    }
  }, [dispatch, templateId, loadedTemplates])

  useEffect(() => {
    if (templateId) {
      dispatch(setActiveTemplate({ id: parseInt(templateId) }))
    }

    if (boardId) {
      dispatch(setActiveBoard({ id: parseInt(boardId) }))
    }
  }, [boardId, dispatch, templateId]);
}
