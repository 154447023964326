import { Board, Template } from "../../../../core/domain/interfaces/supabase/overlay/entity";
import { fetchPassedEvents, syncGameInfo } from "../../../store/gameInfo/actions";
import { setActiveBoard, setActiveTemplate } from "../../../../template-editor/store/templates/templates";
import {
  setBoardLocked,
  setHideControlPanel,
  setPassedEventsId as setPassedEventsIdOverlay,
} from "../../../store/overlay/overlay";
import { useEffect, useMemo } from "react";

import { AppDispatch } from "../../../../../store/store";
import { TokenStorage } from "../../../../core/tokenStorage";
import { fetchTemplateWithBoards } from "../../../../template-editor/store/templates/actions";
import { getCurrentBoard } from "../../../domain/utils/getCurrentBoard";
import { setPassedEventsId as setPassedEventsIdGameInfo } from "../../../store/gameInfo/gameInfo";
import supabase from "../../../../core/supabase/supabase";
import { syncTime } from "../../../store/time/actions";
import { useAuth } from "../../../../auth/ui/hooks/useAuth";
import { useDispatch } from "react-redux";
import useGameInfo from "../useGameInfo";
import { useParams } from "react-router-dom";
import { useQueryParam } from "use-query-params";
import useTemplates from "../../../../template-editor/ui/hooks/useTemplates";

function useInitDataOverlay() {
  const dispatch = useDispatch<AppDispatch>()
  const { matchId: id } = useParams()

  const matchId = useMemo(() => id ? parseInt(id) : 0, [id]);
  const encodeToken = useQueryParam('token')[0] as string | undefined
  const boardId = useQueryParam('boardId')[0] as string | undefined
  const isLocked = useQueryParam('locked')[0] as string | undefined
  const isHidePanel = useQueryParam('hidePanel')[0] as string | undefined
  const { organizer, championship } = useGameInfo()
  const { isLoaded } = useTemplates()

  const isDev = process.env.NODE_ENV !== 'production'
  const { user } = useAuth()
  const isAdmin = useMemo(() => {
    if (isDev) return true
    if (!user) return false
    return user.id === 20
  }, [isDev, user])

  useEffect(() => {
    dispatch(setActiveBoard({ id: boardId ? parseInt(boardId) : undefined }));
    dispatch(setBoardLocked((isLocked) === 'true'))
    dispatch(setHideControlPanel(isHidePanel === 'true'))
  }, [boardId, dispatch, isHidePanel, isLocked])

  useEffect(() => {
    if (!encodeToken) return
    const token = window.atob(encodeToken)
    const tokenStorage = new TokenStorage()
    tokenStorage.saveToken(token)
  }, [encodeToken]);

  useEffect(() => {
    supabase.overlayLogs.init({ matchId })
    dispatch(syncGameInfo({ matchId }))
    dispatch(syncTime({ matchId }))
    dispatch(fetchPassedEvents({ matchId }))
    supabase.matchChronology.getRow(matchId).then((data) => {
      const passedEvents = data?.chronology.map(event => event.id) ?? []
      dispatch(setPassedEventsIdGameInfo(passedEvents))
      dispatch(setPassedEventsIdOverlay(passedEvents))
    })
  }, [dispatch, matchId])

  useEffect(() => {
    if (!organizer) return
    if (isLoaded) return;
    supabase.overlayLogs.init({ tournamentId: organizer.id })
  }, [dispatch, isAdmin, isLoaded, organizer]);

  useEffect(() => {
    if (!championship?.overlayId) return

    dispatch(fetchTemplateWithBoards({ templateId: championship.overlayId }))
      .then(({ payload }) => {
        const { template } = payload as { template: Template, boards: Board[] }
        dispatch(setActiveTemplate({ id: template.id }))
        window?.opener?.postMessage(JSON.stringify({ type: 'popupLoaded' }), '*')
      })
      .catch((data) => {
        supabase.overlayLogs.createError({
          comment: 'Не удалось получить шаблоны пользователя',
          message: data.toString(),
        })
      })

    supabase.overlayLogs.init({
      championshipId: championship.id,
      templateId: championship.overlayId,
    })
  }, [championship?.id, championship?.overlayId, dispatch]);

  useEffect(() => {
    if (!isLoaded) return
    if (boardId) return;
    getCurrentBoard(matchId).then(currentBoard => {
      dispatch(setActiveBoard({ id: currentBoard }))
    })
  }, [dispatch, matchId, isLoaded, boardId]);
}

export default useInitDataOverlay
