import { selectLocationById, selectProcess } from "modules/locations/store/selectors";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";

import { AppDispatch } from "store/store";
import { fetchLocationMapImage } from "modules/locations/store/actions";

interface UseFetchMapImageProps {
    locationId: number;
    width?: number;
    height?: number;
}

function useFetchMapImage(props: UseFetchMapImageProps) {
    const dispatch = useDispatch<AppDispatch>();
    const location = useSelector(selectLocationById(props.locationId));
    const process = useSelector(selectProcess);
    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {
        if (!location?.mapImage && !isLoaded) {
            dispatch(fetchLocationMapImage({ locationId: props.locationId, width: props.width, height: props.height }));
            setIsLoaded(true);
        }
    }, [dispatch, props.locationId, props.width, props.height, location?.mapImage, isLoaded]);

    return {
        isLoaded,
        mapImage: location?.mapImage,
        isLoading: process.isProcessing && process.name === 'fetchLocationMapImage'
    };
}

export default useFetchMapImage;
