import { ValidPhoneCode } from '../formatters/formatPhone';

export const getPhoneMask = (code: ValidPhoneCode) => {
  switch (code) {
    case '+7':
      return '(⦁⦁⦁) ⦁⦁⦁-⦁⦁-⦁⦁';
    case '+375':
      return '(⦁⦁) ⦁⦁⦁-⦁⦁-⦁⦁';
    case '+374':
      return '(⦁⦁) ⦁⦁⦁-⦁⦁⦁';
    default:
      return '(⦁⦁) ⦁⦁⦁-⦁⦁-⦁⦁';
  }
};
