import UDButton, { ButtonVariant } from '../../button';

import React from 'react';
import UDText from "../../text";
import classNames from 'classnames';
import styled from '@emotion/styled';
import { useCallback } from 'react';

const ItemContainer = styled.div<{ backgroundColor?: string }>`
  display: flex;
  align-items: center;
  padding: 0 20px;
  height: 72px;
  min-height: 72px;
  background-color: ${props => props.backgroundColor || '#F7F7F7'};
  border-radius: 8px;
  justify-content: space-between;

  &.white {
    background-color: white;
  }

  &.light-gray {
    background-color: #F7F7F7;
  }

  &.transparent {
    background-color: transparent;
  }

  &.hover {
    transition: background-color 0.2s ease-in-out;

    &:hover:not(:has(button:hover, input:hover, select:hover, [role="button"]:hover, .checkbox-area:hover, .interactive:hover)) {
        background-color:${({ theme }) => theme.colors.AccentCornsilk};
        cursor: pointer;
       }
  }
`;

const ImageWrapper = styled.div<{ size?: number }>`
  width: ${props => props.size || 60}px;
  height: ${props => props.size || 60}px;
  min-width: ${props => props.size || 60}px;
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

`;

export interface UDListItemAction {
    label: string;
    onClick: () => void;
    disabled?: boolean;
    loading?: boolean;
    variant?: ButtonVariant
    style?: React.CSSProperties;
}

export interface UDListItemProps extends Omit<React.HTMLAttributes<HTMLDivElement>, 'title'> {
    index?: number | string;
    image?: string | React.ReactNode;
    title?: string | React.ReactNode;
    subtitle?: string | React.ReactNode;
    additionalFields?: React.ReactNode[];
    actions?: UDListItemAction[];
    backgroundColor?: string;
    imageSize?: number;
    indexWidth?: number;
    titleWidth?: string | number;
}

const UDListItem: React.FC<UDListItemProps> = ({
    index,
    image,
    title,
    subtitle,
    additionalFields = [],
    actions = [],
    backgroundColor,
    imageSize,
    indexWidth = 60,
    titleWidth = '40%',
    onClick,
    ...rest
}) => {
    const showIndex = index !== undefined

    const handleContainerClick = useCallback((event: React.MouseEvent<HTMLDivElement>) => {
        const target = event.target as HTMLElement;
        const isInteractive = target.closest('button, input, select, [role="button"], .interactive, .checkbox-area');

        if (!isInteractive) {
            onClick?.(event);
        }
    }, [onClick]);

    return (
        <ItemContainer backgroundColor={backgroundColor} {...rest} className={classNames('UDListItem', rest.className, { 'hover': onClick })} style={{ padding: !showIndex ? '0 20px' : '0 20px 0 0', ...rest.style }} onClick={handleContainerClick}>
            <div className="d-flex align-items-center" style={{ width: '33%' }}>
                {showIndex && (
                    <UDText
                        type='subhead'
                        className='bold flex-center'
                        style={{ width: indexWidth, minWidth: indexWidth }}
                    >
                        {index}
                    </UDText>
                )}
                {image && (
                    <ImageWrapper size={imageSize}>
                        {typeof image === 'string' ? <img src={image} alt={typeof title === 'string' ? title : 'image'} /> : image}
                    </ImageWrapper>
                )}

                {title && (
                    <UDText
                        type='subhead'
                        className='bold ml-5'
                        style={{
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            fontWeight: 500,
                        }}
                    >
                        {title}
                    </UDText>
                )}
            </div>

            <div className="flex-center align-items-center" style={{ width: '33%' }}>
                {subtitle && (
                    <UDText
                        type='subhead'
                        className='bold w-100 text-center'
                        style={{
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            fontWeight: 500,
                        }}
                    >
                        {subtitle}
                    </UDText>
                )}
            </div>

            <div className="d-flex align-items-center" style={{ width: '33%' }}>
                {additionalFields.map((field, idx) => (
                    <React.Fragment key={idx}>
                        {field}
                    </React.Fragment>
                ))}
                {actions.length > 0 && (
                    <div style={{ marginLeft: 'auto', display: 'flex', gap: '10px' }}>
                        {actions.map((action, idx) => (
                            <UDButton
                                variant={action.variant ?? 'primary'}
                                key={idx}
                                onClick={action.onClick}
                                disabled={action.disabled}
                                loading={action.loading}
                                style={action.style}
                            >
                                {action.label}
                            </UDButton>
                        ))}
                    </div>
                )}
            </div>
        </ItemContainer>
    );
};

export default UDListItem;
