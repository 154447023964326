/** @jsxImportSource @emotion/react */

import React, { useMemo, useRef } from 'react';
import { useLocation, useMatch, useParams } from 'react-router-dom';

import { Pages } from 'modules/navigation/domain/enums/pages';
import TeamComponentsEditForm from './team-view-page';
import TeamEditFormTabs from '../components/team-view-tabs/tabs';
import UDBreadcrumbs from 'modules/ud-ui/components/breadcrumbs';
import UDMainLayout from '../../../ud-ui/layout/main';
import classNames from 'classnames';
import { css } from '@emotion/react';
import { selectOrganizerTeamById } from 'modules/organizer/store/selectors';
import { useSelector } from 'react-redux';

const TeamsEditPage = () => {
  const { id } = useParams();
  const teamId = id ? parseInt(id) : 0;
  const team = useSelector(selectOrganizerTeamById(teamId));
  const teamPlayersPage = useMatch(`${Pages.TEAMS.EDIT}/players`);
  const teamPlayerCreatePage = useMatch(`${Pages.TEAMS.EDIT}/players/create`);
  const teamParticipantsPage = useMatch(`${Pages.TEAMS.EDIT}/participants`);
  const teamWaitingListPage = useMatch(`${Pages.TEAMS.EDIT}/waiting-list`);
  const tabsRef = useRef<HTMLDivElement>(null);

  const playerId = Number(useLocation().state?.fromPlayer);
  const fromPlayer = team?.players.find((player) => player.userId === playerId);

  const breadcrumbsItems = useMemo(() => {
    const fullName = fromPlayer?.lastName + ' ' + fromPlayer?.firstName + ' ' + (fromPlayer?.middleName ?? '');

    const items: { label: string; to: string; state?: any }[] = fromPlayer ? [
      { label: 'Игроки', to: Pages.PLAYERS.INDEX },
      { label: fullName ?? 'Неизвестный игрок', to: Pages.PLAYERS.builders.view(fromPlayer.userId) },
      { label: 'Команды', to: Pages.PLAYERS.builders.view(fromPlayer.userId, 'teams') },
    ] : [{ label: 'Команды', to: Pages.TEAMS.INDEX }];

    if (!team) {
      return items;
    }

    items.push({
      label: team.name,
      to: Pages.TEAMS.builders.edit(team.id),
      state: playerId && { fromPlayer: playerId },
    });

    if (teamPlayersPage || teamPlayerCreatePage) {
      items.push({
        label: 'Игроки',
        to: Pages.TEAMS.builders.edit(team.id, 'players'),
        state: playerId && { fromPlayer: playerId },
      });

      if (teamPlayerCreatePage) {
        items.push({
          label: 'Добавление игрока',
          to: Pages.TEAMS.builders.edit(team.id, 'players/create'),
          state: playerId && { fromPlayer: playerId },
        })
      }
    }

    if (teamParticipantsPage) {
      items.push({
        label: 'Участники',
        to: Pages.TEAMS.builders.edit(team.id, 'participants'),
      });
    }

    if (teamWaitingListPage) {
      items.push({
        label: 'Лист ожидания',
        to: Pages.TEAMS.builders.edit(team.id, 'waiting-list'),
      });
    }

    return items;
  }, [fromPlayer, playerId, team, teamPlayerCreatePage, teamPlayersPage, teamParticipantsPage, teamWaitingListPage]);



  const breadcrumbs = team ? (
    <UDBreadcrumbs className='my-10' breadcrumbs={breadcrumbsItems} />
  ) : null;

  const tabsHeight = useMemo(() => {
    return tabsRef.current?.clientHeight ?? 62;
  }, [tabsRef]);

  return (
    <UDMainLayout headerContent={breadcrumbs}>
      <div className={classNames('container  pt-6 flex-column', { 'h-100': !teamPlayersPage && !teamParticipantsPage && !teamWaitingListPage })}>
        {team && (
          <>
            <div ref={tabsRef} className='pb-6'>
              <TeamEditFormTabs state={{ fromPlayer: playerId }} />
            </div>

            <div css={css`
              height: calc(100% - ${tabsHeight}px);
              display: flex;
              flex-direction: column;
            `}>

              <TeamComponentsEditForm team={team} />
            </div>
          </>

        )}
      </div>
    </UDMainLayout>
  );
};

export default TeamsEditPage;
