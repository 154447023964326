import { combineReducers, configureStore } from '@reduxjs/toolkit';

import TournamentUsersReducer from "../modules/players/store";
import applicationLinkReducer from 'modules/application-link/store';
import authReducer from '../modules/auth/store';
import { createBrowserHistory } from 'history';
import { createReduxHistoryContext } from 'redux-first-history';
import entryRequestsReducer from 'modules/teams/store/entryRequests';
import gamesReducer from 'modules/games/store';
import locationsReducer from '../modules/locations/store';
import mainPageReducer from '../modules/main/store';
import masterBoardReducer from "../modules/board-master/store";
import organizerReducer from '../modules/organizer/store';
import organizersReducer from "../modules/organizers/store";
import overlayReducer from '../modules/stream/store';
import playersReducer from 'modules/teams/store/players';
import { saveState } from '../modules/auth/store/persist';
import scheduleReducer from "../modules/schedule/store";
import teamsReducer from 'modules/teams/store';
import templatesEditorReducer from "../modules/template-editor/store";
import tourneysReducer from '../modules/tourneys/store';

const { createReduxHistory, routerMiddleware, routerReducer } =
  createReduxHistoryContext({
    history: createBrowserHistory(),
  });

export const rootReducer = combineReducers({
  router: routerReducer,
  auth: authReducer,
  organizer: organizerReducer,
  mainPage: mainPageReducer,
  tourneys: tourneysReducer,
  games: gamesReducer,
  teams: teamsReducer,
  players: playersReducer,
  overlay: overlayReducer,
  templateEditor: templatesEditorReducer,
  organizers: organizersReducer,
  schedule: scheduleReducer,
  tournamentUsersSlice: TournamentUsersReducer,
  masterBoard: masterBoardReducer,
  locations: locationsReducer,
  entryRequests: entryRequestsReducer,
  applicationLink: applicationLinkReducer,
});

const defaultMiddlewareConfig = {
  serializableCheck: {
    ignoredPaths: [
      'templateEditor.templates.templates',
      'templateEditor.editor.fabricManager',
      'templateEditor.templates.catalog',
      'schedule.selectedDate',
      'schedule.weeks',
    ],
  },
};

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware(defaultMiddlewareConfig).concat(routerMiddleware),
  devTools: process.env.NODE_ENV !== 'production',
});

store.subscribe(() => {
  saveState(store.getState());
});

export const history = createReduxHistory(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
