import { Container, DropdownItem, Input, Prefix } from '../styles';
import { ValidPhoneCode, formatPhone } from '../../domain/formatters/formatPhone';
import { forwardRef, useMemo, useRef, useState } from 'react';

import { ArrowDownIcon } from '../../../../../ud-ui/components/icon/arrow-down-icon';
import { DivProps } from 'typings';
import { Dropdown } from '../dropdown';
import { UDInputProps } from '../../component/index';
import UDText from 'modules/ud-ui/components/text';
import classNames from 'classnames';
import { getPhoneMask } from '../../domain/helpers/getPhoneMask';
import { parsePhone } from '../../domain/formatters/parsePhone';
import { phonePrefixes } from '../../domain/constants/phone-prefixes';

export type UDInputPhoneProps = UDInputProps & {
    value: string;
    numberPart: string;
    hasError?: boolean;
    valid?: boolean;
    isOpen: boolean;
    activeOption: typeof phonePrefixes[0];
    placeholder?: string;
    wrapperProps?: Omit<DivProps, 'children'>;
    onPrefixClick: () => void;
    onChangeHandler: (value: string) => void;
    selectOption: (option: typeof phonePrefixes[0]) => void;
    onBlur?: (e: React.FocusEvent<any>) => void;
};

const ContainerWithRef = forwardRef<HTMLDivElement, DivProps & { readOnly?: boolean }>((props, ref) => (
    <Container {...props} ref={ref} />
));

const UDInputPhoneComponent = (props: UDInputPhoneProps) => {
    const {
        hasError,
        valid,
        isOpen,
        activeOption,
        numberPart,
        placeholder,
        wrapperProps,
        onPrefixClick,
        onChangeHandler,
        selectOption,
        onBlur,
        readOnly,
        ...rest
    } = props;

    const parentRef = useRef<HTMLDivElement>(null);
    const [isFocused, setIsFocused] = useState(false);

    const isValid = useMemo(() => {
        if (hasError) return false;
        const numberPartWithoutPrefix = numberPart.replace(/\D/g, '')
        return numberPartWithoutPrefix.length === getPhoneMask(activeOption.prefix as ValidPhoneCode).replace(/[^⦁]/g, '').length;
    }, [hasError, numberPart, activeOption.prefix]);

    const options = phonePrefixes.map((option) => (
        <DropdownItem
            key={option.id}
            isActive={activeOption.id === option.id}
            onClick={() => selectOption(option)}
        >
            <option.icon className='icon' />
            <UDText type='subhead' className='country'>{option.name}</UDText>
            <UDText type='subhead' className='prefix'>{option.prefix}</UDText>
        </DropdownItem>
    ));

    return (
        <>
            <ContainerWithRef
                {...(wrapperProps as DivProps)}
                readOnly={readOnly}
                className={classNames({ error: hasError, valid: isValid, '--error': hasError }, wrapperProps?.className)}
                ref={parentRef}
            >
                <Prefix onClick={onPrefixClick}>
                    <activeOption.icon />
                    <UDText type='subhead' style={{ fontFamily: 'SF Pro Text', letterSpacing: 1 }}>
                        {activeOption.prefix}
                    </UDText>
                    <ArrowDownIcon direction={isOpen ? 'down' : 'up'} />
                </Prefix>
                <Input
                    {...rest}
                    readOnly={readOnly}
                    placeholder={placeholder}
                    formatter={(value) => formatPhone(value, activeOption.prefix as ValidPhoneCode, isFocused)}
                    parser={(value) => parsePhone(value, activeOption.prefix as ValidPhoneCode)}
                    onChange={(event) => onChangeHandler(event.target.value)}
                    onFocus={(e) => {
                        setIsFocused(true);
                        rest.onFocus?.(e);
                    }}
                    onBlur={(e) => {
                        setIsFocused(false);
                        onBlur?.(e);
                    }}
                    className={classNames('input', rest.className)}
                    wrapperProps={{ className: classNames('w-100') }}
                    value={numberPart}
                    mask={getPhoneMask(activeOption.prefix as ValidPhoneCode)}
                    type="tel"
                    inputMode="numeric"
                />
            </ContainerWithRef>
            <Dropdown parentRef={parentRef} isOpen={isOpen} options={options} />
        </>
    );
};

export default UDInputPhoneComponent;
