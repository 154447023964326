export type ValidPhoneCode = '+7' | '+375' | '+374';

export const getPhonePrefix = (phone: string): ValidPhoneCode => {
  if (phone.startsWith('+7')) {
    return '+7';
  } else if (phone.startsWith('+375')) {
    return '+375';
  } else {
    return '+374';
  }
};

export const formatPhone = (value = '', code?: ValidPhoneCode, isFocused?: boolean): string => {
  const normalizedPhone = value.replace(/[^0-9]/g, '');

  if (!normalizedPhone && !isFocused) {
    return '';
  }

  let codeToUse = code;
  if (!code) {
    codeToUse = getPhonePrefix(normalizedPhone);
  }

  let regexPhone: RegExp | undefined;
  let maxLength: number | undefined;

  switch (codeToUse) {
    case '+7': {
      // Russia and Kazakhstan
      // +7 (906) 777-77-77
      regexPhone = /^(\d{0,3})(\d{0,3})(\d{0,2})(\d{0,2})$/;
      maxLength = 10;
      break;
    }
    case '+375': {
      // Belarus
      // +375 (33) 302-66-60
      regexPhone = /^(\d{0,2})(\d{0,3})(\d{0,2})(\d{0,2})$/;
      maxLength = 9;
      break;
    }
    case '+374': {
      // Armenia
      // +374 (51) 123-456
      regexPhone = /^(\d{0,2})(\d{0,3})(\d{0,3})$/;
      maxLength = 8;
      break;
    }
  }

  if (!regexPhone || !maxLength) return normalizedPhone;

  // Ограничиваем длину номера
  const truncatedPhone = normalizedPhone.slice(0, maxLength as number);

  return truncatedPhone.replace(regexPhone, (_, ...parts) => {
    if (!normalizedPhone && !isFocused) {
      return '';
    }

    const results = normalizedPhone.length || isFocused ? ['('] : [];

    if (parts[0]) {
      results.push(parts[0]);
    }

    if (parts[1]) {
      results.push(') ', parts[1]);
    }

    if (parts[2]) {
      results.push('-', parts[2]);
    }

    if (parts[3]) {
      results.push('-', parts[3]);
    }

    return results.join('');
  });
};
