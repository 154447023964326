import { FieldProps, useFormikContext } from 'formik';
import { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';

import { DivProps } from 'typings';
import { UDFormComponentProps } from 'modules/ud-form/components/types';
import UDFormFieldWrapper from 'modules/ud-form/wrapper';
import UDInputPhoneComponent from './phone/component';
import classNames from 'classnames';
import { phonePrefixes } from 'modules/ud-form/components/input/domain/constants/phone-prefixes';

type UDFormPhonePrefixProps = UDFormComponentProps & {
    selectComponent?: (props: any) => ReactElement<any, any> | null;
    placeholder?: string;
    value?: string;
    onChange?: (value: string) => void;
    wrapperProps?: Omit<DivProps, 'children'>;
    readOnly?: boolean;
    errorVisible?: boolean;
    style?: React.CSSProperties;
};

const UDInputPhone = (props: UDFormPhonePrefixProps) => {
    const {
        value,
        name,
        containerProps,
        required,
        label,
        labelProps,
        placeholder,
        wrapperProps,
        onChange,
        readOnly,
        errorVisible,
        style,
    } = props;

    const [isOpen, setIsOpen] = useState(false);

    const initialPrefix = useMemo(() => {
        const prefix = phonePrefixes.find(p => value?.startsWith(p.prefix));
        return prefix || phonePrefixes[0];
    }, [value]);

    const [activeOption, setActiveOption] = useState(initialPrefix);
    const { setFieldValue, setFieldTouched } = useFormikContext();

    const onChangeHandler = useCallback(
        (inputValue: string) => {
            const formattedValue = inputValue.replace(/\D/g, '');
            const fullNumber = (`${activeOption.prefix}${formattedValue}`);

            setFieldValue(name, fullNumber);

            if (onChange) {
                onChange(fullNumber);
            }
        },
        [activeOption.prefix, name, onChange, setFieldValue],
    );

    const selectOption = useCallback((option: typeof phonePrefixes[0]) => {
        setActiveOption(option);
        setIsOpen(false);

        const currentNumber = '';
        const newValue = (`${option.prefix}${currentNumber}`);

        setFieldValue(name, newValue);
        if (onChange) {
            onChange(newValue);
        }
    }, [setFieldValue, name, onChange]);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            const target = event.target as HTMLElement;
            if (!target.closest('.phone-input-container')) {
                setIsOpen(false);
            }
        };

        if (isOpen) {
            document.addEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isOpen]);

    return (
        <UDFormFieldWrapper
            name={name}
            containerProps={{
                ...containerProps,
                className: classNames('flex-column phone-input-container', containerProps?.className)
            }}
            label={label}
            labelProps={labelProps}
            required={required}
            errorVisible={errorVisible}
        >
            {({ field, meta }: FieldProps<string>) => {
                const numberPart = field.value.replace(activeOption.prefix.replace(/\D/g, ''), '')
                const hasError = meta.touched && !!meta.error;

                return (
                    <UDInputPhoneComponent
                        {...field}
                        style={style}
                        readOnly={readOnly}
                        value={field.value}
                        numberPart={numberPart}
                        hasError={hasError}
                        isOpen={isOpen}
                        activeOption={activeOption}
                        placeholder={placeholder}
                        wrapperProps={wrapperProps}
                        onPrefixClick={() => setIsOpen(!isOpen)}
                        onChangeHandler={onChangeHandler}
                        selectOption={selectOption}
                        onFocus={() => {
                            setFieldTouched(name, true, false);
                        }}
                        onBlur={(e) => {
                            field.onBlur(e);
                        }}
                    />
                );
            }}
        </UDFormFieldWrapper>
    );
};

export default UDInputPhone;
