import { UDCreatableSelect, UDSelect } from '../../../ud-form/components/select/component';

import UDInput from '../../../ud-form/components/input/component';
import styled from '@emotion/styled';

export const BorderedInput = styled(UDInput)`
  border: 1px solid #e7e7e7;
`;

export const BorderedSelect = styled(UDSelect)`
  border: 1px solid #F4F4F5;
  border-radius: 8px;
` as typeof UDSelect;

export const BorderedCreatableSelect = styled(UDCreatableSelect)`
  border: 1px solid #F4F4F5;
  border-radius: 8px;
` as typeof UDCreatableSelect;

export const PlayerNumberInput = styled(BorderedInput)`
  border: 1px solid transparent;
  padding: 0 6px;
  height: 36px;
  text-align: center;
  max-width: 127px;
  min-width: 127px;
`;
