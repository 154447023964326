import {
    createGetEntryRequests,
    createUpdateEntryRequest
} from './reducers';

import { EntryRequest } from '../../domain/interfaces/EntryRequest';
import { createSlice } from '@reduxjs/toolkit';

export interface EntryRequestsSliceState {
    isLoading: boolean;
    isLoaded: boolean;
    items: EntryRequest[];
    isProcessing: boolean;
    isProcessed: boolean;
    processedName: string | null;
}

const initialState: EntryRequestsSliceState = {
    isLoading: false,
    isLoaded: false,
    items: [],

    isProcessing: false,
    isProcessed: false,
    processedName: null,
};

const slice = createSlice({
    name: 'entryRequests',

    initialState,
    reducers: {},
    extraReducers: (builder) => {
        createGetEntryRequests(builder);
        createUpdateEntryRequest(builder);
    },

});

const entryRequestsReducer = slice.reducer;

export default entryRequestsReducer;