import { Route, Routes } from 'react-router-dom';

import ChampionshipApplications from '../components/championship-teams/championship-applications';
import React from 'react';
import TeamPlayersPage from './team-players-page';

type ChampionshipApplicationsPageProps = {
  championshipId: number;
};

const ChampionshipApplicationsPage = ({ championshipId }: ChampionshipApplicationsPageProps) => {
  return (
    <Routes>
      <Route path=":teamId" element={<TeamPlayersPage />} />
      <Route path="*" element={<ChampionshipApplications championshipId={championshipId} />} />
    </Routes>
  );
};

export default ChampionshipApplicationsPage;
