import React, { useMemo } from 'react';

import { BorderedSelect } from 'modules/teams/ui/components/styles';
import { SelectOption } from 'modules/ud-form/components/select/component';
import StageGroupEmptyBody from './stage-group-empty-body';
import { Team } from 'modules/teams/domain/interfaces/Team';
import { TeamGroupStatisticsRow } from '../../../../../../domain/interfaces/TeamGroupStatistics';
import TeamLogo from 'modules/ud-ui/components/team-logo';
import UDButton from 'modules/ud-ui/components/button';
import UDColorableIcon from 'modules/ud-ui/components/icon/colorable-icon';
import styled from '@emotion/styled';

export const TableCell = styled.div`
  border: 1px solid #EAEAEC;
  background: #FFF;
  padding: 10px;

  &:first-child {
    padding-left: 14px;
  }
`;

export const TableHeader = styled.div`
  border-bottom: 1px solid #2D2D47;
  font-family: -apple-system, BlinkMacSystemFont, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 22px;
`;

const TableRowWrapper = styled.div`
  position: relative;
  font-family: -apple-system, BlinkMacSystemFont, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 22px;

  .remove-row-btn {
    display: none;
    cursor: pointer;
    position: absolute;
    top: calc(50% - 8px);
    left: -18px;
    width: 20px;
    padding: 0;
    margin: 0;

    .icon-wrapper {
      display: inline-flex;
      text-align: center;
      align-items: center;
      justify-content: center;
      border-radius: 3px;
      background: #d4d5d7;
      width: 16px;
      height: 16px;
    }

    &:hover {
      .icon-wrapper {
        background: #ff5630;

        .remove-icon {
          path {
            fill: white;
          }
        }
      }
    }
  }

  &:hover {
    .remove-row-btn {
      display: inline-block;
    }
  }
`;

const TeamInfoWrapper = styled.div`
  display: flex;
  align-items: center;

  .team-select-logo {
    margin-right: 8px;
  }
`;

type TeamInfoProps = { team: Team; };

const TeamInfo = ({ team }: TeamInfoProps) => {
  return (
    <TeamInfoWrapper>
      <TeamLogo
        className="team-select-logo"
        url={team.logo?.url}
        size={32}
      />
      {team.name}
    </TeamInfoWrapper>
  );
};

const teamToTeamOption = (team: Team): SelectOption<number, Team> => ({
  label: (<TeamInfo team={team} />),
  value: team.id,
});

type TeamWithStatistics = {
  team: Team | null;
  statistics: TeamGroupStatisticsRow | null;
};

type TableProps = {
  className?: string;
  isEditMode: boolean;
  teams: (Team | null)[];
  stageTeams: Team[];
  statistics: TeamGroupStatisticsRow[];
  editClick: () => void;
  saveClick: React.MouseEventHandler<HTMLButtonElement>;
  deleteClick: () => void;
  removeTeamClick: (index: number) => void;
  teamChange?: (index: number, teamId: number) => void;
};

export const Table = (props: TableProps) => {
  const {
    className,
    isEditMode,
    teams,
    stageTeams,
    statistics,
    editClick,
    saveClick,
    deleteClick,
    removeTeamClick,
    teamChange,
  } = props;

  const statisticsByTeam: Record<number, TeamGroupStatisticsRow> = useMemo(() => {
    return statistics.reduce((result, item) => {
      return {
        ...result,
        [item.teamId]: item,
      };
    }, {});
  }, [statistics]);

  const teamsWithStatistics: TeamWithStatistics[] = useMemo(() => {
    return teams
      .map((team) => ({
        team,
        statistics: team ? statisticsByTeam[team.id] : null,
      }))
      .sort((a, b) => {
        return (b.statistics?.score || 0) - (a.statistics?.score || 0)
      });
  }, [teams, statisticsByTeam]);

  return (
    <div className={className}>
      <TableHeader className="d-flex flex-row">
        <TableCell className="col-1">#</TableCell>
        <TableCell className="col-3">Команда</TableCell>
        <TableCell className="d-flex col-1 justify-content-center">И</TableCell>
        <TableCell className="d-flex col-1 justify-content-center">В</TableCell>
        <TableCell className="d-flex col-1 justify-content-center">Н</TableCell>
        <TableCell className="d-flex col-1 justify-content-center">П</TableCell>
        <TableCell className="d-flex col-1 justify-content-center">ГЗ</TableCell>
        <TableCell className="d-flex col-1 justify-content-center">ГП</TableCell>
        <TableCell className="d-flex col-1 justify-content-center">+/-</TableCell>
        <TableCell className="d-flex col-1 justify-content-center">О</TableCell>
      </TableHeader>

      {teams.length > 0 && (
        <>
          {teamsWithStatistics.map(({ team, statistics }, index) => (
            <TableRow
              key={`${index}_${team?.id}`}
              index={index}
              isEditMode={isEditMode}
              team={team}
              teamStatistics={statistics}
              stageTeams={stageTeams}
              removeTeamClick={removeTeamClick}
              teamChange={teamChange}
            />
          ))}

          {isEditMode && (
            <>
              <UDButton
                variant="primary"
                className="mt-4"
                onClick={saveClick}
              >Сохранить</UDButton>

              <UDButton
                variant="secondary"
                className="mt-4 ml-6"
                onClick={deleteClick}
              >Удалить</UDButton>
            </>
          )}
        </>
      )}

      {teams.length === 0 && (
        <StageGroupEmptyBody onAddClick={editClick} />
      )}
    </div>
  );
};

type TeamCellBodyProps = {
  isEditMode: boolean;
  teamOptions: SelectOption<number, Team>[];
  team?: Team | null;
  handleTeamChange: (option: any) => void,
};

const TeamCellBody = (props: TeamCellBodyProps) => {
  const {
    isEditMode,
    teamOptions,
    team,
    handleTeamChange,
  } = props;

  const value = team ? teamToTeamOption(team) : undefined;

  if (isEditMode) {
    return (
      <BorderedSelect
        className="w-90"
        name="team"
        value={value}
        options={teamOptions}
        onChange={handleTeamChange}
      />
    );
  }

  if (team) {
    return (<TeamInfo team={team} />);
  }

  return null;
};

type TableRowProps = Omit<TableProps, 'teams' | 'editClick' | 'saveClick' | 'deleteClick' | 'statistics'> & {
  index: number;
  team?: Team | null;
  teamStatistics: TeamGroupStatisticsRow | null;
};

export const TableRow = (props: TableRowProps) => {
  const {
    isEditMode,
    index,
    team,
    teamStatistics,
    stageTeams,
    removeTeamClick,
    teamChange,
  } = props;

  const teamOptions = stageTeams.map(teamToTeamOption);
  const handleRemoveClick = () => removeTeamClick(index);
  const handleTeamChange = (option: any) => teamChange && teamChange(index, option.value);

  return (
    <TableRowWrapper className="d-flex flex-row">
      {isEditMode && (
        <span
          className="remove-row-btn"
          onClick={handleRemoveClick}
        >
          <span className="icon-wrapper">
            <UDColorableIcon
              componentProps={{ className: 'remove-icon' }}
              name="close" size={10}
            />
          </span>
        </span>
      )}

      <TableCell className="d-flex col-1 align-items-center">{index + 1}</TableCell>
      <TableCell className="col-3">
        <TeamCellBody
          isEditMode={isEditMode}
          team={team}
          teamOptions={teamOptions}
          handleTeamChange={handleTeamChange}
        />
      </TableCell>
      <TableCell className="d-flex col-1 justify-content-center align-items-center">
        {teamStatistics?.gamesPlayed}
      </TableCell>
      <TableCell className="d-flex col-1 justify-content-center align-items-center">
        {teamStatistics?.gamesWon}
      </TableCell>
      <TableCell className="d-flex col-1 justify-content-center align-items-center">
        {teamStatistics?.gamesDraw}
      </TableCell>
      <TableCell className="d-flex col-1 justify-content-center align-items-center">
        {teamStatistics?.gamesLost}
      </TableCell>
      <TableCell className="d-flex col-1 justify-content-center align-items-center">
        {teamStatistics?.goalsWon}
      </TableCell>
      <TableCell className="d-flex col-1 justify-content-center align-items-center">
        {teamStatistics?.goalsLost}
      </TableCell>
      <TableCell className="d-flex col-1 justify-content-center align-items-center">
        {teamStatistics && (
          teamStatistics.goalsWon - teamStatistics.goalsLost
        )}
      </TableCell>
      <TableCell className="d-flex col-1 justify-content-center align-items-center">
        {teamStatistics?.score}
      </TableCell>
    </TableRowWrapper>
  );
};
