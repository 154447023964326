import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import tourneysRepository, {
  ChampionshipGamesDTO,
  ChampionshipTeamsWithApplicationsDTO,
  UpsertChampionshipParams,
  UpsertGameDTO,
  UpsertStageTeamGroupParams,
  UpsertSymbolicTeamDTO,
} from '../../domain/repositories/tourneysRepository';

import { Championship } from '../../domain/interfaces/Championship';
import { ChampionshipSettings } from '../../domain/interfaces/ChampionshipSettings';
import { Game } from '../../../games/domain/interfaces/Game';
import { RootState } from '../../../../store/store';
import { Stage } from '../../domain/interfaces/Stage';
import { SymbolicTeam } from '../../domain/interfaces/SymbolicTeam';
import { TeamGroup } from '../../domain/interfaces/TeamGroup';
import { TeamGroupStatistics } from '../../domain/interfaces/TeamGroupStatistics';
import { UpsertChampionshipDto } from '../../domain/interfaces/UpsertChampionshipDto';

const PREFIX = 'tourneys/championship';

export const fetchChampionship = createAsyncThunk<Championship | null, number>(
  `${PREFIX}/fetchChampionship`,
  async (championshipId) => {
    try {
      return await tourneysRepository.fetchChampionshipById(championshipId);
    } catch (err) {
      console.error(`${PREFIX}/fetchChampionship error:`, err);
      return null;
    }
  },
);

export const fetchChampionshipTeams = createAsyncThunk<ChampionshipTeamsWithApplicationsDTO, number>(
  `${PREFIX}/fetchChampionshipTeams`,
  async (championshipId) => {
    try {
      return await tourneysRepository.fetchChampionshipTeamsWithApplications(championshipId);
    } catch (err) {
      console.error(`${PREFIX}/fetchChampionshipTeams error:`, err);
      throw err;
    }
  },
);

export const fetchChampionshipGames = createAsyncThunk<ChampionshipGamesDTO, number>(
  `${PREFIX}/fetchChampionshipGames`,
  async (championshipId) => {
    try {
      return await tourneysRepository.fetchChampionshipMatchesWithoutStage(championshipId);
    } catch (err) {
      console.error(`${PREFIX}/fetchChampionshipGames error:`, err);
      throw err;
    }
  },
);

type CreateChampionshipGameDTO = Omit<UpsertGameDTO, 'matchId'>;
export const createChampionshipGame = createAsyncThunk<Game | null, CreateChampionshipGameDTO>(
  `${PREFIX}/createChampionshipGame`,
  async (payload) => {
    try {
      return await tourneysRepository.upsertGame(payload);
    } catch (err) {
      console.log(`${PREFIX}/createChampionshipGame error:`, err);
      return null;
    }
  },
);

export const upsertChampionshipGame = createAsyncThunk<Game, UpsertGameDTO>(
  `${PREFIX}/upsertChampionshipGame`,
  async (payload) => {
    try {
      return await tourneysRepository.upsertGame(payload);
    } catch (err) {
      console.log(`${PREFIX}/upsertChampionshipGame error:`, err);
      throw err;
    }
  },
);

export const deleteChampionshipGame = createAsyncThunk<number | null, number>(
  `${PREFIX}/deleteChampionshipGame`,
  async (gameId) => {
    try {
      return await tourneysRepository.deleteGame(gameId);
    } catch (err) {
      console.log(`${PREFIX}/deleteGame error:`, err);
      return null;
    }
  },
);

export const clearSelectedChampionship = createAction<void>(`${PREFIX}/clearSelectedChampionship`);

export const upsertChampionship = createAsyncThunk<number, UpsertChampionshipDto, { state: RootState; }>(
  `${PREFIX}/upsertChampionship`,
  async (params, { dispatch, getState }) => {
    try {
      const state = getState();
      const newSettings = {
        ...state.tourneys.championship.data?.settings,
        overlayId: params.overlayId,
      } as ChampionshipSettings;

      if (params.teamPlayersNumber) {
        newSettings.teamPlayersNumber = params.teamPlayersNumber;
      }

      let teamIds: number[] = [];
      if (params.id) {
        const championship = state.tourneys.championship.data!
        teamIds = championship.teams.map((team) => team.id);
      }

      const championshipId = await tourneysRepository.upsertChampionship({
        id: params.id,
        tournamentId: params.tournamentId,
        name: params.name,
        description: params.description,
        startDate: params.startDate,
        endDate: params.endDate,
        address: params.address,
        settings: newSettings,
        locationIds: params.locationIds,
        teamIds,
      });


      dispatch(fetchChampionship(championshipId));

      return championshipId;
    } catch (err) {
      console.error(`${PREFIX}/upsertChampionship error:`, err);
      throw err;
    }
  },
);

export const upsertChampionshipSettings = createAsyncThunk<void, UpsertChampionshipParams, { state: RootState; }>(
  `${PREFIX}/upsertChampionshipSettings`,
  async (params, { dispatch, getState }) => {
    try {
      const state = getState();
      const championship = state.tourneys.championship.data!

      const championshipId = await tourneysRepository.upsertChampionship({
        id: championship.id,
        tournamentId: championship.tournamentId,
        name: championship.name,
        description: championship.description || null,
        startDate: championship.startDate,
        endDate: championship.endDate,
        address: championship.address,
        settings: params.settings,
        teamIds: championship.teams.map((team) => team.id),
      });

      dispatch(fetchChampionship(championshipId));
    } catch (err) {
      console.error(`${PREFIX}/upsertChampionshipSettings error:`, err);
    }
  },
);

export const addChampionshipTeam = createAsyncThunk<void, number, { state: RootState }>(
  `${PREFIX}/addChampionshipTeam`,
  async (teamId, { dispatch, getState }) => {
    try {
      const state = getState();
      const championship = state.tourneys.championship.data!
      const teamIds = new Set(championship.teams.map((team) => team.id));
      teamIds.add(teamId);

      const params = {
        id: championship.id,
        tournamentId: championship.tournamentId,
        name: championship.name,
        description: championship.description || null,
        startDate: championship.startDate,
        endDate: championship.endDate,
        address: championship.address,
        settings: championship.settings,
        teamIds: Array.from(teamIds),
      };

      const championshipId = await tourneysRepository.upsertChampionship(params);
      dispatch(fetchChampionship(championshipId));
    } catch (err) {
      console.error(`${PREFIX}/addChampionshipTeam error:`, err);
      throw err;
    }
  },
);

export const deleteChampionshipStage = createAsyncThunk<boolean, number>(
  `${PREFIX}/deleteChampionshipStage`,
  async (stageId) => {
    try {
      return await tourneysRepository.deleteChampionshipStage(stageId);
    } catch (err) {
      console.error(`${PREFIX}/deleteChampionshipStage error:`, err);
      throw err;
    }
  },
);

export const selectChampionshipStage = createAction<Stage | null>(`${PREFIX}/selectChampionshipStage`);
export const showChampionshipStageForms = createAction<boolean>(`${PREFIX}/showChampionshipStageForms`);
export const selectSymbolicTeam = createAction<SymbolicTeam | null>(`${PREFIX}/selectSymbolicTeam`);

export const upsertStageAllowedTeamsGroup = createAsyncThunk<TeamGroup, UpsertStageTeamGroupParams>(
  `${PREFIX}/upsertStageAllowedTeamsGroup`,
  async (params) => {
    try {
      return await tourneysRepository.upsertStageTeamGroup(params);
    } catch (err) {
      console.error(`${PREFIX}/upsertStageAllowedTeamsGroup error:`, err);
      throw err;
    }
  },
);

export const upsertSymbolicTeam = createAsyncThunk<SymbolicTeam, UpsertSymbolicTeamDTO>(
  `${PREFIX}/upsertSymbolicTeam`,
  async (params) => {
    try {
      return await tourneysRepository.upsertSymbolicTeam(params);
    } catch (err) {
      console.error(`${PREFIX}/upsertSymbolicTeam error:`, err);
      throw err;
    }
  },
);

export const fetchStageGroupsStatistics = createAsyncThunk<Record<number, TeamGroupStatistics>, number[]>(
  `${PREFIX}/fetchStageGroupsStatistics`,
  async (teamGroupIds) => {
    try {
      return tourneysRepository.fetchStageGroupsStatistics(teamGroupIds);
    } catch (err) {
      console.error(`${PREFIX}/fetchStageGroupsStatistics error:`, err);
      throw err;
    }
  },
);

export const deleteChampionship = createAsyncThunk<boolean, { championshipId: number }>(
  `${PREFIX}/deleteChampionship`,
  async (payload, { rejectWithValue }) => {
    try {
      return await tourneysRepository.deleteChampionship(payload.championshipId);
    } catch (error) {
      console.error(`${PREFIX}/deleteChampionship error:`, error);

      let message
      if (error instanceof Error) message = error.message
      else message = String(error)
      return rejectWithValue(message)
    }
  },
);
