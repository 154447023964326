import { EntityState, PayloadAction, createSlice } from '@reduxjs/toolkit';
import { deleteChampionshipGame, upsertChampionshipGame } from '../championship/actions';
import {
  deleteStage,
  deleteStageTeamGroup,
  fetchGameDetails,
  fetchStages,
  updateStageBracketTeams,
  upsertStage,
  upsertStageTeamGroup
} from './stages.actions';

import { GameDetails } from 'modules/games/domain/interfaces/GameDetails';
import { GameTeam } from '../../../games/domain/interfaces/Game';
import { StageV2 } from '../../domain/interfaces/Stage';
import { saveGameDetails } from '../../../games/store/actions';
import stagesAdapter from './stages.adapter';

type InitialStagesState = {
  status: 'idle' | 'loading' | 'loaded';
  championshipId: number | null;
  selectedId: number | null;
  isProcessing: boolean;
  processingName: 'fetchGameDetails' | null;
};

const stagesSlice = createSlice({
  name: 'stages',
  initialState: stagesAdapter.getInitialState<InitialStagesState>({
    status: 'idle',
    championshipId: null,
    selectedId: null,
    isProcessing: false,
    processingName: null,
  }),
  reducers: {
    stageAdded: stagesAdapter.addOne,
    stageSelected: (state, action: PayloadAction<number | null>) => {
      state.selectedId = action.payload;
    },
    updateGameDetails: (state, action: PayloadAction<GameDetails>) => {
      const { payload } = action;
      const stageId = payload.round?.stageId;
      const stage = stageId ? state.entities[stageId] : null;

      if (!stage) return;

      const gameDetailsList = stage.gameDetailsList;

      stagesAdapter.updateOne(state, {
        id: stageId!,
        changes: {
          gameDetailsList: {
            ...gameDetailsList,
            byId: { ...gameDetailsList.byId, [payload.id]: payload },
          },
        },
      });
    }
  },
  extraReducers(builder) {
    builder
      .addCase(fetchStages.pending, (state, action) => {
        state.status = 'loading';
        state.championshipId = action.meta.arg;
      })
      .addCase(fetchStages.fulfilled, (state, action) => {
        const stages = action.payload.map((stage) => {
          const { teamGroups, ...data } = stage;
          return {
            ...data,
            teamGroupIds: teamGroups.map(group => group.id),
            gameDetailsList: {
              byId: {},
              allIds: [],
            },
          };
        });

        state.status = 'loaded';
        stagesAdapter.setAll(state, stages);
      })
      .addCase(fetchStages.rejected, (state) => {
        state.status = 'loaded';
      });

    builder.addCase(upsertStage.fulfilled, (state, action) => {
      stagesAdapter.upsertOne(state, action.payload);
    });

    builder.addCase(deleteStage.fulfilled, (state, action) => {
      if (action.payload) {
        const stageId = action.meta.arg;

        stagesAdapter.removeOne(state, stageId);
        if (state.selectedId === stageId) {
          state.selectedId = null;
        }
      }
    });

    builder.addCase(upsertStageTeamGroup.fulfilled, (state, action) => {
      const teamGroupId = action.payload.id;
      const stageId = action.payload.stageId;
      const stage = state.entities[stageId];

      if (!stage) {
        return;
      }

      stagesAdapter.updateOne(state, {
        id: stageId,
        changes: {
          teamGroupIds: [...stage.teamGroupIds, teamGroupId],
        },
      });
    });

    builder.addCase(deleteStageTeamGroup.fulfilled, (state, action) => {
      const teamGroupId = action.meta.arg;
      const selectedStageId = state.selectedId

      if (!selectedStageId) {
        return;
      }

      const stage = state.entities[selectedStageId];
      if (!stage) {
        return;
      }

      stagesAdapter.updateOne(state, {
        id: selectedStageId,
        changes: {
          teamGroupIds: stage.teamGroupIds.filter((id) => id !== teamGroupId),
        },
      });
    });

    const deleteMatchFromStage = (state: EntityState<StageV2>, stage: StageV2, matchId: number) => {
      const updatedRounds = stage.rounds.map((r) => ({
        ...r,
        matchIds: r.matchIds.filter((id) => id !== matchId),
      }));

      const updatedMatches = stage.matches.filter((m) => m.id !== matchId);

      stagesAdapter.updateOne(state, {
        id: stage.id,
        changes: {
          rounds: updatedRounds,
          matches: updatedMatches,
        },
      });
    };

    builder.addCase(upsertChampionshipGame.fulfilled, (state, action) => {
      const stage = state.selectedId ? state.entities[state.selectedId] : null;
      if (!stage) {
        return;
      }

      const { payload } = action;
      const { round } = payload;

      if (round && round.stageId === stage.id) {
        const stage = state.entities[round.stageId] || null;

        Object.keys(state.entities).forEach((key) => {
          const s = state.entities[key];
          if (s && (!stage || stage.id !== s.id)) {
            deleteMatchFromStage(state, s, payload.id);
          }
        });

        if (!stage) return;

        const hasRound = stage.rounds.find((r) => r.id === round.id);
        let updatedRounds = stage.rounds;

        if (hasRound) {
          updatedRounds = stage.rounds.map((r) => {
            let matchIds = r.matchIds;
            if (matchIds.includes(payload.id) && payload.round?.id !== r.id) {
              matchIds = matchIds.filter((id) => id !== payload.id);
            }

            if (!matchIds.includes(payload.id) && payload.round?.id === r.id) {
              matchIds.push(payload.id);
            }

            return {
              ...r,
              matchIds,
            };
          });
        } else {
          updatedRounds = [
            ...updatedRounds,
            {
              ...round,
              matchIds: [payload.id],
              createdAt: new Date(),
              updatedAt: new Date(),
            }
          ];
        }

        const foundMatch = stage.matches.find((m) => m.id === payload.id);
        let updatedMatches = stage.matches;

        if (foundMatch) {
          updatedMatches = stage.matches.map((m) => {
            if (m.id === payload.id) {
              return payload;
            }

            return m;
          });
        } else {
          updatedMatches = [...updatedMatches, payload].sort((a, b) => b.date.valueOf() - a.date.valueOf());
        }

        stagesAdapter.updateOne(state, {
          id: stage.id,
          changes: {
            rounds: updatedRounds,
            matches: updatedMatches,
          },
        });
      } else {
        deleteMatchFromStage(state, stage, payload.id);
      }
    });

    builder.addCase(deleteChampionshipGame.fulfilled, (state, action) => {
      const { payload } = action;
      if (!payload) return;

      let stageWithMatch: StageV2 | null = null;
      Object.keys(state.entities).forEach((key) => {
        if (!stageWithMatch && state.entities[key]) {
          const s = state.entities[key]!;
          const match = s.matches.find((m) => m.id === payload);

          if (match) {
            stageWithMatch = s;
          }
        }
      });

      if (stageWithMatch) {
        deleteMatchFromStage(state, stageWithMatch, payload);
      }
    });

    builder.addCase(saveGameDetails.fulfilled, (state, action) => {
      const { payload } = action;
      if (!payload) return;

      const stageId = payload.round?.stageId;
      const stage = stageId ? state.entities[stageId] : null;

      if (!stage) return;

      stagesAdapter.updateOne(state, {
        id: stage.id,
        changes: {
          matches: stage.matches.map((m) => {
            if (m.id === payload.id) {
              const hasResults = !!payload.result.firstTeamScore || !!payload.result.secondTeamScore;
              const teams = m.teams.map((t, index) => {
                const score = index === 0 ? payload.result.firstTeamScore : payload.result.secondTeamScore;
                return { ...t, score };
              }) as [GameTeam, GameTeam];

              return {
                ...m,
                hasResults,
                teams,
              };
            }

            return m;
          }),
        },
      });
    });

    builder.addCase(updateStageBracketTeams.fulfilled, (state, { payload }) => {
      const { stageId } = payload;
      const stage = stageId ? state.entities[stageId] : null;

      if (!stage) return;

      const matches = stage.matches.map((match) => {
        if (match.id === payload.match?.id) {
          const teamMatches = payload.match?.teamMatches || [];
          const firstTeam = teamMatches[0].team || null;
          const secondTeam = teamMatches[1].team || null;

          return {
            ...match,
            teams: [firstTeam, secondTeam] as [GameTeam, GameTeam],
          };
        }

        return match;
      });

      stagesAdapter.updateOne(state, {
        id: stageId,
        changes: {
          matches,
        },
      });
    });

    builder.addCase(fetchGameDetails.pending, (state, action) => {
      state.isProcessing = true;
      state.processingName = 'fetchGameDetails';
    });

    builder.addCase(fetchGameDetails.fulfilled, (state, action) => {
      state.isProcessing = false;
      state.processingName = null;

      const { payload } = action;
      if (!payload) return;

      const stageId = payload.round?.stageId;
      const stage = stageId ? state.entities[stageId] : null;

      if (!stage) return;

      const gameDetailsList = stage.gameDetailsList;

      stagesAdapter.updateOne(state, {
        id: stage.id,
        changes: {
          gameDetailsList: {
            byId: {
              ...gameDetailsList.byId,
              [payload.id]: payload,
            },
            allIds: [...gameDetailsList.allIds, payload.id],
          },
        },
      });
    });

    builder.addCase(fetchGameDetails.rejected, (state, action) => {
      state.isProcessing = false;
      state.processingName = null;
    });
  }
});

export const { updateGameDetails } = stagesSlice.actions;

export default stagesSlice;
