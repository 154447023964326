import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

import { Organizer } from '../domain/interfaces/Organizer';
import { Team } from '../../teams/domain/interfaces/Team';
import { TeamPlayer } from 'modules/teams/domain/interfaces/TeamPlayer';
import { UpsertOrganizerInput } from '../domain/interfaces/UpsertOrganizerInput';
import { UpsertTournamentTeamDto } from '../../teams/domain/dtos/UpsertTournamentTeam.dto';
import _ from 'lodash';
import { backgroundColors } from 'shared/constants/backgrounds';
import { format } from 'date-fns';
import i18n from 'i18n';
import organizerRepository from '../domain/repositories/organizatorRepository';
import organizerResource from '../domain/resources/organizerResource';
import supabase from '../../core/supabase/supabase';
import teamsResource from '../../teams/domain/resources/teamsResource';
import { toast } from 'react-toastify';

const PREFIX = 'organizer';

export const fetchOrganizer = createAsyncThunk<Organizer | null, { tournamentId: number; }>(
  `${PREFIX}/fetchOrganizer`,
  async (payload, { rejectWithValue }) => {
    try {

      const results = await Promise.allSettled([
        organizerRepository.fetchOrganizer(payload.tournamentId),
        organizerRepository.fetchAvailableFeature(payload.tournamentId),
        organizerRepository.fetchActivePaidSubscription(payload.tournamentId),
        supabase.organizerLogoBacking.get({ tournamentId: payload.tournamentId })
      ]);

      const [organizerResult, featuresResult, planResult, backgroundResult] = results;

      if (organizerResult.status === 'rejected') {
        console.error(`${PREFIX}/fetchOrganizer error:`, organizerResult.reason);
        return rejectWithValue('Failed to fetch organizer data');
      }

      const organizer = organizerResult.value;
      if (!organizer) return null;

      organizer.availableFeatures = featuresResult.status === 'fulfilled' ? featuresResult.value : {
        MEDIA_MANAGER: false,
        ACTIVE_CHAMPIONSHIPS: false,
        JOYSTICK: false,
        OBS_INTEGRATION: false
      };
      organizer.tournamentPlan = planResult.status === 'fulfilled' ? planResult.value : null;

      organizer.backgroundColor = backgroundResult.status === 'fulfilled' ? backgroundColors[backgroundResult?.value?.type ?? 0] : backgroundColors[0];

      if (featuresResult.status === 'rejected') {
        console.error(`${PREFIX}/fetchFeatures error:`, featuresResult.reason);
      }
      if (planResult.status === 'rejected') {
        console.error(`${PREFIX}/fetchPlan error:`, planResult.reason);
      }
      if (backgroundResult.status === 'rejected') {
        console.error(`${PREFIX}/fetchBackground error:`, backgroundResult.reason);
      }

      return organizer;
    } catch (error) {
      console.error(`${PREFIX}/fetchOrganizers error:`, error);
      return rejectWithValue(error instanceof Error ? error.message : String(error));
    }
  },
);

export const upsertOrganizer = createAsyncThunk<Organizer, UpsertOrganizerInput>(
  `${PREFIX}/upsertOrganizer`,
  async (payload) => {
    const {
      id,
      name,
      description,
      phone,
      email,
      address,
      sportType,
      adminUserId,
      logo,
      background,
    } = payload;

    try {
      let organizer = await organizerRepository.upsertOrganizer({
        id,
        name,
        description,
        phone,
        email,
        address,
        sportType,
        adminUserId,
      });

      let backgroundUpdateResult = false;

      if (background) {
        const backgroundType = parseInt(_.findKey(backgroundColors, background) ?? '')
        if (!isNaN(backgroundType) && organizer.id) {
          const result = await supabase.organizerLogoBacking.update({ tournamentId: organizer.id, type: backgroundType });
          backgroundUpdateResult = result;
        }
      }

      if (logo) {
        await organizerResource.uploadLogo(organizer.id, logo);
        const updated = await organizerRepository.fetchOrganizer(organizer.id);
        if (updated) {
          organizer = updated;
        }
      }

      toast.success(i18n.t('settings.saveSettingsSuccess'));

      return { ...organizer, backgroundColor: backgroundUpdateResult ? background : undefined };
    } catch (err) {
      console.error(`${PREFIX}/upsertOrganizer error:`, err);
      toast.error(i18n.t('settings.saveSettingsInternalError'));

      throw err;
    }
  },
);

export const resetOrganizer = createAction(`${PREFIX}/resetOrganizer`);

export const createTeam = createAsyncThunk<Team, UpsertTournamentTeamDto>(
  `${PREFIX}/createTournamentTeam`,
  async (payload) => {
    try {
      const team = { name: payload.name, tournamentId: payload.tournamentId }
      const teamData = await organizerRepository.upsertTeam(team);
      const teamId = teamData.id;

      if (payload.logo) {
        const res = await teamsResource.uploadLogo(payload.logo, teamId);
        teamData.logo = { id: res.id, url: res.emblem };
      }

      teamData.createdAt = format(new Date(), 'dd.MM.yyyy');

      return teamData;
    } catch (err) {
      console.error(`${PREFIX}/createTournamentTeam error:`, err);
      throw err;
    }
  },
);

export const editTeam = createAsyncThunk<Team, UpsertTournamentTeamDto>(
  `${PREFIX}/editTournamentTeam`,
  async (payload) => {
    try {
      const team = { id: payload.id, name: payload.name, tournamentId: payload.tournamentId }
      const teamData = await organizerRepository.upsertTeam(team);
      const teamId = teamData.id;

      if (payload.logo !== undefined) {
        const res = await teamsResource.uploadLogo(payload.logo, teamId);
        teamData.logo = { id: res.id, url: res.emblem };
      }

      return teamData;
    } catch (err) {
      console.error(`${PREFIX}/editTournamentTeam error:`, err);
      throw err;
    }
  },
);

export const fetchTeamPlayers = createAsyncThunk<TeamPlayer[], { teamId: number }>(
  `${PREFIX}/fetchTeamPlayers`,
  async (payload, { rejectWithValue }) => {
    try {
      return await organizerRepository.fetchTeamPlayers(payload.teamId);
    } catch (error) {
      console.error(`${PREFIX}/fetchTeamPlayers error:`, error);

      let message
      if (error instanceof Error) message = error.message
      else message = String(error)
      return rejectWithValue(message)
    }
  },
);
