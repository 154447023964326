import graphqlResource, { GraphqlResource } from '../../../core/graphqlResource';

import { ApplicationDTO } from '../../../applications/domain/interfaces/ApplicationDTO';
import { ApplicationStatus } from 'modules/applications/domain/enums/ApplicationStatus';
import { PlayerApplication } from '../interfaces/PlayerApplication';
import { TeamDTO } from '../dtos/Team.dto';
import { UpsertChampionshipApplicationDto } from '../dtos/UpsertChampionshipApplication.dto';
import { UpsertChampionshipApplicationResponseDto } from '../dtos/UpsertChampionshipApplicationResponse.dto';
import { UpsertChampionshipApplicationUserDto } from '../dtos/UpsertChampionshipApplicationUser.dto';
import {
  UpsertChampionshipApplicationUserResponseDto,
} from '../dtos/UpsertChampionshipApplicationUserResponse.dto';
import { mapAnnouncedChampionshipTeams } from '../data-mappers/announced-teams.mapper';

export class TeamsRepository {
  constructor(private readonly graphql: GraphqlResource) {
  }

  private async getChampionshipTeams(championshipId: number): Promise<TeamDTO[]> {
    const query = `#graphql
      query getChampionshipTeams($championshipId: Int!) {
        championship(id: $championshipId) {
          teams {
            id
            name
            createdAt
            emblem {
              id
              externalUrl
            }
            members {
              id
              position
              number
              user {
                id
                name
                surname
                middleName
                birthDate
                photo {
                  id
                  externalUrl
                }
              }
            }
          }
        }
      }
    `;

    const variables = { championshipId };
    const response = await this.graphql.query<{ data: { championship: { teams: TeamDTO[]; }; }; }>(query, variables);
    const { teams } = response.data.data.championship;

    return teams;
  }

  private async getChampionshipApplications(championshipId: number): Promise<ApplicationDTO[]> {
    const query = `#graphql
      query getChampionshipAnnouncedTeams($championshipId: Int!) {
        championship(id: $championshipId) {
          championshipApplications {
            id
            team {
              id
              name
              emblem {
                id
                externalUrl
              }
            }
            position
            number
            user {
              id
              name
              surname
              middleName
              birthDate
              photo {
                id
                externalUrl
              }
            }
            state
            createdAt
            updatedAt
          }
        }
      }
    `;

    const variables = { championshipId };
    const response = await this.graphql.query<{ data: { championship: { championshipApplications: ApplicationDTO[] }; }; }>(query, variables);
    const { championshipApplications } = response.data.data.championship;

    return championshipApplications;
  }

  public async getAnnouncedChampionshipTeams(championshipId: number) {
    const teams = await this.getChampionshipTeams(championshipId);
    const championshipApplications = await this.getChampionshipApplications(championshipId);

    return mapAnnouncedChampionshipTeams(
      championshipId,
      teams.filter(Boolean),
      championshipApplications.filter(Boolean),
    );
  }

  public async upsertChampionshipApplicationForUser(params: UpsertChampionshipApplicationUserDto): Promise<PlayerApplication> {
    const query = `#graphql
      mutation upsertChampionshipApplicationUser($input: ChampionshipApplicationOneRowInput!) {
        upsertChampionshipApplicationUser(input: $input) {
          id
          team { id }
          championship { id }
          user {
            id
            name
            surname
            middleName
            birthDate
            photo {
              id
              externalUrl
            }
            height
            weight
          }
          state
          position
          number
          createdAt
        }
      }
    `;

    const { id, ...input } = params;
    const variables = { input };

    const response = await this.graphql.query<UpsertChampionshipApplicationUserResponseDto>(query, variables);
    const { upsertChampionshipApplicationUser: upsertedApplication } = response.data.data;

    return {
      id: upsertedApplication.id,
      teamId: upsertedApplication.team.id,
      championshipId: upsertedApplication.championship.id,
      player: {
        id: upsertedApplication.user.id,
        firstName: upsertedApplication.user.name,
        lastName: upsertedApplication.user.surname,
        middleName: upsertedApplication.user.middleName,
        birthDate: upsertedApplication.user.birthDate,
        logoUrl: upsertedApplication.user.photo?.externalUrl,
      },
      status: upsertedApplication.state,
      position: upsertedApplication.position,
      number: upsertedApplication.number,
      createdAt: upsertedApplication.createdAt,
    };
  }

  public async upsertChampionshipApplication(
    params: UpsertChampionshipApplicationDto
  ) {
    const query = `#graphql
      mutation upsertChampionshipApplication($input: ChampionshipApplicationInput!) {
        upsertChampionshipApplication(input: $input) {
          team {
            id
            name
          }
          championship {
            id
            name
          }
          users {
            id
            name
            surname
            middleName
            birthDate
            photo {
              id
              externalUrl
            }
          }
        }
      }
    `;

    const variables = {
      input: {
        teamId: params.teamId,
        championshipId: params.championshipId,
        createUserOrUpdateApplications: params.applications.map(application => ({
          userId: application.userId,
          name: application.name,
          surname: application.surname,
          middleName: application.middleName,
          phone: application.phone,
          birthDate: application.birthDate,
          height: application.height,
          weight: application.weight,
          number: application.number,
          position: application.position?.toUpperCase(),
          state: application.state
        }))
      }
    };

    const response = await this.graphql.query<UpsertChampionshipApplicationResponseDto>(query, variables);
    const { upsertChampionshipApplication: upsertedApplication } = response.data.data;

    const users = upsertedApplication.users.map(user => ({
      ...user,
      state: params.applications.find(application => application.userId === user.id)?.state
    }))

    const state = users.some(user => user.state === ApplicationStatus.ACCEPTED)
      ? ApplicationStatus.ACCEPTED
      : users.every(user => user.state === ApplicationStatus.DECLINED)
        ? ApplicationStatus.DECLINED
        : ApplicationStatus.PENDING;

    return {
      championshipId: upsertedApplication.championship.id,
      team: { ...upsertedApplication.team, state },
      users,
    };
  }
}

const teamsRepository = new TeamsRepository(graphqlResource);

export default teamsRepository;
