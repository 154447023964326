import UDText, { UDTextType } from "../../../../../../ud-ui/components/text";

import { DivProps } from "../../../../../../../typings";
import { MainBlock } from "./styles";

type IProps = DivProps & {
  header: {
    text: string
    type?: UDTextType
  }
}

function HeaderItem(props: IProps) {
  const { header, children, ...rest } = props
  const { text, type = 'subhead' } = header

  return (
    <MainBlock {...rest}>
      <UDText style={{ textWrap: 'nowrap' }} type={type} className="color-SurfaceRomanSilver30">{text}</UDText>
      {children}
    </MainBlock>
  );
}

export default HeaderItem;
