import { SVGProps } from "react";

type IProps = {
    width?: number;
    height?: number;
} & SVGProps<SVGSVGElement>;

const KazakhstanIcon = (props: IProps) => {
    const { width, height, ...rest } = props;

    return (
        <svg width={width ?? 24} height={height ?? 24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
            <g clipPath="url(#clip0_15414_22471)">
                <path d="M19 5.5H5C4.46957 5.5 3.96086 5.71071 3.58579 6.08579C3.21071 6.46086 3 6.96957 3 7.5L3 16.5C3 17.0304 3.21071 17.5391 3.58579 17.9142C3.96086 18.2893 4.46957 18.5 5 18.5H19C19.5304 18.5 20.0391 18.2893 20.4142 17.9142C20.7893 17.5391 21 17.0304 21 16.5V7.5C21 6.96957 20.7893 6.46086 20.4142 6.08579C20.0391 5.71071 19.5304 5.5 19 5.5Z" fill="#4AADD6" />
                <path d="M10.3999 16C12.609 16 14.3999 14.2091 14.3999 12C14.3999 9.79086 12.609 8 10.3999 8C8.19076 8 6.3999 9.79086 6.3999 12C6.3999 14.2091 8.19076 16 10.3999 16Z" fill="#FFDE00" />
            </g>
            <defs>
                <clipPath id="clip0_15414_22471">
                    <rect width="18" height="18" fill="white" transform="translate(3 3)" />
                </clipPath>
            </defs>
        </svg>

    );
};

export default KazakhstanIcon;
