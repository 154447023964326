import { getAvailableTournaments, switchUserTournament } from '../../../auth/store/actions';

import { AppDispatch } from 'store/store';
import { OrganizerForm } from 'modules/organizer/ui/components/organizer-form';
import { OrganizerUpsertPayload } from 'modules/organizer/domain/interfaces/OrganizerUpsertPayload';
import UDHeaderTitle from '../../../ud-ui/components/header-title';
import UDMainLayout from "modules/ud-ui/layout/main";
import UDText from 'modules/ud-ui/components/text';
import { UpsertOrganizerInput } from 'modules/organizer/domain/interfaces/UpsertOrganizerInput';
import { upsertOrganizer } from 'modules/organizer/store/actions';
import { useAuth } from 'modules/auth/ui/hooks/useAuth';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

const OrganizerCreatePage = () => {
    const dispatch = useDispatch<AppDispatch>()
    const { user } = useAuth();

    const createOrganizer = useCallback((data: OrganizerUpsertPayload) => {
        const { logo, ...restData } = data;

        const params = {
            ...restData,
            adminUserId: user!.id,
            sportType: data.sportType.toString().toUpperCase(),
            logo: logo?.img instanceof File ? logo.img : undefined,
            background: logo?.background,
        } as UpsertOrganizerInput;

        dispatch(upsertOrganizer(params)).unwrap().then(async (result) => {
            await dispatch(getAvailableTournaments());
            dispatch(switchUserTournament(result.id));
        });
    }, [dispatch, user]);

    return (
        <UDMainLayout headerContent={<UDHeaderTitle>Создание организатора</UDHeaderTitle>}>
            <div className='container py-6'>
                <UDText type="headline" className="mr-10">
                    Информация об организаторе
                </UDText>
            </div>
            <div className="container d-flex align-items-center bb-1" />
            <div className='container py-6'>
                <OrganizerForm
                    onSubmit={createOrganizer}
                />
            </div>
        </UDMainLayout >
    );
};

export default OrganizerCreatePage;
