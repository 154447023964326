import * as yup from 'yup';

import { AuthRequestAuthCodePayload } from '../interfaces/AuthRequestAuthCodePayload';
import { TFunction } from 'i18next';
import { validPhoneRegex } from 'modules/ud-form/components/input/domain/validators/phone';

export const authRequestCodeValidator = (
  t: TFunction
): yup.SchemaOf<AuthRequestAuthCodePayload> =>
  yup.object().shape({
    phone: yup
      .string()
      .required(t('signIn.validationError.phoneRequired'))
      .matches(validPhoneRegex, t('signIn.validationError.phoneInvalid')),
  });
