import { DivProps } from 'typings';
import styled from '@emotion/styled';

const CategorySkeletonCard = styled.div`
  border-radius: 5px;
  width: 100%;
  height: 72px;
  margin-bottom: 16px;
`;

export const CategorySkeleton = (props: { count: number } & DivProps) => {
    const items = new Array(props.count).fill('');

    return (
        <div {...props}>
            {items.map((_, index) => (
                <CategorySkeletonCard className="skeleton-block" key={index} />
            ))}
        </div>
    )
};
