import { ChangeEvent, useCallback } from 'react';

import { FilterBlock } from './styles';
import UDInput from 'modules/ud-form/components/input/component';

type FilterProps = {
    value?: string;
    inputPlaceholder?: string;
    onChange?: (value: string) => void;
    className?: string;
} & Omit<React.HTMLAttributes<HTMLDivElement>, 'onChange'>;

function UDFilter(props: FilterProps) {
    const { value = '', inputPlaceholder, onChange, ...rest } = props;

    const handleOnChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        onChange?.(event.target.value);
    }, [onChange]);

    return (
        <FilterBlock {...rest}>
            <div style={{ width: 450 }}>

                <UDInput
                    style={{
                        border: '1px solid #E7E7E7',
                    }}
                    value={value}

                    placeholder={inputPlaceholder}
                    iconProps={{
                        position: 'icon-left',
                        name: 'search',
                        size: 20,
                        componentProps: {
                            style: {
                                color: '#8F8E99',
                            },
                        },
                    }}
                    onChange={handleOnChange}
                />
            </div>

            {/* <div className='d-flex'>
                {options.map((item) => {
                    const isActive = item.status === selectedStatus;
                    const statusString = item.status as any as string;

                    return (
                        <FilterButton
                            key={statusString}
                            className={classNames({ active: isActive })}
                            onClick={handleSelect(item.status)}
                        >
                            <span className='ml-2 text subhead'>
                                {t(`applications.statusFilter.${item.status}`)}
                                {countByStatus && countByStatus[statusString] !== undefined && (
                                    <span
                                        className='ml-1 text'
                                        style={{ color: item.color }}
                                    >({countByStatus[statusString]})</span>
                                )}
                            </span>
                        </FilterButton>
                    )
                })}
            </div> */}
        </FilterBlock>
    );
}



export default UDFilter;
