import { GameDetailsTeam } from 'modules/games/domain/interfaces/GameDetails';
import TeamLogo from 'modules/ud-ui/components/team-logo';
import { UDFormComponentProps } from 'modules/ud-form/components/types';
import UDFormInput from 'modules/ud-form/components/input';
import { UDInputProps } from 'modules/ud-form/components/input/component';
import UDText from 'modules/ud-ui/components/text';
import styled from '@emotion/styled';

const TeamScoreCardContainer = styled.div`
  display: flex;
  justify-content: space-between;
  background: #FFF;
  border-radius: 8px;
  padding: 8px 16px 8px 8px;
  margin-top: 16px;
`;

const TeamInfoWrapper = styled.div`
  display: flex;
  align-items: center;

  .team-logo {
    margin-right: 8px;
  }
`;

const TeamScoreInputWrapper = styled.div`
  width: 120px;

  .team-score-input {
    background: #F4F4F5;
  }
`;

type TeamScoreCardProps = { team: GameDetailsTeam; } & UDFormComponentProps & Omit<UDInputProps, 'hasError' | 'valid' | 'ref'>;

export const TeamScoreCard = (props: TeamScoreCardProps) => {
  const {
    team,
    ...otherProps
  } = props;

  return (
    <TeamScoreCardContainer>
      <TeamInfoWrapper>
        <TeamLogo
          className='team-logo ml-2'
          url={team.logo}
        />
        <UDText type="subhead">{team.name}</UDText>
      </TeamInfoWrapper>
      <TeamScoreInputWrapper>
        <UDFormInput
          className='team-score-input'
          type='number'
          min={0}
          {...otherProps}
        />
      </TeamScoreInputWrapper>
    </TeamScoreCardContainer>
  );
}