import { fetchLocations, fetchLocationsByFilter } from "modules/locations/store/actions";

import { AppDispatch } from "store/store";
import { setFilter } from "modules/locations/store";
import { useCallback } from "react";
import { useDispatch } from "react-redux";
import useLocations from "modules/locations/ui/hooks/useLocations";

export const useLocationList = () => {
    const dispatch = useDispatch<AppDispatch>();
    const { isLoading, isLoaded, locations, tournamentId, locationLoadedLimit, allDataLoaded, filter } = useLocations();

    // Загрузка при изменении фильтра
    // useEffect(() => {
    //   if (!info) return;
    //   if (!tournamentId) return;
    //   if (isLoading) return;

    //   if (filter.text) {
    //     dispatch(fetchLocationsByFilter({
    //       nameFilter: filter.text,
    //       tournamentId,
    //       limit: locationLoadedLimit
    //     }));
    //   } else if (!isLoaded) {
    //     dispatch(fetchLocations({
    //       tournamentId,
    //       limit: locationLoadedLimit
    //     }));
    //   }
    // }, [info, dispatch, isLoaded, isLoading, filter.text, tournamentId, locationLoadedLimit]);

    // Подгрузка дополнительных данных
    // useEffect(() => {
    //   if (!info) return;
    //   if (!tournamentId) return;
    //   if (isLoading) return;
    //   if (filter.text) {
    //     if (filter.locations.length === 0) return;
    //     if (filter.allDataLoaded) return;
    //   } else {
    //     if (locations.length === 0) return;
    //     if (allDataLoaded) return;
    //   }

    //   const shouldLoadMore = pages < 10 || (pages >= 10 && currentPage === pages);

    //   if (shouldLoadMore) {
    //     if (filter.text) {
    //   dispatch(fetchLocationsByFilter({
    //     nameFilter: filter.text,
    //     tournamentId,
    //     limit: locationLoadedLimit,
    //     offset: filter.locations.length
    //   }));
    //     } else {
    //       dispatch(fetchLocations({
    //         tournamentId,
    //         limit: locationLoadedLimit,
    //         offset: locations.length
    //       }));
    //     }
    //   }
    // }, [
    //   info,
    //   tournamentId,
    //   allDataLoaded,
    //   filter.allDataLoaded,
    //   dispatch,
    //   locationLoadedLimit,
    //   locations.length,
    //   filter.locations.length,
    //   isLoading,
    //   pages,
    //   filter.text,
    //   currentPage
    // ]);

    // const changeFilter = useCallback((changes: { search: string }) => {
    //   if (!info) return
    //   const { search } = changes
    //   dispatch(setFilter({ text: search }))
    //   if (search) {
    //     dispatch(fetchLocationsByFilter({ nameFilter: search, tournamentId: info.id, limit: locationLoadedLimit }))
    //   } else {
    //     dispatch(resetFilter())
    //   }
    // }, [dispatch, info, locationLoadedLimit]);

    // const handlePageChange = useCallback((page: number) => {
    //   dispatch(setCurrentPage({ currentPage: page }));
    // }, [dispatch]);

    // const handlePerPageChange = useCallback((value: number) => {
    //   dispatch(setLocationsPerPage({ locationsPerPage: value }));
    // }, [dispatch]);

    // useEffect(() => {
    //   return () => {
    //     dispatch(resetFilter())
    //   };
    // }, [dispatch]);

    const loadNextPage = useCallback(() => {
        if (isLoading) return;
        if (isLoaded) return;
        if (locations.length === 0) return;
        if (!tournamentId) return;
        if (allDataLoaded) return;

        dispatch(fetchLocations({
            tournamentId,
            limit: locationLoadedLimit,
            offset: locations.length
        }));
    }, [isLoading, isLoaded, locations.length, tournamentId, allDataLoaded, dispatch, locationLoadedLimit]);

    const onChangeFilter = useCallback((value: string) => {
        if (!tournamentId) return;

        if (filter.text === value) return
        dispatch(setFilter({ text: value }))

        if (value) {
            dispatch(fetchLocationsByFilter({ nameFilter: value, tournamentId, limit: locationLoadedLimit }))
        }
    }, [dispatch, filter.text, locationLoadedLimit, tournamentId]);

    return {
        isLoading: isLoading || filter.isLoading,
        isLoaded,
        locations,
        loadNextPage,
        onChangeFilter,
        filteredLocations: Boolean(filter.text) ? filter.locations : null
    }
}
