import {
    fetchEntryRequests,
    updateEntryRequest
} from './actions';

import { ActionReducerMapBuilder } from '@reduxjs/toolkit';
import { EntryRequestsSliceState } from './index';

export const createGetEntryRequests = (
    builder: ActionReducerMapBuilder<EntryRequestsSliceState>
) => {
    builder
        .addCase(fetchEntryRequests.pending, (state) => {
            state.isLoading = true;
        })
        .addCase(fetchEntryRequests.fulfilled, (state, action) => {
            state.isLoading = false;
            state.items = action.payload;
        })
        .addCase(fetchEntryRequests.rejected, (state) => {
            state.isLoading = false;
        });
};

export const createUpdateEntryRequest = (
    builder: ActionReducerMapBuilder<EntryRequestsSliceState>
) => {
    builder
        .addCase(updateEntryRequest.pending, (state) => {
            state.isProcessing = true;
            state.isProcessed = false;
            state.processedName = 'updateEntryRequest';
        })
        .addCase(updateEntryRequest.fulfilled, (state, action) => {
            state.isProcessing = false;
            state.isProcessed = true;

            const { id, status } = action.payload;
            const request = state.items.find(item => item.id === id);
            if (request) {
                request.status = status;
            }
        })
        .addCase(updateEntryRequest.rejected, (state) => {
            state.isProcessing = false;
            state.isProcessed = false;
            state.processedName = null;
        });


};
