import * as yup from "yup";

export const applicationLinkValidator = yup.object().shape({
    name: yup.string().required("Имя обязательно"),
    surname: yup.string().required("Фамилия обязательна"),
    patronymic: yup.string().optional(),
    birthday: yup
      .string()
      .matches(
        /^(0[1-9]|[12]\d|3[01])\.(0[1-9]|1[0-2])\.(19\d{2}|20\d{2})$/,
        'Дата рождения должна быть в формате: число.месяц.год',
      )
      .required('Обязательное поле'),
    consent: yup.boolean().oneOf([true], "Я даю согласие на обработку персональных данных обязателен"),
});
