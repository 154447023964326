import { DivProps } from "../../../../typings";
import React from "react";
import UDInput from "../../../ud-form/components/input/component";
import classNames from "classnames";

interface IProps extends Omit<DivProps, 'placeholder' | 'onChange' | 'onBlur' | 'onSubmit'> {
  value?: string
  placeholder: string
  onChange?: (event: React.FocusEvent<HTMLInputElement, Element>) => void
  onBlur?: (event: React.FocusEvent<HTMLInputElement, Element>) => void
  onSubmit?: (value: string) => void

  width?: string
  backgroundColor?: string
  inputRef?: React.RefObject<HTMLInputElement>
}

function UDSearchComponent(props: IProps) {
  const {
    value,
    placeholder,
    onChange,
    width,
    backgroundColor,
    inputRef,
    onBlur,
    autoFocus,
    onSubmit,
    ...rest
  } = props

  return (
    <div className={classNames('d-flex align-items-center justify-content-between')} {...rest}>
      <div style={{ width: width ? width : '100%' }}>
        <UDInput
          autoFocus={autoFocus}
          style={{ backgroundColor }}
          value={value}
          placeholder={placeholder}
          iconProps={{
            position: 'icon-right',
            name: 'search',
          }}
          onChange={onChange}
          onSubmit={event => onSubmit?.(event.currentTarget.value)}
          onBlur={event => onBlur?.(event)}
          ref={inputRef}
        />
      </div>
    </div>
  );
}

export default UDSearchComponent;
