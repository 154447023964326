/** @jsxImportSource @emotion/react */

import React, { useMemo } from 'react';

import { Pages } from 'modules/navigation/domain/enums/pages';
import TourneyComponentsEditForm from '../../components/edit-form';
import TourneysComponentsEditFormTabs from '../../components/edit-form/tabs';
import UDBreadcrumbs from 'modules/ud-ui/components/breadcrumbs';
import UDMainLayout from 'modules/ud-ui/layout/main';
import { announcedChampionshipTeamsDataSelector } from '../../../../teams/store/championshipTeams/selectors';
import { useChampionship } from '../../hooks/use-championship';
import { useChampionshipFetch } from '../../hooks/use-championship-fetch';
import { useMatch } from 'react-router-dom';
import { useSelector } from 'react-redux';

const TourneyPagesEditPage = () => {
  useChampionshipFetch();

  const { data: tourney } = useChampionship();
  const champTeamsData = useSelector(announcedChampionshipTeamsDataSelector);

  const applicationsPageMatch = useMatch(`${Pages.TOURNEYS.EDIT}/applications/*`);
  const applicationPlayersPageMatch = useMatch(`${Pages.TOURNEYS.EDIT}/applications/:teamId`);
  const teamPlayersPageRules = useMatch(`${Pages.TOURNEYS.EDIT}/rules`);
  const teamPlayersPageSteps = useMatch(`${Pages.TOURNEYS.EDIT}/steps`);
  const teamPlayersPageSymbolicTeams = useMatch(`${Pages.TOURNEYS.EDIT}/symbolic-teams`);

  const breadcrumbsItems = useMemo(
    () => {
      const items = [{ label: 'Турниры', to: Pages.TOURNEYS.INDEX }];

      if (!tourney) {
        return items;
      }

      items.push({
        label: tourney.name,
        to: Pages.TOURNEYS.builders.edit(tourney.id),
      });

      if (applicationsPageMatch) {
        items.push({
          label: 'Заявки',
          to: `/tourneys/${tourney.id}/applications`,
        });
      }

      if (teamPlayersPageRules) {
        items.push({
          label: 'Условия',
          to: Pages.TOURNEYS.builders.edit(tourney.id, 'rules'),
        });
      }

      if (teamPlayersPageSteps) {
        items.push({
          label: 'Этапы',
          to: Pages.TOURNEYS.builders.edit(tourney.id, 'steps'),
        });
      }

      if (teamPlayersPageSymbolicTeams) {
        items.push({
          label: 'Сборные',
          to: Pages.TOURNEYS.builders.edit(tourney.id, 'symbolic-teams'),
        });
      }

      if (
        applicationPlayersPageMatch &&
        applicationPlayersPageMatch.params.teamId &&
        champTeamsData
      ) {
        const teamId = parseInt(applicationPlayersPageMatch.params.teamId);
        const champTeam = champTeamsData[teamId];

        if (champTeam) {
          items.push({
            label: champTeam.name,
            to: `/tourneys/${tourney.id}/applications/${champTeam.id}`,
          });
        }
      }

      return items;
    },
    [champTeamsData, applicationPlayersPageMatch, teamPlayersPageRules, teamPlayersPageSteps, teamPlayersPageSymbolicTeams, applicationsPageMatch, tourney],
  );

  const breadcrumbs = tourney ? (
    <UDBreadcrumbs className='my-10' breadcrumbs={breadcrumbsItems} />
  ) : null;

  return (
    <UDMainLayout headerContent={breadcrumbs}>
      {tourney && (
        <>
          <div className='container py-6'>
            <TourneysComponentsEditFormTabs tourney={tourney} />
          </div>
          <TourneyComponentsEditForm tourney={tourney} onSubmit={() => { }} />
        </>
      )}
    </UDMainLayout>
  );
};

export default TourneyPagesEditPage;
