import './i18n';
import './index.css';

import App from './App';
import React from 'react';
import { createRoot } from 'react-dom/client';

const container = document.getElementById('root');
const root = createRoot(container!); // createRoot(container!) if you use TypeScript
root.render(
  // <React.StrictMode>
  <App />
  // </React.StrictMode>,
);

// ReactDOM.render(
//   <React.StrictMode>
//     <App/>
//   </React.StrictMode>,
//   document.getElementById('root'),
// );
export { filteringBySearch } from "./modules/organizers/domain/utils/filteringBySearch";
