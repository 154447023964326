import { Button } from './styles';
import React from 'react';
import classNames from 'classnames';
import { useAsyncButton } from '../../hooks/useAsyncButton';

export type ButtonVariant = 'primary' | 'secondary' | 'dark' | 'light' | 'gray';
export type ButtonTertiaryVariant = 'tertiary';
export type ButtonSubVariant = 'for-dark' | 'for-light';

// Базовые пропсы без onClick
type BaseButtonProps = {
  slim?: boolean;
  children: React.ReactNode;
} & Omit<
  React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  >,
  'children' | 'onClick'
>;

// Пропсы для синхронного обработчика
type SyncHandlerProps = {
  type?: 'button';
  onClick?: (...args: any[]) => void;
  loading?: boolean;
};

// Пропсы для асинхронного обработчика
type AsyncHandlerProps = {
  type?: 'button';
  onClick: (...args: any[]) => Promise<any>;
  loading: boolean;
};

// Пропсы для кнопки отправки формы
type SubmitButtonProps = {
  type: 'submit';
  onClick?: never;
  loading: boolean; // для submit всегда требуем loading, так как обработка формы обычно асинхронная
};

type ButtonCommonProps = BaseButtonProps & (SyncHandlerProps | AsyncHandlerProps | SubmitButtonProps);

type TertiaryVariantProps = {
  variant: ButtonTertiaryVariant;
  subVariant: ButtonSubVariant;
} & ButtonCommonProps;

type CommonVariantProps = {
  variant: ButtonVariant;
  subVariant?: undefined;
} & ButtonCommonProps;

export type UDButtonProps = TertiaryVariantProps | CommonVariantProps;

const UDButton = (props: UDButtonProps) => {
  const {
    slim,
    loading: externalLoading,
    className,
    variant,
    subVariant,
    onClick,
    disabled,
    type = 'button',
    ...otherProps
  } = props;

  const { isLoading, handleClick } = useAsyncButton(type === 'submit' ? undefined : onClick);
  const isDisabled = disabled || isLoading || externalLoading;

  const buttonClassName = classNames(
    variant,
    subVariant,
    className,
    {
      slim,
      loading: isLoading || externalLoading
    },
    'text subhead'
  );

  return (
    <Button
      {...otherProps}
      type={type}
      className={buttonClassName}
      onClick={type === 'submit' ? undefined : handleClick}
      disabled={isDisabled}
    >
      {props.children}
    </Button>
  );
};

export default UDButton;
