import { ReactElement, useCallback, useEffect, useLayoutEffect, useMemo, useState, useTransition } from 'react';
import { getItemsOnPage, getPagesNum } from 'shared/components/category/utils';

import CategoryList from 'shared/components/category/components/list/category-list';
import { CategoryListContextProvider } from 'shared/components/category/hooks/use-category-list-context';
import { CategoryListContextType } from '../hooks/use-category-list-context';
import { CategorySkeleton } from '../components/list/category-skeleton';
import EmptyList from 'shared/components/category/components/list/empty-list';
import ListPageWrapper from 'shared/components/category/pages/list-page-wrapper';
import { UDListItemProps } from 'modules/ud-ui/components/list/components/list-item';
import { useLocalStorage } from 'shared/hooks/useLocalStorage';
import { useQueryParams } from '../../../hooks/useQueryParams';

function CategoryListPage(props: IProps) {
    const {
        getFiltered,
        getNextPages,
        onCreateClick,
        children: items = [],
        isLoading,
        categoryName,
        text
    } = props

    const [params, setParams] = useQueryParams({
        page: {
            key: 'page',
            defaultValue: 1,
            parser: Number,
        },
        filter: {
            key: 'filter',
            defaultValue: '',
        }
    });

    const [storage, setStorage] = useLocalStorage({
        perPage: {
            key: `${categoryName}PerPage`,
            defaultValue: 10,
            parser: Number,
        },
    })

    useLayoutEffect(() => {
        getFiltered?.(params.filter)
    }, [getFiltered, params.filter])

    const totalPages = useMemo(() => {
        const pages = getPagesNum(items.length, storage.perPage);
        if (params.page > pages) setParams({ page: Math.max(pages, 1) });
        return pages
    }, [items.length, storage.perPage])

    const onPageChange = useCallback((page: number) => {
        setParams({ page })
        if (totalPages === page) {
            getNextPages?.()
        }
    }, [getNextPages, setParams, totalPages])

    const onFilterChange = useCallback((value: string) => {
        setParams({ filter: value, page: 1 })
        getFiltered?.(value)
    }, [getFiltered, setParams])

    const onPerPageChange = useCallback((perPage: number) => {
        setStorage({ perPage })
    }, [setStorage])

    const contextValue: CategoryListContextType = useMemo(() => {
        return {
            buttonProps: {
                text: text?.addButtonText ?? `Добавить`,
                variant: `dark`
            },
            textProps: {
                title: params.filter ? 'Ничего не найдено' : `Список пуст`,
                subtitle: params.filter ? `Попробуйте изменить ваш поиск` : '',
            },
            styleProps: {
                containerClassName: 'p-4',
                textClassName: 'my-2',
                buttonClassName: 'mt-3'
            },
            isFilterActive: Boolean(params.filter),
            filter: params.filter,
            onCreateClick: onCreateClick,
        }
    }, [onCreateClick, params.filter])

    const itemsOnPage = useMemo(() => {
        return getItemsOnPage(items, params.page, storage.perPage)
    }, [items, params.page, storage.perPage])



    const renderContent = () => {
        if (isLoading) {
            return <CategorySkeleton count={storage.perPage} className='my-auto' />;
        }

        if (itemsOnPage.length === 0) {
            return <EmptyList />;
        }

        return (
            <CategoryList
                items={itemsOnPage}
                styleProps={contextValue.styleProps}
            />
        );
    };

    return (
        <ListPageWrapper
            pages={totalPages}
            currentPage={params.page}
            onPageChange={onPageChange}
            filterText={params.filter}
            filterInputPlaceholder={text?.searchPlaceholder ?? `Поиск`}
            onFilterChange={onFilterChange}
            perPage={storage.perPage}
            onChangePerPage={onPerPageChange}
        >
            <CategoryListContextProvider value={contextValue}>
                {renderContent()}
            </CategoryListContextProvider>
        </ListPageWrapper>
    )
}

export default CategoryListPage

interface IProps {
    getNextPages?: () => void
    getFiltered?: (value: string) => void
    onCreateClick?: () => void
    children?: ReactElement<UDListItemProps>[];
    isLoading?: boolean

    categoryName: string

    text?: {
        searchPlaceholder?: string
        addButtonText?: string
    }
}
