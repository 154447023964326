import { format, isBefore } from 'date-fns';

import { AnnouncedTeam } from '../interfaces/AnnouncedTeam';
import { AnnouncedTeamStatus } from '../enums/AnnouncedTeamStatus';
import { AnnouncedTeamsMap } from '../interfaces/AnnouncedTeamsMap';
import { ApplicationDTO } from '../../../applications/domain/interfaces/ApplicationDTO';
import { ApplicationStatus } from '../../../applications/domain/enums/ApplicationStatus';
import { PlayerApplication } from '../interfaces/PlayerApplication';
import { TeamDTO } from '../dtos/Team.dto';

function mapApplicationStatusToAnnouncedTeamStatus(appStatus: ApplicationStatus): AnnouncedTeamStatus {
  switch (appStatus) {
    case ApplicationStatus.DECLINED:
      return AnnouncedTeamStatus.DECLINED;
    case ApplicationStatus.ACCEPTED:
      return AnnouncedTeamStatus.ACCEPTED;
    case ApplicationStatus.PENDING:
      return AnnouncedTeamStatus.PENDING;
    default:
      return AnnouncedTeamStatus.ANNOUNCED;
  }
}

function mapChampionshipTeamToAnnouncedTeam(championshipId: number, team: TeamDTO, existingApplications: ApplicationDTO[]): AnnouncedTeam {
  const applications = (team.members || [])
    .filter((member) => !existingApplications.some((app) => app.user.id === member.user.id))
    .map((member) => ({
      id: (new Date()).getTime(),
      championshipId,
      teamId: team.id,
      status: ApplicationStatus.PENDING,
      createdAt: team.createdAt ? team.createdAt : new Date().toISOString(),
      position: member.position,
      number: member.number,
      player: {
        id: member.user.id,
        firstName: member.user.name,
        lastName: member.user.surname,
        middleName: member.user.middleName,
        birthDate: member.user.birthDate,
        logoUrl: member.user.photo?.externalUrl,
      }
    } as PlayerApplication));

  return {
    id: team.id,
    name: team.name,
    logo: team.emblem
      ? {
        id: team.emblem.id,
        url: team.emblem.externalUrl
      }
      : undefined,
    createdAt: team.createdAt ? format(new Date(team.createdAt), 'dd.MM.yyyy') : undefined,
    status: AnnouncedTeamStatus.ANNOUNCED,
    applications,
    isNew: false,
  };
}

export function mapAnnouncedChampionshipTeams(
  championshipId: number,
  teams: TeamDTO[],
  applications: ApplicationDTO[],
) {
  const result = teams.reduce<AnnouncedTeamsMap>((result, team) => ({
    ...result,
    [team.id]: mapChampionshipTeamToAnnouncedTeam(championshipId, team, applications),
  }), {});

  applications.forEach((app) => {
    const teamId = app.team.id;
    const status = app.state;
    const createdAt = new Date(app.createdAt);

    if (!result[teamId]) {
      result[teamId] = {
        id: app.team.id,
        name: app.team.name,
        logo: app.team.emblem
          ? {
            id: app.team.emblem.id,
            url: app.team.emblem.externalUrl,
          }
          : undefined,
        createdAt: app.team.createdAt ? format(new Date(app.team.createdAt), 'dd.MM.yyyy') : undefined,
        status: AnnouncedTeamStatus.ANNOUNCED,
        applications: [],
        isNew: false,
      };
    }

    const announcedTeam = result[teamId];

    const playerApplication = {
      id: app.id,
      championshipId,
      teamId,
      status,
      createdAt: createdAt.toISOString(),
      position: app.position,
      number: app.number,
      player: {
        id: app.user.id,
        firstName: app.user.name,
        lastName: app.user.surname,
        middleName: app.user.middleName,
        birthDate: app.user.birthDate,
        logoUrl: app.user.photo?.externalUrl,
      }
    } as PlayerApplication;

    announcedTeam.applications.push(playerApplication);

    if (!announcedTeam.applicationDate || isBefore(createdAt, new Date(announcedTeam.applicationDate))) {
      announcedTeam.applicationDate = createdAt.toISOString();
    }

    // Новая логика определения статуса команды
    if (announcedTeam.applications.length > 0) {
      const hasAcceptedApplications = announcedTeam.applications.some(app => app.status === ApplicationStatus.ACCEPTED);
      const allApplicationsDeclined = announcedTeam.applications.every(app => app.status === ApplicationStatus.DECLINED);
      const allApplicationsPending = announcedTeam.applications.every(app => app.status === ApplicationStatus.PENDING);

      if (allApplicationsDeclined) {
        announcedTeam.status = AnnouncedTeamStatus.DECLINED;
      } else if (hasAcceptedApplications) {
        announcedTeam.status = AnnouncedTeamStatus.ACCEPTED;
      } else if (allApplicationsPending) {
        announcedTeam.status = AnnouncedTeamStatus.PENDING;
      } else {
        announcedTeam.status = AnnouncedTeamStatus.ANNOUNCED;
      }
    }
  });

  return result;
}
