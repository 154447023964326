import { DivProps } from "../../../../typings";
import React from "react";
import UDInput from "../../../ud-form/components/input/component";
import UDText from "../text";

interface IProps extends Omit<DivProps, 'onChange'> {
  onChange: (value: number) => void;
  value: number;
}

function UDPerPage(props: IProps) {
  const { onChange, value, ...rest } = props

  return (
    <div className='col-3 d-flex align-items-center' {...rest}>
      <UDText
        type='subhead'
        className='color-SurfaceRomanSilver30 mr-2 ml-auto white-space-nowrap'
      >
        На странице:
      </UDText>
      <UDInput
        type='number'
        wrapperProps={{ className: 'w-30' }}
        min={1}
        value={value}
        onChange={(e) => onChange(Number(e.target.value))}
      />
    </div>
  );
}

export default UDPerPage;
