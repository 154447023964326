import { ApplicationLink } from '../interfaces/ApplicationLink';
import { Resource } from 'modules/core/baseResource';
import httpResource from 'modules/core/httpResource';

class ApplicationLinkResource extends Resource {
  constructor() {
    super('application-links', httpResource);
  }

  public async getApplicationLink(): Promise<ApplicationLink> {
    return this.get();
  }

  public async createApplicationLink(): Promise<ApplicationLink> {
    return this.post();
  }
}

const applicationLinkResource = new ApplicationLinkResource();
export default applicationLinkResource;