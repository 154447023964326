import CreateGameForm, { UpsertMatchParams } from '../../../../../../games/ui/components/create-game-form';
import { deleteChampionshipGame, upsertChampionshipGame } from '../../../../../store/championship/actions';
import { selectSelectedStageGroups, stageGameDetailsListSelector, stageProcessingSelector } from "../../../../../store/stages";
import { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { AppDispatch } from '../../../../../../../store/store';
import ChooseGroupForm from "../../../../../../games/ui/components/choose-group-game-form/choose-group-form";
import { Game } from '../../../../../../games/domain/interfaces/Game';
import GamesList from '../../../../../../games/ui/components/games-list/games-list';
import { StageV2 } from '../../../../../domain/interfaces/Stage';
import UDButton from '../../../../../../ud-ui/components/button';
import UDIcon from '../../../../../../ud-ui/components/icon';
import { UpsertGameDTO } from 'modules/tourneys/domain/repositories/tourneysRepository';
import { championshipDataSelector } from '../../../../../store/championship/selectors';
import { fetchGameDetails } from '../../../../../store/stages/stages.actions';
import { toast } from 'react-toastify';
import { useModal } from '../../../../../../ud-ui/components/modal/useModal';

type StageMatchesProps = {
  tournamentId: number;
  championshipId: number;
  stage: StageV2;
  onShowResultsClick?: (gameId: number) => void;
  onShowTemplatesClick?: (gameId: number) => void;
  onShowMomentsClick?: (gameId: number) => void;
};

const StageMatches = (props: StageMatchesProps) => {
  const {
    stage,
    tournamentId,
    championshipId,
    onShowTemplatesClick,
    onShowResultsClick,
    onShowMomentsClick,
  } = props

  const dispatch = useDispatch<AppDispatch>();
  const [selectedGame, selectGame] = useState<Game | null>(null);
  const [selectedGroupId, setSelectedGroupId] = useState<number | null>(null);
  const championship = useSelector(championshipDataSelector);
  const { isProcessing } = useSelector(stageProcessingSelector);
  const gameDetailsList = useSelector(stageGameDetailsListSelector);

  const groups = useSelector(selectSelectedStageGroups);

  const { open, close, Modal } = useModal({ defaultShow: false });
  const { open: openEdit, close: closeEdit, Modal: EditModal } = useModal({ defaultShow: false });
  const { open: openChooseGroup, close: closeChooseGroup, Modal: ModalChooseGroup } = useModal({ defaultShow: false });

  const { teams } = stage;

  const handleCreateGameClick = useCallback(() => {
    if (stage.type === 'GROUP') {
      openChooseGroup();
    } else {
      open();
    }
  }, [open, openChooseGroup, stage.type]);

  const handleEditGameClick = useCallback((game: Game) => {
    if (!gameDetailsList?.byId[game.id]) {
      dispatch(fetchGameDetails({ gameId: game.id }));
    }

    selectGame(game);
    openEdit();
  }, [dispatch, openEdit, gameDetailsList]);

  const handleCloseEditMatchDialog = useCallback(() => {
    selectGame(null);
  }, []);

  const onShowGameResultClick = useCallback((gameId: number) => {
    if (onShowResultsClick) {
      onShowResultsClick(gameId);
    }
  }, [onShowResultsClick]);

  const onShowGameTemplatesClick = useCallback((gameId: number) => {
    if (onShowTemplatesClick) {
      onShowTemplatesClick(gameId);
    }
  }, [onShowTemplatesClick]);

  const handleDeleteGameClick = useCallback((gameId: number) => {
    dispatch(deleteChampionshipGame(gameId))
      .then((result) => {
        if (result.payload) {
          toast.success('Матч удален');
        } else {
          toast.error('Ошибка удаления матча');
        }
      })
      .catch(() => {
        toast.error('Ошибка удаления матча');
      });
  }, [dispatch]);

  const handleCreateGameSubmit = useCallback(async (values: UpsertMatchParams) => {
    const params = {
      tournamentId,
      championshipId,
      values,
    };

    dispatch(upsertChampionshipGame(params))
      .then((result) => {
        if (result.payload) {
          close();
        } else {
          toast.error('Ошибка создания матча');
        }
      })
      .catch((err) => {
        console.error('create game error:', err);
        toast.error('Ошибка создания матча');
      });
  }, [tournamentId, championshipId, close, dispatch]);

  const handleEditGameSubmit = useCallback(async (values: UpsertMatchParams) => {
    if (!selectedGame) {
      return;
    }

    const gameDetails = gameDetailsList?.byId[selectedGame.id];

    const params: UpsertGameDTO = {
      tournamentId,
      championshipId,
      matchId: selectedGame.id,
      values: {
        ...values,
        ...(gameDetails && {
          events: gameDetails.events,
          result: gameDetails.result,
          videoUrls: gameDetails.videoUrls,
        }),
      },
    };

    dispatch(upsertChampionshipGame(params))
      .then((result) => {
        if (result.payload) {
          closeEdit();
        } else {
          toast.error('Ошибка редактирования матча');
        }
      })
      .catch((err) => {
        console.error('update game error:', err);
        toast.error('Ошибка редактирования матча');
      });
  }, [selectedGame, gameDetailsList?.byId, tournamentId, championshipId, dispatch, closeEdit]);

  const teamsForCreate = useMemo(() => {
    if (selectedGroupId) {
      return groups.find((group) => group.id === selectedGroupId)?.teams || [];
    }

    return teams;
  }, [groups, selectedGroupId, teams]);

  const handleChooseGroup = useCallback((id: number) => {
    setSelectedGroupId(id);
    closeChooseGroup();
    open()
  }, [closeChooseGroup, open]);

  return (
    <div className='row gy-2'>
      <div className='col-12'>
        <div className='row'>
          <div className='col-6'>
            <div className='d-flex flex-row'>
            </div>
          </div>
          <div className='col-6 d-flex justify-content-end'>
            <UDButton
              variant='dark'
              className='d-flex align-items-center ml-6'
              onClick={handleCreateGameClick}
            >
              <UDIcon name='plus' componentProps={{ className: 'mr-2' }} />
              Добавить матч
            </UDButton>
          </div>
        </div>
      </div>
      <div className='col-12'>
        <div className='row'>
          <div className='col-12'>
            <GamesList
              games={stage.matches}
              activeGameId={selectedGame?.id}
              onEditGameClick={handleEditGameClick}
              onDeleteGameClick={handleDeleteGameClick}
              onShowGameResultClick={onShowGameResultClick}
              onShowGameTemplatesClick={onShowGameTemplatesClick}
              onShowMomentsClick={onShowMomentsClick}
            />
          </div>
        </div>
      </div>
      <div className='col-6'></div>

      <ModalChooseGroup header={{ title: 'Выбор группы', subtitle: '' }}>
        <ChooseGroupForm
          groups={groups}
          onClick={handleChooseGroup}
        />
      </ModalChooseGroup>
      <Modal header={{ title: 'Добавление матча', subtitle: '' }}>
        <CreateGameForm
          stageId={stage.id}
          rounds={stage.rounds}
          teams={teamsForCreate}
          requiredRound={true}
          onSubmit={handleCreateGameSubmit}
          availableLocations={championship?.locations || []}
        />
      </Modal>
      <EditModal
        header={{ title: 'Редактирование матча', subtitle: '' }}
        onClose={handleCloseEditMatchDialog}
      >
        <CreateGameForm
          stageId={stage.id}
          rounds={stage.rounds}
          teams={teams}
          game={selectedGame!}
          requiredRound={true}
          onSubmit={handleEditGameSubmit}
          availableLocations={championship?.locations || []}
          isLoading={isProcessing}
        />
      </EditModal>
    </div>
  );
};

export default StageMatches;
