/** @jsxImportSource @emotion/react */

import { Container, IconContainer, MainBlock } from "./styles";
import React, { useState } from 'react';

import BackgroundItem from "../../elements/backgroundItem";
import { DivProps } from "../../../../../../../../typings";
import EditIcon from "../../elements/editIcon";
import UDText from "../../../../../../../ud-ui/components/text";
import classNames from "classnames";

type IProps = DivProps & {
  img?: string
  color: string
  colorName: string
}

function LogoView(props: IProps) {
  const { img, color, colorName, onClick, ...rest } = props
  const [isHover, setIsHover] = useState(false)
  return (
    <MainBlock {...rest}>
      <UDText type={'footnote'}>Ваш логотип</UDText>
      <Container
        onClick={onClick}
        onMouseEnter={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}
      >
        <BackgroundItem
          color={color}
          colorName={colorName}
          img={img}
        />
        <IconContainer className={classNames({ show: isHover })}>
          <EditIcon />
        </IconContainer>
      </Container>
    </MainBlock>
  );
}

export default LogoView;
