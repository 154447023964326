/** @jsxImportSource @emotion/react */

import {
  CanvasWrapper,
  Container,
  LeftContainer,
  RightContainer,
} from "../../../ud-ui/components/fabric-canvas/view-boards/styles";
import { setActiveObjects, setHasChanges, setSavedScheme } from "../../store/editor/editor";
import { useCallback, useEffect, useMemo, useState } from 'react';

import ArrowRectangleIcon from "../../../ud-ui/components/icon/arrow-rectangle-icon";
import BoardItem from "../../../ud-ui/components/fabric-canvas/view-boards/boards-list/board-item";
import BoardsList from "../../../ud-ui/components/fabric-canvas/view-boards/boards-list/boards-list";
import EmptyButton from "../../../ud-ui/components/emptyButton";
import { MainBlock } from "./styles";
import NewEditIcon from "../../../ud-ui/components/icon/new-edit-icon";
import { Pages } from "../../../navigation/domain/enums/pages";
import PlusIcon from "../../../ud-ui/components/icon/plus-icon";
import UDBreadcrumbs from "../../../ud-ui/components/breadcrumbs";
import UDHeaderViewBoards from "../../../ud-ui/components/fabric-canvas/view-boards/right/UDHeaderViewBoards";
import UDMainLayout from "../../../ud-ui/layout/main";
import backgroundElems from "../../../ud-ui/components/fabric-canvas/addons/backgroundElems";
import { history } from "../../../../store/store";
import { manual } from "../../domain/consts/urls";
import { setActiveBoard } from "../../store/templates/templates";
import useBoards from "../hooks/useBoards";
import useCreateModal from "../hooks/modals/board/create";
import { useDispatch } from "react-redux";
import useFabric from "../../../ud-ui/components/fabric-canvas/hooks/useFabric";
import { useLoadTemplates } from "../hooks/useLoadTemplates";
import useLocalStorage from "../hooks/useLocalStorage";
import useTemplates from "../hooks/useTemplates";

function Boards() {
  const dispatch = useDispatch()
  const [isInit, setInit] = useState<boolean>(false)
  const { activeTemplate } = useTemplates()
  const { boards, activeBoard, activeScheme, isLoading } = useBoards()
  const { canvas, manager } = useFabric('static', {
    data: { ...activeTemplate?.resolution },
    style: { borderRadius: 8, position: 'absolute' },
    resizing: true,
  })

  useLoadTemplates()

  const { clearData } = useLocalStorage()

  useEffect(() => {
    clearData()
    dispatch(setActiveObjects([]))
    dispatch(setSavedScheme(undefined))
    dispatch(setHasChanges(false))
  }, [clearData, dispatch])


  useEffect(() => {
    if (!boards.length) return
    if (!activeBoard || !activeTemplate || !boards.find(board => board.id === activeBoard.id)) {
      dispatch(setActiveBoard({ id: boards[0].id }))
    }
  }, [activeTemplate, activeBoard, boards, dispatch]);


  const isEditable = useMemo(() => {
    if (!activeTemplate) return false
    return activeTemplate.access !== 'default'
  }, [activeTemplate]);

  useEffect(() => {
    if (!manager) return
    backgroundElems.init(manager.canvas)
    setInit(true)
  }, [manager]);

  useEffect(() => {
    if (!activeTemplate) return
    manager?.setResolution(activeTemplate?.resolution)
    manager?.normalizedViewport()
  }, [activeTemplate, manager])

  useEffect(() => {
    if (!manager) return
    if (!activeScheme) return
    manager.loadLocal({ scheme: activeScheme, safeBackground: true }).then(() => {
      manager.normalizedViewport()
    })
  }, [activeBoard, activeScheme, manager])


  const onGoEdit = useCallback(() => {
    if (!isInit) return
    if (!activeTemplate) return
    if (!activeBoard) return
    if (!isEditable) return;

    history.push(Pages.TEMPLATE.builders.editor(activeTemplate.id, activeBoard.id), { from: 'boards' })
  }, [activeBoard, activeTemplate, isEditable, isInit]);

  const breadcrumb = (
    <UDBreadcrumbs
      className='my-10' breadcrumbs={[
        { label: 'Турниры', to: Pages.TOURNEYS.INDEX },
        { label: 'Шаблоны', to: Pages.TEMPLATE.INDEX },
        { label: activeTemplate?.name ?? 'Табло', to: Pages.TEMPLATE.BOARDS },
      ]}
    />
  )

  const { modal: createModal, open: openCreateModal } = useCreateModal()
  const redirectToManual = useCallback(() => { window.open(manual, '_blank'); }, []);

  return (
    <UDMainLayout headerContent={breadcrumb}>
      <MainBlock>
        <Container style={{ opacity: isLoading ? 0.5 : 1 }}>
          <LeftContainer>
            <BoardsList
              items={
                boards.map((board) => {
                  return (
                    <BoardItem
                      key={board.id}
                      board={board}
                      isEditable={isEditable || boards.length <= 1}
                      onClick={() => dispatch(setActiveBoard({ id: board.id }))}
                      isActive={board.id === activeBoard?.id}
                    />
                  )
                })
              }
              headerText={'Табло'}
            />
            {isEditable && (
              <EmptyButton onClick={openCreateModal} color={'#A2A2A2'} icon={<PlusIcon />}>
                Добавить табло
              </EmptyButton>
            )}
          </LeftContainer>
          <RightContainer>
            <UDHeaderViewBoards
              mainText={activeBoard?.name}
              buttons={[
                <EmptyButton
                  key={'edit'}
                  disabled={!isEditable}
                  onClick={onGoEdit}
                  icon={<NewEditIcon width={24} height={24} />}
                >
                  Редактировать
                </EmptyButton>,
              ]}
              subElems={[
                <EmptyButton
                  key={'manual'}
                  onClick={redirectToManual}
                  icon={<ArrowRectangleIcon width={16} height={16} />}
                >
                  Инструкция
                </EmptyButton>,
              ]}
            />
            <CanvasWrapper>
              {canvas}
            </CanvasWrapper>
          </RightContainer>
        </Container>
      </MainBlock>
      {createModal}
    </UDMainLayout >
  );
}

export default Boards;
