import * as styles from './game-event-form.styles';
import * as yup from 'yup';

import { GameDetailsTeamMember, GameEventType } from 'modules/games/domain/interfaces/GameDetails';
import { useCallback, useMemo, useState } from 'react';

import { Formik } from 'formik';
import { GameEventDTO } from 'modules/games/domain/interfaces/GameEventDTO';
import { SelectOption } from "../../../../ud-form/components/select/component";
import { SingleValue } from "react-select";
import { TFunction } from "i18next";
import UDButton from 'modules/ud-ui/components/button';
import { UDFormDurationInput } from 'modules/ud-form/components/duration-input';
import UDFormSelect from 'modules/ud-form/components/select';
import { useTranslation } from 'react-i18next';

const periodToOption = (period: number) => ({
  label: period === 0 ? 'Серия пенальти' : `${period}`,
  value: period,
});

const PERIOD_OPTIONS = [1, 2, 0].map(periodToOption);

const gameEventTypeToOption = (eventType: GameEventType, t: TFunction) => ({
  label: t(`games.eventTypes.${eventType.toLowerCase()}`),
  value: eventType,
});

const playerToOption = (player: GameDetailsTeamMember) => ({
  label: `#${player.number} ${player.lastName} ${player.firstName}`,
  value: player.id,
});

type GameEventFormProps = {
  members: GameDetailsTeamMember[];
  initialValues: Partial<GameEventDTO>;
  onSubmit: (values: GameEventDTO) => void;
  onDelete?: (id: number) => void;
};

export const GameEventForm = (props: GameEventFormProps) => {
  const {
    initialValues,
    members,
    onSubmit,
    onDelete,
  } = props;

  const { t } = useTranslation();
  const [selectedPeriod, selectPeriod] = useState<number | null>(initialValues?.period ?? null);
  const [selectedType, setSelectedType] = useState<GameEventType | null>(initialValues?.type ?? null);

  const eventTypeOptions = useMemo(() => {
    return Object
      .values(GameEventType)
      .filter((eventType) => {
        if (selectedPeriod === 0) {
          return [GameEventType.AFTER_MATCH_PENALTY_GOAL, GameEventType.AFTER_MATCH_PENALTY_MISSED].includes(eventType);
        }

        return ![
          GameEventType.SAVE,
          GameEventType.DEFAULT,
          GameEventType.AFTER_MATCH_PENALTY_GOAL,
          GameEventType.AFTER_MATCH_PENALTY_MISSED,
        ].includes(eventType);
      })
      .map((eventType) => gameEventTypeToOption(eventType, t));
  }, [t, selectedPeriod]);

  const playerOptions = useMemo(() => {
    return members.map(playerToOption);
  }, [members]);

  const hasPlayer = useMemo(() => !selectedType || ![
    // GameEventType.PENALTY,
    GameEventType.DEFAULT,
    // GameEventType.TIMEOUT,
    // GameEventType.CORNER,
  ].includes(selectedType), [selectedType]);

  const hasSecondPlayer = useMemo(() => selectedType && [
    GameEventType.PASS_AND_GOAL,
    GameEventType.REPLACEMENT,
  ].includes(selectedType), [selectedType]);

  const firstMember = initialValues.firstPlayerId ? members.find((member) => (member.id === initialValues.firstPlayerId)) : null;
  const secondMember = initialValues.secondPlayerId ? members.find((member) => (member.id === initialValues.secondPlayerId)) : null;

  const formInitialValues = {
    id: initialValues.id,
    teamId: initialValues.teamId,
    type: initialValues.type ? gameEventTypeToOption(initialValues.type, t) : null,
    period: initialValues.period === null || initialValues.period === undefined ? null : periodToOption(initialValues.period),
    seconds: initialValues.seconds || null,
    firstPlayerId: firstMember ? playerToOption(firstMember) : null,
    secondPlayerId: secondMember ? playerToOption(secondMember) : null,
  };

  const onTypeChange = useCallback((item: SingleValue<SelectOption>) => {
    setSelectedType(item?.value);
  }, [setSelectedType]);

  const handlePeriodChange = useCallback((setFieldValue: (arg0: string, arg1: null) => void) => (item: any) => {
    selectPeriod(item.value);
    setSelectedType(null);
    setFieldValue('type', null);
  }, []);

  const handleSubmit = useCallback((values: typeof formInitialValues) => {
    const data = {
      id: values.id,
      teamId: values.teamId!,
      type: values.type?.value || null,
      period: values.period?.value || 0,
      seconds: values.seconds || 0,
      firstPlayerId: values.firstPlayerId?.value || null,
      secondPlayerId: values.secondPlayerId?.value || null,
      videoUrl: initialValues.videoUrl,
    };

    onSubmit(data);
  }, [initialValues.videoUrl, onSubmit]);

  const handleDelete = useCallback(() => {
    if (!initialValues.id || !onDelete) {
      return;
    }

    onDelete!(initialValues.id!);
  }, [initialValues.id, onDelete]);

  const validationSchema = yup.object().shape({
    type: yup.object().nullable().required('Обязательное поле'),
    period: yup.object().nullable().required('Обязательное поле'),
    seconds: yup.string().nullable().when('period', {
      is: (val: { label: string, value: number } | null) => val === null || val.value === 0,
      then: (schema) => schema.optional(),
      otherwise: (schema) => schema.required('Обязательное поле'),
    }),
    firstPlayerId: yup.object().when('type', {
      is: (type: { label: string, value: GameEventType }) => ![
        GameEventType.PENALTY,
        GameEventType.DEFAULT,
        GameEventType.TIMEOUT,
        GameEventType.CORNER,
      ].includes(type.value),
      then: (schema) => schema.nullable().required('Обязательное поле'),
      otherwise: (schema) => schema.nullable().optional(),
    }),
    secondPlayerId: yup.object().nullable().when('type', {
      is: (type: { label: string, value: GameEventType }) => [
        GameEventType.PASS_AND_GOAL,
        GameEventType.REPLACEMENT,
      ].includes(type.value),
      then: (schema) => schema.required('Обязательное поле'),
      otherwise: (schema) => schema.optional(),
    }),
  });

  return (
    <Formik
      initialValues={formInitialValues}
      onSubmit={handleSubmit}
      validateOnBlur={false}
      validateOnChange={false}
      validationSchema={validationSchema}
    >
      {(formProps) => {
        return (
          <form onSubmit={formProps.handleSubmit}>
            <input type='hidden' name='teamId' />

            <styles.GameEventFormTimeContainer className='mt-2'>
              <div className='period'>
                <UDFormSelect
                  name='period'
                  label='Период/тайм'
                  isMulti={false}
                  options={PERIOD_OPTIONS}
                  onChange={handlePeriodChange(formProps.setFieldValue)}
                />
              </div>
              {selectedPeriod !== 0 && (
                <div className='time ml-3'>
                  <UDFormDurationInput
                    name='seconds'
                    label='Время'
                    value={formProps.values.seconds}
                  />
                </div>
              )}
            </styles.GameEventFormTimeContainer>

            <div className='mt-2'>
              <UDFormSelect
                name='type'
                label='Событие'
                isMulti={false}
                options={eventTypeOptions}
                onChange={onTypeChange}
              />
            </div>

            {hasPlayer && (
              <div className='mt-2'>
                <UDFormSelect
                  name='firstPlayerId'
                  label='Игрок 1'
                  isMulti={false}
                  options={playerOptions}
                />
              </div>
            )}

            {hasSecondPlayer && (
              <div className='mt-2'>
                <UDFormSelect
                  name='secondPlayerId'
                  label='Игрок 2'
                  isMulti={false}
                  options={playerOptions}
                />
              </div>
            )}

            <div className='mt-10'>
              {onDelete && formProps.values.id && (
                <UDButton
                  variant='secondary'
                  className='w-100 mb-3 flex-grow-1'
                  type='button'
                  onClick={handleDelete}
                >
                  Удалить
                </UDButton>
              )}
              <UDButton
                variant='primary'
                className='w-100 mb-3 flex-grow-1'
                type='submit'
                loading={formProps.isSubmitting}
              >
                Сохранить
              </UDButton>
            </div>
          </form>
        )
      }}
    </Formik>
  );
};
