import pattern from './images/pattern.png';
import styled from '@emotion/styled';

export const Container = styled.div`
  background-image: url(${pattern});
  display: flex;
  justify-content: center;
  align-items: start;
  flex-direction: column;

  padding-left: 110px;
  overflow: hidden;

  & .image {
    width: 380px;
    height: 493px;
  }
`;

export const CopyrightContainer = styled.div`
  max-width: 380px;
  width: 100%;
  justify-content: end;
  display: flex;
`;
