import { RootState } from "../../../store/store";
import { createSelector } from "@reduxjs/toolkit";

export const selectTournamentUserSlice = createSelector(
  (state: RootState) => state.tournamentUsersSlice,
  (state) => state,
);

export const selectPlayers = createSelector(
  [selectTournamentUserSlice],
  (state) => state.players,
);

export const selectLoad = createSelector(
  [selectTournamentUserSlice],
  (state) => ({
    isLoading: state.isLoading,
    isLoaded: state.isLoaded,
  }),
);

export const selectFilter = createSelector(
  [selectTournamentUserSlice],
  (state) => state.filter,
)

export const selectPlayersByFilter = createSelector(
  [selectTournamentUserSlice],
  (state) => Boolean(state.filter.text) ? state.filter.players : state.players,
)

export const selectPlayerById = (id: number) => createSelector(
  [selectPlayers],
  (players) => players.find((player) => player.id === id),
)

export const selectPlayerEvents = (userId: number) => createSelector(
  [selectTournamentUserSlice],
  (state) => state.events[userId] ?? [],
)

export const selectProcess = createSelector(
  [selectTournamentUserSlice],
  (state) => ({
    isProcessing: state.isProcessing,
    isProcessed: state.isProcessed,
    name: state.processName,
  }),
);

export const selectMomentsPaginationData = createSelector(
  [selectTournamentUserSlice],
  (state) => ({
    perPage: state.moments.perPage,
    currentPage: state.moments.currentPage,
    pages: state.moments.pages,
    allDataLoaded: state.moments.allDataLoaded,
    limit: state.moments.loadLimit,
  }),
);

export const selectPlayerEventsOnPage = (userId: number) => createSelector(
  [selectTournamentUserSlice, selectPlayerEvents(userId)],
  (state, events) => {
    const currentPage = state.moments.currentPage
    const perPage = state.moments.perPage

    return events.slice(
      (currentPage - 1) * perPage,
      (currentPage - 1) * perPage + perPage,
    )
  },
);

export const selectCurrentPlayerId = createSelector(
  [selectTournamentUserSlice],
  (state) => state.currentPlayerId,
);
