import styled from '@emotion/styled';

export const TeamContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;

  &.left {
    justify-content: end;

    .score {
      margin-left: 12px;
    }
  }

  &.right {
    justify-content: start;

    .score {
      margin-right: 12px;
    }
  }
`;

export const GameCardContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 24px;
  gap: 16px;
  justify-content: space-between;

  height: 73px;

  background: #FFFFFF;
  border-bottom: 1px solid #EAEAEC;

  flex: none;
  order: 1;
  flex-grow: 0;

  &:last-child {
    border-radius: 0 0 8px 8px;
    border-bottom: none;
  }
`;

export const GameCardTime = styled.div`
  font-family: -apple-system, BlinkMacSystemFont, sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 20px;
  width: 80px;
`;

export const GameCardTeams = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
`;

export const TeamsDelimiterContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 6px;
`;

export const TeamsDelimiter = styled.div`
  width: 4px;
  height: 4px;

  border-radius: 4px;
  background-color: #F5D956;
`;

export const GameTeamScore = styled.div`
  font-family: -apple-system, BlinkMacSystemFont, sans-serif;
  font-size: 17px;
  font-weight: 600;
`;

export const IconButton = styled.button`
  cursor: pointer;
  background: transparent;
  border: none;
`;

export const GameActions = styled.div`
  //width: 80px;
  min-width: 188px;
  text-align: right;

  .game-action-btn {
    margin-left: 14px;
    vertical-align: middle;
  }

  .game-action-btn:first-of-type {
    margin-left: 0;
  }
`;
