import {
  PlayersSliceState,
  createChangePlayerRole,
  createDeleteTournamentPlayerApplication,
  createGetTournamentPlayers,
  createUpsertTournamentPlayerApplication
} from './reducers';

import { createSlice } from '@reduxjs/toolkit';

const initialState: PlayersSliceState = {
  isGettingPlayers: false,
  isLoading: false,
  info: [],
};

const slice = createSlice({
  name: 'players',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    createGetTournamentPlayers(builder);
    createUpsertTournamentPlayerApplication(builder);
    createDeleteTournamentPlayerApplication(builder);
    createChangePlayerRole(builder);
  },
});


const playersReducer = slice.reducer;
export default playersReducer;
