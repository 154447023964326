import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import {
  createAddTournamentUserToTeamReducer,
  createDeleteTournamentUserReducer,
  createFetchPlayersReducer,
  createFetchTournamentUserReducer,
  createFetchTournamentUsersByFilterReducer,
  createFetchUserEventsReducer,
  createKickTournamentUserFromTeamReducer,
  createUpsertTournamentUserReducer,
} from "./reducers";

import { EventForMomentCard } from "../domain/interfaces/event";
import { Player } from "../domain/interfaces/player";

export interface PlayersSliceState {
  players: Player[]
  events: { [playerId: number]: EventForMomentCard[] }
  currentPlayerId?: number

  isLoading: boolean
  isLoaded: boolean

  allDataLoaded: boolean
  playerLoadedLimit: number

  isProcessing: boolean
  isProcessed: boolean
  processName?: string

  filter: {
    text: string
    players: Player[]
    allDataLoaded: boolean
  }

  tournamentId?: number

  moments: {
    perPage: number
    loadLimit: number

    pages: number
    currentPage: number

    allDataLoaded: boolean
  }
}

const initialState: PlayersSliceState = {
  players: [],
  events: {},

  isLoading: false,
  isLoaded: false,

  allDataLoaded: false,
  playerLoadedLimit: 50,

  isProcessing: false,
  isProcessed: false,

  filter: {
    text: '',
    players: [],

    allDataLoaded: false,
  },

  moments: {
    perPage: Number(localStorage.getItem('momentsPerPage')) || 9,
    loadLimit: 20,

    pages: 0,
    currentPage: 0,

    allDataLoaded: false,
  },
};

export const TournamentUsersSlice = createSlice({
  name: 'tournamentUsersSlice',
  initialState,
  reducers: {
    setFilter: (state, action: PayloadAction<{ text: string }>) => {
      state.filter.text = action.payload.text
    },
    resetProcess: (state) => {
      state.isProcessing = false
      state.isProcessed = false
      state.processName = undefined
    },
    setTournamentId: (state, action: PayloadAction<{ tournamentId: number }>) => {
      state.tournamentId = action.payload.tournamentId
      state.players = []
    },
    setMomentsPerPage: (state, action: PayloadAction<{ perPage: number }>) => {
      state.moments.perPage = action.payload.perPage
      localStorage.setItem('momentsPerPage', state.moments.perPage.toString())

      const currentPlayerId = state.currentPlayerId
      if (currentPlayerId === undefined) return

      state.moments.pages = Math.ceil(state.events[currentPlayerId]?.length / state.moments.perPage)
      state.moments.currentPage = state.moments.currentPage > state.moments.pages ? state.moments.pages : state.moments.currentPage
    },
    setMomentsCurrentPage: (state, action: PayloadAction<{ currentPage: number }>) => {
      state.moments.currentPage = action.payload.currentPage
    },
    setCurrentPlayerId: (state, action: PayloadAction<{ playerId: number }>) => {
      state.currentPlayerId = action.payload.playerId
    },
    resetMomentsData: (state) => {
      state.moments = {
        perPage: Number(localStorage.getItem('momentsPerPage')) || 9,
        loadLimit: 20,

        pages: 0,
        currentPage: 0,

        allDataLoaded: false,
      }
    },
  },
  extraReducers: (builder) => {
    createFetchPlayersReducer(builder);
    createFetchTournamentUsersByFilterReducer(builder);
    createUpsertTournamentUserReducer(builder);
    createDeleteTournamentUserReducer(builder);
    createAddTournamentUserToTeamReducer(builder);
    createKickTournamentUserFromTeamReducer(builder);
    createFetchTournamentUserReducer(builder);
    createFetchUserEventsReducer(builder);
  },
});

export const {
  setFilter,
  setTournamentId,
  setMomentsPerPage,
  setMomentsCurrentPage,
  setCurrentPlayerId,
  resetMomentsData,
} = TournamentUsersSlice.actions

export default TournamentUsersSlice.reducer
export * from './selectors'
