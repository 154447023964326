import { useCallback, useState } from 'react';

import { Team } from '../../../teams/domain/interfaces/Team';
import { useOrganizer } from 'modules/organizer/ui/hooks/useOrganizer';

export const useTeamList = () => {
  const { info, isLoading } = useOrganizer();
  const [filteredItems, setFilteredItem] = useState<Team[] | null>(null);

  const getFilteredItems = useCallback((value: string) => {
    if (value) {
      setFilteredItem((info?.teams ?? []).filter(item =>
        item.name.toLowerCase().includes(value.toLowerCase())
      ))
    } else {
      setFilteredItem(null)
    }
  }, [info?.teams])

  return {
    isLoading,
    teams: info?.teams ?? [],
    filteredTeams: filteredItems,
    changeFilter: getFilteredItems,
  };
};
