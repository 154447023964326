export enum SymbolicTeamFormation {
  // 1 player
  ONE = '1',

  // 2 players
  TWO = '2',

  // 3 players
  TWO_ONE = '2-1',

  // 4 players
  TWO_TWO = '2-2',

  ONE_TWO_TWO = '1-2-2',

  // 6 players
  ONE_THREE_TWO = '1-3-2',

  // 7 players
  ONE_THREE_THREE = '1-3-3',

  // 8 players
  ONE_THREE_TWO_TWO = '1-3-2-2',

  // 9 players
  ONE_TWO_FOUR_TWO = '1-2-4-2',
  ONE_THREE_THREE_TWO = '1-3-3-2',

  // 10 players
  ONE_THREE_THREE_THREE = '1-3-3-3',
  ONE_FOUR_THREE_TWO = '1-4-3-2',

  // 11 players
  ONE_FOUR_FOUR_TWO = '1-4-4-2',
  ONE_FOUR_THREE_THREE = '1-4-3-3',
}
