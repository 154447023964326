import { ActivePaidSubscription, RecurringPayment } from '../interfaces/ActivePaidSubscription';
import { ActivePaidSubscriptionDTO, RecurringPaymentDTO } from '../interfaces/ActivePaidSubscriptionDTO';

export class ActivePaidSubscriptionDataMapper {
    public decode(dto: ActivePaidSubscriptionDTO | null): ActivePaidSubscription | null {
        if (!dto) {
            return null;
        }

        return {
            id: dto.id,
            tournamentId: dto.ownerId, // заменяем ownerId на tournamentId
            activeFrom: dto.activeFrom,
            activeTo: dto.activeTo,
            // recurringPayment: this.mapRecurringPayment(dto.recurringPayment),
            recurringPayment: null,
            purchaseType: dto.purchaseType,
        };
    }

    private mapRecurringPayment(dto: RecurringPaymentDTO | null): RecurringPayment | null {
        if (!dto) {
            return null;
        }

        return {
            id: dto.id,
            nextPayDate: dto.nextPayDate,
            period: dto.period,
        };
    }
}
