export type Background = { name: string, value: string }

export const backgroundColors: Record<number, Background> = {
  0: { name: 'Прозрачный', value: 'transparent' },
  1: { name: 'Синий', value: '#1A1A29' },
  2: { name: 'Белый', value: '#FFFFFF' },
}

export const backgrounds: Background[] = [
  { name: 'Прозрачный', value: 'transparent' },
  { name: 'Синий', value: '#1A1A29' },
  { name: 'Белый', value: '#FFFFFF' },
]
