/** @jsxImportSource @emotion/react */

import { UDButton, UDListItem, UDText } from 'modules/ud-ui/components';
import { useCallback, useState } from 'react';

import { AppDispatch } from 'store/store';
import CategoryListPage from 'shared/components/category/pages/category-list-page';
import HeaderContentCategory from 'shared/components/category/components/list-wrapper/headerContent';
import { Pages } from '../../../navigation/domain/enums/pages';
import { Player } from 'modules/players/domain/interfaces/player';
import UDMainLayout from "../../../ud-ui/layout/main";
import { deleteTournamentUser } from '../../store/actions';
import getBirthDay from 'shared/utils/getBirthDay';
import { getFullName } from 'shared/utils/getFullName';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import useModal from 'modules/ud-ui/components/new-modal/useModal';
import { useNavigate } from 'react-router-dom';
import usePlayerList from "../hooks/use-player-list";

const defaultPhoto = 'https://www.iconexperience.com/_img/o_collection_png/green_dark_grey/512x512/plain/user.png'

const naming = {
  searchPlaceholder: 'Поиск по игрокам',
  addButtonText: 'Добавить игрока'
};

function PlayerListPage() {
  const dispatch = useDispatch<AppDispatch>()
  const navigate = useNavigate();
  const { players, isLoading, loadNextPages, changeFilter, playersByFilter: filteringPlayer } = usePlayerList()
  const [selectedPlayer, setSelectedPlayer] = useState<Player | null>(null)
  const [isProcessing, setIsProcessing] = useState(false)
  const { defaultOpen: openDelete, close: closeDelete, Modal: ModalDelete } = useModal()

  const handleCreateClick = useCallback(() => {
    navigate(Pages.PLAYERS.CREATE)
  }, [navigate]);

  const onPlayerClick = useCallback((player: Player) => {
    navigate(Pages.PLAYERS.builders.view(player.id));
  }, [navigate]);

  const handleOpenDeleteModal = useCallback((player: Player) => {
    setSelectedPlayer(player)
    openDelete()
  }, [openDelete])

  const handleCloseDeleteModal = useCallback(() => {
    setSelectedPlayer(null)
    closeDelete()
  }, [closeDelete])

  const handleDeleteUser = useCallback(async () => {
    if (!selectedPlayer) return;
    setIsProcessing(true)
    await dispatch(deleteTournamentUser({ userId: selectedPlayer.id })).unwrap().then(() => {
      toast.success('Игрок успешно удалён')
      setSelectedPlayer(null)
      closeDelete()
    }).finally(() => {
      setIsProcessing(false)
    })
  }, [closeDelete, dispatch, selectedPlayer]);

  const listItems = (filteringPlayer || players).map((player, index) => {
    return (
      <UDListItem
        key={player.id}
        className="mb-2 white"
        index={index + 1}
        image={player.photo?.externalUrl ?? defaultPhoto}
        title={getFullName(player)}
        onClick={() => onPlayerClick(player)}
        subtitle={(
          <div className='d-flex justify-content-between pl-4'>
            <p style={{ width: '60%', textAlign: 'center', fontWeight: 400, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{getBirthDay(player.birthDate ? new Date(player.birthDate) : undefined)}</p>
            <p style={{ width: '20%', textAlign: 'center', fontWeight: 400, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{player.height ? `${player.height} см` : '-'}</p>
            <p style={{ width: '20%', textAlign: 'center', fontWeight: 400, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{player.weight ? `${player.weight} кг` : '-'}</p>
          </div>
        )}
        actions={[
          {
            label: 'Удалить',
            onClick: () => handleOpenDeleteModal(player),
            variant: 'secondary',
            style: { width: 144 }
          }
        ]}
      />
    )
  })

  return (
    <UDMainLayout headerContent={<HeaderContentCategory title='Игроки' createButtonText="Добавить игрока" createButtonOnClick={handleCreateClick} />}>
      <CategoryListPage categoryName='players' text={naming} isLoading={isLoading} getNextPages={loadNextPages} getFiltered={changeFilter} onCreateClick={handleCreateClick}>
        {listItems}
      </CategoryListPage>

      <ModalDelete>
        <div className='flex-center flex-column gap-2'>
          <UDText type='body-text'>Удалить игрока?</UDText>
          <UDText type='subhead'>Игрок <strong>{selectedPlayer ? getFullName(selectedPlayer) : undefined}</strong> будет безвозвратно удалён</UDText>

          <div className="flex-row gap-4 w-100 mt-8">
            <UDButton variant='primary' className='w-50' onClick={handleDeleteUser} loading={isProcessing}>Удалить</UDButton>
            <UDButton variant='secondary' className='w-50' onClick={handleCloseDeleteModal} disabled={isProcessing}>Отмена</UDButton>
          </div>
        </div>
      </ModalDelete>
    </UDMainLayout>
  );
}

export default PlayerListPage;
