import applicationLinkService from '../domain/services/applicationLinkService';
import { createAsyncThunk } from '@reduxjs/toolkit';

const PREFIX = 'applicationLink';

export const getApplicationLink = createAsyncThunk(
  `${PREFIX}/getApplicationLink`,
  async () => {
    return applicationLinkService.getApplicationLink();
  }
);

export const createApplicationLink = createAsyncThunk(
  `${PREFIX}/createApplicationLink`,
  async () => {
    return applicationLinkService.createApplicationLink();
  }
);