import { EntryRequest } from '../../domain/interfaces/EntryRequest';
import { EntryRequestStatus } from '../../../../shared/types/entryRequestStatus';
import { createAsyncThunk } from '@reduxjs/toolkit';
import playersRepository from 'modules/teams/domain/repositories/players.repository';

const PREFIX = 'entryRequests';

export const fetchEntryRequests = createAsyncThunk(
    'entryRequests/fetchEntryRequests',
    async (teamId: number) => {
        try {
            return await playersRepository.getTeamEntryRequests(teamId);
        } catch (err) {
            console.error(`${PREFIX}/getTeamEntryRequests error:`, err);
            throw err;
        }
    }
);

export const updateEntryRequest = createAsyncThunk(
    'entryRequests/updateEntryRequest',
    async ({ entryRequestId, status }: { entryRequestId: number; status: EntryRequestStatus }) => {
        try {
            return await playersRepository.changeEntryRequestStatus(entryRequestId, status);
        } catch (err) {
            console.error(`${PREFIX}/updateEntryRequest error:`, err);
            throw err;
        }
    }
);
