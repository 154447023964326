/** @jsxImportSource @emotion/react */

import { CustomField, CustomTextarea, InfoContainer, disableColor, tooltipCss } from './styles';
import React, { useEffect } from 'react';
import { organizersStoreSelector, selectOrganizerById } from "../../../store/selectors";
import { useDispatch, useSelector } from "react-redux";

import { AppDispatch } from "../../../../../store/store";
import CopyIcon from "../../../../ud-ui/components/icon/copy-icon";
import EmptyButton from "../../../../ud-ui/components/emptyButton";
import HeaderItem from "../header/elements/headerItem";
import PlansBlock from "./components/PlansBlock";
import Tooltip from "../../../../ud-form/components/tooltip";
import UDText from "../../../../ud-ui/components/text";
import { fetchOrganizers } from "../../../store/actions";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

function OrganizerInfo() {
  const dispatch = useDispatch<AppDispatch>()
  const { id } = useParams();
  const organizer = useSelector(selectOrganizerById(Number(id)))
  const { isLoaded } = useSelector(organizersStoreSelector)

  useEffect(() => {
    if (!isLoaded) {
      dispatch(fetchOrganizers())
    }
  }, [dispatch, isLoaded]);

  const { t } = useTranslation();

  const onClickCopy = (text: string) => {
    navigator.clipboard.writeText(text).then(_ => {
      toast.success('Сохранено в буфер обмена!');
    });
  }

  return (
    <InfoContainer>
      <div className='w-50'>
        <HeaderItem
          header={{ text: "Название организатора", type: 'body-text' }}
          className={'mb-4'}
        >
          <CustomField>
            <UDText type={'subhead'}>{organizer?.name}</UDText>
            {organizer?.name && (
              <Tooltip css={tooltipCss} text={'Скопировать'}>
                <EmptyButton
                  onClick={() => onClickCopy(organizer?.name ?? '')}
                  icon={<CopyIcon />}
                />
              </Tooltip>
            )}
          </CustomField>
        </HeaderItem>
        <HeaderItem
          header={{ text: "Тип спорта", type: 'body-text' }}
          className={'mb-4'}
        >
          <CustomField>
            <UDText type={'subhead'}>{t(`sportType.${organizer?.sportType}`)}</UDText>
          </CustomField>
        </HeaderItem>
        <HeaderItem
          header={{ text: 'Email', type: 'body-text' }}
          className={'mb-4'}
        >
          <CustomField>
            <UDText type={'subhead'}>{organizer?.email}</UDText>
            {organizer?.email && (
              <Tooltip css={tooltipCss} text={'Скопировать'}>
                <EmptyButton
                  onClick={() => onClickCopy(organizer?.email ?? '')}
                  icon={<CopyIcon />}
                />
              </Tooltip>
            )}
          </CustomField>
        </HeaderItem>
        <HeaderItem
          header={{ text: "Телефон", type: 'body-text' }}
          className={'mb-4'}
        >
          <CustomField>
            <UDText type={'subhead'}>{organizer?.phone}</UDText>
            {organizer?.phone && (
              <Tooltip css={tooltipCss} text={'Скопировать'}>
                <EmptyButton
                  onClick={() => onClickCopy(organizer?.phone ?? '')}
                  icon={<CopyIcon />}
                />
              </Tooltip>
            )}
          </CustomField>
        </HeaderItem>
        <HeaderItem
          header={{ text: "Город", type: 'body-text' }}
          className={'mb-4'}
        >
          <CustomField>
            <UDText type={'subhead'}>{organizer?.address.city}</UDText>
          </CustomField>
        </HeaderItem>
        <HeaderItem
          header={{ text: "Описание", type: 'body-text' }}
          className={'mb-4'}
        >
          <CustomTextarea
            css={disableColor}
            readOnly
            rows={4}
            value={organizer?.description}
          />
        </HeaderItem>
      </div>
      <div className='w-50'>
        <HeaderItem header={{ text: 'Информация о подписке организатора', type: 'body-text' }}>
          <PlansBlock tournamentId={Number(id)} owner={organizer?.adminUser} />
        </HeaderItem>
      </div>
    </InfoContainer>
  );
}

export default OrganizerInfo;
