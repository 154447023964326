import { Resource } from 'modules/core/baseResource';
import httpResource from 'modules/core/httpResource';

class TeamsResource extends Resource {
  constructor() {
    super('teams', httpResource);
  }

  public async uploadLogo(logo: File, teamId: number) {
    const formData = new FormData();

    formData.append('emblem', logo);

    return await this.child(`${teamId}/emblem`).post(formData);
  }

}

const teamsResource = new TeamsResource();
export default teamsResource;
