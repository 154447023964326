import { selectEntryRequests, selectEntryRequestsLoaded, selectEntryRequestsLoading } from "modules/teams/store/entryRequests/selectors";

import { AppDispatch } from "store/store";
import { fetchEntryRequests } from "modules/teams/store/entryRequests/actions";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { useSelector } from "react-redux";

export const useEntryRequests = (teamId: number) => {
    const dispatch = useDispatch<AppDispatch>();
    const entryRequests = useSelector(selectEntryRequests);
    const isLoading = useSelector(selectEntryRequestsLoading);
    const isLoaded = useSelector(selectEntryRequestsLoaded);

    useEffect(() => {
        if (isLoaded) { return; }
        dispatch(fetchEntryRequests(teamId));
    }, [dispatch, teamId, isLoaded]);

    return { entryRequests, isLoading, isLoaded };
};
