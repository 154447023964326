import CreatableSelect, { CreatableProps } from 'react-select/creatable';
import { CreatableSelectOption, SelectOption } from './types';

import { GroupBase } from 'react-select';
import React from 'react';
import { getSelectStyles } from './styles';
import overriddenSelectComponents from './override';

export type UDCreatableSelectProps<
  isMulti extends boolean = boolean,
  Group extends GroupBase<SelectOption> = GroupBase<SelectOption>
> = Omit<
  CreatableProps<SelectOption, isMulti, Group>,
  'hideSelectedOptions' | 'styles'
> & {
  hasError?: boolean;
}

export const UDCreatableSelect = <
  isMulti extends boolean = false,
  Group extends GroupBase<CreatableSelectOption> = GroupBase<CreatableSelectOption>
>(props: UDCreatableSelectProps<isMulti, Group>) => {
  const {
    components,
    ...restProps
  } = props;

  const styles = getSelectStyles<isMulti, Group>();
  const selectComponents = components || overriddenSelectComponents;

  return (
    <CreatableSelect
      {...restProps}
      hideSelectedOptions={false}
      placeholder={props.placeholder || ''}
      closeMenuOnSelect={!props.isMulti}
      styles={styles}
      components={selectComponents}
    />
  );
};
