import React from 'react';
import styled from '@emotion/styled';

const SkeletonBlock = styled.div`
  border-radius: 8px;
`;

const TeamCardSkeleton = styled(SkeletonBlock)`
  height: 84px;
`;

const filterItemStyles = {
  height: '40px',
  width: '180px',
};

const formStyles = {
  height: '120px',
  width: '100%'
};

const ChampionshipTeamsSkeleton = () => {
  const teams = new Array(3).fill('');

  return <>
    <div className="d-flex align-items-center mb-10">
      <SkeletonBlock className="skeleton-block w-100" style={{ height: '78px' }} />
    </div>

    <SkeletonBlock className="skeleton-block mb-12" style={formStyles} />

    <div>
      {teams.map((team, index) => (
        <TeamCardSkeleton key={index} className="skeleton-block mb-4 last-mb-0" />
      ))}
    </div>
  </>
};

export default ChampionshipTeamsSkeleton;
