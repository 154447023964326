import {
  selectActiveBoard,
  selectBoardLoaded,
  selectBoards,
  selectIsFinished,
  selectIsProcessed,
  selectProcess,
} from "../../store/templates/selectors";

import { Scheme } from "../../../ud-ui/components/fabric-canvas/types";
import { useMemo } from "react";
import { useSelector } from "react-redux";

function useBoards() {
  const boards = useSelector(selectBoards);
  const activeBoard = useSelector(selectActiveBoard)
  const isProcessed = useSelector(selectIsProcessed);
  const isFinished = useSelector(selectIsFinished);
  const activeScheme = useMemo(() => {
    return activeBoard ? {
      version: activeBoard.version,
      objects: activeBoard.objects,
    } : undefined as Scheme | undefined
  }, [activeBoard]);
  const process = useSelector(selectProcess)
  const { isLoaded, isLoading } = useSelector(selectBoardLoaded)

  return ({
    boards,
    isProcessed,
    isFinished,
    activeBoard,
    activeScheme,
    process,
    isLoaded,
    isLoading,
  });
}

export default useBoards;
