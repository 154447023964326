import { useCallback, useState } from 'react';

type AsyncFunction<T = any> = (...args: any[]) => Promise<T>;
type SyncFunction = (...args: any[]) => void;

interface UseAsyncButtonResult<T = any> {
  isLoading: boolean;
  handleClick: (...args: any[]) => Promise<T | void>;
  error: Error | null;
}

export const useAsyncButton = <T = any>(
  handler?: AsyncFunction<T> | SyncFunction,
  options: {
    onError?: (error: Error) => void;
    onSuccess?: (result: T) => void;
  } = {}
): UseAsyncButtonResult<T> => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  const handleClick = useCallback(
    async (...args: any[]) => {
      if (!handler || isLoading) return;

      try {
        setIsLoading(true);
        setError(null);
        const result = await Promise.resolve(handler(...args));
        if (result as T) {
          options.onSuccess?.(result as T);
        }
        return result;
      } catch (err) {
        const error = err instanceof Error ? err : new Error(String(err));
        setError(error);
        if (options.onError) {
          options.onError(error);
        } else {
          throw error;
        }
      } finally {
        setIsLoading(false);
      }
    },
    [handler, isLoading, options]
  );

  return {
    isLoading,
    handleClick,
    error
  };
};
