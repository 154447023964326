import { MainBlock } from './styles';
import classNames from 'classnames';

interface ListProps extends React.HTMLAttributes<HTMLDivElement> {
    variant?: 'transparent' | 'white';
}

const UDList = (props: ListProps) => {
    const { children, variant = 'white', ...rest } = props;

    return (
        <MainBlock {...rest} className={classNames(variant, rest.className)}>
            {children}
        </MainBlock>
    );
}

export default UDList;
