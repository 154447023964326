/** @jsxImportSource @emotion/react */

import React, { useCallback, useEffect, useLayoutEffect, useMemo, useState } from "react";
import { UDButton, UDText } from "../../../ud-ui/components";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import { AppDispatch } from "../../../../store/store";
import LocationForm from "../components/location-form";
import { Pages } from "../../../navigation/domain/enums/pages";
import UDBreadcrumbs from "../../../ud-ui/components/breadcrumbs";
import UDMainLayout from "../../../ud-ui/layout/main";
import { UpsertLocationInput } from "../../domain/interfaces/actions";
import _ from "lodash";
import { authStoreUserTournamentId } from "../../../auth/store/selectors";
import locationsRepository from "../../domain/repositories/locations.repository";
import { selectLocations } from "../../store/selectors";
import { upsertLocation } from "../../store/actions";
import useFetchMapImage from "../hooks/useFetchMapImage";
import { useOrganizer } from "../../../organizer/ui/hooks/useOrganizer";
import { usePreventNavigation } from "shared/hooks";
import { useQuery } from "react-query";

function LocationViewPage() {
    const dispatch = useDispatch<AppDispatch>();
    const { info } = useOrganizer();
    const tournamentId = useSelector(authStoreUserTournamentId);
    const locations = useSelector(selectLocations);
    const [isFormChanged, setIsFormChanged] = useState(false);
    const navigate = useNavigate();

    const id = Number(useParams().id);
    if (isNaN(id)) { navigate(Pages.LOCATIONS.INDEX) }

    // Обработка закрытия страницы
    useEffect(() => {
        const handleBeforeUnload = (e: BeforeUnloadEvent) => {
            if (isFormChanged) {
                e.preventDefault();
                e.returnValue = '';
            }
        };

        window.addEventListener('beforeunload', handleBeforeUnload);
        return () => window.removeEventListener('beforeunload', handleBeforeUnload);
    }, [isFormChanged]);

    const { isLoading: isMapImageLoading } = useFetchMapImage({ locationId: id });

    const { openLeaveModal, setNextLocation, PreventNavigationModal } = usePreventNavigation({
        shouldPrevent: isFormChanged,
        onConfirm: () => {
            setIsFormChanged(false);
        },
        defaultPath: Pages.LOCATIONS.INDEX,
    });

    const handleBackToList = useCallback(() => {
        if (isFormChanged) {
            setNextLocation(-1);
            openLeaveModal();
        } else {
            navigate(-1);
        }
    }, [navigate, isFormChanged, openLeaveModal, setNextLocation]);

    // Сначала ищем локацию в сторе
    const storedLocation = useMemo(() => locations.find(loc => loc.id === id), [locations, id]);

    const { data: location, isLoading } = useQuery(
        ['location', id, tournamentId, storedLocation],
        () => locationsRepository.getLocation(id),
        {
            enabled: !isNaN(id) && !storedLocation && !!tournamentId,
            refetchOnWindowFocus: false,
            initialData: storedLocation
        }
    );

    const handleSubmit = useCallback(async (values: UpsertLocationInput) => {
        if (!tournamentId) {
            console.error('No tournament selected');
            return;
        }

        try {
            const locationData: UpsertLocationInput = {
                ...values,
                tournamentId,
            };

            await dispatch(upsertLocation(locationData)).unwrap();
            setIsFormChanged(false);
            // Из-за того, что валидация проходит асинхронно, нужно подождать пока она пройдет, и только потом перенаправлять
            setTimeout(() => {
                navigate(Pages.LOCATIONS.INDEX);
            }, 0);
        } catch (error) {
            console.error('Failed to save location:', error);
        }
    }, [dispatch, navigate, tournamentId]);

    const handleFormChange = useCallback((values: UpsertLocationInput) => {
        if (!location) return;

        const isChanged = Object.keys(values).some(key => {
            const valueKey = key as keyof typeof values;
            const locationKey = key as keyof typeof location;
            const isEqual = _.isEqual(values[valueKey], location[locationKey]);
            return !isEqual;
        });

        setIsFormChanged(isChanged);
    }, [location]);

    const breadcrumbs = useMemo(() => {
        return [
            { label: 'Локации', to: Pages.LOCATIONS.INDEX },
            { label: location?.name ?? 'Неизвестная локация', to: Pages.LOCATIONS.builders.edit(id) },
        ];
    }, [id, location?.name]);

    const breadcrumb = useMemo(
        () => (
            <UDBreadcrumbs
                className='my-10'
                breadcrumbs={breadcrumbs}
            />
        ),
        [breadcrumbs],
    );

    if (!info || isLoading) {
        return (
            <UDMainLayout headerContent={breadcrumb}>
                <div className='container pt-6'>
                    <div className='skeleton-block' style={{ height: '200px' }} />
                </div>
            </UDMainLayout>
        );
    }

    if (!location) {
        return (
            <UDMainLayout headerContent={breadcrumb}>
                <div className='container pt-6'>
                    <div className='flex-column flex-center mt-24'>
                        <div className='text body-text bold mb-2'>
                            Такая локация не найдена
                        </div>
                    </div>
                </div>
            </UDMainLayout>
        );
    }

    return (
        <UDMainLayout headerContent={breadcrumb}>
            <div className='container py-6'>
                <UDText type="headline" className="mr-10">
                    Локация для проведения турнира {isFormChanged && '(есть несохраненные изменения)'}
                </UDText>
            </div>
            <div className="container d-flex align-items-center bb-1" />
            <div className='container pt-6'>
                <LocationForm
                    location={location}
                    isLoading={isLoading}
                    onSubmit={handleSubmit}
                    onCancel={handleBackToList}
                    action='edit'
                    onChange={handleFormChange}
                    hasUnsavedChanges={isFormChanged}
                    isAvailableFeatures={Boolean(info?.tournamentPlan)}
                    isMapImageLoading={isMapImageLoading}
                />
            </div>

            <PreventNavigationModal />
        </UDMainLayout>
    );
}

export default LocationViewPage;
