import styled from "@emotion/styled";

export const MainBlock = styled.div`

`

export const Container = styled.div`
  transition: border 0.25s ease-in-out;

  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid transparent;
  border-radius: 8px;
  left: -6px;
  &:hover {
    border: 1px solid #F5D956;
    cursor: pointer;
  }
`

export const IconContainer = styled.div`
  position: absolute;
  top: calc(62px / 2 - 30px / 2 / 2); // 62 высота блока с логотипом; 30 высота иконки
  opacity: 0;
  transition: opacity 0.25s ease-in-out;

  &.show {
    opacity: 1;
  }
`
