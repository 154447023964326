/** @jsxImportSource @emotion/react */

import { UDListItem, UDText } from 'modules/ud-ui/components';
import { useCallback, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';

import { AppDispatch } from "../../../../store/store";
import CategoryListPage from 'shared/components/category/pages/category-list-page';
import EmptyState from "../components/empty-state";
import HeaderContent from "../components/header-content";
import { Location } from "modules/locations/domain/interfaces/location";
import { Pages } from '../../../navigation/domain/enums/pages';
import StadiumPlaceholderIcon from '../../../ud-ui/components/icon/stadium-placeholder-icon';
import UDButton from '../../../ud-ui/components/button';
import UDMainLayout from "modules/ud-ui/layout/main";
import { deleteLocation } from '../../store/actions';
import { mapAddressToDadataArea } from 'shared/features/dadata-address-autocomplete/infra/mappers/dadata-area.mapper';
import { selectIsProcessing } from '../../store/selectors';
import { toast } from 'react-toastify';
import { useFetchLocation } from '../hooks/useFetchLocation';
import { useLocationList } from '../hooks/useLocationList';
import useModal from '../../../ud-ui/components/new-modal/useModal';
import { useNavigate } from 'react-router-dom';

const naming = {
  searchPlaceholder: "Поиск по названию локации",
  addButtonText: "Добавить локацию",
}

function LocationsListPage() {
  const dispatch = useDispatch<AppDispatch>()
  const { isLoading, isLoaded, locations, loadNextPage, onChangeFilter, filteredLocations } = useLocationList()
  const navigate = useNavigate();
  const { isProcessing } = useSelector(selectIsProcessing);
  const { defaultOpen: openDeleteModal, close: closeDeleteModal, Modal: ModalDelete } = useModal();

  const [selectedLocation, setSelectedLocation] = useState<Location | null>(null);
  useFetchLocation()

  const onClickCard = (location: Location) => {
    if (!location) return;
    navigate(Pages.LOCATIONS.builders.edit(location.id));
  };

  const handleDeleteLocation = async () => {
    try {
      if (!selectedLocation) return;
      await dispatch(deleteLocation({ id: selectedLocation.id })).unwrap();
      closeDeleteModal();
    } catch (error) {
      console.error('Failed to delete location:', error);
      toast.error('Не удалось удалить локацию, возможно она используется в турнирах/матчах');
    }
  };

  const onClickDelete = useCallback((location: Location) => {
    setSelectedLocation(location)
    openDeleteModal();
  }, [openDeleteModal]);

  const handleCloseDeleteModal = useCallback(() => {
    closeDeleteModal();
    setSelectedLocation(null);
  }, [closeDeleteModal]);

  return (
    <UDMainLayout headerContent={<HeaderContent />}>
      {locations.length === 0 && isLoaded ? (
        <EmptyState />
      ) : (
        <CategoryListPage
          isLoading={isLoading || !isLoaded}
          categoryName="locations"
          text={naming}
          getFiltered={onChangeFilter}
          getNextPages={loadNextPage}
          onCreateClick={() => navigate(Pages.LOCATIONS.CREATE)}
        >
          {(filteredLocations || locations).map((location, index) => {
            const address = location.address ? mapAddressToDadataArea(location.address).value : ''
            return (
              <UDListItem
                className="white"
                onClick={() => onClickCard(location)}
                image={location?.images[0]?.externalUrl ?? <StadiumPlaceholderIcon size={52} className="color-SurfaceRomanSilver30" />}
                index={index + 1}
                title={location.name}
                subtitle={<p style={{ fontWeight: 400, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }} title={address}>{address}</p>}
                actions={[
                  {
                    label: 'Удалить',
                    onClick: () => onClickDelete(location),
                    variant: 'secondary',
                    style: { width: 144 }
                  }
                ]}
              />
            )
          })}
        </CategoryListPage>
      )}

      <ModalDelete>
        <div className='flex-center flex-column gap-2'>
          <UDText type='body-text'>Удалить локацию?</UDText>
          <UDText type='subhead'>Локация <strong>{selectedLocation ? selectedLocation.name : undefined}</strong> будет безвозвратно удалёна</UDText>

          <div className="flex-row gap-4 w-100 mt-8">
            <UDButton variant='primary' className='w-50' onClick={handleDeleteLocation} loading={isProcessing}>Удалить</UDButton>
            <UDButton variant='secondary' className='w-50' onClick={handleCloseDeleteModal} disabled={isProcessing}>Отмена</UDButton>
          </div>
        </div>
      </ModalDelete>
    </UDMainLayout >
  );
}

export default LocationsListPage;
