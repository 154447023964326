/** @jsxImportSource @emotion/react */

import '@splidejs/react-splide/css';

import { Options, Splide, SplideSlide } from '@splidejs/react-splide';

import { Image } from '../../../ud-form/components/dropzone/types';
import React from 'react';
import { galleryStyles } from './styles';

interface ImageGalleryProps {
    images: Image[];
    currentIndex: number;
    onClose: () => void;
    options?: Options;
    style?: React.CSSProperties;
}

export const ImageGallery: React.FC<ImageGalleryProps> = ({
    images,
    currentIndex,
    onClose,
    options,
    style,
}) => {
    return (
        <div css={galleryStyles.container}>
            <Splide
                options={{
                    start: currentIndex,
                    perPage: 1,
                    arrows: images.length > 1,
                    pagination: true,
                    height: '60vh',
                    cover: true,
                    heightRatio: 0.5,
                    focus: 'center',
                    keyboard: 'global',
                    drag: images.length > 1,
                    wheel: false,
                    ...options,
                }}
                style={style}
            >
                {images.map((img, index) => (
                    <SplideSlide key={index}>
                        <div css={galleryStyles.slideContainer}>
                            <img
                                src={img.url || img.externalUrl}
                                alt={`Изображение ${index + 1}`}
                                css={galleryStyles.image}
                            />
                        </div>
                    </SplideSlide>
                ))}
            </Splide>
        </div>
    );
};
