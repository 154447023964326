import { Form, Formik } from 'formik';
import React, { useCallback, useState } from 'react';
import {
  championshipDataSelector,
  championshipISavingSelector,
} from '../../../../store/championship/selectors';
import { useDispatch, useSelector } from 'react-redux';

import { AppDispatch } from '../../../../../../store/store';
import ApplicationCompany from './application-company';
import Regulations from './regulations';
import { TabMenu } from '../../../../../ud-ui/components/tab-menu';
import UDButton from '../../../../../ud-ui/components/button';
import { mapChampionshipSettings } from '../../../../domain/data-mappers/championship-game-teams-mapper';
import { upsertChampionshipSettings } from '../../../../store/championship/actions';

type TourneysComponentsFormRulesPartProps = {
  tournamentId: number;
};

const TourneysComponentsFormRulesPart = (props: TourneysComponentsFormRulesPartProps) => {
  const dispatch = useDispatch<AppDispatch>();
  const championship = useSelector(championshipDataSelector);
  const isChampionshipSaving = useSelector(championshipISavingSelector);
  const [activeTab, setActiveTab] = useState(0);

  const hasChampionship = !!championship;
  const initialValues = hasChampionship ? {
    id: championship.id,
    name: championship.name,
    tournamentId: props.tournamentId,
    startDate: championship.startDate ? new Date(championship.startDate) : null,
    endDate: championship.endDate ? new Date(championship.endDate) : null,
    settings: championship.settings,
    address: championship.address
      ? { ...championship.address }
      : {
        city: '',
        addressLine1: '',
      },
  } : {
    id: null,
    name: '',
    tournamentId: props.tournamentId,
    startDate: null,
    endDate: null,
    settings: mapChampionshipSettings(),
    address: {
      city: '',
      addressLine1: '',
    },
  };

  const handleSubmit = useCallback((data: any) => {
    const { address } = data;
    let params = data;

    if (address.city !== championship?.address?.city || address.addressLine1 !== championship?.address?.addressLine1) {
      params = {
        ...data,
        address: {
          city: address.city,
          addressLine1: address.addressLine1,
        },
      };
    }

    dispatch(upsertChampionshipSettings(params));
  }, [championship?.address?.addressLine1, championship?.address?.city, dispatch]);

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize={true}
      onSubmit={handleSubmit}
    >
      {(formProps) => (
        <Form onSubmit={formProps.handleSubmit}>
          <div className="mb-5">
            <TabMenu
              tabs={[
                { name: 'Заявочная компания', active: true },
                { name: 'Регламент', active: true },
              ]}
              activeTab={activeTab}
              onChange={setActiveTab}
            />
          </div>

          <div className="container g-2">
            {formProps.initialValues.id && (
              <input type="hidden" name="id" value={formProps.initialValues.id} />
            )}

            <input type="hidden" name="tournamentId" value={formProps.initialValues.tournamentId} />

            {activeTab === 0 && (
              <ApplicationCompany />
            )}

            {activeTab === 1 && (
              <Regulations />
            )}

            <div className="col-6"></div>

            <UDButton
              variant="primary"
              type="submit"
              disabled={isChampionshipSaving || !formProps.dirty}
              className="col-6 mt-5 mb-3"
              loading={isChampionshipSaving}
            >
              Сохранить
            </UDButton>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default TourneysComponentsFormRulesPart;
