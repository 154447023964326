import {
    selectLoad,
    selectLocations,
    selectLocationsByFilter,
    selectLocationsSlice,
    selectProcess,
} from "../../store/selectors";

import { useSelector } from "react-redux";

export default function useLocations() {
    const slice = useSelector(selectLocationsSlice);
    const load = useSelector(selectLoad);
    const locations = useSelector(selectLocations);
    const locationsByFilter = useSelector(selectLocationsByFilter);
    const process = useSelector(selectProcess);

    return {
        ...slice,
        ...load,
        locations,
        locationsByFilter,
        ...process,
    };
}
