/** @jsxImportSource @emotion/react */

import { useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import { AppDispatch } from "../../../../store/store";
import LocationForm from "../components/location-form";
import { Pages } from "../../../navigation/domain/enums/pages";
import UDBreadcrumbs from "../../../ud-ui/components/breadcrumbs";
import UDMainLayout from "../../../ud-ui/layout/main";
import { UDText } from '../../../ud-ui/components';
import { UpsertLocationInput } from "../../domain/interfaces/actions";
import { authStoreUserTournamentId } from "../../../auth/store/selectors";
import { selectLoad } from "../../store/selectors";
import { upsertLocation } from "../../store/actions";
import { useNavigate } from "react-router-dom";
import { useOrganizer } from "modules/organizer/ui/hooks/useOrganizer";

function LocationCreatePage() {
    const navigate = useNavigate();
    const dispatch = useDispatch<AppDispatch>();
    const { isLoading } = useSelector(selectLoad);
    const tournamentId = useSelector(authStoreUserTournamentId);
    const { info } = useOrganizer();

    const breadcrumb = useMemo(() => (
        <UDBreadcrumbs
            className='my-10'
            breadcrumbs={[
                { label: 'Локации', to: Pages.LOCATIONS.INDEX },
                { label: 'Добавление локации', to: Pages.LOCATIONS.CREATE },
            ]}
        />
    ), []);

    const handleSubmit = useCallback(async (values: UpsertLocationInput) => {
        if (!tournamentId) {
            console.error('No tournament selected');
            return;
        }

        try {
            const locationData: UpsertLocationInput = {
                ...values,
                tournamentId,
            };

            const result = await dispatch(upsertLocation(locationData)).unwrap();
            navigate(Pages.LOCATIONS.builders.edit(result.id));
        } catch (error) {
            console.error('Failed to create location:', error);
        }
    }, [dispatch, navigate, tournamentId]);

    const handleCancel = useCallback(() => {
        navigate(Pages.LOCATIONS.INDEX);
    }, [navigate]);

    return (
        <UDMainLayout headerContent={breadcrumb}>
            <div className='container py-6'>
                <UDText type="headline" className="mr-10">
                    Локация для проведения турнира
                </UDText>
            </div>
            <div className="container d-flex align-items-center bb-1" />
            <div className={'container pt-6'}>
                <LocationForm
                    isLoading={isLoading}
                    onSubmit={handleSubmit}
                    onCancel={handleCancel}
                    tournamentId={tournamentId || undefined}
                    action='create'
                    isAvailableFeatures={Boolean(info?.tournamentPlan)}
                />
            </div>
        </UDMainLayout>
    );
}

export default LocationCreatePage;
