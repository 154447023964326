import { Form, Formik, FormikHelpers, FormikProps } from "formik";

import React from "react";
import S from "./styles";
import { UDFormCheckbox } from "modules/ud-form/components";
import UDFormInput from "modules/ud-form/components/input";
import UDText from "modules/ud-ui/components/text";
import { applicationLinkValidator } from "modules/application-link/domain/validators/application-link.validator";

const format = (value: string) => {
    // Удаляем все нецифровые символы
    const numbers = value.replace(/\D/g, '');

    // Ограничиваем длину до 8 цифр (DDMMYYYY)
    const cleaned = numbers.slice(0, 8);

    // Форматируем с точками
    let formatted = '';
    for (let i = 0; i < cleaned.length; i++) {
        if (i === 2 || i === 4) {
            formatted += '.';
        }
        formatted += cleaned[i];
    }

    return formatted;
}

const parse = (value: string) => {
    // Удаляем все символы кроме цифр и точек
    const cleaned = value.replace(/[^0-9.]/g, '');

    // Ограничиваем длину до 10 символов (DD.MM.YYYY)
    return cleaned.slice(0, 10);
}

export interface IFormValues {
    surname: string;
    name: string;
    patronymic: string;
    birthday: string;
    consent: boolean;
}

interface IProps {
    onSubmit: (values: IFormValues, formikHelpers: FormikHelpers<IFormValues>) => void;
}

const ApplicationLinkForm = (props: IProps) => {

    return (
        <Formik<IFormValues>
            initialValues={{
                surname: '',
                name: '',
                patronymic: '',
                birthday: '',
                consent: false,
            }}
            validationSchema={applicationLinkValidator}
            onSubmit={props.onSubmit}
            validateOnChange={true}
            validateOnBlur={true}
        >
            {(formikProps: FormikProps<IFormValues>) => {
                return (
                    <Form onSubmit={formikProps.handleSubmit} className='flex-column gap-5 justify-content-center' style={{ maxWidth: 390, width: '100%' }}>
                        <UDFormInput name="surname" placeholder="Фамилия" errorVisible={true} />
                        <UDFormInput name="name" placeholder="Имя" errorVisible={true} />
                        <UDFormInput name="patronymic" placeholder="Отчество (не обязательно)" errorVisible={true} />
                        <UDFormInput name="birthday" placeholder="Дата рождения" errorVisible={true} mask='__.__.____' formatter={format} parser={parse} />
                        <S.CheckboxContainer>
                            <UDFormCheckbox size={20} name="consent" onChange={() => {
                                formikProps.validateField('consent');
                            }} />
                            <UDText type="subhead" style={{ fontWeight: 300, opacity: 0.8 }} color='white'>
                                Я даю согласие на обработку персональных данных
                            </UDText>
                        </S.CheckboxContainer>
                        <div className="flex-center flex-column gap-4">
                            <S.Button type="submit" variant="primary" style={{ width: '100%' }} disabled={!formikProps.isValid || !formikProps.dirty} loading={formikProps.isSubmitting}>
                                Отправить заявку
                            </S.Button>
                            <UDText type="subhead" style={{ fontWeight: 400, textAlign: 'center' }} color='white'>
                                Ваша заявка будет направлена в кабинет организатора
                            </UDText>
                        </div>
                    </Form>
                );
            }}
        </Formik>
    );
};

export default ApplicationLinkForm;
