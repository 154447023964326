import { OnChangeValue, SingleValue } from 'react-select';
import React, { useCallback, useEffect, useState } from 'react';
import {
  championshipPlayerApplicationChangeNumber,
  championshipPlayerApplicationChangePosition,
  upsertChampionshipPlayerApplication,
} from '../../../store/championshipTeams/actions';

import { AppDispatch } from 'store/store';
import { ApplicationStatus } from '../../../../applications/domain/enums/ApplicationStatus';
import { DecisionSelect } from "./styles";
import NewEditIcon from 'modules/ud-ui/components/icon/new-edit-icon';
import { PlayerApplication } from '../../../domain/interfaces/PlayerApplication';
import { PlayerNumberInput } from "modules/teams/ui/components/styles";
import { PlayerPosition } from '../../../domain/enums/PlayerPosition';
import PositionSelect from '../position-select';
import { SelectOption } from '../../../../ud-form/components/select/component';
import { TeamPlayerAvatar } from '../team-card/styles';
import { UDCheckbox } from 'modules/ud-form/components';
import UDText from '../../../../ud-ui/components/text';
import { fetchChampionshipTeams } from '../../../../tourneys/store/championship/actions';
import getBirthDay from 'shared/utils/getBirthDay';
import styled from '@emotion/styled';
import { useDispatch } from 'react-redux';

const defaultAvatar =
  'https://www.iconexperience.com/_img/o_collection_png/green_dark_grey/512x512/plain/user.png';

const CardContainer = styled.div<{ isSelected?: boolean }>`
  display: flex;
  align-items: center;
  padding: 0 20px 0 0;
  height: 72px;
  background-color: ${({ isSelected }) => isSelected ? '#F5D95680' : '#F7F7F7'};
  border-radius: 8px;
  margin-bottom: 10px;
  user-select: none;
  transition: background-color 0.2s ease;

  &:hover:not(:has(button:hover, input:hover, select:hover, [role="button"]:hover, .checkbox-area:hover, .interactive:hover)) {
    background-color: #FFF7DF;
    cursor: pointer;
    .avatar-wrapper {
      &:after {
        opacity: 1;
      }

      svg {
        opacity: 1;
      }
    }
  }
`;

const AvatarWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #FFF3D18F;
    opacity: 0;
    transition: opacity 0.2s ease;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  svg {
    position: absolute;
    z-index: 1;
    opacity: 0;
    transition: opacity 0.2s ease;
  }
`;

type TeamPlayerCardProps = {
  teamId: number;
  index: number;
  player: PlayerApplication;
  isUpdating?: boolean;
  isSelected?: boolean;
  onSelect?: (id: number) => void;
  onMouseDownCheckbox?: (event: React.MouseEvent<HTMLDivElement>) => void;
  onMouseEnterCheckbox?: () => void;
  onMouseLeaveCheckbox?: () => void;
  onClick?: () => void;
};

const TeamPlayerCard = ({
  index,
  player,
  isUpdating,
  isSelected,
  onSelect,
  onMouseDownCheckbox,
  onMouseEnterCheckbox,
  onMouseLeaveCheckbox,
  onClick,
}: TeamPlayerCardProps) => {
  const dispatch = useDispatch<AppDispatch>();

  // Используем useRef для хранения таймера
  const timerRef = React.useRef<NodeJS.Timeout | null>(null);

  const [data, setData] = useState(player);

  useEffect(() => {
    setData(player);
  }, [player]);

  const handleChange = useCallback(
    (option: SingleValue<SelectOption>) => {
      dispatch(
        upsertChampionshipPlayerApplication({
          id: data.id,
          teamId: data.teamId,
          championshipId: data.championshipId,
          createUserOrUpdateApplication: {
            userId: data.player.id,
            name: data.player.firstName,
            surname: data.player.lastName,
            middleName: data.player.middleName,
            birthDate: data.player.birthDate,
            position: data.position ? data.position.toUpperCase() : data.position,
            number: data.number,
            state: option?.value.toUpperCase(),
          },
        }),
      ).unwrap().then(() => {
        dispatch(fetchChampionshipTeams(data.championshipId));
      });
    },
    [dispatch, data],
  );

  const name = [
    data.player.lastName,
    data.player.firstName,
    data.player.middleName,
  ]
    .filter(Boolean)
    .join(' ');

  const birthDate = data.player.birthDate
    ? new Date(data.player.birthDate)
    : null;
  const full = getBirthDay(birthDate ?? undefined);

  const handleNumberChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const number = parseInt(event.target.value);

    setData({
      ...data,
      number: number < 0 ? 0 : number,
    });

    // Реализуем debounce напрямую
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }

    timerRef.current = setTimeout(() => {
      dispatch(
        championshipPlayerApplicationChangeNumber({
          id: data.id,
          teamId: data.teamId,
          championshipId: data.championshipId,
          createUserOrUpdateApplication: {
            userId: data.player.id,
            name: data.player.firstName,
            surname: data.player.lastName,
            middleName: data.player.middleName,
            birthDate: data.player.birthDate,
            position: data.position ? data.position.toUpperCase() : data.position,
            number: number < 0 ? 0 : number,
            state: data.status.toUpperCase() as ApplicationStatus,
          },
        }),
      );
    }, 2 * 1000);
  }, [dispatch, data]);

  // Добавляем обработчик потери фокуса
  const handleNumberBlur = useCallback(() => {
    // Очищаем существующий таймер debounce, чтобы избежать дублирования запросов
    if (timerRef.current) {
      clearTimeout(timerRef.current);
      timerRef.current = null;
    }

    // Немедленно отправляем текущее значение
    dispatch(
      championshipPlayerApplicationChangeNumber({
        id: data.id,
        teamId: data.teamId,
        championshipId: data.championshipId,
        createUserOrUpdateApplication: {
          userId: data.player.id,
          name: data.player.firstName,
          surname: data.player.lastName,
          middleName: data.player.middleName,
          birthDate: data.player.birthDate,
          position: data.position ? data.position.toUpperCase() : data.position,
          number: data.number,
          state: data.status.toUpperCase() as ApplicationStatus,
        },
      }),
    );
  }, [dispatch, data]);

  const handlePositionChange = useCallback(
    (option: OnChangeValue<SelectOption, false>) => {
      const position = option ? (option.value as PlayerPosition) : undefined;
      setData({
        ...data,
        position,
      });

      dispatch(
        championshipPlayerApplicationChangePosition({
          id: data.id,
          teamId: data.teamId,
          championshipId: data.championshipId,
          createUserOrUpdateApplication: {
            userId: data.player.id,
            name: data.player.firstName,
            surname: data.player.lastName,
            middleName: data.player.middleName,
            birthDate: data.player.birthDate,
            position: position ? position.toUpperCase() : position,
            number: data.number,
            state: data.status.toUpperCase() as ApplicationStatus,
          },
        }),
      );
    },
    [dispatch, data],
  );

  const options = []
  if (player.status !== 'ACCEPTED') {
    options.push({
      value: ApplicationStatus.ACCEPTED,
      label: 'Принять',
    })
  }
  if (player.status !== 'DECLINED') {
    options.push({
      value: ApplicationStatus.DECLINED,
      label: 'Отклонить',
    })
  }

  const handleContainerClick = useCallback((event: React.MouseEvent<HTMLDivElement>) => {
    const target = event.target as HTMLElement;
    const isInteractive = target.closest('button, input, select, [role="button"], .interactive, .checkbox-area');

    if (!isInteractive) {
      onClick?.();
    }
  }, [onClick]);

  return (
    <CardContainer
      isSelected={isSelected}
      onClick={handleContainerClick}
    >
      <div
        className='flex-center checkbox-area'
        style={{ width: 60, cursor: 'pointer', minWidth: 60 }}
        onMouseDown={onMouseDownCheckbox}
        onMouseEnter={onMouseEnterCheckbox}
        onMouseLeave={onMouseLeaveCheckbox}
      >
        <UDCheckbox
          checked={isSelected}
          onClick={() => onSelect?.(data.player.id)}
        />
      </div>
      <AvatarWrapper className="avatar-wrapper">
        <TeamPlayerAvatar
          url={data.player.logoUrl || defaultAvatar}
          size={60}
          minSize={60}
          square
        />
        <NewEditIcon width={24} height={24} color="#000000" />
      </AvatarWrapper>
      <UDText
        type='subhead' className='bold ml-5' style={{
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          width: '40%',
          maxWidth: 300,
        }}
      >
        {name}
      </UDText>
      <UDText
        type='subhead'
        className='bold'
        style={{
          flexBasis: '15%',
          whiteSpace: 'nowrap',
          width: 150,
          marginLeft: 20,
        }}
      >
        {full}
      </UDText>

      <div className='d-flex align-items-center' style={{ marginLeft: 'auto' }}>
        <PlayerNumberInput
          wrapperProps={{
            style: {
              marginLeft: 20,
              minWidth: 127,
            },
          }}
          name='number'
          placeholder='Номер игрока'
          min={0}
          value={data.number}
          onChange={handleNumberChange}
          onBlur={handleNumberBlur}
        />
        <PositionSelect
          className='interactive'
          name='position'
          value={data.position}
          placeholder='Амплуа'
          onChange={handlePositionChange}
        />
        <DecisionSelect
          divProps={{ style: { marginLeft: 20 } }}
          className='interactive'
          isLoading={isUpdating}
          isDisabled={isUpdating}
          placeholder='Выбрать'
          options={options}
          onChange={handleChange}
        />
      </div>
    </CardContainer>
  );
};

export default TeamPlayerCard;
