import classNames from 'classnames';
import { UDButton, UDIcon, UDText } from 'modules/ud-ui/components';
import {
    useCategoryContext,
    type StyleProps,
    type TextProps,
    type ButtonProps
} from 'shared/components/category/hooks/use-category-list-context';

// Пропсы для EmptyList
export type EmptyListProps = {
    // Индивидуальные пропсы, перезаписывающие контекст
    textProps?: Partial<TextProps>;
    buttonProps?: Partial<ButtonProps>;
    styleProps?: Partial<StyleProps>;

    // Пропсы для кнопки
    onCreateClick?: () => void;
};

/**
 * Компонент для отображения пустого состояния списка
 */
function EmptyList({
    // Локальные пропсы для перезаписи контекста
    textProps: localTextProps,
    buttonProps: localButtonProps,
    styleProps: localStyleProps,

    onCreateClick: localOnCreateClick
}: EmptyListProps) {
    // Получаем данные из контекста
    const context = useCategoryContext();

    // Объединяем данные из контекста с локальными пропсами
    const onCreateClick = localOnCreateClick || context.onCreateClick;

    const textProps = { ...context.textProps, ...localTextProps };
    const buttonProps = { ...context.buttonProps, ...localButtonProps };
    const styleProps = { ...context.styleProps, ...localStyleProps };

    // Компоненты для рендеринга
    const ButtonComponent = styleProps.buttonComponent || UDButton;

    return (
        <div className={classNames('flex-center flex-column my-auto', styleProps.containerClassName)}>
            {textProps.title && (
                <UDText
                    style={{ fontWeight: 700 }}
                    className={styleProps.textClassName}
                    type="body-text"
                >
                    {textProps.title}
                </UDText>
            )}

            {textProps.subtitle && (
                <UDText
                    className={styleProps.textClassName}
                    type="subhead"
                >
                    {textProps.subtitle}
                </UDText>
            )}

            {onCreateClick && (
                <ButtonComponent
                    className={classNames('flex-center', styleProps.buttonClassName)}
                    onClick={onCreateClick}
                    variant={buttonProps.variant || "dark"}
                >
                    <UDIcon name="plus" componentProps={{ className: 'mr-2' }} />
                    {buttonProps.text}
                </ButtonComponent>
            )}
        </div>
    );
}

export default EmptyList;
