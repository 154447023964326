import { Image, Location } from '../interfaces/location';

import { LocationDTO } from '../interfaces/dto';

export const mapLocationDtoToLocation = (dto: LocationDTO): Location => {

  const coords = dto.address?.coords && dto.address.coords.lat && dto.address.coords.lon ? {
    lat: dto.address.coords.lat,
    lon: dto.address.coords.lon,
  } : undefined;

  return {
    id: dto.id,
    name: dto.name || '',
    addressId: dto.address?.id || 0,
    address: dto.address ? {
      id: dto.address.id,
      addressLine1: dto.address?.addressLine1 || '',
      addressLine2: dto.address?.addressLine2 || '',
      city: dto.address?.city || '',
      region: dto.address?.region || '',
      country: dto.address?.country || '',
      coords: coords,
    } : null,
    description: dto.description || '',
    tournamentId: dto.tournament?.id || null,
    images: mapLocationImageDtoToLocationImage(dto),
    mapImage: dto.mapImage || null,
  };
};

export const mapLocationsDtoToLocations = (dtos: LocationDTO[]): Location[] => {
  return dtos.map(mapLocationDtoToLocation);
};

const mapLocationImageDtoToLocationImage = (dto: LocationDTO): Image[] => {

  return dto.locationImages?.map(image => ({
    id: image.image?.id || 0,
    filename: image.image?.filename || '',
    externalUrl: image.image?.externalUrl || '',
    ordinalNumber: image.ordinalNumber || 0,
  })).sort((a, b) => a.ordinalNumber - b.ordinalNumber) || [];
};
