/** @jsxImportSource @emotion/react */

import { Player, Team } from "../../domain/interfaces/player";
import { resetMomentsData, setCurrentPlayerId } from "../../store";
import { selectCurrentPlayerId, selectPlayerById } from "../../store/selectors";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { AppDispatch } from "../../../../store/store";
import { Pages } from "../../../navigation/domain/enums/pages";
import PlayerEditFormTabs from "../components/tabs-player-view";
import PlayerForm from "../components/player-form";
import PlayerLoad from "../components/playerLoad";
import PlayerMomentsPage from "../components/player-moments-page";
import { SubmitPlayerForm } from '../components/player-form';
import TeamsList from "../components/teams-list";
import UDBreadcrumbs from "../../../ud-ui/components/breadcrumbs";
import UDMainLayout from "../../../ud-ui/layout/main";
import playersRepository from "../../domain/repositories/player.repository";
import { selectOrganizerTeamById } from "../../../organizer/store/selectors";
import { toast } from "react-toastify";
import { upsertTournamentUser } from "modules/players/store/actions";
import { useOrganizer } from "../../../organizer/ui/hooks/useOrganizer";
import { usePlayers } from "modules/organizer/ui/hooks/usePlayers";
import useTournamentUsers from "../hooks/useTournamentUsers";

function PlayerViewPage() {
  const dispatch = useDispatch<AppDispatch>()

  const id = Number(useParams().id);
  const navigate = useNavigate();
  if (isNaN(id)) { navigate(Pages.PLAYERS.INDEX) }

  const location = useLocation()
  const activeTab = (location.pathname || '').split('/').at(-1);

  const { info } = useOrganizer()
  const [isLoading, setIsLoading] = useState(true)
  const [player, setPlayer] = useState<Player>()
  const findPlayer = useSelector(selectPlayerById(id));
  const { isProcessed } = useTournamentUsers()
  const currentPlayerId = useSelector(selectCurrentPlayerId)
  const [isLoadingPlayers, setIsLoadingPlayers] = useState(false)

  const teamId = Number(useLocation().state?.fromTeam);
  const fromTeam = useSelector(selectOrganizerTeamById(teamId));

  useEffect(() => {
    if (isProcessed) {
      playersRepository.getUser(id).then((player) => {
        setPlayer(player)
        setIsLoading(false)
      })
    }
  }, [id, isProcessed])

  useEffect(() => {
    if (findPlayer === undefined) {
      playersRepository.getUser(id).then((player) => {
        setPlayer(player)
        setIsLoading(false)
      })
    } else {
      setPlayer(findPlayer)
      setIsLoading(false)
    }
  }, [findPlayer, id]);

  useEffect(() => {
    if (id && id !== currentPlayerId) {
      dispatch(resetMomentsData())
      dispatch(setCurrentPlayerId({ playerId: id }))
    }
  }, [currentPlayerId, dispatch, id]);

  const fullName = useMemo(() => {
    return player && `${player?.surname} ${player?.name} ${player?.middleName ?? ''}`;
  }, [player]);

  const breadcrumbs = useMemo(() => {
    if (fromTeam) {
      const items = [
        { label: 'Команды', to: Pages.TEAMS.INDEX },
        { label: fromTeam.name, to: Pages.TEAMS.builders.edit(fromTeam.id) },
        { label: 'Игроки', to: Pages.TEAMS.builders.edit(fromTeam.id, 'players') },
        { label: fullName ?? 'Неизвестный игрок', to: Pages.PLAYERS.builders.view(id), state: { fromTeam: fromTeam.id } },
      ];

      if (activeTab === 'teams') {
        items.push({ label: 'Команды', to: Pages.PLAYERS.builders.view(id, 'teams'), state: { fromTeam: fromTeam.id } })
      }

      if (activeTab === 'moments') {
        items.push({ label: 'Моменты', to: Pages.PLAYERS.builders.view(id, 'moments'), state: { fromTeam: fromTeam.id } })
      }

      return items
    }

    const result = [
      { label: 'Игроки', to: Pages.PLAYERS.INDEX },
      { label: fullName ?? 'Неизвестный игрок', to: Pages.PLAYERS.builders.view(id) },
    ]

    if (activeTab === 'teams') {
      result.push({ label: 'Команды', to: Pages.PLAYERS.builders.view(id, 'teams') })
    }

    if (activeTab === 'moments') {
      result.push({ label: 'Моменты', to: Pages.PLAYERS.builders.view(id, 'moments') })
    }

    return result
  }, [activeTab, fullName, id, fromTeam]);

  const breadcrumb = useMemo(
    () => (
      <UDBreadcrumbs
        className='my-10'
        breadcrumbs={breadcrumbs}
      />),
    [breadcrumbs],
  );

  const teams = useMemo(() => {
    return player?.positionsInTeams?.map(positionInTeam => positionInTeam.team).filter(Boolean) as Team[] ?? []
  }, [player?.positionsInTeams]);

  const handleSubmit = useCallback<SubmitPlayerForm>(({ values, formikHelpers }) => {
    if (!info) return;

    const photo = (values.photo && Array.isArray(values.photo)) ? values.photo : undefined;
    const height = values.height && !isNaN(Number(values.height)) ? Number(values.height) : undefined;
    const weight = values.weight && !isNaN(Number(values.weight)) ? Number(values.weight) : undefined;

    setIsLoadingPlayers(true)

    dispatch(
      upsertTournamentUser({
        userId: values?.id,
        tournamentId: info.id,
        name: values.name,
        surname: values.surname,
        middleName: values.middleName,
        birthDate: values.birthDate,
        height,
        weight,
        photo,
      }),
    ).unwrap().then(() => {
      toast.success('Игрок успешно сохранён');
    }).finally(() => {
      formikHelpers.setSubmitting(false);
      setIsLoadingPlayers(false)
    });
  }, [dispatch, info])

  const body = useMemo(() => {
    if (!info || isLoading) {
      return <PlayerLoad />
    }

    if (!player) {
      return (
        <div className='container pt-6'>
          <div className='flex-column flex-center mt-24'>
            <div className='text body-text bold mb-2'>
              Такой игрок не найден
            </div>
          </div>
        </div>
      )
    }

    switch (activeTab) {
      case 'teams':
        return (
          <div className='mt-6'>
            <TeamsList player={player} teams={teams ?? []} />
          </div>
        )
      case 'common':
        return (
          <PlayerForm
            initialValues={player}
            onSubmit={handleSubmit}
            isLoading={isLoadingPlayers}
          />
        )
      case 'moments':
        return (
          <PlayerMomentsPage player={player} />
        )
      default:
        return null
    }
  }, [activeTab, handleSubmit, info, isLoading, isLoadingPlayers, player, teams]);

  return (
    <UDMainLayout headerContent={breadcrumb}>
      <div className={'container pt-6'}>
        <div className={'mb-6'}>
          {player ? (
            <PlayerEditFormTabs state={{ fromTeam: teamId }} />
          ) : (
            <></>
          )}
        </div>
        <div>
          {body}
        </div>
      </div>
    </UDMainLayout>
  );
}

export default PlayerViewPage;
