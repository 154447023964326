import { Field, FieldProps, useFormikContext } from 'formik';
import { Image, UDFormDropZoneProps } from '../types';

import React from 'react';
import UDButton from '../../../../ud-ui/components/button';
import UDDropZone from './UDDropZone';
import UDFormLabel from '../../label';
import UDText from '../../../../ud-ui/components/text';
import classNames from 'classnames';
import { useImageManagement } from '../hooks/useImageManagement';

const ACCEPTED_FORMATS = 'image/png,image/jpeg,image/jpg';

type UDFormSingleImageProps = Omit<UDFormDropZoneProps, 'multiple' | 'enableDragAndDrop'> & {
    name: string;
    label?: string;
};

const UDFormSingleImage: React.FC<UDFormSingleImageProps> = ({
    name,
    containerProps,
    label,
    labelProps,
    formProps,
    onChangeInterceptor = (value: Blob) => value,
    value,
    ...otherDropZoneProps
}) => {
    const { setFieldValue, values } = useFormikContext<Record<string, any>>();
    const currentValue = values[name] as Image | File | null;

    const {
        images,
        handleFileChange,
    } = useImageManagement({
        name,
        multiple: false,
        currentValue,
        setFieldValue,
        onChangeInterceptor
    });

    const getImageUrl = () => {
        if (!images.length) return null;
        const currentImage = images[0];

        if (currentImage instanceof File) {
            return URL.createObjectURL(currentImage);
        }

        return currentImage.url || currentImage.externalUrl;
    };

    const imageUrl = getImageUrl();

    const renderMainContent = () => {
        return (
            <div className='d-flex flex-column align-items-center'>
                <UDText type="footnote" className="color-SurfaceRaisinBlack50 mb-2">
                    Изменить фото игрока
                </UDText>
                <UDButton variant='secondary' type='button'>
                    Выберите файлы
                </UDButton>
            </div>
        )
    }

    const renderDropzoneContent = () => {
        if (imageUrl) {
            return (
                <div className="d-flex flex-row align-items-center px-5 w-100">
                    <img
                        src={imageUrl}
                        alt="Preview"
                        style={{
                            width: '60px',
                            height: '60px',
                            objectFit: 'cover',
                            borderRadius: '8px',
                            marginRight: '82px'
                        }}
                    />
                    {renderMainContent()}
                </div>
            );
        }

        return renderMainContent();
    };

    return (
        <Field name={name}>
            {({ field, meta }: FieldProps<any>) => {
                const isErrorVisible = (meta.touched && meta.error && label);
                return (
                    <div {...containerProps}>
                        {label && (
                            <UDFormLabel
                                {...labelProps}
                                className={classNames('mb-2', labelProps?.className)}
                            >
                                {label}
                            </UDFormLabel>
                        )}
                        {isErrorVisible && (
                            <UDText type="caption-1" className="color-CaseError">
                                {meta.error}
                            </UDText>
                        )}
                        <div {...formProps}>
                            <UDDropZone
                                accept={ACCEPTED_FORMATS}
                                {...otherDropZoneProps}
                                {...field}
                                onChange={handleFileChange}
                                multiple={false}
                            >
                                {renderDropzoneContent()}
                            </UDDropZone>
                            <span className="css-1 text footnote mt-2">
                                Формат: png, jpg
                            </span>
                        </div>
                    </div>
                );
            }}
        </Field>
    );
};

export default UDFormSingleImage;
