import { useCategoryContext, type StyleProps } from 'shared/components/category/hooks/use-category-list-context';

import { DivProps } from "typings";
import { ReactElement } from "react";
import { UDList } from 'modules/ud-ui/components';
import { UDListItemProps } from 'modules/ud-ui/components/list/components/list-item';
import classNames from "classnames";

// Пропсы для компонента списка
export type CategoryListProps = {
    // Элементы списка
    items: ReactElement<UDListItemProps>[];

    // Дополнительные настройки стилей (опционально)
    styleProps?: Partial<StyleProps>;
} & DivProps;

/**
 * Компонент для отображения списка элементов
 * Используется внутри CategoryListProvider
 */
function CategoryList({ items, styleProps: customStyleProps, ...rest }: CategoryListProps) {
    // Получаем стили из контекста
    const { styleProps: contextStyleProps } = useCategoryContext();

    // Объединяем стили из контекста и переданные напрямую
    const mergedStyleProps = { ...contextStyleProps, ...customStyleProps };

    // Определяем компонент для отображения списка
    const ListComponent = mergedStyleProps.listComponent || UDList;

    return (
        <ListComponent
            {...rest}
            className={classNames('transparent', mergedStyleProps.listClassName, rest.className)}
        >
            {items}
        </ListComponent>
    );
}

export default CategoryList;
