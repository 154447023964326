/** @jsxImportSource @emotion/react */

import CrossIcon from '../../../../ud-ui/components/icon/cross-icon';
import EmptyButton from '../../../../ud-ui/components/emptyButton';
import { Image } from '../types';
import React from 'react';
import UDText from "../../../../ud-ui/components/text";
import classNames from 'classnames';
import { formatBytes } from '../utils/formatBytes';
import { imageContainerStyles } from '../styles';

interface ImageItemProps {
    img: Omit<Image, 'id'>;
    index: number;
    onDelete: (index: number) => void;
    onDeleteImage?: (index: number) => void;
    multiple?: boolean;
    onImageClick?: (index: number) => void;
    disabled?: boolean;
}

export const ImageItem: React.FC<ImageItemProps> = ({
    img,
    index,
    onDelete,
    onDeleteImage,
    multiple,
    onImageClick,
    disabled,
}) => (
    <div css={imageContainerStyles}>
        {onDeleteImage && !disabled && (
            <div>
                <EmptyButton
                    type="button"
                    icon={<CrossIcon />}
                    onClick={(e) => {
                        e.stopPropagation();
                        onDelete(index);
                    }}
                    className="delete-button"
                />
            </div>
        )}
        <div
            onClick={(e) => {
                e.stopPropagation();
                if (!e.defaultPrevented) {
                    onImageClick?.(index);
                }
            }}
            style={{ cursor: 'pointer' }}
        >
            <img
                className="br-8"
                src={img.url || img.externalUrl}
                alt={`Изображение ${index + 1}`}
            />
        </div>
        {img.name && (
            <div
                className={classNames('d-flex flex-column', { 'ml-5': !multiple })}
                onClick={(e) => e.stopPropagation()}
            >
                <UDText
                    style={multiple ? {
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        width: 100
                    } : {}}
                    type="subhead"
                >
                    {img.name}
                </UDText>
                <span className="css-1 text footnote">
                    {img.size ? formatBytes(img.size) : ''}
                </span>
                <span className="css-1 text footnote" style={{ textAlign: 'end' }}>
                    100%
                </span>
                <progress style={{ width: 'auto' }} max="100" value="100" />
            </div>
        )}
    </div>
);
