import * as S from './styles';

import ChangeProfileIcon from 'modules/ud-ui/components/icon/change-profile-icon';
import { Organizer } from 'modules/organizer/domain/interfaces/Organizer';
import { Pages } from '../../../../../navigation/domain/enums/pages';
import PlusIcon from 'modules/ud-ui/components/icon/plus-icon';
import React from 'react';
import UDButton from 'modules/ud-ui/components/button';
import UDColorableIcon from 'modules/ud-ui/components/icon/colorable-icon';
import UDLink from '../../../link';

type UDMenuLogoutProps = {
  isOrganizerLoading: boolean;
  organizerInfo: Organizer | null;
  onLogoutClick?: React.MouseEventHandler<HTMLElement>;
  onPlusClick?: React.MouseEventHandler<HTMLElement>;
  onChangeProfileClick?: React.MouseEventHandler<HTMLElement>;
};

const UDMenuLogout = (props: UDMenuLogoutProps) => {
  if (!props.organizerInfo) {
    return (
      <S.MenuLogout className="d-flex flex-row flex-center align-items-start pl-10 pr-2">
        <UDButton
          variant="dark"
          className="logout d-flex align-items-center ml-auto"
          onClick={props.onLogoutClick}
        >
          <UDColorableIcon name="logout" />
        </UDButton>
      </S.MenuLogout>
    );
  }

  const logo = props.organizerInfo.logoUrl
    ? (<S.MenuLogo src={props.organizerInfo.logoUrl!} width={36} />)
    : null;

  return (
    <S.MenuLogout className="d-flex flex-row flex-center align-items-start pl-10 pr-2">
      <UDLink
        className="bold color-SurfaceWhite organizer-link"
        to={Pages.SELECT_TOURNAMENT}
      >
        {logo}
        <span className='organizer-link-name'>{props.organizerInfo.name}</span>
      </UDLink>

      <UDButton
        variant="dark"
        className="icon d-flex align-items-center ml-auto"
        onClick={props.onChangeProfileClick}
      >
        <ChangeProfileIcon />
      </UDButton>
      <UDButton
        variant="dark"
        className="icon d-flex align-items-center"
        onClick={props.onPlusClick}
      >
        <PlusIcon />
      </UDButton>
      <UDButton
        variant="dark"
        className="icon d-flex align-items-center"
        onClick={props.onLogoutClick}
      >
        <UDColorableIcon name="logout" />
      </UDButton>
    </S.MenuLogout>
  );
};

export default UDMenuLogout;
