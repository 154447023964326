/** @jsxImportSource @emotion/react */

import React, { useEffect, useMemo } from "react";
import { fetchBoardsByTemplateId, fetchCatalogTemplates } from "../../store/templates/actions";
import { useDispatch, useSelector } from "react-redux";

import { AppDispatch } from "../../../../store/store";
import Header from "../components/catalog/header";
import NewTemplate from "../components/catalog/newTemplate";
import NotFound from '../../../ud-ui/components/images/notFound.png';
import { Pages } from "../../../navigation/domain/enums/pages";
import TemplateItem from "../components/catalog/templateItem";
import { TemplateListCatalog } from "./styles";
import UDBreadcrumbs from "../../../ud-ui/components/breadcrumbs";
import UDMainLayout from "../../../ud-ui/layout/main";
import UDText from "../../../ud-ui/components/text";
import { selectCatalogTemplatesByFilter } from "../../store/templates/selectors";
import { useAuth } from "../../../auth/ui/hooks/useAuth";
import useTemplates from "modules/template-editor/ui/hooks/useTemplates";

function Catalog() {
  const { user } = useAuth()
  const breadcrumb = useMemo(() => (<UDBreadcrumbs
    className='my-10' breadcrumbs={[
      { label: 'Турниры', to: Pages.TOURNEYS.INDEX },
      { label: 'Шаблоны', to: Pages.TEMPLATE.INDEX },
      { label: 'Готовые шаблоны', to: Pages.TEMPLATE.CATALOG.INDEX },
    ]}
  />), []);

  const dispatch = useDispatch<AppDispatch>()
  const templates = useSelector(selectCatalogTemplatesByFilter);
  const { loadedTemplates } = useTemplates()

  const isDev = process.env.REACT_APP_ENVIRONMENT !== 'production'
  const isAdmin = useMemo(() => {
    if (isDev) return true
    if (!user) return false
    return user.id === 20
  }, [isDev, user]);

  useEffect(() => {
    if (templates.length > 0) return
    dispatch(fetchCatalogTemplates({ visibility: isAdmin ? ['superAdmin', 'all'] : ['all'] }))
  }, [dispatch, isAdmin, templates.length]);

  useEffect(() => {
    if (templates.length > 0) {
      const promises = templates.filter(template => !loadedTemplates.includes(template.id)).map(template => dispatch(fetchBoardsByTemplateId({ templateId: template.id })))
      Promise.all(promises)
    }
  }, [dispatch, loadedTemplates, templates, templates.length]);

  return (
    <UDMainLayout headerContent={breadcrumb}>
      <div className={'d-flex flex-column'} style={{ height: '100%', overflow: 'hidden' }}>
        <Header />
        {templates.length > 0 ? (
          <TemplateListCatalog>
            {templates.map((template) => (
              <TemplateItem key={template.id} template={template} />
            ))}
            {isAdmin && (
              <NewTemplate />
            )}
          </TemplateListCatalog>
        ) : (
          <div className={'flex-column h-100 w-100 flex-center'}>
            <img src={NotFound} alt={'empty'} style={{ height: 130, width: 130 }} />
            <UDText type={'callout'} style={{ color: '#8F8E99' }}>По такому запросу ничего не найдено</UDText>
          </div>
        )}
      </div>
    </UDMainLayout>
  );
}

export default Catalog;
