import { SVGProps } from "react";

type IProps = {
    width?: number;
    height?: number;
} & SVGProps<SVGSVGElement>;

const BelarusIcon = (props: IProps) => {
    const { width, height, ...rest } = props;

    return (
        <svg width={width ?? 24} height={height ?? 24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
            <g clip-path="url(#clip0_15414_22686)">
                <path d="M6 18.5H19C19.5304 18.5 20.0391 18.2893 20.4142 17.9142C20.7893 17.5391 21 17.0304 21 16.5V14.5H6V18.5Z" fill="#4AA657" />
                <path d="M19 5.5H6V14.5H21V7.5C21 6.96957 20.7893 6.46086 20.4142 6.08579C20.0391 5.71071 19.5304 5.5 19 5.5Z" fill="#C8313E" />
                <path d="M5 5.5C4.46957 5.5 3.96086 5.71071 3.58579 6.08579C3.21071 6.46086 3 6.96957 3 7.5L3 16.5C3 17.0304 3.21071 17.5391 3.58579 17.9142C3.96086 18.2893 4.46957 18.5 5 18.5H6V5.5H5Z" fill="white" />
                <path d="M3.38305 17.594L3.69555 17.0395L4.01605 17.61L3.74505 18.0455C3.83044 18.1149 3.92142 18.1771 4.01705 18.2315L4.41305 17.513L3.70305 16.375L3.14455 17.238C3.23605 17.468 3.37005 17.674 3.53505 17.853L3.38305 17.594ZM5.99055 17.5485L5.24205 16.4375L4.49105 17.523L5.13405 18.5H5.45755L5.99055 17.5485ZM5.26405 17.039L5.58455 17.6095L5.26405 18.125L4.95155 17.594L5.26405 17.039ZM6.00005 12.1665V11.7195L5.84355 11.9375L6.00005 12.1665ZM4.41255 6.513L3.96555 5.796C3.5745 6.03436 3.2771 6.39972 3.12305 6.831L3.76555 7.6875L4.41255 6.513ZM3.38305 6.594L3.69555 6.0395L4.01605 6.61L3.69555 7.125L3.38305 6.594ZM5.30855 7.7655L5.99055 6.548L5.28455 5.5H5.19905L4.49155 6.523L5.30855 7.7655ZM5.58405 6.6095L5.26405 7.125L4.95155 6.594L5.26405 6.0395L5.58405 6.6095Z" fill="#C8313E" />
                <path d="M5.4765 10.5L5.914 9.7735L6 9.8115V9.5L5.297 8.406L4.453 9.578L3.703 8.375L3 9.4615V9.7865L3.4295 10.5L3.734 10.0545L3.5155 9.75L3.6795 9.531L4.156 10.148L3.453 11.304L3 10.6805V11.457L3.029 11.4185L3.5055 12.0355L3 12.8675V13.456L3.453 12.832L4.156 13.988L3.761 14.5L3.6795 14.6055L3.6005 14.5L3.5155 14.3865L3.7345 14.082L3.43 13.6365L3 14.3505V14.638L3.719 15.7345L4.419 14.5L4.4455 14.453L4.4745 14.5L5.2815 15.8045L6 14.531V14.3355L5.9375 14.3635L5.5 13.6365L5.195 14.082L5.414 14.3865L5.329 14.5L5.25 14.6055L5.1685 14.5L4.7735 13.9885L5.4765 12.8325L6 13.553V12.982L5.4215 12.031L5.898 11.414L6 11.55V10.5515L5.453 11.305L4.75 10.1485L5.2265 9.5315L5.3905 9.75L5.1715 10.0545L5.4765 10.5ZM5.1425 11.5155V12.4795L4.445 13.581L3.788 12.475V11.521L4.445 10.4145L5.1425 11.5155Z" fill="#C8313E" />
                <path d="M4.01568 11.4113L4.31468 11.9568L4.01568 12.5023L4.23468 12.6223L4.45718 12.2168L4.67968 12.6223L4.89868 12.5023L4.59968 11.9568L4.89868 11.4113L4.67968 11.2913L4.45718 11.6968L4.23468 11.2913L4.01568 11.4113ZM3.01318 16.0938L3.32568 16.6248L3.64618 16.1093L3.32568 15.5388L3.01318 16.0938ZM3.77118 16.0938L4.08368 16.6248L4.40368 16.1093L4.08368 15.5388L3.77118 16.0938ZM4.51318 16.0938L4.82568 16.6248L5.14618 16.1093L4.82568 15.5388L4.51318 16.0938ZM5.30918 16.0938L5.62168 16.6248L5.94168 16.1093L5.62168 15.5388L5.30918 16.0938ZM3.01318 8.06231L3.32568 8.59381L3.64618 8.07781L3.32568 7.50781L3.01318 8.06231ZM3.77118 8.06231L4.08368 8.59381L4.40368 8.07781L4.08368 7.50781L3.77118 8.06231ZM4.51318 8.06231L4.82568 8.59381L5.14618 8.07781L4.82568 7.50781L4.51318 8.06231ZM5.30918 8.06231L5.62168 8.59381L5.94168 8.07781L5.62168 7.50781L5.30918 8.06231Z" fill="#C8313E" />
            </g>
            <defs>
                <clipPath id="clip0_15414_22686">
                    <rect width="18" height="18" fill="white" transform="translate(3 3)" />
                </clipPath>
            </defs>
        </svg>

    );
};

export default BelarusIcon;
