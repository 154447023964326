/** @jsxImportSource @emotion/react */

import { scrollbar } from 'shared/styles/scrollbar';
import styled from '@emotion/styled';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
  overflow-y: auto;
  ${({ theme }) => scrollbar(theme)}
  background-color: ${({ theme }) => theme.colors.SurfaceRaisinBlack50};

  & .header {
    margin-bottom: 20px;
  }

  & .content {
    margin-block: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  & .left,
  & .right {
    width: 50%;
  }

  & .left {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 40px 110px 50px 50px;
  }

  & .right {
    padding: 40px 50px 50px 110px;
  }

  .tournaments-list {
    overflow-x: auto;
  }

  & .footer {
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    gap: 10px;
    flex-wrap: wrap;
  }
`;

export const AlreadyContainer = styled.div`
  box-sizing: border-box;
  color: ${({ theme }) => theme.colors.SurfaceWhite};
  display: flex;
  width: 100%;
  justify-content: center;
  gap: 4px;

  margin-top: auto;
`;

export const FormContainer = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.SurfaceIndependence40};
  box-sizing: border-box;
  border-radius: 8px;
  padding: 30px 30px 40px 30px;
  width: 404px;

  display: flex;
  flex-direction: column;
  justify-content: center;

  ${({ theme }) => scrollbar(theme)}
`;
