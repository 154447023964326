import { Background, backgrounds } from 'shared/constants/backgrounds';
import { Field, FieldProps, useFormikContext } from 'formik';

import BackgroundLogo from './component/BackgroundLogo';
import UDDropZone from './component/UDDropZone';
import { UDFormDropZoneProps } from './types';
import UDFormLabel from '../label';
import UDText from '../../../ud-ui/components/text';
import classNames from 'classnames';
import { useImageManagement } from './hooks/useImageManagement';

const ACCEPTED_FORMATS = 'image/png,image/jpeg,image/jpg';

interface UDFormDropZoneCoverProps extends UDFormDropZoneProps {
    background?: Background;
    backgroundsList?: Background[];
    isLoading?: boolean;
}

export interface ImageBackground {
    img?: string | File;
    background?: Background;
}

const UDFormDropZoneCover = (props: UDFormDropZoneCoverProps) => {
    const {
        name,
        containerProps,
        label,
        labelProps,
        formProps,
        onChangeInterceptor = (value: Blob) => value,
        value,
        background,
        backgroundsList,
        isLoading,
        ...otherDropZoneProps
    } = props;

    const { setFieldValue, values } = useFormikContext<Record<string, any>>();
    const currentValue = values[name] as ImageBackground | null;

    const changeImage = (name: string, value: File[]) => {
        setFieldValue(name, { ...currentValue, img: value[0] });
    }

    const changeBackground = (value: Background) => {
        setFieldValue(name, { ...currentValue, background: value });
    }

    const currentValueImg = typeof currentValue?.img === 'string' ? { url: currentValue.img } : currentValue?.img ?? null;

    const {
        images,
        handleFileChange,
    } = useImageManagement({
        name,
        multiple: false,
        currentValue: currentValueImg,
        setFieldValue: changeImage,
        onChangeInterceptor
    });

    const getImageUrl = () => {
        if (!images.length) return null;
        const currentImage = images[0];

        if (currentImage instanceof File) {
            return URL.createObjectURL(currentImage);
        }

        return currentImage.url || currentImage.externalUrl;
    };

    const imageUrl = getImageUrl();

    const renderImage = () => {
        if (!imageUrl) return null;

        return (
            <div className="mt-7 d-flex" style={{ position: 'relative' }}>
                <BackgroundLogo
                    img={imageUrl}
                    background={currentValue?.background}
                    isLoading={isLoading}
                    onChangeBackground={changeBackground}
                    backgroundsList={backgroundsList ?? backgrounds}
                />
            </div>
        );
    };

    return (
        <Field name={name}>
            {({ field, meta }: FieldProps<any>) => {
                const isErrorVisible = (meta.touched && meta.error && label);
                return (
                    <div {...containerProps}>
                        {label && (
                            <UDFormLabel
                                {...labelProps}
                                className={classNames('mb-2', labelProps?.className)}
                            >
                                {label}
                            </UDFormLabel>
                        )}
                        {isErrorVisible && (
                            <UDText type="caption-1" className="color-CaseError">
                                {meta.error}
                            </UDText>
                        )}
                        <div {...formProps}>
                            <UDDropZone
                                accept={ACCEPTED_FORMATS}
                                {...otherDropZoneProps}
                                {...field}
                                onChange={handleFileChange}
                                multiple={false}
                            />
                            <span className="css-1 text footnote mt-2">
                                Формат: png, jpg
                            </span>
                            {renderImage()}
                        </div>
                    </div>
                );
            }}
        </Field>
    );
};

export default UDFormDropZoneCover;
