import UDButton from 'modules/ud-ui/components/button';
import sizes from 'modules/application-link/domain/constants/sizes';
import styled from '@emotion/styled';

const styles = {
  Button: styled(UDButton)`
    transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;

    &:disabled {
      background-color: #C0C0C0 !important;
      outline: #C0C0C0 !important;
      border:1px solid #C0C0C0 !important;
      color: ${({ theme }) => theme.colors.DarkBlue} !important;

      cursor: not-allowed;
    }
  `,
  CheckboxContainer: styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;

    @media (max-width: ${sizes.mobile.WIDTH}px) {
      justify-content: start;
    }
  `,
};

export default styles;
