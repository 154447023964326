/** @jsxImportSource @emotion/react */

import * as S from './styles';

import AuthComponentsBillboard from '../components/billboard';
import { DivProps } from 'typings';
import React from 'react';
import UDLink from 'modules/ud-ui/components/link';
import UDLogo from 'modules/ud-ui/components/logo';
import UDText from 'modules/ud-ui/components/text';
import classNames from 'classnames';

type Props = {
  title: string;
  subtitle: string;
  action?: {
    title: string;
    linkLabel: string;
    link: string;
  };
  children: React.ReactNode;
  contentClassName?: string;
  formContainerProps?: DivProps;
};

const AuthLayoutsMain = (props: Props) => {
  return (
    <S.Container>
      <div className="left p-10 flex-column">
        <div className="flex-column h-100 ml-auto" style={{ width: 'min-content' }}>
          <UDLogo to={''} className="d-block header" />
          <div className="content">
            <S.FormContainer {...props.formContainerProps}>
              {props.title && props.subtitle && <div className="d-flex flex-column justify-content-between header" style={{ gap: 10, marginBottom: 30 }}>
                <UDText type="title-1" className="color-SurfaceWhite" style={{ fontFamily: 'SF Pro Text', fontWeight: 300 }}>
                  {props.title}
                </UDText>
                <UDText
                  type="callout"
                  style={{ fontFamily: 'SF Pro Text' }}
                  className="subtitle color-WhiteGray">
                  {props.subtitle}
                </UDText>
              </div>}
              <div className={classNames(props.contentClassName)}>{props.children}</div>
              {props.action && (
                <S.AlreadyContainer>
                  <UDText type="subhead" className="color-SurfaceWhite">
                    {props.action.title}
                  </UDText>
                  <UDLink
                    to={props.action.link}
                    type="subhead"
                    className="color-AccentNapplesYellow">
                    {props.action.linkLabel}
                  </UDLink>
                </S.AlreadyContainer>
              )}
            </S.FormContainer>
          </div>
          <div className="footer">
            <a
              className="link text subhead mr-6"
              href="https://imgameapp.ru/#organizer"
              target="_blank"
              rel="noreferrer"
            >
              О приложении
            </a>
            <a
              className="link text subhead mr-6"
              href="https://imgameapp.ru/privacy-policy"
              target="_blank"
              rel="noreferrer"
            >
              Политика конфиденциальности
            </a>
            <a
              className="link text subhead mr-6"
              href="https://imgameapp.ru/terms-and-conditions"
              target="_blank"
              rel="noreferrer"
            >
              Условия использования
            </a>
          </div>

        </div></div>
      <AuthComponentsBillboard className="right" />
    </S.Container>
  );
};

export default AuthLayoutsMain;
