import styled from '@emotion/styled';

export const MenuLogout = styled.div`
  & .icon {
    padding: 6px;

    &:active {
      background-color: ${({ theme }) => theme.colors.SurfaceSpaceCadet50};
    }
    &:focus {
      outline-color: transparent;
      border-color: transparent;
    }
  }

  & .organizer-link {
    display: flex;
    align-items: center;

    & .organizer-link-name {
      transition: color 0.2s ease-in-out;
    }

    &:hover {
      & .organizer-link-name {
        color: ${({ theme }) => theme.colors.AccentNaplesYellow};
      }
    }
  }
`;

export const MenuLogo = styled.img`
  margin-right: 8px;
  border-radius: 8px;
`;
