import { ActionReducerMapBuilder, PayloadAction, createSlice } from "@reduxjs/toolkit";
import { createTeam, editTeam, fetchOrganizer, fetchTeamPlayers, resetOrganizer, upsertOrganizer } from "./actions";

import { Organizer } from "../domain/interfaces/Organizer";

type OrganizerSliceState = {
  isLoading: boolean;
  isLoaded: boolean;
  isTeamCreating: boolean;
  isTeamEditing: boolean;
  info: Organizer | null;
};

type OrganizerReducerBuilder = ActionReducerMapBuilder<OrganizerSliceState>;

const initialState: OrganizerSliceState = {
  isLoading: false,
  isLoaded: false,
  isTeamCreating: false,
  isTeamEditing: false,
  info: null,
};

function createFetchOrganizerReducer(builder: OrganizerReducerBuilder) {
  builder.addCase(fetchOrganizer.pending, (state) => {
    state.isLoading = true;
  });

  builder.addCase(fetchOrganizer.fulfilled, (state, action) => {
    const info = action.payload
      ? {
        ...action.payload,
        teams: action.payload.teams.sort((a, b) => {
          if (a.name.toLowerCase() < b.name.toLowerCase()) {
            return -1;
          }
          if (a.name.toLowerCase() > b.name.toLowerCase()) {
            return 1;
          }
          return 0;
        }),
      }
      : action.payload;

    state.isLoading = false;
    state.isLoaded = true;
    state.info = info;
  });

  builder.addCase(fetchOrganizer.rejected, (state) => {
    state.isLoading = false;
    state.isLoaded = true;
  });
}

function createUpsertOrganizerReducer(builder: OrganizerReducerBuilder) {
  builder.addCase(upsertOrganizer.pending, (state) => {
    state.isLoading = true;
  });

  builder.addCase(upsertOrganizer.fulfilled, (state, action) => {
    state.isLoading = false;
    state.isLoaded = true;
    const prevBackgroundColor = action.payload.backgroundColor;
    state.info = action.payload;
    if (action.payload.backgroundColor) {
      state.info.backgroundColor = action.payload.backgroundColor;
    } else {
      state.info.backgroundColor = prevBackgroundColor
    }
  });

  builder.addCase(upsertOrganizer.rejected, (state) => {
    state.isLoading = false;
    state.isLoaded = true;
  });
}

function createResetOrganizerReducer(builder: OrganizerReducerBuilder) {
  builder.addCase(resetOrganizer, (state) => {
    state.isLoaded = false;
    state.isLoading = false;
    state.info = null;
  });
}

function createCreateOrganizerTeam(builder: OrganizerReducerBuilder) {
  builder.addCase(createTeam.pending, (state) => {
    state.isTeamCreating = true;
  });

  builder.addCase(createTeam.fulfilled, (state, { payload }) => {
    state.isTeamCreating = false;

    if (state.info) {
      const teams = [
        ...state.info.teams,
        payload,
      ].sort((a, b) => {
        if (a.name.toLowerCase() < b.name.toLowerCase()) {
          return -1;
        }
        if (a.name.toLowerCase() > b.name.toLowerCase()) {
          return 1;
        }
        return 0;
      })

      state.info = {
        ...state.info,
        teams,
      };
    }
  });

  builder.addCase(createTeam.rejected, (state) => {
    state.isTeamCreating = false;
  });
}

function createEditOrganizerTeam(builder: OrganizerReducerBuilder) {
  builder.addCase(editTeam.pending, (state) => {
    state.isTeamEditing = true;
  });

  builder.addCase(editTeam.fulfilled, (state, { payload }) => {
    state.isTeamEditing = false;

    if (state.info) {
      const editingTeam = state.info.teams.find((team) => team.id === payload.id);
      const editingTeamIndex = editingTeam ? state.info.teams.indexOf(editingTeam) : -1;

      if (editingTeamIndex !== -1) {
        state.info.teams.splice(editingTeamIndex, 1, payload);
        const teams = [
          ...state.info.teams,
        ].sort((a, b) => {
          if (a.name.toLowerCase() < b.name.toLowerCase()) {
            return -1;
          }
          if (a.name.toLowerCase() > b.name.toLowerCase()) {
            return 1;
          }
          return 0;
        })

        state.info = {
          ...state.info,
          teams,
        };
      }
    }
  });

  builder.addCase(editTeam.rejected, (state) => {
    state.isTeamEditing = false;
  });
}

function createFetchTeamPlayersReducer(builder: OrganizerReducerBuilder) {
  builder.addCase(fetchTeamPlayers.pending, (state) => {
    state.isTeamEditing = true;
  });

  builder.addCase(fetchTeamPlayers.fulfilled, (state, { payload, meta }) => {
    state.isTeamEditing = false;
    if (state.info) {
      const team = state.info.teams.find((team) => team.id === meta.arg.teamId);
      if (team) {
        team.players = payload;
      }
    }
  });

  builder.addCase(fetchTeamPlayers.rejected, (state) => {
    state.isTeamEditing = false;
  });
}
const slice = createSlice({
  name: 'organizer',
  initialState,
  reducers: {
    removeChampionshipFromList: (state, action: PayloadAction<{ id: number }>) => {
      if (!state.info) return
      state.info.latestTournaments = state.info.latestTournaments.filter((t) => t.id !== action.payload.id);
    }
  },
  extraReducers: (builder) => {
    createFetchOrganizerReducer(builder);
    createUpsertOrganizerReducer(builder);
    createResetOrganizerReducer(builder);
    createCreateOrganizerTeam(builder);
    createEditOrganizerTeam(builder);
    createFetchTeamPlayersReducer(builder);
  },
});

export const {
  removeChampionshipFromList,
} = slice.actions;

const organizerReducer = slice.reducer;
export default organizerReducer;
