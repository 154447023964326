import * as yup from 'yup';

import { Formik, FormikHelpers } from 'formik';
import { Image, Player } from '../../domain/interfaces/player';

import { BorderedInput } from 'modules/teams/ui/components/styles';
import { TeamPlayerForm } from "modules/teams/ui/components/team-card/styles";
import UDButton from 'modules/ud-ui/components/button';
import UDFormDropZone from 'modules/ud-form/components/dropzone/index';
import UDFormInput from 'modules/ud-form/components/input';
import { birthDateFormatter } from "../../../ud-form/components/input/domain/formatters/birthDateFormatter";
import { format } from "date-fns";
import { history } from "../../../../store/store";
import { useCallback } from 'react';

interface FormValues {
  id?: number,
  surname: string,
  name: string,
  middleName?: string,
  birthDate: string,
  height?: string,
  weight?: string,
  photo?: Image | File[]
}

export type SubmitPlayerForm = ({ values, formikHelpers }: { values: FormValues, formikHelpers: FormikHelpers<FormValues> }) => void;

interface FormProps {
  onSubmit: SubmitPlayerForm;
  initialValues?: Player
  isLoading?: boolean
};

export const teamPlayerValidator = yup.object().shape({
  name: yup.string().required('Обязательное поле'),
  surname: yup.string().required('Обязательное поле'),
  birthDate: yup
    .string()
    .matches(
      /^(\d{2})\.(\d{2})\.(\d{4})$/,
      'Дата рождения должна быть в формате: число.месяц.год',
    )
    .required('Обязательное поле'),
});

const PlayerForm = (props: FormProps) => {
  const {
    onSubmit,
    initialValues: initialPlayer,
    isLoading: isLoadingForm,
  } = props;

  const initialValues: FormValues = initialPlayer
    ? {
      id: initialPlayer.id,
      surname: initialPlayer.surname,
      name: initialPlayer.name,
      middleName: initialPlayer.middleName,
      birthDate: initialPlayer.birthDate ? format(new Date(initialPlayer.birthDate), 'dd.MM.yyyy') : '',
      height: initialPlayer.height?.toString(),
      weight: initialPlayer.weight?.toString(),
      photo: initialPlayer.photo,
    }
    : {
      surname: '',
      name: '',
      middleName: '',
      birthDate: '',
    };

  const handleCancel = useCallback(() => {
    history.back()
  }, [])

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={teamPlayerValidator}
      onSubmit={(values, formikHelpers) => onSubmit({ values, formikHelpers })}
    >
      {(formProps) => (
        <form
          className='d-flex flex-column justify-content-between h-100'
          onSubmit={formProps.handleSubmit}
        >
          <TeamPlayerForm>
            <div className='row'>
              <div className='col-6' style={{ paddingRight: 24 }}>
                <UDFormInput
                  name='surname'
                  label='Фамилия'
                  placeholder='Введите фамилию'
                />

                <div className='player-data'>
                  <UDFormInput
                    name='name'
                    label='Имя'
                    placeholder='Введите имя'
                  />

                  <UDFormInput
                    name='middleName'
                    label='Отчество'
                    placeholder='Введите отчество'
                  />
                </div>

                <div className='player-data'>
                  <UDFormInput
                    name='birthDate'
                    label='Дата рождения'
                    placeholder='Укажите дату рождения'
                    mask='дд.мм.гггг'
                    formatter={birthDateFormatter}
                    inputComponent={BorderedInput}
                  />
                </div>

                <div className='player-data'>
                  <UDFormInput
                    name='height'
                    label='Рост игрока'
                    placeholder='Укажите рост'
                  />

                  <UDFormInput
                    name='weight'
                    label='Вес игрока'
                    placeholder='Укажите вес'
                  />
                </div>
              </div>

              <div className='col-6' style={{ paddingLeft: 24 }}>
                <UDFormDropZone
                  name='photo'
                  label='Фото игрока'
                  formProps={{ className: 'bgc-SurfaceWhite br-8 p-6' }}
                />
              </div>
            </div>

            <div className='form-buttons col-6 pr-6'>
              <UDButton
                type='submit'
                variant='primary'
                disabled={isLoadingForm || !formProps.isValid || formProps.isSubmitting}
                loading={isLoadingForm || formProps.isSubmitting}
                className='w-50 mr-4'
              >
                Сохранить
              </UDButton>

              <UDButton
                type='button'
                variant='secondary'
                disabled={isLoadingForm}
                onClick={handleCancel}
                className='w-50'
              >
                Отменить
              </UDButton>
            </div>
          </TeamPlayerForm>
        </form>
      )}
    </Formik>
  );
};

export default PlayerForm;
