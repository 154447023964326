import graphqlResource, { GraphqlResource } from 'modules/core/graphqlResource';
import { mapTeamDTOToTeam, mapperPlayer } from '../../../tourneys/domain/data-mappers/championship-game-teams-mapper';

import { ActivePaidSubscription } from '../interfaces/ActivePaidSubscription';
import { ActivePaidSubscriptionDataMapper } from '../data-mappers/active-paid-subscription.data-mapper';
import { ActivePaidSubscriptionResponse } from '../interfaces/ActivePaidSubscriptionResponse';
import { AuthAvailableFeatureResponse } from '../interfaces/AvaibleFeatureDTO';
import { CreateTournamentTeamResponseDto } from '../../../teams/domain/dtos/CreateTournamentTeamResponse.dto';
import { Feature } from '../interfaces/AvailableFeature';
import { Organizer } from '../interfaces/Organizer';
import { OrganizerDTO } from '../interfaces/OrganizerDTO';
import { OrganizerDataMapper } from '../data-mappers/organizer.data-mapper';
import { PlayerPosition } from 'modules/teams/domain/enums/PlayerPosition';
import { Team } from '../../../teams/domain/interfaces/Team';
import { TeamPlayer } from 'modules/teams/domain/interfaces/TeamPlayer';
import { TeamPlayersResponseDto } from '../interfaces/TeamPlayersResponseDto';
import { UpsertOrganizerInput } from '../interfaces/UpsertOrganizerInput';
import { UpsertOrganizerResponse } from '../interfaces/UpserOrganizerResponse';
import { UpsertTournamentTeamDto } from '../../../teams/domain/dtos/UpsertTournamentTeam.dto';

export class OrganizerRepository {
  constructor(
    private readonly graphqlResource: GraphqlResource,
  ) { }

  async fetchOrganizer(tournamentId: number): Promise<Organizer | null> {
    const query = `#graphql
      query getTournamentDetails($id: Int!) {
        tournament(id: $id) {
          id
          name
          sportType
          email
          phone
          description
          championships {
            id
            name
            startDate
            endDate
          }
          images {
            type
            externalUrl
            createdAt
          }
            address {
              id
              addressLine1
              addressLine2
              city
              country
              region
          }
          teams {
            id
            name
            emblem {
              id
              externalUrl
            }
            createdAt
            members {
              id
              position
              number
              user {
                id
                name
                surname
                middleName
                birthDate
                photo {
                  id
                  externalUrl
                }
              }
            }
          }
        }
      }
    `;

    const response = await this.graphqlResource.query<OrganizerDTO>(query, { id: tournamentId });
    const dataMapper = new OrganizerDataMapper();

    return dataMapper.decode(response.data.data.tournament);
  }

  async upsertOrganizer(input: UpsertOrganizerInput): Promise<Organizer> {
    const query = `
      mutation upsertTournament($input: UpsertTournamentInput!) {
        upsertTournament(input: $input) {
          id
          name
          sportType
          email
          phone
          description
          championships {
            id
            name
            startDate
            endDate
          }
          images {
            type
            externalUrl
          }
            address {
              id
              addressLine1
              addressLine2
              city
              country
              region
          }
          teams {
            id
            name
            emblem {
              id
              externalUrl
            }
            members {
              id
              position
              number
              user {
                id
                name
                surname
                middleName
                birthDate
                photo {
                  id
                  externalUrl
                }
              }
            }
          }
        }
      }
    `;

    const variables = { input };
    const response = await graphqlResource.query<UpsertOrganizerResponse>(query, variables);
    const { data: result } = response;

    if (result.errors) {
      throw new Error(result.errors[0].message);
    }

    const dataMapper = new OrganizerDataMapper();
    return dataMapper.decode(result.data.upsertTournament);
  }

  public async upsertTeam(values: UpsertTournamentTeamDto): Promise<Team> {
    const query = `#graphql
      mutation upsertTeam($input: UpsertTeamInput!) {
        upsertTeam(input: $input) {
          id
          name
          emblem {
            id
            externalUrl
          }
          createdAt
          members {
            id
            position
            number
            user {
              id
              name
              surname
              photo {
                id
                externalUrl
              }
            }
          }
        }
      }
    `;

    const variables = {
      input: values,
    };

    const response = await this.graphqlResource.query<CreateTournamentTeamResponseDto>(query, variables);
    const { upsertTeam: result } = response.data.data;

    return mapTeamDTOToTeam(result, null);
  }

  async fetchAvailableFeature(tournamentId: number): Promise<Feature> {
    const query = `
      query getMyFeatures($id: Int!) {
        features(tournamentId: $id) {
          feature
          isEnabled
        }
      }
    `;

    const variables = { id: tournamentId };
    const response = await graphqlResource.query<AuthAvailableFeatureResponse>(query, variables);
    const { data: result } = response;

    if (result.errors) {
      throw new Error(result.errors[0].message);
    }

    const mappedFeatures: Feature = {
      MEDIA_MANAGER: false,
      ACTIVE_CHAMPIONSHIPS: false,
      OBS_INTEGRATION: false,
      JOYSTICK: false,
    };

    result.data.features.forEach((loopFeature) => {
      const feature = loopFeature.feature
      mappedFeatures[feature] = loopFeature.isEnabled;
    });

    return mappedFeatures;
  }

  async fetchActivePaidSubscription(tournamentId: number): Promise<ActivePaidSubscription | null> {
    const query = `#graphql
    query ActivePaidSubscription($tournamentId: Int!) {
       activePaidSubscription(tournamentId: $tournamentId) {
          id
          ownerId
          activeFrom
          activeTo
          # recurringPayment {
              # id
              # nextPayDate
              # period
          # }
          purchaseType
         }
     }
    `

    const response = await this.graphqlResource.query<ActivePaidSubscriptionResponse>(query, { tournamentId });
    const { data: result } = response;

    if (result.errors) {
      throw new Error(result.errors[0].message);
    }

    const dataMapper = new ActivePaidSubscriptionDataMapper();
    return dataMapper.decode(result.data.activePaidSubscription);
  }

  async fetchTeamPlayers(teamId: number): Promise<TeamPlayer[]> {
    const query = `#graphql
      query getTeamPlayers($teamId: Int!) {
        team(id: $teamId) {
          members {
            id
            position
            number
            user {
              id
              name
              surname
              middleName
              birthDate
              photo {
                id
                externalUrl
              }
            }
          }
        }
      }
    `;

    const response = await this.graphqlResource.query<TeamPlayersResponseDto>(query, { teamId });
    const { data: result } = response;

    return result.data.team.members.map((member) => mapperPlayer({
      id: member.id,
      userId: member.user.id,
      firstName: member.user.name,
      lastName: member.user.surname,
      middleName: member.user.middleName,
      birthDate: member.user.birthDate,
      position: member.position as PlayerPosition,
      number: member.number,
      avatar: member.user.photo?.externalUrl,
    }));
  }
}

const organizerRepository = new OrganizerRepository(graphqlResource);
export default organizerRepository;
