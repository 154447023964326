import { ActiveTournamentPlanDTO, ResponseActiveTournamentPlanDTO } from "../interfaces/dto";
import graphqlResource, { GraphqlResource } from '../../../core/graphqlResource';

import { Organizer } from "../interfaces/Organizer";
import { OrganizersDTO } from "../interfaces/OrganizerDTO";
import { OrganizersDataMapper } from "../data-mappers/organizers.data-mapper";

export class OrganizersRepository {
  constructor(
    private readonly graphqlResource: GraphqlResource,
  ) { }

  async fetchOrganizers(): Promise<Organizer[]> {
    const query = `#graphql
      query getTournamentsDetails {
        tournaments {
          id
          name
          sportType
          phone
          email
          createdAt
          description
          images {
            type
            externalUrl
            createdAt
          }
          address {
            id
            city
            country
          }
          adminUser {
            id
            name
            surname
            middleName
            createdAt
            updatedAt
            wallet {
              balanceInMinorUnits
            }
          }
        }
      }
    `;

    const response = await this.graphqlResource.query<OrganizersDTO>(query);
    const dataMapper = new OrganizersDataMapper();
    return dataMapper.decode(response.data.data.tournaments);
  }

  async fetchOrganizerPlan(tournamentId: number): Promise<ActiveTournamentPlanDTO | null> {
    const query = `#graphql
      query getTournamentsDetails($tournamentId: Int!) {
        activeTournamentPlan(tournamentId: $tournamentId) {
          id
          name
          priceInMinorUnits
          features {
            id
            featureLimit
            createdAt
            updatedAt
            feature
          }
          createdAt
          updatedAt
          period
        }
      }
    `;

    const response = await this.graphqlResource.query<ResponseActiveTournamentPlanDTO>(query, { tournamentId });
    if (response.data?.error) {
      console.log({ response: response.data?.error })
      return null
    }

    return response.data.data.activeTournamentPlan
  }
}

const organizersRepository = new OrganizersRepository(graphqlResource);
export default organizersRepository;
