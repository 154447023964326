import { MenuList } from "../../../../../../ud-form/components/select/component/styles";
import { css } from "@emotion/react";
import styled from "@emotion/styled";

export const MainBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`

export const RowWrapper = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;

  width: 100%;
`

export const RowHeaderCss = css`
  color: #969696;
  width: 124px;
  min-width: 124px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

export const StyledSelect = styled.div`
  background-color: #F2F2F2;
  border-radius: 8px;
  overflow: hidden;
  border-width: 1px;
  border-style: solid;
  border-color: ${({ theme }) => theme.colors.SurfaceWhite};
  cursor: pointer;

  width: 100%;

  &:hover {
    border-color: ${({ theme }) => theme.colors.AccentNaplesYellow};
  }

  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  &.opened {
    border-color: ${({ theme }) => theme.colors.AccentNaplesYellow};
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
`;

export const MultiValueContainer = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  width: 214px;

  display: flex;
  flex-direction: row;
  align-items: center;

  &::-webkit-scrollbar {
    width: 1px;
    background-color: #fcfcfc;
    height: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #2a2929;
    border-radius: 8px;
  }
`

export const MenuListCustom = styled(MenuList)`
  max-height: 200px;
`
