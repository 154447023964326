import { InputWrapper, stateDisabled, stateStyles } from '../component/styles';

import UDInput from 'modules/ud-form/components/input/component';
import { css } from '@emotion/react';
import { scrollbar } from 'shared/styles/scrollbar';
import styled from '@emotion/styled';

export const Container = styled(InputWrapper)<{ readOnly?: boolean }>`
  ${({ theme }) => stateStyles(theme)}

  width: 100%;
  height: 40px;
  padding: 8px 6px;
  gap: 8px;

  &:has(.input:focus) {
    border-color: ${({ theme }) => theme.colors.AccentNaplesYellow};
  }

  & .input-mask {
    color: ${({ theme }) => theme.colors.DarkBlue} !important;
    font-variant-numeric: tabular-nums;
  }

  ${({ readOnly, theme }) =>
    readOnly &&
    css`
      cursor: not-allowed;
      pointer-events: none;
      user-select: none;

      &.input,
      &.input::placeholder {
        color: ${theme.colors.DisabledInputColor};
      }
    `}
`;

export const Prefix = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 26px;
  border-radius: 6px;
  padding-inline: 5px;
  gap: 1px;

  background: #9093aa26;
  user-select: none;

  &:hover {
    cursor: pointer;
  }
`;

export const Dropdown = styled.div<{
  isOpen: boolean;
  top: number;
  left: number;
  width: number;
}>`
  position: absolute;
  top: ${({ top }) => top}px;
  left: ${({ left }) => left}px;
  width: ${({ width }) => width}px;
  height: min-content;
  max-height: 400px;
  overflow-y: auto;
  opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
  visibility: ${({ isOpen }) => (isOpen ? 'visible' : 'hidden')};
  transform: ${({ isOpen }) =>
    isOpen ? 'translateY(0)' : 'translateY(-10px)'};
  transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out,
    transform 0.2s ease-in-out;
  flex-direction: column;
  gap: 8px;

  ${({ theme }) => scrollbar(theme)}
  background-color: white;
  border-radius: 8px;
  padding-block: 16px;
  z-index: 1000;
`;

export const DropdownItem = styled.div<{ isActive: boolean }>`
  padding-inline: 12px;
  height: 40px;
  background-color: ${({ isActive }) =>
    isActive ? '#9093AA26' : 'transparent'};
  transition: background-color 0.2s ease-in-out;

  display: flex;
  align-items: center;
  cursor: pointer;

  &:hover {
    background-color: ${({ isActive }) =>
      isActive ? '#9093AA26' : 'rgba(46, 47, 54, 0.15)'};
  }

  .icon {
    margin-right: 10px;
  }

  .prefix {
    margin-left: auto;
  }
`;

export const Input = styled(UDInput)`
  padding: 0;
  font-variant-numeric: tabular-nums;

  &:hover,
  &:focus {
    outline: none;
    border: 1px solid transparent;
  }

  ${({ theme }) => stateDisabled(theme)}
`;
