/** @jsxImportSource @emotion/react */

import Filter from '../components/list-wrapper/filter';
import UDPagination from 'modules/ud-ui/components/pagination';
import classNames from 'classnames';
import { useMemo } from 'react';

interface FilterProps {
    filter?: React.ReactNode;
    filterText?: string;
    filterInputPlaceholder?: string;
    onFilterChange?: (value: string) => void;
    filterClassName?: string;
}

interface PerPageProps {
    perPage?: number;
    onChangePerPage?: (perPage: number) => void;
}

interface PaginationProps {
    pages: number;
    currentPage: number;
    onPageChange: (page: number) => void;
    paginationClassName?: string;
}

interface IProps extends PaginationProps, FilterProps, PerPageProps {
    children: React.ReactNode;
}

function ListPageWrapper(props: IProps) {
    const {
        pages,
        currentPage,
        onPageChange,
        children,
        paginationClassName,
        filter,
        filterText,
        filterClassName,
        onFilterChange,
        filterInputPlaceholder,
        perPage,
        onChangePerPage
    } = props;

    const filterComponent = useMemo(() => {
        if (filter) return filter;
        return <Filter value={filterText || ""} placeholder={filterInputPlaceholder} onFilter={onFilterChange} className={filterClassName} perPage={perPage ?? 10} onChangePerPage={onChangePerPage} />;
    }, [filter, filterText, filterClassName, onFilterChange, filterInputPlaceholder, perPage, onChangePerPage]);

    return (
        <div className='px-6 pt-6 flex-column' style={{ height: 'calc(100vh - 140px)' }}>
            {filterComponent}
            {children}
            <div className="mt-auto">
                <UDPagination
                    className={classNames('justify-content-end px-6 my-4', paginationClassName)}
                    pages={pages}
                    currentPage={currentPage}
                    onPageChange={onPageChange}
                />
            </div>
        </div>
    );
}

export default ListPageWrapper;
