import { Image } from '../types';

export function fileToImage(file: File): Image {
    return {
        url: URL.createObjectURL(file),
        name: file.name,
        size: file.size,
    };
}

export function fileToImageList(files: FileList): Image[] {
    return Array.from(files).map(file => fileToImage(file));
}
