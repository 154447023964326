import React, { useCallback } from 'react';

import { Pages } from '../../../../navigation/domain/enums/pages';
import UDButton from 'modules/ud-ui/components/button';
import UDColorableIcon from 'modules/ud-ui/components/icon/colorable-icon';
import UDText from 'modules/ud-ui/components/text';
import classNames from 'classnames';
import { clearSelectedChampionship } from '../../../../tourneys/store/championship/actions';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

type Props = {
  className?: string;
};

const MainComponentsEmptyListMessage = (props: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleCreateTourneyClick = useCallback(() => {
    dispatch(clearSelectedChampionship());
    navigate(Pages.TOURNEYS.CREATE);
  }, [dispatch, navigate]);

  return (
    <div
      className={classNames(
        'd-flex flex-column align-items-center',
        props.className,
      )}>
      <UDText type="headline" className="mb-2">
        {t('championships.emptyList.title')}
      </UDText>
      <UDText type="body-text" className="mb-6">
        {t('championships.emptyList.body')}
      </UDText>
      <UDButton
        variant="dark"
        className="d-flex flex-row align-items-center"
        onClick={handleCreateTourneyClick}
      >
        <UDColorableIcon
          name="plus"
          componentProps={{ className: 'mr-2 color-AccentNapplesYellow' }}
        />
        {t('championships.createTournamentButtonTitle')}
      </UDButton>
    </div>
  );
};

export default MainComponentsEmptyListMessage;
