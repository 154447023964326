import { useCallback, useMemo } from 'react';

import { useSearchParams } from 'react-router-dom';

/**
 * Конфигурация для отдельного query параметра
 * @template T - Тип значения параметра после парсинга
 */
type QueryParamConfig<T> = {
  /** Ключ параметра в URL */
  key: string;
  /** Значение по умолчанию, если параметр отсутствует в URL */
  defaultValue?: T;
  /** Функция для преобразования строкового значения из URL в нужный тип */
  parser?: (value: string) => T;
  /** Функция для преобразования значения в строку для URL */
  serializer?: (value: T) => string;
};

/** Конфигурация для всех query параметров */
type QueryParamsConfig = {
  [key: string]: QueryParamConfig<any>;
};

/** Тип возвращаемых параметров на основе конфигурации */
type ParsedQueryParams<T extends QueryParamsConfig> = {
  [K in keyof T]: T[K] extends QueryParamConfig<infer U> ? U : never;
};

/** Стандартные парсеры для базовых типов данных */
const defaultParsers = {
  string: (value: string) => value,
  number: (value: string) => Number(value),
  boolean: (value: string) => value === 'true',
};

/** Стандартные сериализаторы для базовых типов данных */
const defaultSerializers = {
  string: (value: string) => value,
  number: (value: number) => value.toString(),
  boolean: (value: boolean) => value.toString(),
};

/**
 * Хук для типобезопасной работы с query параметрами URL
 *
 * @description
 * Этот хук предоставляет удобный интерфейс для работы с query параметрами в URL,
 * включая автоматическое преобразование типов, значения по умолчанию и типизацию.
 *
 * @template T - Тип конфигурации параметров
 * @param {T} config - Конфигурация параметров
 *
 * @returns {[ParsedQueryParams<T>, (newParams: Partial<ParsedQueryParams<T>>) => void]}
 * Кортеж из текущих значений параметров и функции для их обновления
 *
 * @example
 * // Базовое использование
 * const [params, setParams] = useQueryParams({
 *   page: {
 *     key: 'page',
 *     defaultValue: 1,
 *     parser: Number,
 *   },
 *   search: {
 *     key: 'q',
 *     defaultValue: '',
 *   }
 * });
 *
 * // Получение значений
 * console.log(params.page); // number
 * console.log(params.search); // string
 *
 * // Обновление значений
 * setParams({ page: 2, search: 'test' });
 *
 * @example
 * // Использование с кастомными типами
 * interface FilterStatus {
 *   id: number;
 *   name: string;
 * }
 *
 * const [params, setParams] = useQueryParams({
 *   status: {
 *     key: 'status',
 *     defaultValue: { id: 1, name: 'active' },
 *     parser: (value: string) => {
 *       const [id, name] = value.split(',');
 *       return { id: Number(id), name };
 *     },
 *     serializer: (value: FilterStatus) => `${value.id},${value.name}`,
 *   }
 * });
 *
 * @example
 * // Использование с массивами
 * const [params, setParams] = useQueryParams({
 *   tags: {
 *     key: 'tags',
 *     defaultValue: [],
 *     parser: (value: string) => value.split(','),
 *     serializer: (value: string[]) => value.join(','),
 *   }
 * });
 *
 * @example
 * // Использование в фильтрах
 * function PlayersList() {
 *   const [filters, setFilters] = useQueryParams({
 *     search: {
 *       key: 'q',
 *       defaultValue: '',
 *     },
 *     status: {
 *       key: 'status',
 *       defaultValue: 'all',
 *     },
 *     page: {
 *       key: 'page',
 *       defaultValue: 1,
 *       parser: Number,
 *     },
 *     sortBy: {
 *       key: 'sort',
 *       defaultValue: { field: 'name', direction: 'asc' },
 *       parser: (value: string) => {
 *         const [field, direction] = value.split(':');
 *         return { field, direction };
 *       },
 *       serializer: (value) => `${value.field}:${value.direction}`,
 *     }
 *   });
 *
 *   // Использование в запросе
 *   useEffect(() => {
 *     fetchPlayers({
 *       search: filters.search,
 *       status: filters.status,
 *       page: filters.page,
 *       sortBy: filters.sortBy
 *     });
 *   }, [filters]);
 *
 *   return (
 *     <div>
 *       <input
 *         value={filters.search}
 *         onChange={(e) => setFilters({ search: e.target.value })}
 *       />
 *       <select
 *         value={filters.status}
 *         onChange={(e) => setFilters({ status: e.target.value })}
 *       >
 *         <option value="all">Все</option>
 *         <option value="active">Активные</option>
 *         <option value="inactive">Неактивные</option>
 *       </select>
 *     </div>
 *   );
 * }
 */
export function useQueryParams<T extends QueryParamsConfig>(config: T) {
  const [searchParams, setSearchParams] = useSearchParams();

  const params = useMemo(() => {
    const result: Record<string, any> = {};

    Object.entries(config).forEach(([key, paramConfig]) => {
      const rawValue = searchParams.get(paramConfig.key);
      const parser = paramConfig.parser || defaultParsers.string;

      result[key] = rawValue !== null
        ? parser(rawValue)
        : paramConfig.defaultValue;
    });

    return result as ParsedQueryParams<T>;
  }, [searchParams, config]);

  const setParams = useCallback((newParams: Partial<ParsedQueryParams<T>>) => {
    const updatedParams = new URLSearchParams(searchParams);

    Object.entries(newParams).forEach(([key, value]) => {
      const paramConfig = config[key];
      if (!paramConfig) return;

      const serializer = paramConfig.serializer || defaultSerializers.string;

      if (value === undefined || value === null) {
        updatedParams.delete(paramConfig.key);
      } else {
        updatedParams.set(paramConfig.key, serializer(value));
      }
    });

    setSearchParams(updatedParams);
  }, [searchParams, setSearchParams, config]);

  return [params, setParams] as const;
}
