import { UDButton, UDList, UDText } from 'modules/ud-ui/components';
import { createContext, useContext } from 'react';

import { ButtonVariant } from '../../../../modules/ud-ui/components/button/index';

// Типы пропсов для стилей
export type StyleProps = {
    // className'ы для разных компонентов
    containerClassName?: string;
    listClassName?: string;
    textClassName?: string;
    buttonClassName?: string;

    // Компоненты для кастомизации
    listComponent?: typeof UDList;
    textComponent?: typeof UDText;
    buttonComponent?: typeof UDButton;
};

// Типы пропсов для текстов
export type TextProps = {
    title?: string;
    subtitle?: string;
}

// Типы пропсов для кнопки
export type ButtonProps = {
    text?: string;
    variant?: ButtonVariant;
}

// Контекст для CategoryList и его дочерних компонентов
export type CategoryListContextType = {
    isFilterActive?: boolean;
    filter?: string | null;
    onCreateClick?: () => void;

    // Пропсы для текстов и компонентов
    textProps: TextProps;
    buttonProps: ButtonProps;
    styleProps: StyleProps;
};

// Дефолтные значения для текстов
export const defaultTextProps: TextProps = {
    title: 'У вас нет ещё ни одного элемента',
    subtitle: 'Давайте добавим?',
};

// Дефолтные значения для кнопки
export const defaultButtonProps: ButtonProps = {
    text: 'Создать элемент',
    variant: 'dark'
};

// Дефолтные значения для стилей
export const defaultStyleProps: StyleProps = {
    containerClassName: '',
    listClassName: '',
    textClassName: '',
    buttonClassName: '',

    listComponent: UDList,
    textComponent: UDText,
    buttonComponent: UDButton
};

// Создаем контекст с дефолтными значениями
export const CategoryListContext = createContext<CategoryListContextType>({
    isFilterActive: false,
    filter: null,
    textProps: defaultTextProps,
    buttonProps: defaultButtonProps,
    styleProps: defaultStyleProps
});

// Хук для использования контекста
export function useCategoryContext() {
    return useContext(CategoryListContext);
}

// Провайдер контекста
export const CategoryListContextProvider = CategoryListContext.Provider;
