import ChampionshipApplicationFilters, {
  ChampionshipApplicationFiltersOption,
  ChampionshipTeamsFilterValues,
} from '../championship-application-filters/championship-application-filters';
import {
  announcedChampionshipTeamsDataSelector,
  announcedChampionshipTeamsIsLoadingSelector,
  updatingChampionshipTeamSelector,
} from '../../../store/championshipTeams/selectors';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import AddTeamApplicationForm from '../add-team-application-form/add-team-application-form';
import { AnnouncedTeamStatus } from '../../../domain/enums/AnnouncedTeamStatus';
import ChampionshipTeamCard from './championship-team-card';
import ChampionshipTeamsSkeleton from './championship-teams-skeleton';
import CircleAttentionIcon from '../../../../ud-ui/components/icon/circle-attention-icon';
import CircleCheckIcon from '../../../../ud-ui/components/icon/circle-check-icon';
import CircleCloseIcon from '../../../../ud-ui/components/icon/circle-close-icon';
import { Team } from '../../../domain/interfaces/Team';
import { getChampionshipAnnouncedTeams } from '../../../store/championshipTeams/actions';
import { useOrganizer } from '../../../../organizer/ui/hooks/useOrganizer';

const statusItems: ChampionshipApplicationFiltersOption<AnnouncedTeamStatus>[] = [
  {
    status: AnnouncedTeamStatus.PENDING,
    icon: CircleAttentionIcon,
    color: '#000000',
  },
  {
    status: AnnouncedTeamStatus.ACCEPTED,
    icon: CircleCheckIcon,
    color: '#58B879',
  },
  {
    status: AnnouncedTeamStatus.DECLINED,
    icon: CircleCloseIcon,
    color: '#FF7061',
  },
];

type ChampionshipApplicationsProps = {
  championshipId: number;
};

const ChampionshipApplications = ({ championshipId }: ChampionshipApplicationsProps) => {
  const { info } = useOrganizer();
  const dispatch = useDispatch();

  const [status, selectStatus] = useState<AnnouncedTeamStatus>(AnnouncedTeamStatus.PENDING);
  const [name, setName] = useState<string>('');

  const isLoading = useSelector(announcedChampionshipTeamsIsLoadingSelector);
  const announcedTeams = useSelector(announcedChampionshipTeamsDataSelector);
  const updatingTeamId = useSelector(updatingChampionshipTeamSelector);

  useEffect(() => {
    dispatch(getChampionshipAnnouncedTeams(championshipId));
  }, [championshipId, dispatch]);

  const handleFilterChange = useCallback((values: ChampionshipTeamsFilterValues<AnnouncedTeamStatus>) => {
    setName(values.name);
    selectStatus(values.status);
  }, []);

  const teamsApplicationsList = useMemo(() => {
    return Object
      .values(announcedTeams || [])
      .filter((team) => (
        status === team.status
        || (status === AnnouncedTeamStatus.PENDING && team.status === AnnouncedTeamStatus.ANNOUNCED)
      ))
      .filter((team) => team.name.toLowerCase().includes(name.toLowerCase()));
  }, [announcedTeams, status, name]);

  const teamsToAdd: Team[] = useMemo(() => {
    if (!info) {
      return [];
    }

    const existTeamIds = Object
      .values(announcedTeams || [])
      .map((team) => team.id);

    return info.teams.filter((team) => !existTeamIds.includes(team.id));
  }, [info, announcedTeams]);


  const countByStatus = useMemo(() => {
    return Object
      .values(announcedTeams || [])
      .reduce<Record<string, number>>((result, item) => {
        if (item.status === AnnouncedTeamStatus.ANNOUNCED) {
          const count = result[AnnouncedTeamStatus.PENDING] || 0
          return {
            ...result,
            [AnnouncedTeamStatus.PENDING]: count + 1,
          };
        }

        const count = result[item.status] || 0
        return {
          ...result,
          [item.status]: count + 1,
        };
      }, {
        [AnnouncedTeamStatus.PENDING]: 0,
        [AnnouncedTeamStatus.ACCEPTED]: 0,
        [AnnouncedTeamStatus.DECLINED]: 0,
      });
  }, [announcedTeams]);

  return (
    <div className='container my-4'>
      {isLoading
        ? (<ChampionshipTeamsSkeleton />)
        : (
          <>
            <div className='mb-10' style={{ backgroundColor: 'white', borderRadius: 8, padding: 20 }}>
              <ChampionshipApplicationFilters<AnnouncedTeamStatus>
                selectedStatus={status}
                name={name}
                options={statusItems}
                inputPlaceholder='Поиск по команде'
                countByStatus={countByStatus}
                onChange={handleFilterChange}
              />
            </div>

            <AddTeamApplicationForm teamsToAdd={teamsToAdd} />

            <div>
              {teamsApplicationsList.map((team, index) => (
                <ChampionshipTeamCard
                  key={`${team.id}-${team.name}`}
                  index={index + 1}
                  championshipTeam={team}
                  championshipId={championshipId}
                  isUpdating={updatingTeamId === team.id}
                />
              ))}
            </div>
          </>
        )
      }
    </div>
  );
};

export default ChampionshipApplications;
