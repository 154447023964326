import { LocationDTO } from '../../../locations/domain/interfaces/dto';

export enum GameEventType {
  GOAL = 'GOAL',
  YELLOW_CARD = 'YELLOW_CARD',
  RED_CARD = 'RED_CARD',
  FOUL = 'FOUL',
  PASS_AND_GOAL = 'PASS_AND_GOAL',
  PENALTY = 'penalty',
  SAVE = 'save',
  TIMEOUT = 'timeout',
  REPLACEMENT = 'replacement',
  OWN_GOAL = 'owngoal',
  CORNER = 'corner',

  AFTER_MATCH_PENALTY_GOAL = 'after_match_penalty_goal',
  AFTER_MATCH_PENALTY_MISSED = 'after_match_penalty_missed',

  DEFAULT = 'default',
}

export interface GameEvent {
  id: number;
  teamId: number;
  type: GameEventType;
  period: number;
  seconds: number;
  minutes: number;
  extraMinutes: number;
  playerIds: number[];
  videoUrl?: string;
}

export interface GameDetailsTeamMember {
  id: number;
  teamId: number;
  firstName: string;
  lastName: string;
  avatar: string;
  position: string;
  number: number;
}

export interface GameDetailsTeam {
  id: number;
  name: string;
  logo?: string;
  members: GameDetailsTeamMember[];
}

export interface GameDetailsScore {
  firstTeamScore: number;
  secondTeamScore: number;
}

export interface GameDetails {
  id: number;
  sportType: string;
  date: Date;
  location?: LocationDTO;
  championship: {
    id: number;
    name: string;
  };
  tournament: {
    id: number;
    name: string;
  };
  hasScore: boolean;
  result: {
    firstTeamScore: number;
    secondTeamScore: number;
  };
  teams: [GameDetailsTeam, GameDetailsTeam];
  events: GameEvent[];
  round?: {
    id: number;
    name: string;
    stageId: number;
  };
  videoUrls: string[];
  timestamps?: { [period: string]: { second: number, url: string } };
  participants: number[];
}
