/** Конфигурация для отдельного параметра в localStorage */

import { useCallback, useState } from 'react';

type LocalStorageConfig<T> = {
  /** Ключ параметра в localStorage */
  key: string;
  /** Значение по умолчанию, если параметр отсутствует в localStorage */
  defaultValue?: T;
  /** Функция для преобразования строкового значения из localStorage в нужный тип */
  parser?: (value: string) => T;
  /** Функция для преобразования значения в строку для сохранения в localStorage */
  serializer?: (value: T) => string;
};

/** Конфигурация для всех параметров localStorage */
type LocalStorageConfigs = {
  [key: string]: LocalStorageConfig<any>;
};

/** Тип возвращаемых значений на основе конфигурации */
type ParsedLocalStorageValues<T extends LocalStorageConfigs> = {
  [K in keyof T]: T[K] extends LocalStorageConfig<infer U> ? U : never;
};

/**
 * Хук для типобезопасной работы с localStorage
 *
 * @description
 * Этот хук предоставляет удобный интерфейс для работы с данными в localStorage,
 * включая автоматическое преобразование типов, значения по умолчанию и типизацию.
 *
 * @template T - Тип конфигурации параметров
 * @param {T} config - Конфигурация параметров
 *
 * @returns {[ParsedLocalStorageValues<T>, (newValues: Partial<ParsedLocalStorageValues<T>>) => void]}
 * Кортеж из текущих значений параметров и функции для их обновления
 *
 * @example
 * const [values, setValues] = useLocalStorage({
 * theme: {
 *   key: 'theme',
 *   defaultValue: 'light',
 * },
 * count: {
 *   key: 'count',
 *   defaultValue: 0,
 *   parser: (v: string) => parseInt(v, 10),
 *   serializer: (v: number) => v.toString(),
 * },
 * });
 *
 * console.log(values.theme); // 'light' | string
 * console.log(values.count); // 0 | number
 *
 *  // Обновление значений
 * setValues({ theme: 'dark', count: 5 });
 *
 *
 */
export function useLocalStorage<T extends LocalStorageConfigs>(config: T) {
  // Инициализируем состояние на основе значений из localStorage
  const [values, setValues] = useState(() => {
    const initialValues: Record<string, any> = {};

    // Проходим по всем параметрам конфигурации
    Object.entries(config).forEach(([key, paramConfig]) => {
      const storedValue = localStorage.getItem(paramConfig.key);
      if (storedValue !== null) {
        // Используем парсер из конфигурации или идентичную функцию по умолчанию
        const parser = paramConfig.parser || ((v: string) => v.toString());
        try {
          initialValues[key] = parser(storedValue);
        } catch {
          // Если парсинг не удался, используем значение по умолчанию
          initialValues[key] = paramConfig.defaultValue;
        }
      } else {
        // Если значения в localStorage нет, используем значение по умолчанию
        initialValues[key] = paramConfig.defaultValue;
      }
    });

    return initialValues as ParsedLocalStorageValues<T>;
  });

  // Функция для обновления значений
  const setLocalStorageValues = useCallback(
    (newValues: Partial<ParsedLocalStorageValues<T>>) => {
      // Обновляем localStorage
      Object.entries(newValues).forEach(([key, value]) => {
        const paramConfig = config[key];
        if (!paramConfig) return;

        if (value === undefined || value === null) {
          // Если значение undefined или null, удаляем ключ из localStorage
          localStorage.removeItem(paramConfig.key);
        } else {
          // Используем сериализатор из конфигурации или преобразование в строку по умолчанию
          const serializer = paramConfig.serializer || ((v: any) => String(v));
          localStorage.setItem(paramConfig.key, serializer(value));
        }
      });

      // Обновляем состояние, объединяя новые значения с предыдущими
      setValues((prev) => ({ ...prev, ...newValues }));
    },
    [config]
  );

  return [values, setLocalStorageValues] as const;
}
