import * as styles from './game-result.styles';

import { editGameScore, saveGameDetails } from 'modules/games/store/actions';
import { gameDetailsIsSavingSelector, gameEventsChangedSelector } from 'modules/games/store/selectors';
import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { GameDetailsScore } from 'modules/games/domain/interfaces/GameDetails';
import { GameDetailsSliceState } from 'modules/games/store/game-details';
import { GameEventCreator } from '../game-event-creator';
import { GameResultEventsList } from '../game-result-events';
import { GameResultHeader } from '../game-result-header';
import { GameScoreForm } from '../game-score-form/game-score-form';
import MainStreamURL from "./components/MainStreamURL";
import { ThunkDispatch } from '@reduxjs/toolkit';
import UDButton from 'modules/ud-ui/components/button';
import styled from '@emotion/styled';
import { toast } from 'react-toastify';
import { updateGameDetails } from 'modules/tourneys/store/stages/stages.slice';
import { useGameDetails } from '../../hooks/use-game-details';
import { useGameDetailsFetch } from '../../hooks/use-game-details-fetch';
import { useModal } from 'modules/ud-ui/components/modal/useModal';
import { useTranslation } from 'react-i18next';

const ActionsPanel = styled.div`
  margin-top: 10px;
  padding: 20px 30px;
  display: flex;
  justify-content: end;
`;

type GameResultProps = {
  gameId: number;
};

export const GameResult = ({ gameId }: GameResultProps) => {
  const { t } = useTranslation()
  const dispatch: ThunkDispatch<GameDetailsSliceState, {}, any> = useDispatch();

  useGameDetailsFetch(gameId);

  const { isLoading, data } = useGameDetails();
  const eventsChanged = useSelector(gameEventsChangedSelector);
  const isDetailsSaving = useSelector(gameDetailsIsSavingSelector);
  const {
    open: openScoreForm,
    close: closeScoreForm,
    Modal: ScoreFormModal,
  } = useModal({ defaultShow: false });

  const isSaveButtonDisabled = useMemo(() => {
    return !eventsChanged || isDetailsSaving;
  }, [eventsChanged, isDetailsSaving]);

  const hasEvents = useMemo(() => (data && data.events.length > 0), [data]);

  const showActionsPanel = useMemo(() => {
    return !isLoading && (hasEvents || eventsChanged);
  }, [isLoading, hasEvents, eventsChanged]);

  const handleChangeScoreClick = useCallback((values: GameDetailsScore) => {
    dispatch(editGameScore(values));
    closeScoreForm();
  }, [closeScoreForm, dispatch]);

  const handleSaveClick = useCallback(() => {
    if (!data || !eventsChanged || isDetailsSaving) {
      return;
    }

    dispatch(saveGameDetails(data))
      .unwrap()
      .then(() => {
        toast.success(t('games.saveDetailsSuccess'));
        dispatch(updateGameDetails(data));
      })
      .catch(() => {
        toast.error(t('games.saveDetailsError'));
      });
  }, [data, dispatch, eventsChanged, isDetailsSaving, t]);

  const listClassName = [
    'game-result-events',
    hasEvents ? '' : 'empty',
  ].join(' ');

  return (
    <>
      <styles.GameResult>
        <GameResultHeader
          isLoading={isLoading}
          game={data}
          onEditScoreClick={openScoreForm}
        />

        <MainStreamURL game={data} isLoading={isLoading} />

        <div className='create-events'>
          <GameEventCreator
            isLoading={isLoading}
            teamIndex={0}
            game={data}
          />
          <GameEventCreator
            isLoading={isLoading}
            teamIndex={1}
            game={data}
          />
        </div>
        <GameResultEventsList
          className={listClassName}
          isLoading={isLoading}
          game={data}
        />
        {showActionsPanel && (
          <ActionsPanel>
            <UDButton
              variant='primary'
              type='button'
              disabled={isSaveButtonDisabled}
              loading={isDetailsSaving}
              onClick={handleSaveClick}
            >{t('games.saveEventsBtnLabel')}</UDButton>
          </ActionsPanel>
        )}
      </styles.GameResult>

      <ScoreFormModal
        header={{ title: 'Изменить счет матча', subtitle: '' }}
        containerProps={{ style: { width: '500px' } }}
      >
        {data && (
          <GameScoreForm
            teams={data!.teams}
            initialValues={{
              firstTeamScore: data!.result.firstTeamScore,
              secondTeamScore: data!.result.secondTeamScore,
            }}
            onSubmit={handleChangeScoreClick}
          />
        )}
      </ScoreFormModal>
    </>
  );
};
