import { Container, CopyrightContainer } from './styles';

import React from 'react';
import UDText from 'modules/ud-ui/components/text';
import classNames from 'classnames';
import image from './images/illustration.svg';

type Props = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
>;

const AuthComponentsBillboard = (props: Props) => {
  const currentYear = new Date().getFullYear();
  return (
    <Container
      {...props}
      className={classNames('d-flex justify-content-center', props.className)}>
      <div className="h-100 flex-center"><img src={image} className="image" /></div>
      <CopyrightContainer>
        <UDText type="callout" style={{ fontFamily: 'SF Pro Text' }} color="#686A79">
          © Roowix, {currentYear}
        </UDText>
      </CopyrightContainer>
    </Container>
  );
};

export default AuthComponentsBillboard;
