/** @jsxImportSource @emotion/react */

import React, { useCallback } from 'react';
import { UDButton, UDIcon, UDText } from 'modules/ud-ui/components';

import { Pages } from "../../../navigation/domain/enums/pages";
import { css } from '@emotion/react';
import { theme } from 'styles/theme/theme';
import { useNavigate } from "react-router-dom";

function EmptyState() {
    const navigate = useNavigate();

    const handleCreateClick = useCallback(() => {
        navigate(Pages.LOCATIONS.CREATE);
    }, [navigate]);

    return (
        <div className='container' style={{ paddingTop: 50 }}>
            <div css={css`
                padding: 34px;
                display: flex;
                flex-direction: column;
                gap: 30px;
                background-color: white;
                border-radius: 8px;
            `}>
                <UDText type='title-3' style={{ fontWeight: 600 }} color={theme.colors.DarkBlue}>
                    У Вас пока нет добавленных локаций.
                </UDText>
                <div css={css`
                    display: flex;
                    flex-direction: column;
                    gap: 14px;
                    background-color: #F4F4F4;
                    padding: 20px;
                    border-radius: 8px;
                    min-height: 163px;
                `}>
                    <UDText type='title-3' style={{ fontWeight: 600 }}>
                        Что такое локация?
                    </UDText>
                    <UDText type='subhead' style={{ fontWeight: 400, letterSpacing: 0 }}>
                        Локация — это конкретное место, где проводится турнир или отдельный матч. В мобильном приложении участники и зрители увидят не только название локации, но и её детальное описание: точный адрес, особенности инфраструктуры (например, раздевалки, парковка, зоны для зрителей). Чем полнее и точнее вы заполните информацию о месте, тем более привлекательным будет Ваш турнир.
                    </UDText>
                </div>
                <UDButton variant='dark' onClick={handleCreateClick} className='flex-center' style={{ width: 'fit-content' }}>
                    <UDIcon componentProps={{ className: 'mr-2' }} name='plus' />
                    <UDText type='subhead' style={{ fontWeight: 300, color: 'inherit' }}>
                        Добавить локацию
                    </UDText>
                </UDButton>
            </div>
        </div>
    );
}

export default EmptyState;