import { AuthSignInPayload } from 'modules/auth/domain/interfaces/AuthSignInPayload';
import { Formik } from 'formik';
import React from 'react';
import { SchemaOf } from 'yup';
import UDButton from 'modules/ud-ui/components/button';
import UDFormInput from 'modules/ud-form/components/input';
import UDInputPhone from 'modules/ud-form/components/input/phone';
import { theme } from 'styles/theme/theme';
import { useTranslation } from 'react-i18next';

type SignInFormProps = {
  initialValues: {
    username: string;
    password: string;
  };
  isLoading: boolean;
  onSubmit: (values: AuthSignInPayload) => void;
  validationSchema?: SchemaOf<AuthSignInPayload>;
};

const SignInForm = (props: SignInFormProps) => {
  const { t } = useTranslation();

  return (
    <Formik
      initialValues={props.initialValues}
      onSubmit={props.onSubmit}
      validateOnBlur={false}
      validateOnChange={false}
      validationSchema={props.validationSchema}
    >
      {(formProps) => (
        <form onSubmit={formProps.handleSubmit}>
          <UDInputPhone
            style={{
              color: theme.colors.DarkBlue,
            }}
            name='username'
            label={t('signIn.phoneInputLabel')}
            placeholder={t('signIn.phoneInputPlaceholder')}
            wrapperProps={{ className: 'mb-3' }}
            value={formProps.values.username}
            readOnly
          />
          <UDFormInput
            autoFocus
            autoComplete="off"
            name="password"
            type="password"
            placeholder={t('signIn.passwordInputPlaceholder')}
            containerProps={{ className: 'mb-4' }}
          />
          <UDButton
            variant="primary"
            type="submit"
            className="w-100 mb-3"
            style={{ fontFamily: 'SF Pro Display', fontWeight: 300 }}
            loading={props.isLoading}
          >
            {t('signIn.signInButtonLabel')}
          </UDButton>
        </form>
      )}
    </Formik>
  );
};

export default SignInForm;
