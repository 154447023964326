import { Board, Template } from './../../../core/domain/interfaces/supabase/overlay/entity';
import {
  addLike,
  addView,
  copyBoard,
  copyTemplate,
  createBoard,
  createTemplate,
  deleteBoard,
  deleteTemplate,
  fetchBoardsByTemplateId,
  fetchCatalogTemplates,
  fetchTemplateById,
  fetchTemplateWithBoards,
  fetchUserTemplates,
  updateBoard,
  updateTemplate,
} from "./actions";

import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { TemplatesSliceState } from "./templates";
import sortTemplates from "../../domain/utils/sortTemplates";
import { toast } from "react-toastify";

type TemplateEditorReducerBuilder = ActionReducerMapBuilder<TemplatesSliceState>;

export function createFetchUserTemplatesReducer(builder: TemplateEditorReducerBuilder) {
  builder.addCase(fetchUserTemplates.pending, (state) => {
    state.isLoading = true;
    state.isLoaded = false;
  });

  builder.addCase(fetchUserTemplates.fulfilled, (state, action) => {
    state.isLoading = false;
    state.isLoaded = true;
    const templates = action.payload
    if (!templates) { return; }
    state.templates = {
      byId: templates.reduce((acc, template) => {
        acc[template.id] = template as any
        return acc
      }, {} as Record<number, Template>),
      allIds: templates.map(template => template.id),
    }
  });

  builder.addCase(fetchUserTemplates.rejected, (state) => {
    state.isLoading = false;
    state.isLoaded = true;

    toast.error('Не удалось загрузить шаблоны')
  });
}

export function createCreateTemplateReducer(builder: TemplateEditorReducerBuilder) {
  builder.addCase(createTemplate.pending, (state) => {
    state.isProcessed = true;
    state.isFinished = false;
    state.process = 'create template'
  });

  builder.addCase(createTemplate.fulfilled, (state, action) => {
    if (action.payload.access === 'private') {
      state.templates.byId[action.payload.id] = action.payload as any
      state.templates.allIds.push(action.payload.id)
    } else if (action.payload.access === 'public' || action.payload.access === 'default') {
      state.catalog.byId[action.payload.id] = action.payload as any
      state.catalog.allIds.push(action.payload.id)
    }
    state.isProcessed = false;
    state.isFinished = true;
  });

  builder.addCase(createTemplate.rejected, (state) => {
    state.isProcessed = false;
    state.isFinished = false;

    toast.error('Не удалось создать шаблон')
  });
}

export function createDeleteTemplateReducer(builder: TemplateEditorReducerBuilder) {
  builder.addCase(deleteTemplate.pending, (state) => {
    state.isProcessed = true;
    state.isFinished = false;
    state.process = 'delete template'
  });

  builder.addCase(deleteTemplate.fulfilled, (state, action) => {
    delete state.templates.byId[action.payload.id];
    state.templates.allIds = state.templates.allIds.filter(id => id !== action.payload.id);
    delete state.catalog.byId[action.payload.id];
    state.catalog.allIds = state.catalog.allIds.filter(id => id !== action.payload.id);
    state.activeTemplateId = undefined;
    state.isProcessed = false;
    state.isFinished = true;
  });

  builder.addCase(deleteTemplate.rejected, (state) => {
    state.isProcessed = false;
    state.isFinished = false;

    toast.error('Не удалось удалить шаблон')
  });
}

export function createUpdateTemplateReducer(builder: TemplateEditorReducerBuilder) {
  builder.addCase(updateTemplate.pending, (state) => {
    state.isProcessed = true;
    state.isFinished = false;
    state.process = 'update template'
  });

  builder.addCase(updateTemplate.fulfilled, (state, action) => {
    const oldAccess = state.templates.byId[action.payload.id]?.access ?? state.catalog.byId[action.payload.id]?.access
    const currentAccess = action.payload.access
    switch (oldAccess) {
      case 'private': {
        if (currentAccess === 'default') {
          delete state.templates.byId[action.payload.id];
          state.templates.allIds = state.templates.allIds.filter(id => id !== action.payload.id);
        }
        if (currentAccess === 'public' || currentAccess === 'default') {
          state.catalog.byId[action.payload.id] = action.payload as any
          state.catalog.allIds.push(action.payload.id)
        }
        break;
      }
      case 'public': {
        if (currentAccess === 'private') {
          delete state.catalog.byId[action.payload.id];
          state.catalog.allIds = state.catalog.allIds.filter(id => id !== action.payload.id);
        }
        if (currentAccess === 'default') {
          delete state.templates.byId[action.payload.id];
          state.templates.allIds = state.templates.allIds.filter(id => id !== action.payload.id);
        }
        break;
      }
      case 'default': {
        if (currentAccess === 'private') {
          delete state.catalog.byId[action.payload.id];
          state.catalog.allIds = state.catalog.allIds.filter(id => id !== action.payload.id);
        }
        if (currentAccess === 'public') {
          const hasInTemplates = state.templates.byId[action.payload.id]
          if (!hasInTemplates) {
            state.templates.byId[action.payload.id] = action.payload as any
            state.templates.allIds.push(action.payload.id)
          }
        }
        break;
      }
    }

    state.templates.byId[action.payload.id] = action.payload as any
    state.catalog.byId[action.payload.id] = action.payload as any
    state.isProcessed = false;
    state.isFinished = true;
  });

  builder.addCase(updateTemplate.rejected, (state) => {
    state.isProcessed = false;
    state.isFinished = false;

    toast.error('Не удалось обновить шаблон')
  });
}

export function createCreateBoardReducer(builder: TemplateEditorReducerBuilder) {
  builder.addCase(createBoard.pending, (state) => {
    state.isProcessed = true;
    state.isFinished = false;
    state.process = 'create board'
  });

  builder.addCase(createBoard.fulfilled, (state, action) => {
    state.boards.byId[action.payload.id] = action.payload as any
    state.boards.allIds.push(action.payload.id)

    state.isProcessed = false;
    state.isFinished = true;
  });

  builder.addCase(createBoard.rejected, (state) => {
    state.isProcessed = false;
    state.isFinished = false;

    toast.error('Не удалось создать табло')
  });
}

export function createDeleteBoardReducer(builder: TemplateEditorReducerBuilder) {
  builder.addCase(deleteBoard.pending, (state) => {
    state.isProcessed = true;
    state.isFinished = false;
    state.process = 'delete board'
  });

  builder.addCase(deleteBoard.fulfilled, (state, action) => {
    delete state.boards.byId[action.payload.id];
    state.boards.allIds = state.boards.allIds.filter(id => id !== action.payload.id);

    state.isProcessed = false;
    state.isFinished = true;
  });

  builder.addCase(deleteBoard.rejected, (state) => {
    state.isProcessed = false;
    state.isFinished = false;

    toast.error('Не удалось удалить табло')
  });
}

export function createUpdateBoardReducer(builder: TemplateEditorReducerBuilder) {
  builder.addCase(updateBoard.pending, (state) => {
    state.isProcessed = true;
    state.isFinished = false;
    state.process = 'update board'
  });

  builder.addCase(updateBoard.fulfilled, (state, action) => {
    state.boards.byId[action.payload.id] = action.payload as any

    state.isProcessed = false;
    state.isFinished = true;
  });

  builder.addCase(updateBoard.rejected, (state) => {
    state.isProcessed = false;
    state.isFinished = false;

    toast.error('Не удалось обновить табло')
  });
}

export function createCopyTemplateReducer(builder: TemplateEditorReducerBuilder) {
  builder.addCase(copyTemplate.pending, (state, action) => {
    state.isProcessed = true;
    state.isFinished = false;
    state.process = action.meta.arg.process ?? 'copy template'
  });

  builder.addCase(copyTemplate.fulfilled, (state, action) => {
    state.templates.byId[action.payload.id] = action.payload as any
    state.templates.allIds.push(action.payload.id)

    state.isProcessed = false;
    state.isFinished = true;
  },
  );

  builder.addCase(copyTemplate.rejected, (state) => {
    state.isProcessed = false;
    state.isFinished = false;
    toast.error('Не удалось создать копию')
  });
}

export function createCopyBoardReducer(builder: TemplateEditorReducerBuilder) {
  builder.addCase(copyBoard.pending, (state, action) => {
    state.isProcessed = true;
    state.isFinished = false;
    state.process = action.meta.arg.process ?? 'copy board'
  });

  builder.addCase(copyBoard.fulfilled, (state, action) => {
    state.boards.byId[action.payload.id] = action.payload as any
    state.boards.allIds.push(action.payload.id)

    state.isProcessed = false;
    state.isFinished = true;
  });

  builder.addCase(copyBoard.rejected, (state) => {
    state.isProcessed = false;
    state.isFinished = false;
    toast.error('Не удалось создать копию')
  });
}

export function createFetchCatalogTemplatesReducer(builder: TemplateEditorReducerBuilder) {
  builder.addCase(fetchCatalogTemplates.fulfilled, (state, action) => {
    const templates = action.payload
    if (!templates) {
      toast.error('Не удалось загрузить шаблоны каталога')
      return;
    }

    state.catalog = {
      byId: sortTemplates(templates).reduce((acc, template) => {
        acc[template.id] = template
        return acc
      }, {} as Record<number, Template>),
      allIds: sortTemplates(templates).map(template => template.id),
    }
  });

  builder.addCase(fetchCatalogTemplates.rejected, (state) => {
    toast.error('Не удалось загрузить шаблоны каталога')
  });
}

export function createAddLikeReducer(builder: TemplateEditorReducerBuilder) {
  builder.addCase(addLike.fulfilled, (state, action) => {
    state.catalog.byId[action.payload.id] = action.payload as any
  });

  builder.addCase(addLike.rejected, (state) => {
    toast.error('Не удалось поставить лайк')
  });
}

export function createAddViewReducer(builder: TemplateEditorReducerBuilder) {
  builder.addCase(addView.fulfilled, (state, action) => {
    state.catalog.byId[action.payload.id] = action.payload as any
  });

  builder.addCase(addView.rejected, (state) => {
    toast.error('Не удалось засчитать просмотр')
  });
}

export function createFetchBoardsByTemplateIdReducer(builder: TemplateEditorReducerBuilder) {
  builder.addCase(fetchBoardsByTemplateId.pending, (state) => {
    state.isLoadedBoards = false;
    state.isLoadingBoards = true;
  });

  builder.addCase(fetchBoardsByTemplateId.fulfilled, (state, action) => {
    const boards = action.payload;

    // Добавляем новые id без дубликатов
    state.boards.allIds = state.boards.allIds
      .concat(boards.map(board => board.id))
      .filter((id, index, self) => self.indexOf(id) === index);

    // Добавляем/обновляем доски в byId
    state.boards.byId = {
      ...state.boards.byId,
      ...boards.reduce<Record<number, Board>>((acc, board) => {
        acc[board.id] = board;
        return acc;
      }, {}) as any
    };

    state.loadedTemplates.push(action.meta.arg.templateId);

    state.isLoadedBoards = true;
    state.isLoadingBoards = false;
  });

  builder.addCase(fetchBoardsByTemplateId.rejected, (state) => {
    state.isLoadingBoards = false;
    toast.error('Не удалось загрузить табло');
  });
}

export function createFetchTemplateByIdReducer(builder: TemplateEditorReducerBuilder) {
  builder.addCase(fetchTemplateById.pending, (state) => {
    state.isLoading = true;
    state.isLoaded = false;
  });

  builder.addCase(fetchTemplateById.fulfilled, (state, action) => {
    state.templates.byId[action.payload.id] = action.payload as any
    state.isLoading = false;
    state.isLoaded = true;
  });

  builder.addCase(fetchTemplateById.rejected, (state) => {
    state.isLoading = false;
    toast.error('Не удалось загрузить шаблон')
  });
}

export function createFetchTemplateWithBoardsReducer(builder: TemplateEditorReducerBuilder) {
  builder.addCase(fetchTemplateWithBoards.pending, (state) => {
    state.isLoading = true;
    state.isLoaded = false;
  });

  builder.addCase(fetchTemplateWithBoards.fulfilled, (state, action) => {
    state.templates.byId[action.payload.template.id] = action.payload.template as any
    state.templates.allIds.push(action.payload.template.id)

    state.boards.allIds = state.boards.allIds
      .concat(action.payload.boards.map(board => board.id))
      .filter((id, index, self) => self.indexOf(id) === index);

    state.boards.byId = {
      ...state.boards.byId,
      ...action.payload.boards.reduce<Record<number, Board>>((acc, board) => {
        acc[board.id] = board;
        return acc;
      }, {}) as any
    };

    state.isLoading = false;
    state.isLoaded = true;
  });

  builder.addCase(fetchTemplateWithBoards.rejected, (state) => {
    state.isLoading = false;
    state.isLoaded = false;
    toast.error('Не удалось загрузить шаблон и табло')
  });
}
