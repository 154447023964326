import { createApplicationLink, getApplicationLink } from './actions';

import { ApplicationLink } from '../domain/interfaces/ApplicationLink';
import { createSlice } from '@reduxjs/toolkit';

type ApplicationLinkState = {
  loading: boolean;
  error: string | null;
  applicationLink: ApplicationLink | null;
};

const initialState: ApplicationLinkState = {
  loading: false,
  error: null,
  applicationLink: null,
};

const slice = createSlice({
  name: 'applicationLink',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getApplicationLink.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getApplicationLink.fulfilled, (state, action) => {
        state.loading = false;
        state.applicationLink = action.payload;
      })
      .addCase(getApplicationLink.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'Unknown error';
      });
  },
});

export default slice.reducer;