import {
  DadataAreaType,
  DadataAutocomplete,
  DadataAutocompleteProps,
  DadataSelectOption,
  IDadataArea
} from 'shared/features/dadata-address-autocomplete';
import { GroupBase, InputActionMeta, PropsValue } from 'react-select/dist/declarations/src';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import Select from 'react-select/dist/declarations/src/Select';
import { SingleValue } from 'react-select';
import UDText from 'modules/ud-ui/components/text';
import { getSelectStyles } from './styles';
import {
  isEqualDadataArea,
} from '../../../../../shared/features/dadata-address-autocomplete/infra/helper/isEqualDadataArea';
import overriddenSelectComponents from './override';
import { useTranslation } from 'react-i18next';

const LoadingMessage = () => {
  const { t } = useTranslation();

  return (
    <UDText type='subhead' className='color-SurfaceRomanSilver30'>
      {t('dadataAreaSelect.loadingMessage')}
    </UDText>
  );
};

const EmptyAddress = () => {
  const { t } = useTranslation();

  return (
    <UDText type='subhead' className='color-SurfaceRomanSilver30'>
      {t('dadataAreaSelect.emptyValue')}
    </UDText>
  );
};

const loadingMessage = () => (<LoadingMessage />);

const emptyOption = {
  label: (<EmptyAddress />),
  value: undefined,
};

const defaultOption = {
  label: 'Россия, г Новосибирск',
  value: {
    "value": "Россия, г Новосибирск",
    "type": DadataAreaType.CITY,
    "city": "г Новосибирск",
    "region": "Новосибирская обл",
    "country": "Россия",
    "street": undefined,
    "house": undefined,
    "lat": 55.028141,
    "lon": 82.921117
  },
};

type UDDadataAreaSelectAdditionalProps = {
  value?: IDadataArea;
  onChange?: (value: IDadataArea | undefined) => void;
  allowedTypes?: DadataAreaType[];
}

export type UDDadataAreaSelectProps = Omit<DadataAutocompleteProps, 'hideSelectedOptions' | 'styles' | 'value' | 'onChange'> & UDDadataAreaSelectAdditionalProps;

export const UDDadataAreaSelect = (props: UDDadataAreaSelectProps) => {
  const {
    components,
    value,
    onChange,
    allowedTypes = [DadataAreaType.CITY],
    ...restProps
  } = props;

  const [selectedValue, selectValue] = useState<IDadataArea | undefined>(value);
  const [inputValue, setInputValue] = useState<string>(value?.value || '');
  const selectRef = useRef<Select<DadataSelectOption, boolean, GroupBase<DadataSelectOption>>>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const isContainerFocusedRef = useRef(false);

  useEffect(() => {
    selectValue(value);
  }, [value]);

  const selectedOption = useMemo(() => {
    return selectedValue ? { label: selectedValue.value, value: selectedValue } : emptyOption;
  }, [selectedValue]);

  const defaultOptions = useMemo(() => {
    return selectedOption && selectedOption.value && !isEqualDadataArea(selectedOption.value, defaultOption.value)
      ? [emptyOption, selectedOption]
      : [emptyOption, defaultOption];
  }, [selectedOption]);

  const styles = getSelectStyles();
  const selectComponents = components || overriddenSelectComponents;

  const handleChange = useCallback((selected: PropsValue<DadataSelectOption>) => {
    if (Array.isArray(selected)) { return; } // Мульти-выбор не поддерживается в данном компоненте
    const singleSelected = selected as SingleValue<DadataSelectOption>;
    const newValue = singleSelected?.value ? { ...singleSelected.value, id: value?.id, } : singleSelected?.value;
    selectValue(newValue ?? undefined);
    onChange?.(newValue ?? undefined);
    const label = singleSelected && (typeof singleSelected.label === 'string') ? singleSelected.label : "";
    setInputValue(label as string);
  }, [onChange, value?.id]);

  const handleInputChange = useCallback((inputValue: string, { action }: InputActionMeta) => {
    if (action === 'input-change') {
      setInputValue(inputValue);
    }
  }, []);

  const handleContainerMouseDown = useCallback((e: React.MouseEvent) => {
    isContainerFocusedRef.current = e.target !== selectRef.current?.inputRef;
    if (e.target !== selectRef.current?.inputRef) {
      isContainerFocusedRef.current = true;
      if ((e.target as HTMLElement).closest('#dropdown-indicator') === null) {
        const input = selectRef.current?.inputRef;
        if (input) {
          const length = inputValue.length;
          input.setSelectionRange(length, length);
          // Находим родительский контейнер со скроллом
          const container = input.closest('#value-container');
          if (container) {
            container.scrollLeft = container.scrollWidth;
          }
        }
      }
    }
  }, [inputValue.length]);

  const handleKeyDown = useCallback((event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Home') {
      event.preventDefault();
      const input = selectRef.current?.inputRef;
      if (input) {
        input.setSelectionRange(0, 0);
        // Находим родительский контейнер со скроллом
        const container = input.closest('#value-container');
        if (container) {
          container.scrollLeft = 0;
        }
      }
    } else if (event.key === 'End') {
      event.preventDefault();
      const input = selectRef.current?.inputRef;
      const length = inputValue.length;
      if (input) {
        input.setSelectionRange(length, length);
        // Находим родительский контейнер со скроллом
        const container = input.closest('#value-container');
        if (container) {
          container.scrollLeft = container.scrollWidth;
        }
      }
    }
  }, [inputValue.length]);

  const onFocus = useCallback((event: React.FocusEvent<HTMLInputElement, Element>) => {
    restProps.onFocus?.(event);
    if (selectedOption) {
      selectRef.current?.inputRef?.focus();
    }
  }, [restProps, selectedOption]);

  return (
    <div ref={containerRef} onMouseDown={handleContainerMouseDown} style={{ maxWidth: '100%' }}>
      <DadataAutocomplete
        {...restProps}
        ref={selectRef}
        value={selectedOption}
        hideSelectedOptions={false}
        placeholder={props.placeholder || ''}
        closeMenuOnSelect={!props.isMulti}
        styles={styles}
        components={selectComponents}
        defaultOptions={defaultOptions}
        loadingMessage={loadingMessage}
        onChange={handleChange}
        allowedTypes={allowedTypes}
        inputValue={inputValue}
        onInputChange={handleInputChange}
        onFocus={onFocus}
        onKeyDown={handleKeyDown}
        openMenuOnFocus={false}
        controlShouldRenderValue={false}
      />
    </div>
  );
};
