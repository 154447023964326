import { useDispatch, useSelector } from 'react-redux';

import { authStoreUserTournamentId } from '../../../auth/store/selectors';
import { fetchLocations } from '../../store/actions';
import { setTournamentId } from 'modules/locations/store';
import { useEffect } from 'react';
import useLocations from './useLocations';

export const useFetchLocation = () => {
    const dispatch = useDispatch();
    const tournamentId = useSelector(authStoreUserTournamentId);
    const { isLoading, isLoaded, locations, tournamentId: tournamentIdFromLocations } = useLocations();


    useEffect(() => {
        if (tournamentId && tournamentId !== tournamentIdFromLocations) {
            dispatch(fetchLocations({ tournamentId }));
            dispatch(setTournamentId({ tournamentId }));
        }
    }, [dispatch, isLoaded, isLoading, tournamentId, tournamentIdFromLocations]);

    return {
        isLoading,
        isLoaded,
        locations
    };
};
