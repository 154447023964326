import styled from '@emotion/styled';

export const MainBlock = styled.div<{ isActive: boolean | undefined }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  gap: 8px;
  padding: 6px;

  transition: border 0.25s ease-in-out;
  border: ${({ isActive }) =>
        isActive ? '1px solid #F5D956' : '1px solid transparent'};
  border-radius: 8px;
  cursor: pointer;

  &.hover {
    &:hover {
      border: 1px solid
        ${({ theme, isActive }) =>
        isActive
            ? theme.colors.AccentNaplesYellow
            : theme.colors.AccentMediumChampagne};
    }
  }
`;

export const ImageBlock = styled.div<{ color?: string; imgUrl?: string }>`
  width: 94px;
  height: 62px;

  background: ${({ color, imgUrl }) => color ?? `url(${imgUrl}) -4px -4px`};
  background-size: auto;

  border: 1px solid #9093aa;
  border-radius: 8px;

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ColorBlock = styled.div`
  display: flex;
  flex-direction: row;
  gap: 6px;
`;

export const ColorIcon = styled.div<{ color?: string; imgUrl?: string }>`
  width: 10px;
  height: 10px;
  background: ${({ color, imgUrl }) => color ?? `url(${imgUrl})`};
  background-size: 14px;
  border: 1px solid #9093aa;
  border-radius: 1px;
`;
