import { Theme, css } from "@emotion/react";

import size from "../../../../domain/constants/size";
import styled from "@emotion/styled";

export const GlowingCss = (color1: string, color2: string, speed?: number) => css`
  animation: glowing ${speed ? speed : '2s'} linear infinite;

  @keyframes glowing {
    0% {
      background-color: ${color1};
    }
    50% {
      background-color: ${color2};
    }
    100% {
      background-color: ${color1};
    }
  }
`

export const HoverLinkCss = (theme: Theme) => css`
  color: #3064FF;

  &:hover {
    color: ${theme.colors.AccentNaplesYellow};
  }
`

export const UrlInputWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.DarkBlue};

  width: 100%;
  height: 60px;
  border-radius: 8px;
  padding-left: 20px;

  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const CopyIconBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 60px;
  height: 60px;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.colors.Blue};
`

export const RightSide = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  @media (max-width: ${size.tablet}px) {
    justify-content: start;
    align-items: start;
    width: 100%;

    & > .tm-logo {
      display: none;
    }
  }

  @media (max-width: ${size.mobile.width}px) {
    justify-content: center;
    align-items: center;

    text-align: center;

    & > .create-board-button {
      width: 100%;
      max-width: 360px;
      height: clamp(40px, 60px, 8vw);
      margin: 0 auto;
      border-radius: 4px !important;

      & .monitor-icon {
        width: clamp(16px, 5vw, 24px);
        height: clamp(15px, 5vw, 22px);
      }

      & > .text {
        font-weight: 400 !important;
        font-size: clamp(15px, 3vw, 20px);
        gap: clamp(10px, 2vw, 20px) !important;
      }
    }
  }

  @media screen and (orientation: landscape) and (max-height: ${size.mobile.height}px) and (max-width: ${size.tablet}px) {
    flex-direction: row;
    align-items: center;
    gap: 40px;

    & > .create-board-button {
      width: 100%;
      max-width: clamp(290px, 8vw, 360px);
      height: clamp(40px, 2vw, 60px);
      border-radius: 4px !important;

      & .monitor-icon {
        width: clamp(16px, 2vw, 24px);
        height: clamp(15px, 2vw, 22px);
      }

      & > .text {
        font-weight: 400 !important;
        font-size: clamp(15px, 1vw, 20px);
        gap: clamp(10px, 2vw, 20px) !important;
      }
    }

    & > .text {
      margin-top: 0 !important;
    }
  }
`

export const MobileOfflineStyles = css`
  height: 322px !important;
  width: 100% !important;
  padding-inline: 20px !important;
  position: absolute;
  bottom: 0;

  overflow: auto !important;

  padding: 60px 19px 30px 19px !important;

  & .header {
    font-size: clamp(24px, 5vw, 36px) !important;
    line-height: clamp(28px, 5vw, 45px) !important;
  }

  & .description {
    margin-top: 20px !important;
  }

  & .description, .description > span {
    font-size: clamp(15px, 2vw, 22px) !important;
  }

  .control {
    margin-top: 6px !important;
    flex-direction: column !important;
    gap: 10px !important;
  }

  & .url-open-panel {
    height: 40px;
    border-radius: 4px;

    & > p {
      font-size: 12px !important;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    & > .url-icon-box {
      width: 40px !important;
      height: 40px !important;

      min-width: 40px !important;
    }

    & .url-copy-icon {
      width: 16px !important;
      height: 16px !important;
    }
  }

  & .open-panel-button {
    width: 100% !important;
    max-width: 290px !important;
    border-radius: 4px !important;

    height: 40px !important;

    & > h3 {
      font-weight: 400 !important;
      font-size: 15px !important;
      line-height: 20px !important;
    }
  }

  & .tip {
    width: 100% !important;
    margin-top: 30px !important;
    font-size: 14px !important;
    line-height: 18px !important;
  }

  & .watermark {
    display: none;
  }
`

export const OfflineModalCss = css`
  @media (max-width: ${size.tablet}px) {
    height: 350px !important;
    padding-inline: 20px !important;
  }

  & .icon-wrapper {
    padding: 30px !important;
  }

  & .modal-close-icon {
    width: 20px !important;
    height: 20px !important;
  }

  & .url-open-panel {
    overflow: hidden;

    & > p {
      padding-right: 10px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  & .url-icon-box {
    min-width: 60px !important;
  }


  @media (max-width: ${size.tablet}px) {
    & .icon-wrapper {
      padding: 20px !important;
    }
  }

  @media (max-width: ${size.mobile.width}px) {
    ${MobileOfflineStyles}
  }

  @media screen and (orientation: landscape) and (max-height: ${size.mobile.height}px) and (max-width: ${size.tablet}px) {
    ${MobileOfflineStyles};

    height: 274px !important;
    padding: 40px 48px 30px 48px !important;

    & .description, .description > span {
      font-size: clamp(15px, 1vw, 22px) !important;
    }

    & .header {
      font-size: clamp(24px, 1vw, 36px) !important;
      line-height: clamp(28px, 1vw, 45px) !important;
    }

    & .url-open-panel {
      width: 50%;
    }

    & .tip {
      margin-top: 20px !important;
    }
  }
`

export const OnlineModalCss = css`
  ${OfflineModalCss};

  @media (max-width: ${size.tablet}px) {
    height: 485px !important;
    padding-inline: 20px !important;
  }

  @media screen and (orientation: landscape) and (max-height: ${size.mobile.height}px) and (max-width: ${size.tablet}px) {
    height: 274px !important;

    & .controls {
      flex-direction: row !important;
      gap: 50px;

      width: calc(100% - 50px) !important;

      & .controls-item {
        width: 50% !important;
      }
    }

    & .url-open-panel {
      width: 100%;
    }
  }
`
