import styled from "@emotion/styled";

export const MainBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`

export const BackgroundList = styled.div`
  display: flex;
  flex-direction: row;
  left: -6px;
  position: relative;
  flex-wrap: wrap;

  gap: 8px;
`

export const ButtonsBlock = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  flex-wrap: wrap;
`
