import 'tippy.js/dist/tippy.css';

import { Field, FieldProps } from 'formik';

import { UDFormComponentProps } from '../components/types';
import UDFormLabel from '../components/label';
import UDText from 'modules/ud-ui/components/text';
import classNames from 'classnames';

type Props = UDFormComponentProps & {
  children: (fieldProps: FieldProps<any>) => JSX.Element;
};

const UDFormFieldWrapper = (props: Props) => {
  const { name, containerProps, labelProps, label, required, children, errorVisible } = props;

  return (
    <Field name={name}>
      {(fieldProps: FieldProps<any>) => {
        const isTouched = fieldProps.meta.touched;
        const errorText = getErrorText(fieldProps.meta.error);
        const isErrorVisible = (errorVisible || label) && isTouched && errorText;

        return (
          <div {...containerProps}>
            {label && (
              <UDFormLabel
                {...labelProps}
                className={classNames({ 'mb-2': !isErrorVisible }, labelProps?.className)}
              >
                {label}
              </UDFormLabel>
            )}
            {isErrorVisible && (
              <UDText type="caption-1" className="color-CaseError">
                {errorText}
              </UDText>
            )}
            {children(fieldProps)}
          </div>
        )
      }}
    </Field>
  );
};

export default UDFormFieldWrapper;

const getErrorText = (error: string | object | undefined): string => {
  if (typeof error === 'string') {
    return error;
  } else if (typeof error === 'object' && error !== null) {
    return Object.values(error)[0] as string;
  }
  return '';
};
