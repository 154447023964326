import TabMenuButton from './tab-menu-button';
import classNames from 'classnames';
import styled from '@emotion/styled';
import { useCallback } from 'react';

type TabMenuProps = {
  className?: string;
  tabs: { name: string; active: boolean }[];
  activeTab?: number;
  onChange?: (index: number) => void;
};

const TabMenu = (props: TabMenuProps) => {
  const {
    className,
    tabs,
    activeTab = 0,
    onChange,
  } = props;

  const handleOnChange = useCallback((index: number) => () => {
    if (onChange && index !== activeTab) {
      onChange(index);
    }
  }, [onChange, activeTab]);

  return (
    <div className={className}>
      {tabs.map(({ name, active }, index) => (
        name && (
          <TabMenuButton
            className={classNames('tab-menu-item', { 'disabled': !active, })}
            key={index}
            isActive={index === activeTab}
            onClick={handleOnChange(index)}
          >{name}</TabMenuButton>
        )
      ))}
    </div>
  );
};

const StyledTabMenu = styled(TabMenu)`
  display: flex;
  background: #EAEAEC;
  padding: 10px 24px;

  .tab-menu-item {
    margin-left: 32px;

    &:first-of-type {
      margin-left: 0;
    }

    &.disabled {
      opacity: 0.5;
      pointer-events: none;
      cursor: not-allowed;
    }
  }
`;

export default StyledTabMenu;
