import { AppDispatch, history } from "../../../../../store/store";
import { ButtonWatch, ButtonWrapper, CanvasWrapper, TemplateItemWrapper } from "./styles";
import { MouseEvent, useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Likes from "../../../../ud-ui/components/icon/likes";
import { Pages } from "../../../../navigation/domain/enums/pages";
import { Template } from "../../../../core/domain/interfaces/supabase/overlay/entity";
import UDText from "../../../../ud-ui/components/text";
import Viewers from "../../../../ud-ui/components/icon/viewers";
import { selectBoardsByTemplateId } from "../../../store/templates/selectors";
import { setActiveTemplate } from "../../../store/templates/templates";
import { useAuth } from "../../../../auth/ui/hooks/useAuth";
import useDeleteModal from "../../hooks/modals/template/delete";
import useEditModal from "../../hooks/modals/template/edit";
import useFabric from "../../../../ud-ui/components/fabric-canvas/hooks/useFabric";

interface IProps {
  template: Template
}

function TemplateItem(props: IProps) {
  const dispatch = useDispatch<AppDispatch>()
  const { template } = props
  const boards = useSelector(selectBoardsByTemplateId(template.id))
  const [isHover, setIsHover] = useState(false)
  const { manager, canvas } = useFabric("static", { id: template.id.toString(), data: { ...template.resolution } });
  const { user } = useAuth()

  const { modal: deleteModal, open: openDeleteModal } = useDeleteModal()
  const { modal: editModal, open: openEditModal } = useEditModal()

  useEffect(() => {
    if (!manager) return

    const resizing = () => {
      manager.setParentSize()
      manager.normalizedViewport()
    }

    const board = boards[0]
    if (!board) return
    const scheme = { objects: board.objects, version: board.version }
    manager.loadLocal({ scheme: scheme, safeBackground: true })

    resizing()
    window.addEventListener('resize', resizing)
    return () => {
      window.removeEventListener('resize', resizing)
    }
  }, [boards, manager])

  const onClick = useCallback(() => {
    dispatch(setActiveTemplate({ id: template.id }))
    history.push(Pages.TEMPLATE.CATALOG.builders.card(template.id))
  }, [dispatch, template.id]);

  const isDev = process.env.REACT_APP_ENVIRONMENT !== 'production'
  const isAdmin = useMemo(() => {
    if (isDev) return true
    if (!user) return false
    return user.id === 20
  }, [isDev, user]);

  const onClickEdit = useCallback((event: MouseEvent) => {
    event.stopPropagation()
    if (!isAdmin) return
    dispatch(setActiveTemplate({ id: template.id }))
    openEditModal()
  }, [dispatch, isAdmin, openEditModal, template.id]);

  const onDelete = useCallback((event: MouseEvent) => {
    event.stopPropagation()
    if (!isAdmin) return
    dispatch(setActiveTemplate({ id: template.id }))
    openDeleteModal()
  }, [dispatch, isAdmin, openDeleteModal, template.id]);

  return (
    <TemplateItemWrapper>
      <CanvasWrapper
        onClick={onClick}
        onMouseEnter={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}
      >
        {canvas}
        <ButtonWrapper>
          <ButtonWatch onClick={onClick} isShow={isHover}>
            Смотреть
          </ButtonWatch>
          {isAdmin && (
            <>
              <ButtonWatch isShow={isHover} onClick={onClickEdit}>
                Редактировать
              </ButtonWatch>
              <ButtonWatch isShow={isHover} onClick={onDelete}>
                Удалить
              </ButtonWatch>
            </>
          )}
        </ButtonWrapper>
      </CanvasWrapper>
      <div className={'d-flex align-items-center justify-content-between'} style={{ marginTop: 8 }}>
        <UDText type={'caption-1'} style={{ color: '#1A1A29', fontWeight: 'bold' }}>{template.name}</UDText>
        <div className='flex-center' style={{ gap: 14, color: '#9093AA' }}>
          <div className='flex-center' style={{ gap: 4 }}>
            <Likes />
            <UDText type={'tip'} style={{ color: 'inherit' }}>{template.likes?.length}</UDText>
          </div>
          <div className='flex-center' style={{ gap: 4 }}>
            <Viewers />
            <UDText type={'tip'} style={{ color: 'inherit' }}>{template.views?.length}</UDText>
          </div>
        </div>
      </div>
      <UDText type={'tip'} style={{ color: '#9093AA', marginTop: 2 }}>{template.ownerId ?? 'ImGame'}</UDText>
      {deleteModal}
      {editModal}
    </TemplateItemWrapper>
  );
}

export default TemplateItem;
