import { IPoint } from "fabric/fabric-impl";
import { fabric } from "fabric";

export enum LastGameResultColors {
  WON = '#57B34F',
  DRAW = '#867D7DFF',
  LOST = '#EF3D3D',
}

export interface Player {
  name: string;
  number: number;
  avatar: string;
}

export interface ReplaceTextOptions {
  leftPosition?: fabric.Point
  centerPosition?: fabric.Point
  opacity?: number
  adjustText?: boolean
  additionalScale?: { x: number, y: number }
  offset?: Offset
  additionalData?: any
}

type Scale = { scaleX: number, scaleY: number };
type Offset = fabric.Point;

export interface TeamCompositionComponentsOptions {
  scale: Scale,
  offset: Offset,
  opacity?: number
  onLoaded?: (img: fabric.Image) => void
  elementColor?: string
  borderColor?: string
  textColor?: string
}

interface ReplaceTeamCompositionOptions {
  teamIndex: 0 | 1
  opacity?: number
  onLoaded?: (img: fabric.Image) => void
}

export interface PlayerBlockOptions {
  opacity?: number
  onLoaded?: (img: fabric.Image) => void
  elementColor?: string
  borderColor?: string
  textColor?: string
}

export interface ReplaceTextByIndicatorOptions {
  opacity?: number
  reverse?: boolean
  playerIndex?: number
  teamIndex?: number
  offset?: IPoint
  additionalData?: any
}

interface ReplaceImageByIndicatorOptions {
  opacity?: number
  onLoaded?: (img: fabric.Image) => void
  reverse?: boolean | false
  playerIndex?: number
  teamIndex?: number
  removePlaceholder?: boolean
  hidePlaceholder?: boolean
  additionalData?: any
}

export interface GetObjectsOptions {
  indicator: string
  teamIndex?: number
  reverse?: boolean
  playerIndex?: number

  [key: string]: any
}

export interface IUpdateTextOptions {
  indicator: string,
  text: string,
  teamReverse?: boolean
  playerIndex?: number
  teamIndex?: number
}

export interface IReplaceImageByIndicator {
  indicator: string,
  url: string,
  options?: ReplaceImageByIndicatorOptions
}

interface IReplaceResultsOptions {
  opacity?: number
  teamIndex?: number
}

export interface IReplaceResultsByIndicator {
  indicator: string,
  results: (keyof typeof LastGameResultColors)[] | undefined,
  options?: IReplaceResultsOptions
}

export interface IReplaceTeamComposition {
  players: Player[],
  options: ReplaceTeamCompositionOptions
}
