import { ArmeniaIcon, BelarusIcon, KazakhstanIcon, RussiaIcon } from "modules/ud-ui/components/icon/countries";

export const phonePrefixes = [
    {
        id: 1,
        name: "Russia",
        prefix: "+7",
        icon: RussiaIcon,
    },
    {
        id: 2,
        name: "Kazakhstan",
        prefix: "+7",
        icon: KazakhstanIcon,
    },
    {
        id: 3,
        name: "Belarus",
        prefix: "+375",
        icon: BelarusIcon,
    },
    {
        id: 4,
        name: "Armenia",
        prefix: "+374",
        icon: ArmeniaIcon,
    },
];
