import AsyncSelect, { AsyncProps } from 'react-select/async';
import { DadataAreaType, IDadataArea } from '../../domain';
import React, { forwardRef } from 'react';

import { GroupBase } from 'react-select';
import Select from 'react-select/dist/declarations/src/Select';
import { createDadataRepository } from '../../infra/repositories/dadata-repository.factory';

const dadataRepository = createDadataRepository();
const loadingOptions = async (
  inputValue: string,
  onlyCity?: boolean,
  allowedTypes: DadataAreaType[] = [DadataAreaType.CITY]
): Promise<DadataSelectOption[]> => {
  const areas = await dadataRepository.getSuggestedAreas(inputValue, {
    onlyCity,
    types: allowedTypes,
  });
  return areas.map((area) => ({
    label: area.value,
    value: area,
  }));
};

export type DadataSelectOption = {
  label: string | number | JSX.Element;
  value: IDadataArea | null | undefined;
};

export type Options = {
  onlyCity?: boolean;
  allowedTypes?: DadataAreaType[];
};

export type DadataAutocompleteProps = Omit<AsyncProps<DadataSelectOption, boolean, GroupBase<DadataSelectOption>>, 'loadOptions'> & Options;

export const DadataAutocomplete = forwardRef<Select<DadataSelectOption, boolean, GroupBase<DadataSelectOption>>, DadataAutocompleteProps>((props, ref) => {
  const { onlyCity, allowedTypes = [DadataAreaType.CITY], ...rest } = props;
  return (
    <AsyncSelect
      {...rest}
      cacheOptions
      ref={ref}
      loadOptions={(value) => loadingOptions(value, onlyCity, allowedTypes)}
    />
  );
});
