import * as S from './styles';

import { useMemo } from 'react';

interface DropdownProps {
    isOpen: boolean;
    options: React.ReactNode;
    parentRef: React.RefObject<HTMLDivElement>;
}

const offset = 4;

export const Dropdown: React.FC<DropdownProps> = ({ isOpen, options, parentRef }) => {

    const dropdownPosition = useMemo(() => {
        if (!parentRef.current) return {};
        const rect = parentRef.current.getBoundingClientRect();
        return {
            top: rect.bottom + offset,
            left: rect.left,
            width: rect.width
        };
    }, [parentRef, isOpen]);

    return (
        <S.Dropdown isOpen={isOpen} top={dropdownPosition.top ?? 0} left={dropdownPosition.left ?? 0} width={dropdownPosition.width ?? 0}>
            {options}
        </S.Dropdown>
    );
};
