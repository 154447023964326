import { SVGProps } from "react";

type ArrowDownIconProps = SVGProps<SVGSVGElement> & {
    width?: number;
    height?: number;
    color?: string;
    direction?: 'up' | 'down';
}

export function ArrowDownIcon(props: ArrowDownIconProps) {
    const { color = 'currentColor', width = 24, height = 24, direction = 'down', ...rest } = props;

    const transform = direction === 'up' ? 'rotate(180deg)' : 'rotate(0deg)';
    return <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ transform }} {...rest}>
        <g clip-path="url(#clip0_15414_22696)">
            <path d="M8.38666 14.3653L7.67999 13.658L11.5313 9.80534C11.593 9.74324 11.6664 9.69395 11.7473 9.66032C11.8281 9.62669 11.9148 9.60937 12.0023 9.60937C12.0899 9.60937 12.1766 9.62669 12.2574 9.66032C12.3382 9.69395 12.4116 9.74324 12.4733 9.80534L16.3267 13.658L15.62 14.3647L12.0033 10.7487L8.38666 14.3653Z" fill={color} />
        </g>
        <defs>
            <clipPath id="clip0_15414_22696">
                <rect width="8" height="16" fill="white" transform="matrix(0 -1 1 0 4 16)" />
            </clipPath>
        </defs>
    </svg>
        ;
}
