import { SVGProps } from "react";

type IProps = {
    width?: number;
    height?: number;
} & SVGProps<SVGSVGElement>;

const RussiaIcon = (props: IProps) => {
    const { width, height, ...rest } = props;

    return (
        <svg width={width ?? 24} height={height ?? 24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
            <g clipPath="url(#clip0_15414_22455)">
                <path d="M21 16.5C21 17.0304 20.7893 17.5391 20.4142 17.9142C20.0391 18.2893 19.5304 18.5 19 18.5H5C4.46957 18.5 3.96086 18.2893 3.58579 17.9142C3.21071 17.5391 3 17.0304 3 16.5V14.5H21V16.5Z" fill="#CE2028" />
                <path d="M3 9.5H21V14.5H3V9.5Z" fill="#22408C" />
                <path d="M19 5.5H5C4.46957 5.5 3.96086 5.71071 3.58579 6.08579C3.21071 6.46086 3 6.96957 3 7.5L3 9.5H21V7.5C21 6.96957 20.7893 6.46086 20.4142 6.08579C20.0391 5.71071 19.5304 5.5 19 5.5Z" fill="#EEEEEE" />
            </g>
            <defs>
                <clipPath id="clip0_15414_22455">
                    <rect width="18" height="18" fill="white" transform="translate(3 3)" />
                </clipPath>
            </defs>
        </svg>
    );
};

export default RussiaIcon;
