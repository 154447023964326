import { Player, Team } from "../../domain/interfaces/player";
import React, { useMemo, useState } from "react";

import TeamCard from "./team-card";
import UDButton from "../../../ud-ui/components/button";
import UDIcon from "../../../ud-ui/components/icon";
import UDSearchComponent from "../../../ud-ui/components/search/search-component";
import useModalAddToTeam from "../hooks/use-modal-add-to-team";

interface IProps {
  player: Player
  teams: Team[]
}

function TeamsList(props: IProps) {
  const { teams, player } = props
  const { open: openModalAddToTeam, Modal: ModalAddToTeam } = useModalAddToTeam()

  const [searchValue, setSearchValue] = useState('')
  const handleSearch = (value: string) => setSearchValue(value)

  const filteredTeams = useMemo(() => {
    if (!searchValue) {
      return teams
    } else {
      return teams.filter(team => team.name.toLowerCase().includes(searchValue.toLowerCase()))
    }
  }, [searchValue, teams]);

  const body = useMemo(() => {
    if (!teams || teams?.length === 0) {
      return (
        <div className='container pt-6'>
          <div className='flex-column flex-center mt-24'>
            <div className='text body-text bold mb-2'>
              Игрок не состоит ни в одной команде
            </div>
          </div>
        </div>
      )
    }

    return (
      <div className={'d-flex flex-column'} style={{ gap: 16 }}>
        {filteredTeams.map((team, index) => {
          return (
            <TeamCard player={player} key={team.id} team={team} index={index} />
          )
        })}
      </div>
    )
  }, [filteredTeams, player.id, teams]);

  return (
    <>
      <div className={'d-flex w-100 justify-content-between'}>
        {teams.length > 0 ? (
          <UDSearchComponent
            style={{ marginBottom: 24 }}
            value={searchValue}
            placeholder={'Поиск по команде'}
            onChange={(event) => handleSearch(event.target.value)}
          />
        ) : <div />}
        <UDButton
          className={'flex-center'}
          style={{ height: 38 }}
          variant={"dark"}
          onClick={() => openModalAddToTeam(player)}
        >
          <UDIcon name={'plus'} componentProps={{ className: 'mr-2' }} />
          Добавить в команду
        </UDButton>
      </div>

      {body}

      <ModalAddToTeam />
    </>
  );
}

export default TeamsList;
