import * as S from './styles';

import CalendarNavIcon from "../../../icon/calendar-nav-icon";
import { Pages } from 'modules/navigation/domain/enums/pages';
import React from 'react';
import UDColorableIcon from 'modules/ud-ui/components/icon/colorable-icon';
import UDNavLink from 'modules/ud-ui/components/link/nav-link';
import classNames from 'classnames';
import { useAuth } from "../../../../../auth/ui/hooks/useAuth";
import { useMatch } from "react-router-dom";

type Props = { className?: string };

const UDMenuSystemNavigation = (props: Props) => {
  const isSettings = useMatch(Pages.SETTINGS);
  const isTemplate = useMatch(Pages.TEMPLATE.INDEX + '*');
  const isOrganizers = useMatch(Pages.ORGANIZERS.INDEX + '*');
  const isSchedule = useMatch(Pages.SCHEDULE.INDEX + '*');
  const { user } = useAuth()
  const isDev = process.env.REACT_APP_ENVIRONMENT === 'development';

  return (
    <S.MenuSystemNavigation className={classNames('d-flex flex-column', props.className)}>
      <UDNavLink
        to={Pages.SCHEDULE.INDEX}
        checkPath={Pages.SCHEDULE.INDEX + '*'}
        className='d-flex align-items-center pl-10 pr-6 py-2'
        style={{ gap: 8 }}
      >
        <div className={'flex-center'} style={{ color: isSchedule ? 'white' : undefined }}>
          <CalendarNavIcon width={16} height={16} />
        </div>
        Расписание
      </UDNavLink>
      <UDNavLink
        to={Pages.SETTINGS}
        className='d-flex align-items-center pl-10 pr-6 py-2'
      >
        <div className={'flex-center'} style={{ color: isSettings ? 'white' : undefined }}>
          <UDColorableIcon name='gear' componentProps={{ className: 'mr-2' }} />
        </div>
        Настройки
      </UDNavLink>
      <UDNavLink
        to={Pages.TEMPLATE.INDEX}
        checkPath={Pages.TEMPLATE.INDEX + '*'}
        className='d-flex align-items-center pl-10 pr-6 py-2'
      >
        <div className={'flex-center'} style={{ color: isTemplate ? 'white' : undefined }}>
          <UDColorableIcon name={'templates'} componentProps={{ className: 'mr-2' }} />
        </div>
        Шаблоны
      </UDNavLink>
      {(user?.id === 20 || isDev) && (
        <UDNavLink
          to={Pages.ORGANIZERS.INDEX}
          checkPath={Pages.ORGANIZERS.INDEX + '*'}
          className='d-flex align-items-center pl-10 pr-6 py-2'
        >
          <div className={'flex-center'} style={{ color: isOrganizers ? 'white' : undefined }}>
            <UDColorableIcon name={'organizers'} componentProps={{ className: 'mr-2' }} />
          </div>
          Организаторы
        </UDNavLink>
      )}
      {/*<UDNavLink*/}
      {/*  to={Pages.NOTIFICATIONS}*/}
      {/*  className="notification d-flex align-items-center pl-10 pr-6 py-2">*/}
      {/*  <UDColorableIcon*/}
      {/*    name="notification"*/}
      {/*    componentProps={{ className: 'mr-2' }}*/}
      {/*  />*/}
      {/*  Уведомления*/}
      {/*  <UDText type="footnote" className="count ml-2">*/}
      {/*    7*/}
      {/*  </UDText>*/}
      {/*</UDNavLink>*/}
      {/*<UDNavLink*/}
      {/*  to={Pages.SUPPORT}*/}
      {/*  className="d-flex align-items-center pl-10 pr-6 py-2">*/}
      {/*  <UDColorableIcon*/}
      {/*    name="support"*/}
      {/*    componentProps={{ className: 'mr-2' }}*/}
      {/*  />*/}
      {/*  Помощь*/}
      {/*</UDNavLink>*/}
    </S.MenuSystemNavigation>
  );
};

export default UDMenuSystemNavigation;
