import React, { useCallback, useMemo } from "react";

import UDInput from "modules/ud-form/components/input/component";
import UDPerPage from "modules/ud-ui/components/per-page/per-page";
import classNames from "classnames";
import debounce from "modules/utils/debounce";

type Props = {
    className?: string;

    perPage: number;
    onChangePerPage?: (value: number) => void;

    onFilter?: (value: string) => void;
    value: string | null;

    placeholder?: string;
};

function Filter(props: Props) {
    const { onFilter, className, value, perPage, onChangePerPage, placeholder } = props;

    const handleChangeSearch = useMemo(() =>
        debounce((e: React.ChangeEvent<HTMLInputElement>) => {
            onFilter?.(e.target.value);
        }, 300),
        [onFilter],
    );

    const handleChangeResultsOnPage = useCallback((value: number) => {
        onChangePerPage?.(value)
    }, [onChangePerPage]);

    return (
        <div className={classNames('mb-6 d-flex justify-content-between', className)}>
            <div className='col-5'>
                <UDInput
                    value={value ?? ''}
                    placeholder={placeholder}
                    onChange={handleChangeSearch}
                    iconProps={{
                        position: 'icon-left',
                        name: 'search',
                        size: 20,
                        componentProps: { className: 'color-SurfaceRomanSilver30' },
                    }}
                />
            </div>
            <UDPerPage value={perPage} onChange={handleChangeResultsOnPage} />
        </div>
    );
}

export default Filter;
