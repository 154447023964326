import { FieldProps, useFormikContext } from 'formik';
import React, { useCallback } from 'react';
import { UDDadataAreaSelect, UDDadataAreaSelectProps } from './component';

import { DadataAreaType } from '../../../../shared/features/dadata-address-autocomplete/domain/entities/dadata-area.entity';
import { IDadataArea } from "../../../../shared/features/dadata-address-autocomplete";
import { UDFormComponentProps } from '../types';
import UDFormFieldWrapper from '../../wrapper';

type UDFormAddressSelectProps = UDFormComponentProps & UDDadataAreaSelectProps & {
  allowedTypes?: DadataAreaType[];
};

type Returning = (props: UDFormAddressSelectProps) => JSX.Element;

export const UDFormAddressSelect: Returning = (props) => {
  const {
    name,
    containerProps,
    required,
    label,
    labelProps,
    allowedTypes = [DadataAreaType.CITY],
    ...otherSelectProps
  } = props;

  const { setFieldValue } = useFormikContext();
  const onChange = useCallback(
    (value: IDadataArea | undefined) => {
      setFieldValue(name, value);

      if (props.onChange) {
        props.onChange(value);
      }
    },
    [name, props, setFieldValue],
  );

  return (
    <UDFormFieldWrapper
      name={name}
      containerProps={containerProps}
      label={label}
      labelProps={labelProps}
      required={required}>
      {({ field }: FieldProps<any>) => (
        <UDDadataAreaSelect
          {...otherSelectProps}
          {...field}
          onChange={onChange}
          allowedTypes={allowedTypes}
        />
      )}
    </UDFormFieldWrapper>
  );
};
