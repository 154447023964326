import TeamPlayersApplicationFilters, { TeamPlayersFilterValues } from './players-filters/players-filters';
import { useCallback, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { Pages } from "../../../../../navigation/domain/enums/pages";
import PlayersComponentsPlayerCard from './player-card';
import { TournamentUser } from 'modules/teams/domain/interfaces/TournamentUser';
import UDButton from 'modules/ud-ui/components/button';
import UDIcon from 'modules/ud-ui/components/icon';

type TeamsComponentsFormPlayersListPartProps = {
  teamId: number;
  players: TournamentUser[];
};

const TeamsComponentsFormPlayersListPart = (
  props: TeamsComponentsFormPlayersListPartProps,
) => {
  const navigate = useNavigate()
  const { teamId, players } = props;

  const playerId = Number(useLocation().state?.fromPlayer);

  const [name, setName] = useState<string>('');
  const playersList = useMemo(() => {
    if (!teamId && !players.length) {
      return [];
    }

    return players.filter((player) => {
      const query = name.toLowerCase();
      const firstName = player.name.toLowerCase();
      const surname = player.surname.toLowerCase();
      const middleName = player.middleName
        ? player.middleName.toLowerCase()
        : '';

      return (
        firstName.includes(query) ||
        surname.includes(query) ||
        middleName.includes(query)
      );
    });
  }, [name, players, teamId]);

  const handleFilterChange = useCallback((values: TeamPlayersFilterValues) => {
    setName(values.name);
  }, []);

  const handleToCreateNewPlayer = useCallback(() => {
    navigate(Pages.TEAMS.builders.edit(teamId, 'players/create'), { state: playerId && { fromPlayer: playerId } },);
  }, [navigate, playerId, teamId]);

  if (players.length === 0) {
    return (
      <div className='container pt-6'>
        <div className='flex-column flex-center mt-24'>
          <div className='text body-text bold mb-2'>В команде пока нет игроков</div>
          <div className='text body-text'>Давайте добавим?</div>
          <div className='mt-6'>
            <UDButton
              className='d-flex align-items-center'
              variant='dark'
              onClick={handleToCreateNewPlayer}
            >
              <UDIcon name='plus' componentProps={{ className: 'mr-2' }} />
              <span>Добавить игрока</span>
            </UDButton>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className='pb-6'>
      <div className='d-flex justify-content-between mb-6'>
        <TeamPlayersApplicationFilters
          name={name}
          inputPlaceholder='Поиск по игроку'
          onChange={handleFilterChange}
          className='w-50'
        />
        <UDButton
          className='d-flex align-items-center'
          variant='dark'
          onClick={handleToCreateNewPlayer}
        >
          <UDIcon name='plus' componentProps={{ className: 'mr-2' }} />
          <span>Добавить игрока</span>
        </UDButton>
      </div>

      {playersList.map((player, index) => (
        <PlayersComponentsPlayerCard
          key={player.id}
          teamId={teamId}
          player={player}
          index={index}
        />
      ))}
    </div>
  );
};

export default TeamsComponentsFormPlayersListPart;
