import { ApplicationLink } from '../interfaces/ApplicationLink';
import applicationLinkResource from '../resources/applicationLinkResource';

class ApplicationLinkService {
  public async getApplicationLink(): Promise<ApplicationLink> {
    return applicationLinkResource.getApplicationLink();
  }

  public async createApplicationLink(): Promise<ApplicationLink> {
    return applicationLinkResource.createApplicationLink();
  }
}

const applicationLinkService = new ApplicationLinkService();
export default applicationLinkService;