import { GroupBase, Props as SelectProps } from 'react-select';
import { SelectOption, UDSelect } from '../../../ud-form/components/select/component';

import { BorderedSelect } from 'modules/teams/ui/components/styles';
import { PlayerPosition } from '../../domain/enums/PlayerPosition';
import React from 'react';
import styled from "@emotion/styled";
import { useTranslation } from 'react-i18next';

type PositionSelectProps<
  IsMulti extends boolean = boolean,
  Group extends GroupBase<SelectOption> = GroupBase<SelectOption>
> = Omit<
  SelectProps<SelectOption, IsMulti, Group>,
  'hideSelectedOptions' | 'isSearchable' | 'styles' | 'value'
> & {
  value?: string;
};

type ReturningPositionSelect = <
  isMulti extends boolean = false,
  Group extends GroupBase<SelectOption> = GroupBase<SelectOption>
>(
  props: PositionSelectProps<isMulti, Group> & {
    divProps?: React.HTMLAttributes<HTMLDivElement>;
  },
) => JSX.Element;

const PositionSelect: ReturningPositionSelect = (props) => {
  const { t } = useTranslation();
  const {
    value,
    ...restProps
  } = props;

  const positionOptions = Object
    .values(PlayerPosition)
    .map((position) => ({
      value: position,
      label: t(`player.position.${position}`),
    }));

  const selectedOption = value
    ? positionOptions.find((item) => item.value === value)
    : undefined

  return (
    <Selector
      {...restProps}
      options={positionOptions}
      value={selectedOption}
    />
  );
};

export default PositionSelect;

const Selector = styled(BorderedSelect)`
  margin-left: 20px;
  width: 180px;
  overflow: unset;
` as typeof UDSelect;
