/** @jsxImportSource @emotion/react */

import * as S from './styles';

import React from 'react';
import { SerializedStyles } from '@emotion/react';
import { generateUUID } from 'modules/utils/uuid';

export type UDCheckboxProps = {
  className?: string;
  css?: SerializedStyles;
} & Omit<
  React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  >,
  'className' | 'hidden' | 'id'
>;

const UDCheckbox = (props: UDCheckboxProps) => {
  const { className, ...inputProps } = props;
  const id = generateUUID();
  return (
    <S.CheckboxLabel htmlFor={id} className={className}>
      <S.CheckboxInput {...inputProps} hidden type="checkbox" id={id} />
      <S.CustomCheckbox />
    </S.CheckboxLabel>
  );
};

export default UDCheckbox;
