import React, { ChangeEvent, useCallback } from 'react';

import UDInput from 'modules/ud-form/components/input/component';
import classNames from 'classnames';

export type TeamPlayersFilterValues = {
  name: string;
};

type TeamPlayersApplicationFiltersProps = {
  name?: string;
  inputPlaceholder?: string;
  onChange?: (values: TeamPlayersFilterValues) => void;
  className?: string;
};

function TeamPlayersApplicationFilters(
  props: TeamPlayersApplicationFiltersProps
) {
  const { name = '', inputPlaceholder, onChange, className } = props;

  const handleOnChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (onChange) {
        onChange({
          name: event.target.value,
        });
      }
    },
    [onChange]
  );

  return (
    <div
      className={classNames(
        'd-flex align-items-center justify-content-between',
        className
      )}
    >
      <div className="w-100">
        <UDInput
          value={name}
          placeholder={inputPlaceholder}
          iconProps={{
            position: 'icon-right',
            name: 'search',
          }}
          onChange={handleOnChange}
        />
      </div>
    </div>
  );
}

export default TeamPlayersApplicationFilters;
