import { UNSAFE_NavigationContext, useNavigate } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';

import React from 'react';
import { usePreventNavigationModal } from './usePreventNavigationModal';

interface UsePreventNavigationProps {
    shouldPrevent: boolean;
    onPrevent?: () => void;
    onConfirm?: () => void;
    onCancel?: () => void;
    defaultPath?: string;
}

export function usePreventNavigation(props: UsePreventNavigationProps) {
    const { shouldPrevent, onPrevent, onConfirm, onCancel, defaultPath } = props;
    const navigator = React.useContext(UNSAFE_NavigationContext).navigator as any;
    const [tx, setTx] = useState<any>(null);
    const [targetPath, setTargetPath] = useState<string | null>(null);
    const [nextLocation, setNextLocation] = useState<string | number | null>(null);
    const navigate = useNavigate();

    const handleConfirm = useCallback(() => {
        if (tx) {
            const currentTx = tx;
            setTx(null);
            setTargetPath(null);
            setTimeout(() => {
                currentTx.retry();
            }, 0);
            return true;
        }
        return false;
    }, [tx]);

    const { openLeaveModal, closeLeaveModal, Modal: PreventNavigationModal } = usePreventNavigationModal({
        navigationTx: tx,
        nextLocation,
        targetPath,
        confirmNavigation: handleConfirm,
        navigate,
        defaultPath,
        onConfirm,
        onCancel,
    });

    useEffect(() => {
        let unblock: (() => void) | undefined;

        if (shouldPrevent) {
            unblock = navigator?.block?.((transaction: any) => {
                setTx(transaction);
                setTargetPath(transaction.location.pathname);
                openLeaveModal();
                onPrevent?.();
                return false;
            });
        } else {
            setTx(null);
            setTargetPath(null);
        }

        return () => {
            if (unblock) {
                unblock();
            }
        };
    }, [navigator, shouldPrevent, onPrevent, openLeaveModal]);


    return {
        tx,
        targetPath,
        confirmNavigation: handleConfirm,
        openLeaveModal,
        closeLeaveModal,
        PreventNavigationModal,
        setNextLocation,
    };
}
