import { fetchTournamentUsers, fetchTournamentUsersByFilter } from "modules/players/store/actions";
import { selectFilter, selectPlayersByFilter } from '../../store/selectors';
import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { AppDispatch } from 'store/store';
import { selectLoad } from "modules/players/store/selectors";
import { setFilter } from "modules/players/store";
import { useOrganizer } from '../../../organizer/ui/hooks/useOrganizer';
import useTournamentUsers from './useTournamentUsers';

const usePlayerList = () => {
    const dispatch = useDispatch<AppDispatch>()
    const { players, allDataLoaded, playerLoadedLimit } = useTournamentUsers()
    const { isLoading, isLoaded } = useSelector(selectLoad)
    const { info } = useOrganizer()

    const filter = useSelector(selectFilter)
    const playersByFilter = useSelector(selectPlayersByFilter)

    const [isProcessing, setIsProcessing] = useState(false)

    const loadNextPages = useCallback(() => {
        if (!info) return
        if (allDataLoaded) return
        dispatch(fetchTournamentUsers({ tournamentId: info.id, limit: playerLoadedLimit, offset: players.length }))
    }, [allDataLoaded, dispatch, info, playerLoadedLimit, players.length])

    const changeFilter = useCallback((value: string) => {
        if (value === filter.text) return
        dispatch(setFilter({ text: value }))

        if (!info) return
        setIsProcessing(true)
        dispatch(fetchTournamentUsersByFilter({ nameFilter: value, tournamentId: info.id, limit: playerLoadedLimit })).unwrap().finally(() => {
            setIsProcessing(false)
        })
    }, [dispatch, filter, info, playerLoadedLimit])

    return {
        players,
        isLoaded,
        isLoading: isLoading || isProcessing,
        loadNextPages,
        changeFilter,
        playersByFilter: filter.text ? playersByFilter : null,
    }
};

export default usePlayerList;
