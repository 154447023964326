import { useEffect, useState } from 'react';

/**
 * Хук для отслеживания медиа-запросов
 * @param query - CSS медиа-запрос
 * @returns boolean - результат проверки медиа-запроса
 */
const useMediaQuery = (query: string): boolean => {
    const [matches, setMatches] = useState<boolean>(false);

    useEffect(() => {
        const mediaQuery = window.matchMedia(query);

        // Установка начального значения
        setMatches(mediaQuery.matches);

        // Создаем функцию обработчик
        const handler = (event: MediaQueryListEvent) => {
            setMatches(event.matches);
        };

        // Подписываемся на изменения
        mediaQuery.addEventListener('change', handler);

        // Отписываемся при размонтировании
        return () => {
            mediaQuery.removeEventListener('change', handler);
        };
    }, [query]);

    return matches;
};

export default useMediaQuery;