import basketball from '../icon/icons/basketball-ball.svg';
import { keyframes } from '@emotion/react';
import soccer from '../icon/icons/soccer-ball.svg';
import styled from '@emotion/styled';
import voleyball from '../icon/icons/voleyball-ball.svg';

const loaderAnimation = keyframes`
  from {
    background-image: url(${soccer});
    transform: translateY(0);
  }
  12.5%{
    background-image: url(${soccer});
    transform: translateY(-4px);
  }
  25%{
    background-image: url(${soccer});
    transform: translateY(0);
  }
  37.5% {
    background-image: url(${voleyball});
    transform: translateY(0);
  }
  50%{
    background-image: url(${voleyball});
    transform: translateY(-4px);
  }
  62.5%{
    background-image: url(${voleyball});
    transform: translateY(0);
  }
  75% {
    background-image: url(${basketball});
    transform: translateY(0);
  }
  87.5%{
    background-image: url(${basketball});
    transform: translateY(-4px);
  }
  to{
    background-image: url(${basketball});
    transform: translateY(0);
  }
`;

export const Button = styled.button`
  padding: 8px 16px;
  box-sizing: border-box;
  border-radius: 8px;
  border-width: 1px;
  border-style: solid;
  cursor: pointer;
  user-select: none;
  line-height: unset;
  border-color: transparent;

  transition: all 0.2s ease-in-out;

  &.loading {
    position: relative;
    user-select: none;
    pointer-events: none;
    &::before {
      position: absolute;
      top: 0;
      left: 0;
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      background-color: inherit;
      border-radius: inherit;
    }
    &::after {
      position: absolute;
      top: 50%;
      left: 50%;
      content: '';
      display: block;
      width: 14px;
      height: 14px;
      margin-top: -7px;
      margin-left: -7px;
      background-size: 14px 14px;
      animation: ${loaderAnimation} 3s ease infinite alternate;
    }
  }

  &.slim {
    padding: 4px 8px;
    border-radius: 4px;
  }

  &[disabled] {
    cursor: default;
    pointer-events: none;
  }

  &.primary {
    background-color: ${({ theme }) => theme.colors.AccentNaplesYellow};
    border-color: ${({ theme }) => theme.colors.AccentNaplesYellow};
    color: ${({ theme }) => theme.colors.SurfaceRaisinBlack50};

    &:focus {
      border-color: ${({ theme }) => theme.colors.SurfaceWhite};
    }
    &:hover {
      background-color: ${({ theme }) => theme.colors.AccentJonquil};
      border-color: ${({ theme }) => theme.colors.AccentJonquil};
    }
    &:active {
      background: radial-gradient(
          circle at center,
          ${({ theme }) => theme.colors.AccentJonquil} 9%,
          rgba(242, 202, 24, 0) 10%
        ),
        ${({ theme }) => theme.colors.AccentNaplesYellow};
      border-color: ${({ theme }) => theme.colors.AccentNaplesYellow};
    }
    &[disabled] {
      background: ${({ theme }) => theme.colors.AccentMediumChampagne};
      border-color: ${({ theme }) => theme.colors.AccentMediumChampagne};
      color: ${({ theme }) => theme.colors.SurfaceRomanSilver30};
    }
  }

  &.secondary {
    background-color: #FFFFFF;
    border-color: ${({ theme }) => theme.colors.AccentNaplesYellow};
    border-width: 1px;
    color: ${({ theme }) => theme.colors.SurfaceRaisinBlack50};

    &:focus {
      position: relative;
      background-color: ${({ theme }) => theme.colors.AccentCornsilk};
      border-color: ${({ theme }) => theme.colors.AccentJonquil};
      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        border-radius: inherit;
        width: calc(100% - 2px);
        height: calc(100% - 2px);
        background-color: transparent;
        border: 1px solid ${({ theme }) => theme.colors.AccentJonquil};
      }
    }
    &:hover {
      background-color: ${({ theme }) => theme.colors.AccentCornsilk};
      border-color: ${({ theme }) => theme.colors.AccentJonquil};
    }
    &:active {
      background: radial-gradient(
          circle at center,
          ${({ theme }) => theme.colors.AccentCornsilk} 9%,
          rgba(242, 202, 24, 0) 10%
        ),
        ${({ theme }) => theme.colors.SurfaceWhite};
      border-color: ${({ theme }) => theme.colors.AccentMediumChampagne};
      &::after {
        display: none;
      }
    }
    &[disabled] {
      background: ${({ theme }) => theme.colors.AccentMediumChampagne};
      border-color: ${({ theme }) => theme.colors.AccentMediumChampagne};
      color: ${({ theme }) => theme.colors.SurfaceRomanSilver30};
    }
  }

  &.dark {
    background-color: ${({ theme }) => theme.colors.SurfaceIndependence40};
    border-color: ${({ theme }) => theme.colors.SurfaceIndependence40};
    color: ${({ theme }) => theme.colors.SurfaceWhite};

    &:focus {
      border-color: ${({ theme }) => theme.colors.AccentJonquil};
      color: ${({ theme }) => theme.colors.AccentNaplesYellow};
    }
    &:hover {
      color: ${({ theme }) => theme.colors.AccentNaplesYellow};
    }
    &:active {
      border-color: ${({ theme }) => theme.colors.AccentNaplesYellow};
      color: ${({ theme }) => theme.colors.AccentNaplesYellow};
    }
    &[disabled] {
      color: ${({ theme }) => theme.colors.SurfaceRomanSilver30};
    }
    &.loading {
      &::after {
        filter: invert(1) saturate(5) hue-rotate(351deg);
      }
    }
  }

  &.light {
    background-color: ${({ theme }) => theme.colors.SurfaceWhite};
    border-color: ${({ theme }) => theme.colors.SurfaceWhite};
    color: ${({ theme }) => theme.colors.SurfaceRaisinBlack50};

    &:focus {
      position: relative;
      background-color: ${({ theme }) => theme.colors.AccentCornsilk};
      border-color: ${({ theme }) => theme.colors.AccentNaplesYellow};
      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        border-radius: inherit;
        width: calc(100% - 2px);
        height: calc(100% - 2px);
        background-color: transparent;
        border: 1px solid ${({ theme }) => theme.colors.AccentJonquil};
      }
    }
    &:hover {
      background-color: ${({ theme }) => theme.colors.AccentCornsilk};
      border-color: ${({ theme }) => theme.colors.AccentNaplesYellow};
    }
    &:active {
      background-color: ${({ theme }) => theme.colors.AccentNaplesYellow};
      border-color: ${({ theme }) => theme.colors.AccentNaplesYellow};
      &::after {
        display: none;
      }
    }
    &[disabled] {
      border-color: ${({ theme }) => theme.colors.SurfaceRomanSilver30};
      color: ${({ theme }) => theme.colors.SurfaceRomanSilver30};
    }
  }

  &.tertiary {
    background-color: transparent;
    border-color: transparent;
    color: ${({ theme }) => theme.colors.AccentNaplesYellow};

    &:focus {
      border-color: ${({ theme }) => theme.colors.AccentJonquil};
    }
    &:hover {
      background-color: ${({ theme }) => theme.colors.AccentCornsilk};
      border-color: ${({ theme }) => theme.colors.AccentCornsilk};
      color: ${({ theme }) => theme.colors.AccentJonquil};
    }
    &.for-dark:hover {
      background-color: transparent;
      border-color: transparent;
      color: ${({ theme }) => theme.colors.AccentNaplesYellow};
    }
    &:active {
      border-color: ${({ theme }) => theme.colors.AccentCornsilk};
      color: ${({ theme }) => theme.colors.AccentJonquil};
      background: radial-gradient(
          circle at center,
          ${({ theme }) => theme.colors.AccentCornsilk} 9%,
          rgba(242, 202, 24, 0) 10%
        ),
        transparent;
    }
    &.for-dark:active {
      background: ${({ theme }) => theme.colors.AccentMediumChampagne};
      border-color: transparent;
      color: ${({ theme }) => theme.colors.AccentNaplesYellow};
    }
    &[disabled] {
      color: ${({ theme }) => theme.colors.SurfaceRomanSilver30};
    }
  }

  &.gray {
    background-color: ${({ theme }) => theme.colors.WhiteGray};
    border-color: ${({ theme }) => theme.colors.WhiteGray};
    color: ${({ theme }) => theme.colors.SurfaceWhite};
    &:hover {
      color: ${({ theme }) => theme.colors.AccentNaplesYellow};
    }
    &:active {
      border-color: ${({ theme }) => theme.colors.AccentJonquil};
      color: ${({ theme }) => theme.colors.AccentNaplesYellow};
    }
    &[disabled] {
      color: ${({ theme }) => theme.colors.SurfaceRomanSilver30};
    }
    &.loading {
      &::after {
        filter: invert(1) saturate(5) hue-rotate(351deg);
      }
    }
  }
`;
