import { scrollbar } from "shared/styles/scrollbar";
import styled from "@emotion/styled";

export const MainBlock = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;

    overflow-y: auto;
    height: 100%;

    scrollbar-gutter: stable;
    padding-left: 4px;
    ${({ theme }) => scrollbar(theme)};

    & > .UDListItem {
        margin-bottom: 10px;
    }

    & > .UDListItem:last-child {
        margin-bottom: 0;
    }

    &.white {
        background-color: white;
    }

    &.transparent {
        background-color: transparent;
    }
`;
