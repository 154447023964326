import UDButton from 'modules/ud-ui/components/button';
import size from '../../domain/constants/sizes';
import styled from '@emotion/styled';

const styles = {
  Container: styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-block: auto;
    width: 100%;
    height: 100%;
    background-color: ${({ theme }) => theme.colors.DarkBlue};
    overflow: auto;

    @media (max-width: ${size.mobile.WIDTH}px) {
      justify-content: flex-start;
    }
  `,

  FormContainer: styled.div`
    height: 100%;
    padding: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-block: auto;
    width: 100%;
    height: 100%;
    z-index: 1;

    @media (max-width: ${size.mobile.WIDTH}px) {
      padding: 24px;
      max-width: 100%;
      height: auto;
      justify-content: flex-start;
      gap: 8px;

      #logo {
        margin-bottom: 16px;
      }

      #title {
        font-size: 24px;
        line-height: 32px;
        text-align: center;
        margin-bottom: 30px;
      }

      #organizer {
        margin-bottom: 20px;

        &-name{
          text-align: center;
          font-size: 20px !important;
        }
      }

      #tournament {
        margin-bottom: 20px;

        &-name{
          text-align: center;
          font-size: 16px !important;
          line-height: 20px !important;
        }
      }

      #team {
        margin-bottom: 20px;

        &-name{
          text-align: center;
          font-size: 16px !important;
          line-height: 20px !important;
        }
      }
    }
  `,

  Button: styled(UDButton)`
    &:disabled {
      background-color: #C0C0C0;
    }
  `,
};

export default styles;
