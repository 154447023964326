import { Canvas, Group, IText, Object, StaticCanvas, Text, Textbox } from "fabric/fabric-impl";
import {
  GetObjectsOptions,
  IReplaceImageByIndicator,
  IReplaceResultsByIndicator,
  IReplaceTeamComposition,
  IUpdateTextOptions,
  LastGameResultColors,
  Player,
  PlayerBlockOptions,
  ReplaceTextByIndicatorOptions,
  ReplaceTextOptions,
  TeamCompositionComponentsOptions,
} from "../../types/replacer";

import { CanvasUtils } from "./canvasUtils";
import Fade from "../fadeIn";
import { FixedTextbox } from "../../components/FixedTextbox";
import ReplaceCarousel from "./replaceCarousel";
import ReplaceCommon from "./replaceCommon";
import ReplaceEvent from "./replaceEvent";
import Static from "../../canvases/static";
import { fabric } from "fabric";
import getGroupObjects from "./utils/getGroupObjects";
import { getPositionFromGroup } from "./utils/getPositionFromGroup";

export class Replacer {
  private canvas!: StaticCanvas | Canvas;
  public common!: ReplaceCommon
  public event!: ReplaceEvent
  public carousel!: ReplaceCarousel
  public utils!: CanvasUtils
  private isInit = false
  private fade = new Fade()

  init(manager: Static) {
    this.canvas = manager.canvas
    this.fade.init(manager)
    this.common = new ReplaceCommon(this, this.fade)
    this.event = new ReplaceEvent(this, this.fade)
    this.carousel = new ReplaceCarousel(this, this.fade)
    this.utils = new CanvasUtils(manager)
    this.isInit = true
  }

  private checkInit() {
    if (!this.isInit) {
      console.error({ event: 'Using the Replacer module before initialization' });
      return false;
    } else {
      return true
    }
  }

  replaceImageByIndicator(props: IReplaceImageByIndicator) {
    if (!this.checkInit()) { return; }

    const { indicator, url, options } = props
    const { opacity = 1, onLoaded, reverse, playerIndex, teamIndex, ...rest } = options ?? {}

    const objects = this.getObjects({ indicator, reverse, playerIndex, teamIndex });
    if (!objects) { return; }
    this.replaceImage(
      objects,
      url,
      {
        opacity,
        onLoaded,
        ...rest,
      },
    )
  }

  replaceImage(
    objects: Object[],
    url: string,
    options: {
      opacity?: number,
      onLoaded?: (img: fabric.Image) => void,
      centerPos?: fabric.Point,
      additionalScale?: { x: number, y: number },
      removePlaceholder?: boolean,
      hidePlaceholder?: boolean,
      additionalData?: any
    },
  ) {
    if (!this.checkInit()) { return; }

    const {
      opacity = 1,
      onLoaded,
      centerPos,
      additionalScale = { x: 1, y: 1 },
      removePlaceholder = true,
      hidePlaceholder = false,
      additionalData = {},
    } = options

    for (const object of objects) {
      removePlaceholder && this.canvas.remove(object);
      !removePlaceholder && hidePlaceholder && object.set('visible', false);

      fabric.Image.fromURL(url, (img) => {
        if (!object.width) {
          console.warn({ event: 'width not found' });
          return;
        }
        if (!object.height) {
          console.warn({ event: 'height not found' });
          return;
        }
        img.opacity = opacity

        img.set({ left: (object.left ?? 0), top: (object.top ?? 0), selectable: false });
        if (img.width === undefined || img.height === undefined) {
          console.warn({ event: 'width or height img not found' });
          return;
        }

        const scale = Math.min(
          object.width * object.getObjectScaling().scaleX / img.width * additionalScale.x,
          object.height * object.getObjectScaling().scaleY / img.height * additionalScale.y,
        );
        img.scale(scale);

        const center = centerPos ?? getPositionFromGroup({ object, x: 'center', y: 'center' });

        img.setPositionByOrigin(center, 'center', 'center');
        img.data = { ...img.data, ...additionalData };

        this.canvas.add(img);
        this.canvas.renderAll()
        if (!onLoaded) return;
        onLoaded(img)
      })
    }

  }

  replaceTextByIndicator(props: { indicator: string, text: string, options?: ReplaceTextByIndicatorOptions }) {
    if (!this.checkInit()) { return; }

    const { indicator, text, options } = props
    const {
      opacity = 1,
      reverse,
      playerIndex,
      teamIndex,
      offset,
      additionalData,
    } = options ?? {}

    const objects = this.getObjects({ indicator, reverse, playerIndex, teamIndex })
    if (!objects) { return; }

    this.replaceText({
      objects,
      text,
      options: {
        opacity,
        offset: new fabric.Point(offset?.x ?? 0, offset?.y ?? 0),
        additionalData,
      },
    })
  }

  replaceText(props: { objects: Object[], text: string, options?: ReplaceTextOptions }) {
    if (!this.checkInit()) { return; }

    const { objects, text, options } = props
    const {
      opacity = 1,
      offset = new fabric.Point(0, 0),
      additionalData,
    } = options ?? {};
    for (const object of objects) {
      if (object?.type !== 'group') continue;
      const placeholder = object as Group
      let textbox = this.getText(placeholder)
      if (!textbox) {
        console.warn({ event: 'text not found' });
        return;
      }

      textbox.set({ text: text ?? '' })

      let itext: IText | Textbox
      if (textbox.textAlign === 'justify') {
        itext = textbox as Textbox
        this.adjustJustifyText({ placeholder })
      } else {
        const result = this.replaceToIText(placeholder)
        if (!result) {
          console.warn({ event: 'When replacing with IText, the object could not be obtained' });
          continue
        }
        itext = result
      }

      placeholder.set({ opacity })
      placeholder.data = { ...placeholder.data, ...additionalData }

      // placeholder.add(new fabric.Rect({
      //   fill: 'rgba(0, 0, 0, 0.25)',
      //   width: placeholder.width,
      //   height: placeholder.height ?? 0,
      //   originX: 'center',
      //   originY: 'center',
      // }))
      // // itext.set({ backgroundColor: 'rgba(0, 0, 0, 0.25)' })

      const currentPosition = itext.getPointByOrigin('center', 'center')
      itext.setPositionByOrigin(currentPosition.add(offset), 'center', 'center')
      this.canvas.requestRenderAll()
    }
  }

  adjustText(props: { placeholder: Group }) {
    if (!this.checkInit()) { return; }

    const { placeholder } = props
    const textbox = this.getText(placeholder)
    if (!textbox) return

    if (placeholder.scaleX === undefined || placeholder.scaleY === undefined) return;
    const defaultScaleX = 1 / placeholder.scaleX
    const defaultScaleY = 1 / placeholder.scaleY

    if (textbox.width === undefined || textbox.height === undefined) return;
    const factorScaleX = placeholder.getScaledWidth() / textbox.width
    const factorScaleY = placeholder.getScaledHeight() / textbox.height

    const factorScale = Math.min(factorScaleX, factorScaleY)
    const xModifier = 0.95
    const yModifier = 1

    textbox.set({ scaleX: defaultScaleX * factorScale * xModifier, scaleY: defaultScaleY * factorScale * yModifier })
  }

  replaceLastResultGame(props: IReplaceResultsByIndicator) {
    if (!this.checkInit()) { return; }

    const { indicator, results, options } = props
    const { opacity = 1, teamIndex } = options ?? {}
    const objects = this.getObjects({ indicator, teamIndex }) as Group[]
    if (!objects) return;
    const notInit = !results || results.length === 0

    for (const object of objects) {
      // this.canvas.add(new fabric.Rect({
      //   fill: 'rgba(0, 0, 0, 0.5)',
      //   width: object.getScaledWidth(),
      //   height: object.getScaledHeight(),
      //   top: object.top,
      //   left: object.left,
      // }))
      this.canvas.remove(object);
      if (notInit) continue;
      const position = getPositionFromGroup({ object, x: 'left', y: 'center' })
      const radius = Math.min(object.getScaledHeight(), object.getScaledWidth() / 3) / 2
      const gap = (object.getScaledWidth() - ((radius * 2) * 3)) / 2
      const circles = []
      for (let i = 0; i < 3; i++) {
        const circle = new fabric.Circle({
          left: position.x + (radius * 2) * i + gap * i,
          top: position.y,
          radius,
          originY: 'center',
          fill: LastGameResultColors[results[i]],
          strokeWidth: 1,
          selectable: false,
          opacity: opacity,
        })
        circles.push(circle)
        this.canvas.add(circle)
      }
    }
  }

  private getObjects(options: GetObjectsOptions) {
    if (!this.checkInit()) { return; }

    const { indicator, teamIndex, reverse, playerIndex } = options

    if (!this.canvas) {
      console.error('Canvas not found');
      return [];
    }

    const allObjects = this.canvas.getObjects()
    const objects = allObjects.reduce((previousValue: Object[], object) => {
      if (object.data?.type === 'group') {
        const group = object as Group
        this.canvas.remove(group)
        const children = getGroupObjects(group)
        this.canvas.add(...children)
        return [...previousValue, ...children]
      } else {
        return [...previousValue, object]
      }
    }, [])

    return objects.filter((obj) => {
      const isIndicator = obj?.data?.name === indicator || obj?.data?.indicator === indicator
      const team = teamIndex !== undefined ? obj?.data?.team === teamIndex || obj?.data?.teamIndex === teamIndex : true
      const player = playerIndex !== undefined ? obj?.data?.index === playerIndex || obj?.data?.playerIndex === playerIndex : true
      const isReverse = reverse !== undefined ? obj?.data?.reverse === reverse || obj?.data?.teamReverse === reverse : true
      return isIndicator && team && player && isReverse
    });
  }

  updateText(options: IUpdateTextOptions) {
    if (!this.checkInit()) { return; }

    const {
      indicator,
      text,
      playerIndex,
      teamReverse,
      teamIndex,
    } = options

    const objects = this.getObjects({ indicator, playerIndex: playerIndex, reverse: teamReverse, teamIndex })
    if (!objects) return;

    for (const object of objects) {
      if (object?.type !== 'group') continue;
      const placeholder = object as Group
      const textObject = this.getText(placeholder)
      if (!textObject) continue;
      textObject.set({ text })
    }

    this.canvas.renderAll()
  }

  private getText(placeholder: Group): Text | Textbox | IText | undefined {
    if (!this.checkInit()) { return; }

    if (placeholder?.type !== 'group') return undefined;
    for (const object of placeholder.getObjects()) {
      if (!object?.type?.includes('text')) continue;
      return object as Text | Textbox | IText
    }

    return undefined
  }

  replaceTeamComposition(props: IReplaceTeamComposition) {
    if (!this.checkInit()) { return; }

    const { players, options } = props
    const { opacity = 1, onLoaded, teamIndex } = options
    const result = this.getObjects({ indicator: "teamComposition", teamIndex }) as Group[]
    if (!result) return;
    if (!result.length) return;

    for (const teamComposition of result) {
      // this.canvas.remove(teamComposition)
      if (!teamComposition || teamComposition.type !== 'group') continue;
      const { elementColor, borderColor, backgroundColor, textColor } = teamComposition?.data ?? {}

      const width = teamComposition.getScaledWidth()
      const height = teamComposition.getScaledHeight()
      const point = getPositionFromGroup({ object: teamComposition, x: 'left', y: 'top' })
      this.canvas.add(new fabric.Rect({
        width,
        height,
        top: point.y,
        left: point.x,
        fill: backgroundColor,
      }))

      const objects = teamComposition.getObjects()
      if (!objects) continue;

      for (let i = 0; i < objects.length; i++) {
        const object = objects[i]
        const name = object?.data?.name ?? object?.data?.indicator
        if (!name) continue;

        switch (name) {
          case 'playerBlock': {
            const index = object.data?.number ?? object.data?.index
            if (players[index] === undefined) {
              teamComposition.remove(object)
              continue
            }
            this.createPlayerBlock(
              object as Group,
              players[index],
              teamComposition,
              { opacity, onLoaded, elementColor, borderColor, textColor },
            )
            break
          }
        }
      }
    }

    this.canvas.renderAll()
  }

  private createAvatar(avatar: Object, url: string, options: TeamCompositionComponentsOptions) {
    if (!this.checkInit()) { return; }

    const { scale, offset, opacity, onLoaded, elementColor, borderColor } = options

    const left = avatar.getPointByOrigin('left', 'top')
    const pos = (new fabric.Point(left.x * scale.scaleX, left.y * scale.scaleY)).add(offset);
    const center = avatar.getPointByOrigin('center', 'center')
    const centerPos = (new fabric.Point(center.x * scale.scaleX, center.y * scale.scaleY)).add(offset);

    const react = new fabric.Rect({
      left: pos.x,
      top: pos.y,
      width: (avatar.width ?? 0) * scale.scaleX - scale.scaleX / 2, // - scale.scaleX / 2 иначе бортик вылазивает наполовину
      height: (avatar.height ?? 0) * scale.scaleY - scale.scaleY / 2, // - scale.scaleY / 2 иначе бортик вылазит наполовину
      fill: elementColor ?? "#1A1A29",
      strokeWidth: scale.scaleX,
      stroke: borderColor ?? "#404861",
      opacity,
    })
    this.canvas.add(react)
    this.replaceImage(
      [avatar],
      url,
      {
        opacity,
        onLoaded,
        centerPos,
      },
    )
  }

  private createNumber(number: Object, text: string, options: TeamCompositionComponentsOptions) {
    if (!this.checkInit()) { return; }

    const { scale, offset, opacity, elementColor, borderColor, textColor } = options
    number.data = { ...number.data, color: textColor }

    const left = number.getPointByOrigin('left', 'top')
    const scalingLeft = left.setXY(left.x * scale.scaleX, left.y * scale.scaleY);
    const pos = scalingLeft.add(offset);

    const react = new fabric.Rect({
      left: pos.x,
      top: pos.y,
      width: (number.width ?? 0) * scale.scaleX - scale.scaleX / 2,
      height: (number.height ?? 0) * scale.scaleY - scale.scaleY / 2,
      fill: elementColor ?? "#1A1A29",
      strokeWidth: scale.scaleX,
      stroke: borderColor ?? "#404861",
      opacity: opacity,
    })
    this.canvas.add(react)
    this.canvas.bringToFront(number)

    this.replaceText(
      {
        objects: [number],
        text,
        options: {
          opacity: opacity,
          offset: new fabric.Point(0.5 * -scale.scaleX, 0.5 * scale.scaleY),
        },
      },
    )
  }

  private createName(name: Object, text: string, options: TeamCompositionComponentsOptions) {
    if (!this.checkInit()) { return; }

    const { scale, offset, opacity, elementColor, borderColor, textColor } = options
    name.data = { ...name.data, color: textColor }

    const leftForBackground = name.getPointByOrigin('left', 'top')
    const scalingLeftBackground = leftForBackground.setXY(
      leftForBackground.x * scale.scaleX,
      leftForBackground.y * scale.scaleY,
    );
    const posForBackground = scalingLeftBackground.add(offset);
    const react = new fabric.Rect({
      left: posForBackground.x,
      top: posForBackground.y,
      width: (name.width ?? 0) * scale.scaleX - scale.scaleX / 2,
      height: (name.height ?? 0) * scale.scaleY - scale.scaleX / 2,
      fill: elementColor ?? "#1A1A29",
      strokeWidth: scale.scaleX,
      stroke: borderColor ?? "#404861",
      opacity: opacity,
    })
    this.canvas.add(react)

    const fixedTextbox = new FixedTextbox(text, {
      width: (name.width ? name.width * scale.scaleX : 0) - 20 * scale.scaleX,
      height: (name.height ? name.height * scale.scaleY : 0),
      fontSize: name.data?.fontSize * scale.scaleX,
      fill: name.data?.color ?? '#000000',
      fontFamily: name.data?.fontFamily ?? 'Big Shoulders Display, sans-serif',
      fontWeight: name.data?.fontWeight ?? '500',
      opacity: opacity,
    })

    const left = name.getPointByOrigin('left', 'center')
    const scalingLeft = left.setXY(left.x * scale.scaleX, left.y * scale.scaleY);
    const pos = scalingLeft.add(offset.add({ x: 10 * scale.scaleX, y: 0 }));
    fixedTextbox.setPositionByOrigin(pos, 'left', 'center')

    this.canvas.add(fixedTextbox)
  }

  private createPlayerBlock(group: Group, player: Player, teamComposition: Group, options: PlayerBlockOptions) {
    if (!this.checkInit()) { return; }

    const { opacity, onLoaded, ...rest } = options
    const avatarIndicator = 'playerAvatar'
    const numberIndicator = 'playerNumber'
    const nameIndicator = 'playerName'

    const avatar = group.getObjects()
      .find((obj) => obj?.data?.name === avatarIndicator || obj?.data?.indicator === avatarIndicator)
    const number = group.getObjects()
      .find((obj) => obj?.data?.name === numberIndicator || obj?.data?.indicator === numberIndicator)
    const name = group.getObjects()
      .find((obj) => obj?.data?.name === nameIndicator || obj?.data?.indicator === nameIndicator)
    const scale = teamComposition.getObjectScaling()
    const mainOffset = getPositionFromGroup({ object: teamComposition, x: 'center', y: 'center' });
    const blockOffset = group.getPointByOrigin('center', "center");
    const offset = mainOffset.add(blockOffset.setXY(blockOffset.x * scale.scaleX, blockOffset.y * scale.scaleY));

    if (avatar) {
      this.createAvatar(avatar, player.avatar, { offset, scale, opacity, onLoaded, ...rest });
      (avatar as Group).remove(...((avatar as Group)?.getObjects()));
    }

    if (number) {
      this.createNumber(number, player?.number?.toString(), { offset, scale, opacity, ...rest });
      const rect = (number as Group)?.getObjects('rect');
      (number as Group).remove(...(rect ?? []));
    }

    if (name) {
      this.createName(name, player.name, { offset, scale, opacity, ...rest })
      group.remove(name)
    }
  }

  private adjustJustifyText(param: { placeholder: Group }) {
    if (!this.checkInit()) { return; }

    const { placeholder } = param
    const textbox = this.getText(placeholder)
    if (!textbox) return

    if (placeholder.scaleX === undefined || placeholder.scaleY === undefined) return;
    const defaultScaleX = 1 / placeholder.scaleX
    const defaultScaleY = 1 / placeholder.scaleY

    if (textbox.width === undefined || textbox.height === undefined) return;
    const factorScaleX = placeholder.getScaledWidth() / textbox.width
    const factorScaleY = placeholder.getScaledHeight() / textbox.height

    const minFactorScale = Math.min(factorScaleX, factorScaleY)
    const maxFactorScale = Math.max(factorScaleX, factorScaleY)
    const xModifier = 0.97
    const yModifier = 1

    textbox.set({
      scaleX: defaultScaleX * minFactorScale * xModifier,
      scaleY: defaultScaleY * minFactorScale * yModifier,
      width: placeholder.getScaledWidth(),
    })

    if (textbox.text && textbox.text?.split(' ')?.length < 2) {
      this.replaceToIText(placeholder)
      return;
    }

    const fontModifier = placeholder.height && textbox.height && (textbox.height > placeholder.height ? minFactorScale : maxFactorScale)
    const initialFontSize = textbox.fontSize
    if (!initialFontSize) return

    textbox.set({
      fontSize: fontModifier && initialFontSize * fontModifier,
    })

    if (textbox.textLines.length > 1) {
      this.replaceToIText(placeholder)
    }

    if (textbox.width > placeholder.getScaledWidth()) {
      this.replaceToIText(placeholder)
    }
  }

  private replaceToIText(placeholder: Group) {
    if (!this.checkInit()) { return; }

    const textbox = this.getText(placeholder)
    if (!textbox) return
    if (textbox.text === undefined) return

    placeholder.remove(textbox)
    const itext = new fabric.IText(textbox.text, {
      originX: textbox?.originX,
      originY: textbox?.originY,
      left: textbox?.left,
      top: textbox?.top,
      width: textbox?.width,
      height: textbox?.height,
      fill: textbox?.fill,
      fontFamily: textbox?.fontFamily,
      fontSize: textbox?.fontSize,
      textAlign: textbox?.textAlign,
    })
    placeholder.add(itext)
    this.adjustText({ placeholder })
    return itext;
  }
}
