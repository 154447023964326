import { Organizer } from "../interfaces/Organizer";
import { OrganizerInfoDTO } from "../interfaces/OrganizerDTO";
import { SportType } from "../../../core/domain/enums/SportType";
import _ from "lodash";

export class OrganizersDataMapper {
  public decode(payload: OrganizerInfoDTO[]): Organizer[] {
    return _.reduce(payload, (acc, curr) => {
      const tournament = curr;
      if (!tournament) {
        return acc;
      }

      const logo = tournament?.images.find((image) => image.type === 'logo');

      acc.push({
        id: tournament.id,
        name: tournament.name,
        sportType: (tournament.sportType as SportType).toLowerCase(),
        logoUrl: logo?.externalUrl,
        email: tournament.email,
        phone: tournament.phone,
        description: tournament.description,
        address: tournament.address ?? {
          addressLine1: '',
          addressLine2: '',
          city: '',
          country: '',
        },
        createdAt: new Date(tournament.createdAt),
        updatedAt: new Date(tournament.updatedAt),
        adminUser: {
          id: tournament.adminUser?.id,
          name: tournament.adminUser?.name,
          surname: tournament.adminUser?.surname,
          middleName: tournament.adminUser?.middleName,
          balance: tournament.adminUser?.wallet?.balanceInMinorUnits ?? 0,
        },
      } as Organizer);
      return acc
    }, [] as Organizer[]);
  }
}
