import { ValidPhoneCode } from './formatPhone';

export const parsePhone = (value = '', code?: ValidPhoneCode): string => {
  const normalizedPhone = value.replace(/[^0-9+]/g, '');

  let codeToUse = code;

  if (!code) {
    const offset = normalizedPhone.startsWith('+') ? 1 : 0;
    if (normalizedPhone.startsWith('7', offset)) {
      codeToUse = '+7';
    } else if (normalizedPhone.startsWith('375', offset)) {
      codeToUse = '+375';
    } else if (normalizedPhone.startsWith('374', offset)) {
      codeToUse = '+374';
    }
  }

  let maxLength = -1; // значение по умолчанию для Армении

  if (codeToUse === '+7') {
    maxLength = 10;
  } else if (codeToUse === '+375') {
    maxLength = 9;
  } else if (codeToUse === '+374') {
    maxLength = 8;
  }

  if (maxLength === -1) {
    return normalizedPhone;
  }

  const digits = normalizedPhone.replace(/[^0-9]/g, '');
  const truncatedDigits = digits.slice(0, maxLength);
  return truncatedDigits;
};
