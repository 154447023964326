import { UDListItem, UDText } from 'modules/ud-ui/components';
import { useCallback, useState } from 'react';

import CategoryListPage from 'shared/components/category/pages/category-list-page';
import HeaderContentCategory from 'shared/components/category/components/list-wrapper/headerContent';
import UDMainLayout from 'modules/ud-ui/layout/main';

/**
 * Тестовая страница для демонстрации компонентов категорий
 * с использованием стандартного макета страницы списка ListPageWrapper
 */
const CategoryTestPage = () => {
    // Генерируем больше тестовых данных для демонстрации пагинации
    const [items, setItems] = useState(Array.from({ length: 27 }, (_, index) => ({
        id: index + 1,
        name: `Элемент ${index + 1}`,
        description: `Описание элемента ${index + 1}`,
        type: index % 3 === 0 ? 'Тип А' : index % 3 === 1 ? 'Тип Б' : 'Тип В'
    })));
    const [filteredItems, setFilteredItems] = useState<typeof items | null>(null);


    const getFilteredItems = useCallback((value: string) => {
        if (value) {
            setFilteredItems(items.filter(item =>
                item.name.toLowerCase().includes(value.toLowerCase()) ||
                item.description.toLowerCase().includes(value.toLowerCase()) ||
                item.type.toLowerCase().includes(value.toLowerCase())
            ))
        } else {
            setFilteredItems(null)
        }
    }, [items])

    const listItems = (filteredItems !== null ? filteredItems : items).map(item => (
        <UDListItem
            key={item.id}
            className="mb-2 white"
            title={item.name}
            subtitle={item.description}
            additionalFields={[
                <UDText key={item.id} type='subhead'>{item.type}</UDText>
            ]}
        />
    ));

    const handleCreateClick = () => {
        const newId = items.length + 1;
        setItems([...items, {
            id: newId,
            name: `Новый элемент ${newId}`,
            description: `Описание нового элемента ${newId}`,
            type: 'Новый'
        }]);
    };

    return (
        <UDMainLayout headerContent={<HeaderContentCategory title='Демонстрация компонентов CategoryList' createButtonText="Добавить элемент" createButtonOnClick={handleCreateClick} />}>
            <CategoryListPage categoryName='elements' getFiltered={getFilteredItems}>
                {listItems}
            </CategoryListPage>
        </UDMainLayout>
    );
};

export default CategoryTestPage;
