import * as S from './styles';

import { DivProps } from 'typings';
import React from 'react';

type TeamLogoProps = DivProps & {
  url?: string;
  name?: string;
  size?: number;
  className?: string;
  style?: React.CSSProperties;
};

const TeamLogo = (props: TeamLogoProps) => (
  <S.TeamLogoContainer
    {...props}
  >
    <S.TeamLogo logoUrl={props.url} />
  </S.TeamLogoContainer>
);

export default TeamLogo;
