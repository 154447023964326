import UDFormLabel from 'modules/ud-form/components/label';

export const SkeletonOrganizerForm = () => {

  return (
    <div className="row g-2">
      <div className="col-6">
        <SkeletonBlock height={40} title="Название организации" />
        <SkeletonBlock height={40} title="Тип спорта" />
        <SkeletonBlock height={40} title="Email" />
        <SkeletonBlock height={40} title="Телефон" />
        <SkeletonBlock height={40} title="Город" />
        <SkeletonBlock height={58} title="Описание" />
      </div>
      <div className="col-1" />
      <div className="col-5">
        <SkeletonBlock height={319} title="Обложка и изображение" />
      </div>
    </div>
  );
};

const SkeletonBlock = ({ height, title }: { height: number, title: string }) => {
  return (
    <div className="mb-5">
      <UDFormLabel className="mb-2">{title}</UDFormLabel>
      <div
        style={{ height, width: '100%' }}
        className="skeleton-block"
      />
    </div>
  );
};
