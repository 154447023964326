import ApplicationLinkForm, { IFormValues } from '../components/application-link-form';

import { FormikHelpers } from 'formik';
import S from './styles';
import TeamLogo from 'modules/ud-ui/components/team-logo';
import UDLogo from 'modules/ud-ui/components/logo';
import UDText from 'modules/ud-ui/components/text';
import backgroundLeftSide from 'modules/ud-ui/components/images/background-left-side.png';
import backgroundRightSide from 'modules/ud-ui/components/images/background-right-side.png';
import sizes from 'modules/application-link/domain/constants/sizes';
import { toast } from 'react-toastify';
import useMediaQuery from 'shared/hooks/useMediaQuery';

const ApplicationLinkPage: React.FC = () => {

    const submit = (_: IFormValues, formikHelpers: FormikHelpers<IFormValues>) => {
        setTimeout(() => {
            toast.success('Заявка отправлена');
            formikHelpers.resetForm();
        }, 3000);
    }

    const isMobile = useMediaQuery(`(max-width: ${sizes.mobile.WIDTH}px)`);

    return (
        <S.Container>
            <S.FormContainer>
                <UDLogo id='logo' fontSize={isMobile ? 28 : 24} className='mb-2' />
                <UDText id='title' type="title-1" style={{ fontWeight: 700 }} color='white' className='mb-9'>
                    Онлайн заявка на участие в турнире
                </UDText>
                <div id='organizer' className='flex-row align-items-center justify-content: center gap-3 mb-5'>
                    <TeamLogo id='organizer-logo' style={{ border: '1px solid #8F8E99' }} size={54} />
                    <UDText id='organizer-name' type="title-1" style={{ fontWeight: 500, fontSize: 26 }} color='white'>
                        Название организатора
                    </UDText>
                </div>
                <div id='tournament' className='flex-column justify-content-center align-items-center gap-2 mb-5'>
                    <UDText id='tournament-title' type="subhead" style={{ fontWeight: 400 }} color='#8F8E99'>
                        Турнир
                    </UDText>
                    <UDText id='tournament-name' type="title-1" style={{ fontWeight: 500, fontSize: 20 }} color='white'>
                        Название турнира
                    </UDText>
                </div>
                <div id='team' className='flex-column justify-content-center align-items-center gap-2 mb-7'>
                    <UDText id='team-title' type="subhead" style={{ fontWeight: 400 }} color='#8F8E99'>
                        Ваша команда
                    </UDText>
                    <UDText id='team-name' type="title-1" style={{ fontWeight: 500, fontSize: 20 }} color='white'>
                        Название команды
                    </UDText>
                </div>
                <ApplicationLinkForm onSubmit={submit} />
            </S.FormContainer>

            <img style={{ position: 'absolute', top: 0, left: 0, height: '100%', zIndex: 0, display: isMobile ? 'none' : 'block' }} src={backgroundLeftSide} alt="background" />
            <img style={{ position: 'absolute', top: 0, right: 0, height: '100%', zIndex: 0, display: isMobile ? 'none' : 'block' }} src={backgroundRightSide} alt="background" />
        </S.Container >
    );
};

export default ApplicationLinkPage;
