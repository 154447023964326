import * as S from './styles';

import { AppDispatch } from 'store/store';
import { Organizer } from 'modules/organizer/domain/interfaces/Organizer';
import { Pages } from 'modules/navigation/domain/enums/pages';
import React from 'react';
import UDMenuLogout from './components/logout';
import UDMenuMainNavigation from './components/main-navigation';
import UDMenuSystemNavigation from './components/system-navigation';
import { authSignOut } from 'modules/auth/store/actions';
import { resetOrganizer } from '../../../organizer/store/actions';
import { toast } from 'react-toastify';
import { useAuth } from '../../../auth/ui/hooks/useAuth';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

type UDMenuProps = {
  isOrganizerLoading: boolean;
  organizerInfo: Organizer | null;
};

const UDMenu = (props: UDMenuProps) => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const { availableTournaments } = useAuth();

  const onLogoutClicked = () => {
    dispatch(authSignOut());
    dispatch(resetOrganizer());
  };

  const onPlusClicked = () => {
    if (availableTournaments.length >= 5) {
      toast.warn('Вы можете создать только 5 организаций');
      return;
    }
    navigate(Pages.ORGANIZER.CREATE);
  };

  const onChangeProfileClicked = () => {
    navigate(Pages.SELECT_TOURNAMENT);
  };

  return (
    <S.MenuContainer className="d-flex flex-column pb-10">
      <S.MenuLogo className="ml-10 mb-5 d-flex align-items-center" />
      <UDMenuMainNavigation
        isOrganizerLoading={props.isOrganizerLoading}
        organizerInfo={props.organizerInfo}
      />
      <UDMenuSystemNavigation className="mt-auto mb-13" />
      <div className="mt-auto mb-13"></div>
      <UDMenuLogout
        isOrganizerLoading={props.isOrganizerLoading}
        organizerInfo={props.organizerInfo}
        onLogoutClick={onLogoutClicked}
        onPlusClick={onPlusClicked}
        onChangeProfileClick={onChangeProfileClicked}
      />
    </S.MenuContainer>
  );
};

export default UDMenu;
