import { Resource } from 'modules/core/baseResource';
import httpResource from 'modules/core/httpResource';

interface LocationImageResponse {
    id: number;
    image: string;
    ordinalNumber: number;
}


class LocationsResource extends Resource {
    constructor() {
        super('location', httpResource);
    }

    public async uploadImage(
        image: File | string,
        locationId: number,
        ordinalNumber: number
    ): Promise<LocationImageResponse> {
        const formData = new FormData();
        formData.append('image', image);
        formData.append('ordinalNumber', ordinalNumber.toString());

        const result = await this.child(`${locationId}/image`).post(formData);
        return result;
    }

    public async deleteImage(
        locationId: number,
        imageId: number
    ): Promise<LocationImageResponse> {
        const result = await this.child(`${locationId}/image/${imageId}`).delete();
        return result;
    }

    public async updateImagesOrder(
        locationId: number,
        images: { imageId: number, ordinalNumber: number }[],
    ): Promise<LocationImageResponse> {
        const result = await this.child(`${locationId}/images`).put(images);
        return result;
    }
}

const locationsResource = new LocationsResource();
export default locationsResource;