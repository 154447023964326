import TeamForm, { SubmitTeamForm } from './team-form/team-form';
import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { AppDispatch } from 'store/store';
import { Pages } from 'modules/navigation/domain/enums/pages';
import { Team } from 'modules/teams/domain/interfaces/Team';
import { editTeam } from 'modules/organizer/store/actions';
import { organizerIsTeamEditingSelector } from 'modules/organizer/store/selectors';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

type EditTeamProps = {
    team: Team;
    tournamentId: number;
};

const mapLogo = (logo: File[]) => {
    return logo.length > 0 ? logo[0] : undefined;
};

function isChanged(team: Team, values: Parameters<SubmitTeamForm>[0]['values']) {
    if (team.name !== values.name) return true;
    if (Array.isArray(values.logo)) return true;
    return false;
}

const EditTeam = (props: EditTeamProps) => {
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();

    const { team, tournamentId } = props;
    const isLoadingTeamEditing = useSelector(organizerIsTeamEditingSelector);
    const [isLoading, setIsLoading] = useState(false);

    const handleCancelClick = useCallback(() => {
        navigate(Pages.TEAMS.INDEX, { replace: true });
    }, [navigate]);

    const handleSubmit = useCallback<SubmitTeamForm>(({ values, formikHelpers }) => {
        setIsLoading(true);

        if (isChanged(team, values)) {
            dispatch(editTeam({
                tournamentId,
                id: team.id,
                name: values.name,
                logo: mapLogo(values.logo as File[]),
            }))
                .unwrap()
                .then(() => {
                    handleCancelClick();
                    toast.success('Команда успешно обновлена');
                });
        } else {
            handleCancelClick();
            toast.info('Ничего не изменилось');
        }
    }, [dispatch, handleCancelClick, team, tournamentId]);

    return (
        <TeamForm
            initialValues={team}
            onSubmit={handleSubmit}
            isLoading={isLoadingTeamEditing || isLoading}
        />
    );
};

export default EditTeam;
