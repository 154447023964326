import { Image, Location } from '../interfaces/location';
import graphqlResource, { GraphqlResource } from '../../../core/graphqlResource';
import { mapLocationDtoToLocation, mapLocationsDtoToLocations } from '../mappers/location-data-mapper';

import {
  LocationDTO
} from "../interfaces/dto";
import { UpsertLocationInput } from "../interfaces/actions";

export class LocationsRepository {
  constructor(private readonly graphql: GraphqlResource) { }

  public async getLocation(id: number): Promise<Location> {
    const query = `#graphql
      query getLocation($id: Int!) {
        location(id: $id) {
          id
          name
          mapImage (width: 650, height: 450)
          address {
            id
            addressLine1
            addressLine2
            city
            region
            country
            coords {
              lat
              lon
            }
          }
          description
          locationImages {
            image {
              id
              filename
              externalUrl
            }
            ordinalNumber
          }
          tournament {
            id
          }
          createdAt
          updatedAt
        }
      }
    `;

    const response = await this.graphql.query<{ data: { location: LocationDTO } }>(query, { id });
    return mapLocationDtoToLocation(response.data.data.location);
  }

  public async deleteLocation(id: number): Promise<{ status: string }> {
    const mutation = `#graphql
      mutation DeleteLocation($id: Int!) {
        deleteLocation(id: $id) {
          status
        }
      }
    `;

    const response = await this.graphql.query<{ data: { deleteLocation: { status: string } } }>(mutation, { id });
    return response.data.data.deleteLocation;
  }

  public async fetchLocations(
    tournamentId: number,
    limit: number = 50,
    offset: number = 0,
    nameFilter?: string
  ): Promise<Location[]> {
    const query = `#graphql
      query getTournamentLocations($tournamentId: Int!, $limit: Int!, $offset: Int!, $nameFilter: String) {
        tournament(id: $tournamentId) {
          locations(limit: $limit, offset: $offset, nameFilter: $nameFilter) {
            id
            name
            address {
              id
              addressLine1
              addressLine2
              city
              region
              country
              coords {
                lat
                lon
              }
            }
            description
            locationImages {
              image {
                id
                filename
                externalUrl
              }
              ordinalNumber
            }
            tournament {
              id
            }
            createdAt
            updatedAt
          }
        }
      }
    `;

    const response = await this.graphql.query<{ data: { tournament: { locations: LocationDTO[] } } }>(
      query,
      { tournamentId, limit, offset, nameFilter }
    );
    return mapLocationsDtoToLocations(response.data.data.tournament.locations);
  }

  public async upsertLocation(input: UpsertLocationInput, tournamentId: number): Promise<Location> {
    const mutation = `#graphql
      mutation upsertLocation($input: UpsertLocationInput!) {
        upsertLocation(input: $input) {
          id
          name
          mapImage (width: 650, height: 450)
          address {
            id
            addressLine1
            addressLine2
            city
            region
            country
            coords {
              lat
              lon
            }
          }
          description
          locationImages {
            image {
              id
              filename
              externalUrl
            }
            ordinalNumber
          }
          tournament {
            id
          }
          createdAt
          updatedAt
        }
      }
    `;

    const modifiedInput = {
      ...input,
      tournamentId
    };

    const response = await this.graphql.query<{ data: { upsertLocation: LocationDTO } }>(mutation, { input: modifiedInput });
    return mapLocationDtoToLocation(response.data.data.upsertLocation);
  }

  public async getLocationImages(id: number): Promise<{ images: Image[], locationId: number }> {
    const query = `#graphql
      query getLocation($id: Int!) {
        location(id: $id) {
          id
          locationImages {
            image {
              id
              filename
              externalUrl
            }
            ordinalNumber
          }
        }
      }
    `;

    const response = await this.graphql.query<{ data: { location: LocationDTO } }>(query, { id });
    return { images: mapLocationDtoToLocation(response.data.data.location).images, locationId: response.data.data.location.id };
  }

  public async getLocationMapImage(id: number, width: number = 650, height: number = 450): Promise<string | null> {
    const query = `#graphql
      query getLocationMapImage($id: Int!, $width: Int!, $height: Int!) {
        location(id: $id) {
          mapImage(width: $width, height: $height)
        }
      }
    `;

    const response = await this.graphql.query<{ data: { location: { mapImage: string | null } } }>(
      query,
      { id, width, height }
    );
    return response.data.data.location.mapImage;
  }

}

const locationsRepository = new LocationsRepository(graphqlResource);

export default locationsRepository;
