import { useCallback, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { AppDispatch } from 'store/store';
import { Pages } from '../../../../../navigation/domain/enums/pages';
import PlayerForm from 'modules/players/ui/components/player-form';
import { SubmitPlayerForm } from 'modules/players/ui/components/player-form';
import { fetchTeamPlayers } from '../../../../../organizer/store/actions';
import { fetchTournamentUser } from '../../../../../players/store/actions';
import { upsertTournamentPlayerApplication } from '../../../../store/players/actions';
import { useDispatch } from 'react-redux';
import { usePlayers } from 'modules/organizer/ui/hooks/usePlayers';

interface CreatePlayerProps {
    tournamentId: number;
    teamId: number;
}

const CreatePlayer = (props: CreatePlayerProps) => {
    const dispatch = useDispatch<AppDispatch>()
    const navigate = useNavigate()

    const { tournamentId, teamId } = props;
    const { isLoading: isLoadingPlayers } = usePlayers();
    const [isLoading, setIsLoading] = useState(false)
    const playerId = Number(useLocation().state?.fromPlayer);

    const handleDisableForm = useCallback(() => {
        navigate(Pages.TEAMS.builders.edit(teamId, 'players'), { state: { fromPlayer: playerId } })
    }, [navigate, playerId, teamId])

    const handleSubmit = useCallback<SubmitPlayerForm>(({ values, formikHelpers }) => {
        const photo = (values.photo && Array.isArray(values.photo)) ? values.photo : undefined;
        const height = values.height && !isNaN(Number(values.height)) ? Number(values.height) : undefined;
        const weight = values.weight && !isNaN(Number(values.weight)) ? Number(values.weight) : undefined;

        setIsLoading(true)

        dispatch(
            upsertTournamentPlayerApplication({
                teamId,
                tournamentId,
                name: values.name,
                surname: values.surname,
                middleName: values.middleName,
                birthDate: values.birthDate,
                height,
                weight,
                photo,
            }),
        ).unwrap().then((data) => {
            handleDisableForm()
            dispatch(fetchTournamentUser({ userId: data.id }));
            dispatch(fetchTeamPlayers({ teamId }));
        }).finally(() => {
            formikHelpers.setSubmitting(false);
            setIsLoading(false)
        });
    }, [dispatch, handleDisableForm, teamId, tournamentId])

    return (
        <div className='pt-6'>
            <PlayerForm
                onSubmit={handleSubmit}
                isLoading={isLoading || isLoadingPlayers}
            />
        </div>
    )
}

export default CreatePlayer;
