import { Board, Template } from "../../../core/domain/interfaces/supabase/overlay/entity";

import { RootState } from "../../../../store/store";
import { createSelector } from "@reduxjs/toolkit";
import sortBoards from "../../domain/utils/sortBoards";
import sortTemplates from "../../domain/utils/sortTemplates";

const selectState = (state: RootState) => state.templateEditor.templates
export const selectTemplates = createSelector(selectState, (state) => sortTemplates(Object.values(state.templates.byId)))
export const selectActiveTemplate = createSelector(
  selectState,
  (state) => state.templates.byId[state.activeTemplateId as number] ?? state.catalog.byId[state.activeTemplateId as number] as Template | undefined,
)
export const selectIsLoading = createSelector(selectState, (state) => state.isLoading)
export const selectIsLoaded = createSelector(selectState, (state) => state.isLoaded)
export const selectIsProcessed = createSelector(selectState, (state) => state.isProcessed)
export const selectIsFinished = createSelector(selectState, (state) => state.isFinished)
export const selectLoadedTemplates = createSelector(selectState, (state) => state.loadedTemplates)

export const selectActiveBoard = createSelector(
  [selectState],
  (state) => {
    const board = state.boards.byId[state.activeBoardId as number]
    return board as Board | undefined
  },
);

export const selectTemplateById = (templateId?: number) => createSelector(
  [selectState],
  (state) => {
    if (!templateId) return undefined;
    return state.templates.byId[templateId] as Template | undefined;
  }
);

export const selectBoardsByTemplateId = (templateId?: number) => createSelector(
  [selectState],
  (state) => {
    if (!templateId) return [];
    return sortBoards(
      Object.values(state.boards.byId).filter(board => board.templateId === templateId)
    );
  }
);

// Добавим новый селектор для мемоизации отфильтрованных досок
const selectFilteredBoardsByTemplateId = createSelector(
  [(state: RootState) => state.templateEditor.templates.boards.byId,
  (state: RootState) => state.templateEditor.templates.activeTemplateId],
  (boardsById, activeTemplateId) => {
    if (!activeTemplateId) return [];
    return Object.values(boardsById).filter(board => board.templateId === activeTemplateId);
  }
);

// Обновим существующий селектор selectBoards
export const selectBoards = createSelector(
  [selectFilteredBoardsByTemplateId],
  (filteredBoards) => sortBoards(filteredBoards)
);

export const selectCatalogTemplates = createSelector(
  [selectState],
  (state) => sortTemplates(Object.values(state.catalog.byId)),
);

export const selectCatalogTemplatesByFilter = createSelector(
  [selectState, selectCatalogTemplates],
  (state, templates) => {
    const filter = state.filterByName.toLowerCase()
    const filteredByName = filter ? templates?.filter((template) => template.name.toLocaleLowerCase()
      .includes(filter)) ?? [] : templates ?? []
    switch (state.sortByDate) {
      case 'desc':
        return filteredByName.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())
      case 'asc':
        return filteredByName.sort((a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime())
      default:
        return filteredByName
    }
  },
)
export const selectFilterByName = createSelector(
  selectState,
  (state) => state.filterByName,
)
export const selectSortByDate = createSelector(
  selectState,
  (state) => state.sortByDate,
)
export const selectProcess = createSelector(
  selectState,
  (state) => state.process,
)
export const selectTournamentIdLoadedTemplate = createSelector(
  selectState,
  (state) => state.tournamentId,
)
export const selectBoardLoaded = createSelector(
  selectState,
  (state) => ({ isLoaded: state.isLoadedBoards, isLoading: state.isLoadingBoards }),
)