import { Board, Template } from "../../../core/domain/interfaces/supabase/overlay/entity";
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
  createAddLikeReducer,
  createAddViewReducer,
  createCopyBoardReducer,
  createCopyTemplateReducer,
  createCreateBoardReducer,
  createCreateTemplateReducer,
  createDeleteBoardReducer,
  createDeleteTemplateReducer,
  createFetchBoardsByTemplateIdReducer,
  createFetchCatalogTemplatesReducer,
  createFetchTemplateByIdReducer,
  createFetchTemplateWithBoardsReducer,
  createFetchUserTemplatesReducer,
  createUpdateBoardReducer,
  createUpdateTemplateReducer,
} from "./reducers";

import { Processes } from "../../domain/interface/processes";

type Entity<T> = {
  byId: { [key: number]: T }
  allIds: number[]
}

export type TemplatesSliceState = {
  tournamentId?: number

  templates: Entity<Template>
  boards: Entity<Board>
  catalog: Entity<Template>
  activeTemplateId?: number
  activeBoardId?: number
  loadedTemplates: number[]

  isLoading: boolean
  isLoaded: boolean

  isLoadingBoards: boolean
  isLoadedBoards: boolean

  isProcessed: boolean,
  isFinished: boolean,
  process?: Processes

  filterByName: string
  sortByDate: 'none' | 'desc' | 'asc'
};

const initialState: TemplatesSliceState = {
  templates: {
    byId: {},
    allIds: [],
  },
  boards: {
    byId: {},
    allIds: [],
  },
  catalog: {
    byId: {},
    allIds: [],
  },

  loadedTemplates: [],

  isLoaded: false,
  isLoading: false,

  isLoadingBoards: false,
  isLoadedBoards: false,

  isProcessed: false,
  isFinished: false,

  filterByName: '',
  sortByDate: 'none',
};

export const templatesSlice = createSlice({
  name: 'templates',
  initialState,
  reducers: {
    setActiveTemplate(state, action: PayloadAction<{ id: number | undefined }>) {
      if (action.payload.id && isNaN(action.payload.id)) { throw new Error('Invalid template id') }
      state.activeTemplateId = action.payload.id
    },
    resetPressedState(state) {
      state.isProcessed = false
      state.isFinished = false
      state.process = undefined
    },
    setActiveBoard(state, action: PayloadAction<{ id: number | undefined }>) {
      if (action.payload.id && isNaN(action.payload.id)) { throw new Error('Invalid board id') }
      state.activeBoardId = action.payload.id
    },
    favoriteBoard(state, action: PayloadAction<{ id: number }>) {
      const board = state.boards.byId[action.payload.id]
      if (!board) return
      board.isFavorite = !board.isFavorite
    },
    setFilterByName: (state, action: PayloadAction<TemplatesSliceState['filterByName']>) => {
      state.filterByName = action.payload
    },
    setSortByDate: (state, action: PayloadAction<TemplatesSliceState['sortByDate']>) => {
      state.sortByDate = action.payload
    },
    setProcess(state, action: PayloadAction<TemplatesSliceState['process']>) {
      state.process = action.payload
    },
    setTournamentId(state, action: PayloadAction<{ id: number | undefined }>) {
      state.tournamentId = action.payload.id
    },
  },
  extraReducers: (builder) => {
    createFetchUserTemplatesReducer(builder);
    createCreateTemplateReducer(builder);
    createDeleteTemplateReducer(builder);
    createUpdateTemplateReducer(builder);
    createCreateBoardReducer(builder);
    createDeleteBoardReducer(builder);
    createUpdateBoardReducer(builder);
    createCopyTemplateReducer(builder);
    createCopyBoardReducer(builder);
    createFetchCatalogTemplatesReducer(builder);
    createAddLikeReducer(builder);
    createAddViewReducer(builder);
    createFetchBoardsByTemplateIdReducer(builder);
    createFetchTemplateByIdReducer(builder);
    createFetchTemplateWithBoardsReducer(builder);
  },
});

export const {
  setActiveTemplate,
  resetPressedState,
  setActiveBoard,
  favoriteBoard,
  setFilterByName,
  setSortByDate,
  setProcess,
  setTournamentId,
} = templatesSlice.actions

export default templatesSlice.reducer
