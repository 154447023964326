import { FetchLocationMapImage, FetchLocations, FetchLocationsByFilter, UpsertLocationInput } from '../domain/interfaces/actions';
import { Image, Location } from '../domain/interfaces/location';

import { createAsyncThunk } from '@reduxjs/toolkit';
import locationsRepository from '../domain/repositories/locations.repository';
import locationsResource from '../domain/resources/locations.resource';

const PREFIX = 'locations';

const handleError = (error: unknown, prefix: string) => {
  console.error(`${prefix} error:`, error);
  const message = error instanceof Error ? error.message : String(error);
  return message;
};

export const fetchLocations = createAsyncThunk<
  Location[],
  Omit<FetchLocations, 'nameFilter'>
>(`${PREFIX}/locations`, async (payload, { rejectWithValue }) => {
  try {
    return await locationsRepository.fetchLocations(
      payload.tournamentId,
      payload.limit,
      payload.offset
    );
  } catch (error) {
    return rejectWithValue(handleError(error, `${PREFIX}/fetchLocations`));
  }
});

export const fetchLocationsByFilter = createAsyncThunk<
  Location[],
  FetchLocationsByFilter
>(`${PREFIX}/fetchLocationsByFilter`, async (payload, { rejectWithValue }) => {
  try {
    return await locationsRepository.fetchLocations(
      payload.tournamentId,
      payload.limit,
      payload.offset,
      payload.nameFilter
    );
  } catch (error) {
    return rejectWithValue(
      handleError(error, `${PREFIX}/fetchLocationsByFilter`)
    );
  }
});

const deleteLocationImages = async (locationId: number, images: Image[]) => {
  if (images?.length) {
    await Promise.all(
      images.map((image) => locationsResource.deleteImage(locationId, image.id))
    );
  }
};

export const deleteLocation = createAsyncThunk<{ id: number }, { id: number }>(
  `${PREFIX}/deleteLocation`,
  async (payload, { rejectWithValue }) => {
    try {
      const location = await locationsRepository.getLocation(payload.id);
      await deleteLocationImages(location.id, location.images);
      await locationsRepository.deleteLocation(payload.id);
      return { id: payload.id };
    } catch (error) {
      return rejectWithValue(handleError(error, `${PREFIX}/deleteLocation`));
    }
  }
);

export const upsertLocation = createAsyncThunk<Location, UpsertLocationInput>(
  `${PREFIX}/upsertLocation`,
  async (input, { rejectWithValue }) => {
    try {
      const { images = [], tournamentId, ...locationData } = input;
      let location = await locationsRepository.upsertLocation(
        { ...locationData, tournamentId },
        tournamentId
      );

      if (location.id) {
        location.images = await processLocationImages(location, images);
      }

      return location;
    } catch (error) {
      return rejectWithValue(handleError(error, `${PREFIX}/upsertLocation`));
    }
  }
);

interface UploadedImage {
  id: number;
  externalUrl: string;
  ordinalNumber: number;
  filename: string;
}

interface UploadedFile {
  img: File;
  index: number;
}

const processLocationImages = async (
  location: Location,
  images: (Image | File)[]
) => {
  // Разделяем изображения по типу
  const existingUrls = images.filter((img): img is Image => typeof img === 'object' && 'id' in img);
  const newFiles: UploadedFile[] = images
    .map((img, index) => ({ img, index }))
    .filter((file): file is UploadedFile => file.img instanceof File);

  // Обработка изображений
  await deleteUnusedImages(location, existingUrls);
  await uploadNewImages(location.id, newFiles);
  await updateImagesOrder(location, images);
  return (await locationsRepository.getLocationImages(location.id)).images;
};

const deleteUnusedImages = async (
  location: Location,
  existingUrls: Image[]
) => {
  const existingIds = existingUrls.map((img) => img.id);
  const imagesToDelete = location.images.filter((img) => !existingIds.includes(img.id));
  await deleteLocationImages(location.id, imagesToDelete);
};

const uploadNewImages = async (
  locationId: number,
  files: UploadedFile[]
): Promise<UploadedImage[]> => {
  if (!files.length) return [];

  return Promise.all(
    files.map(async (file) => {
      const result = await locationsResource.uploadImage(
        file.img,
        locationId,
        file.index
      );
      return {
        id: result.id,
        externalUrl: result.image,
        ordinalNumber: result.ordinalNumber,
        filename: file.img.name,
      };
    })
  );
};

const updateImagesOrder = async (location: Location, images: (Image | File)[]) => {
  const reorderedImages = images.reduce<{ imageId: number; ordinalNumber: number }[]>((acc, url, index) => {
    if (typeof url === 'object' && 'id' in url) {
      const img = location.images.find((i) => i.id === url.id);
      if (img && img.ordinalNumber !== index) {
        acc.push({ imageId: img.id, ordinalNumber: index });
      }
    }

    return acc;
  }, []);

  if (reorderedImages.length > 0) {
    await locationsResource.updateImagesOrder(location.id, reorderedImages);
  }
};

export const fetchLocationMapImage = createAsyncThunk<string | null, FetchLocationMapImage>(
  `${PREFIX}/fetchLocationMapImage`, async (payload, { rejectWithValue }) => {
    try {
      return await locationsRepository.getLocationMapImage(
        payload.locationId,
        payload.width,
        payload.height
      );
    } catch (error) {
      return rejectWithValue(handleError(error, `${PREFIX}/fetchLocationMapImage`));
    }
  });
