import * as S from './styles';

import { format, parseISO } from 'date-fns';

import { Organizer } from 'modules/organizer/domain/interfaces/Organizer';
import { Pages } from 'modules/navigation/domain/enums/pages';
import UDColorableIcon from 'modules/ud-ui/components/icon/colorable-icon';
import UDNavLink from 'modules/ud-ui/components/link/nav-link';
import UDText from 'modules/ud-ui/components/text';

type UDMenuMainNavigationProps = {
  className?: string;
  isOrganizerLoading: boolean;
  organizerInfo: Organizer | null;
};

const UDMenuMainNavigation = (props: UDMenuMainNavigationProps) => {
  if (!props.organizerInfo) {
    return null;
  }

  const recentlyAdded = props.organizerInfo.latestTournaments.map((item) => {
    const { id, name, startDate, endDate } = item;

    const startYear = startDate ? format(parseISO(startDate), 'yyyy') : null;
    const endYear = endDate ? format(parseISO(endDate), 'yyyy') : null;
    const dates = [startYear];

    if (endYear && endYear !== startYear) {
      dates.push(endYear);
    }

    return {
      link: Pages.TOURNEYS.builders.edit(id),
      checkPath: Pages.TOURNEYS.builders.edit(id, '*'),
      label: `${name} ${dates.join('/')}`,
    };
  });

  return (
    <S.MenuMainNavigation className={props.className}>
      <UDNavLink
        to={Pages.TOURNEYS.INDEX}
        className='pl-10 nav-item d-flex align-items-center'
      >
        <UDColorableIcon
          name='cup'
          size={24}
          componentProps={{ className: 'mr-2' }}
        />
        Турниры
      </UDNavLink>
      <div className='overview pt-1 pl-10 pr-4 pb-6'>
        <UDText type='caption-2' className='color-SurfaceRomanSilver30 mb-3'>
          последние добавленные:
        </UDText>
        <ul className='recently-added'>
          {recentlyAdded.map((play) => (
            <li key={play.link}>
              <UDNavLink
                to={play.link}
                checkPath={play.checkPath}
                type='subhead'
                style={{ lineClamp: 2, boxOrient: 'vertical', display: '-webkit-box', overflow: 'hidden' }}
                title={play.label}
              >
                {play.label}
              </UDNavLink>
            </li>
          ))}
        </ul>
      </div>

      <S.MenuMainNavigationLinksContainer>
        <UDNavLink
          checkPath={Pages.TEAMS.INDEX + '*'}
          to={Pages.TEAMS.INDEX}
          className='pl-10 nav-item d-flex align-items-center'
        >
          Команды
        </UDNavLink>
        <UDNavLink
          checkPath={Pages.PLAYERS.INDEX + '*'}
          to={Pages.PLAYERS.INDEX}
          className='pl-10 nav-item d-flex align-items-center'
        >
          Игроки
        </UDNavLink>
        <UDNavLink
          checkPath={Pages.LOCATIONS.INDEX + '*'}
          to={Pages.LOCATIONS.INDEX}
          className='pl-10 nav-item d-flex align-items-center'
        >
          Локации
        </UDNavLink>
      </S.MenuMainNavigationLinksContainer>
    </S.MenuMainNavigation>
  );
};

export default UDMenuMainNavigation;
