import { css } from '@emotion/react';

export const galleryStyles = {
    container: css`
        display: flex;
        justify-content: center;
        align-items: center;
        /* width: 100vw; */
        max-width: 1200px;
        /* background: #fff; */
        border-radius: 8px;
    `,
    slideContainer: css`
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    `,
    image: css`
        max-height: 100%;
        max-width: 100%;
        object-fit: contain;
    `
};