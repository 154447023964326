/** @jsxImportSource @emotion/react */

import '@splidejs/react-splide/css';

import {
    DndContext,
    DragEndEvent,
    DragOverlay,
    DragStartEvent,
    KeyboardSensor,
    PointerSensor,
    closestCenter,
    useSensor,
    useSensors
} from '@dnd-kit/core';
import React, { useMemo, useState } from 'react';
import { SortableContext, sortableKeyboardCoordinates, useSortable } from '@dnd-kit/sortable';

import { CSS } from '@dnd-kit/utilities';
import { Image } from '../types';
import { ImageItem } from './ImageItem';
import { useImageGallery } from '../../../../ud-ui/components/image-gallery/modal';

interface ImageListProps {
    images: Image[];
    enableDragAndDrop: boolean;
    onDelete: (index: number) => void;
    onDeleteImage?: (index: number) => void;
    multiple?: boolean;
    onDragEnd: (result: any) => void;
    disabled?: boolean;
}

export const ImageList: React.FC<ImageListProps> = ({
    images,
    enableDragAndDrop,
    onDelete,
    onDeleteImage,
    multiple,
    onDragEnd,
    disabled,
}) => {
    const [activeId, setActiveId] = useState<string | null>(null);
    const { handleImageClick, GalleryModalWrapper } = useImageGallery({ images });

    const sensors = useSensors(
        useSensor(PointerSensor, {
            activationConstraint: {
                distance: 5,
            },
        }),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        })
    );

    const items = useMemo(() =>
        images.map((img) => ({
            ...img,
            id: (img instanceof File ? img.name : (((img.url || img.externalUrl) ?? '') + (img.id ?? 0))).toString()
        })),
        [images]
    );

    if (!enableDragAndDrop) {
        return (
            <>
                <div className="mt-7 d-flex flex-wrap gap-4">
                    {images.map((img, index) => (
                        <ImageItem
                            key={img.url ?? img.externalUrl ?? img.name ?? index}
                            img={img}
                            index={index}
                            onDelete={onDelete}
                            onDeleteImage={onDeleteImage}
                            multiple={multiple}
                            onImageClick={handleImageClick}
                            disabled={disabled}
                        />
                    ))}
                </div>
                <GalleryModalWrapper />
            </>
        );
    }

    const handleDragStart = (event: DragStartEvent) => {
        if (disabled) {
            return;
        }
        setActiveId(event.active.id.toString());
    };

    const handleDragEnd = (event: DragEndEvent) => {
        if (disabled) return;

        const { active, over } = event;
        setActiveId(null);

        if (over && active.id !== over.id) {
            const oldIndex = items.findIndex(item => item.id === active.id);
            const newIndex = items.findIndex(item => item.id === over.id);

            onDragEnd({
                source: { index: oldIndex },
                destination: { index: newIndex },
            });
        }
    };

    const activeItem = activeId ? items.find(item => item.id === activeId) : null;

    return (
        <>
            <DndContext
                sensors={sensors}
                collisionDetection={closestCenter}
                onDragStart={handleDragStart}
                onDragEnd={handleDragEnd}
            >
                <div className="mt-7 d-flex flex-wrap gap-4" style={{ listStyle: 'none' }}>
                    <SortableContext items={items}>
                        {items.map((item, index) => (
                            <SortableImage
                                key={item.id}
                                id={item.id}
                                img={item}
                                index={index}
                                onDelete={onDelete}
                                onDeleteImage={onDeleteImage}
                                multiple={multiple}
                                onImageClick={handleImageClick}
                                disabled={disabled}
                            />
                        ))}
                    </SortableContext>
                    <DragOverlay>
                        {activeId && activeItem ? (
                            <ImageItem
                                img={activeItem}
                                index={items.findIndex(item => item.id === activeId)}
                                onDelete={onDelete}
                                onDeleteImage={onDeleteImage}
                                multiple={multiple}
                                onImageClick={() => { }}
                                disabled={disabled}
                            />
                        ) : null}
                    </DragOverlay>
                </div>
            </DndContext>
            <GalleryModalWrapper />
        </>
    );
};

const SortableImage = ({ img, index, onDelete, onDeleteImage, isCover, multiple, id, onImageClick, disabled }: any) => {
    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
        isDragging
    } = useSortable({
        id,
        transition: {
            duration: 500,
            easing: 'cubic-bezier(0.25, 1, 0.5, 1)'
        },
        disabled
    });

    const style = {
        opacity: isDragging ? 0.4 : 1,
        transform: CSS.Transform.toString(transform),
        transition,
        touchAction: 'none',
        cursor: disabled ? 'default' : 'grab'
    };

    return (
        <div ref={setNodeRef} style={style} {...(disabled ? {} : { ...attributes, ...listeners })}>
            <ImageItem
                img={img}
                index={index}
                onDelete={onDelete}
                onDeleteImage={onDeleteImage}
                multiple={multiple}
                onImageClick={onImageClick}
                disabled={disabled}
            />
        </div>
    );
};
