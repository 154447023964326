import { Form, Formik } from "formik";
import { useCallback, useState } from "react";

import { AppDispatch } from "store/store";
import { BorderedInput } from "modules/teams/ui/components/styles";
import { PlayerApplication } from "modules/teams/domain/interfaces/PlayerApplication";
import UDButton from "modules/ud-ui/components/button";
import UDFormInput from "modules/ud-form/components/input/index";
import UDFormSingleImage from "modules/ud-form/components/dropzone/component/UDFormDropZoneWithImage";
import UDText from "modules/ud-ui/components/text";
import { birthDateFormatter } from '../../../ud-form/components/input/domain/formatters/birthDateFormatter';
import { format } from 'date-fns';
import { mapPlayerToPlayerApplication } from "modules/teams/domain/data-mappers/player-player-application.mapper";
import { teamPlayerValidator } from '../../../players/ui/components/player-form';
import { toast } from "react-toastify";
import { updatePlayerApplication } from "modules/teams/store/championshipTeams";
import { upsertTournamentUser } from "modules/players/store/actions";
import { useDispatch } from "react-redux";
import useModal from "modules/ud-ui/components/new-modal/useModal";
import { useOrganizer } from "modules/organizer/ui/hooks/useOrganizer";

const getBirthDate = (birthDate: string) => {
  return format(new Date(birthDate), 'dd.MM.yyyy');
}

const modalStyles = {
  modalStyle: {
    padding: '34px 24px',
  },
  containerProps: {
    style: {
      height: '596px',
    }
  },
  childrenContainerProps: {
    style: {
      height: '100%',
    }
  }
};

const useModalEditApplication = () => {
  const { isOpen, defaultOpen: open, Modal, close } = useModal();
  const dispatch = useDispatch<AppDispatch>();
  const [data, setData] = useState<PlayerApplication | null>(null);
  const [teamId, setTeamId] = useState<number | null>(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [formValues, setFormValues] = useState<any>(null);
  const { info, isLoading } = useOrganizer();

  const onOpen = useCallback((player: PlayerApplication, teamId: number) => {
    setIsProcessing(false);
    setData(player);
    setTeamId(teamId);
    setFormValues({
      surname: player.player.lastName,
      name: player.player.firstName,
      middleName: player.player.middleName,
      birthDate: player.player.birthDate ? getBirthDate(player.player.birthDate) : '',
      photo: player.player.logoUrl ? { externalUrl: player.player.logoUrl } : null,
    });
    open(modalStyles);
  }, [open]);

  const handleSubmit = useCallback((values: any) => {
    if (!info) {
      toast.error('Произошла ошибка, попробуйте перезагрузить страницу');
      throw new Error('Organizer info not found|info: ' + JSON.stringify(info, null, 2));
    }

    if (!teamId) {
      toast.error('Произошла ошибка, попробуйте перезагрузить страницу');
      throw new Error('Team id not found|teamId: ' + JSON.stringify(teamId, null, 2));
    }

    if (!data) {
      toast.error('Произошла ошибка, попробуйте перезагрузить страницу');
      throw new Error('Data not found|data: ' + JSON.stringify(data, null, 2));
    }

    setIsProcessing(true);
    setFormValues(values);
    dispatch(
      upsertTournamentUser({
        userId: data?.player?.id,
        tournamentId: info.id,
        name: values.name,
        surname: values.surname,
        middleName: values.middleName,
        birthDate: values.birthDate,
        height: +values.height,
        weight: +values.weight,
        photo: values.photo || null,
      }),
    ).unwrap().then((responsePlayer) => {
      dispatch(updatePlayerApplication({ teamId, id: data.id, application: { ...data, player: mapPlayerToPlayerApplication(responsePlayer).player } }));
      setIsProcessing(false);
      close();
      toast.success('Игрок успешно сохранён');
    });
  }, [close, data, dispatch, info, teamId]);

  const modal = useCallback(() => {
    return (
      <Modal>
        <Formik
          initialValues={formValues || {
            surname: '',
            name: '',
            middleName: '',
            birthDate: '',
            photo: '',
          }}
          enableReinitialize={false}
          validationSchema={teamPlayerValidator}
          onSubmit={handleSubmit}
        >
          {({ isValid }) => (
            <Form className="d-flex flex-column h-100">
              <UDText type="title-3" style={{ fontFamily: 'SF Pro Text', marginBottom: '40px' }}>
                Редактирование
              </UDText>
              <div className="d-flex flex-column gap-4 px-8 h-100">
                <UDFormInput
                  name="surname"
                  placeholder='Введите фамилию'
                  errorVisible
                  inputComponent={BorderedInput}
                />
                <UDFormInput
                  name="name"
                  placeholder='Введите имя'
                  errorVisible
                  inputComponent={BorderedInput}
                />
                <UDFormInput
                  name="middleName"
                  placeholder='Введите отчество'
                  errorVisible
                  inputComponent={BorderedInput}
                />
                <UDFormInput
                  name='birthDate'
                  placeholder='Укажите дату рождения'
                  mask='дд.мм.гггг'
                  formatter={birthDateFormatter}
                  inputComponent={BorderedInput}
                  errorVisible
                />
                <UDFormSingleImage
                  name="photo"
                  value={[]}
                />
                <UDButton
                  type="submit"
                  variant="primary"
                  className="mt-auto"
                  loading={isProcessing}
                  disabled={!isValid || isLoading}
                >
                  Сохранить
                </UDButton>
              </div>
            </Form>
          )}
        </Formik>
      </Modal>
    )
  }, [Modal, formValues, handleSubmit, isLoading, isProcessing]);

  return { isOpen, open: onOpen, Modal: modal };
};

export default useModalEditApplication;
