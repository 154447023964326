import TeamForm, { SubmitTeamForm } from '../components/team-form/team-form';
import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { AppDispatch } from 'store/store';
import { Pages } from '../../../navigation/domain/enums/pages';
import { Team } from '../../domain/interfaces/Team';
import UDHeaderTitle from '../../../ud-ui/components/header-title';
import UDMainLayout from '../../../ud-ui/layout/main';
import { createTeam } from '../../../organizer/store/actions';
import { organizerIsTeamCreatingSelector } from '../../../organizer/store/selectors';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { useOrganizer } from '../../../organizer/ui/hooks/useOrganizer';

const TeamsCreatePage = () => {
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();

  const { info } = useOrganizer();
  const isCreating = useSelector(organizerIsTeamCreatingSelector);
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = useCallback<SubmitTeamForm>(({ values, formikHelpers }) => {
    if (!info?.id) {
      toast.error('Турнир не найден. Попробуйте перезагрузить страницу.');
      return
    };

    const logo = Array.isArray(values.logo) ? values.logo[0] : undefined;

    setIsLoading(true);
    dispatch(createTeam({
      tournamentId: info.id,
      name: values.name,
      logo,
    })).unwrap().then((data) => {
      navigate(Pages.TEAMS.builders.edit((data as Team)?.id))
    }).finally(() => {
      setIsLoading(false);
      formikHelpers.setSubmitting(false)
    })
  }, [dispatch, info?.id, navigate])

  return (
    <UDMainLayout
      headerContent={
        <>
          <UDHeaderTitle>Добавление команды</UDHeaderTitle>
        </>
      }
    >
      <div className="container h-100 pt-6">
        {info && (
          <div className="h-100">
            {/* <CreateTeamForm tournamentId={info.id} /> */}
            <TeamForm
              onSubmit={onSubmit}
              isLoading={isLoading || isCreating}
            />
          </div>
        )}
      </div>
    </UDMainLayout>
  );
};

export default TeamsCreatePage;
