/** @jsxImportSource @emotion/react */

import PlayerForm, { SubmitPlayerForm } from "../components/player-form";
import { useCallback, useMemo, useState } from "react";

import { AppDispatch } from "store/store";
import { Pages } from "../../../navigation/domain/enums/pages";
import UDBreadcrumbs from "../../../ud-ui/components/breadcrumbs";
import UDMainLayout from "../../../ud-ui/layout/main";
import { toast } from "react-toastify";
import { upsertTournamentUser } from '../../store/actions';
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useOrganizer } from '../../../organizer/ui/hooks/useOrganizer';
import useTournamentUsers from '../hooks/useTournamentUsers';

function PlayerCreatePage() {
  const navigate = useNavigate()
  const dispatch = useDispatch<AppDispatch>()
  const { info } = useOrganizer()
  const { isProcessing } = useTournamentUsers()
  const [isLoading, setIsLoading] = useState(false)

  const breadcrumb = useMemo(() => (<UDBreadcrumbs
    className='my-10' breadcrumbs={[
      { label: 'Игроки', to: Pages.PLAYERS.INDEX },
      { label: 'Добавление игрока', to: Pages.PLAYERS.CREATE },
    ]}
  />), []);

  const onSubmit = useCallback<SubmitPlayerForm>(({ values, formikHelpers }) => {
    if (!info) return

    setIsLoading(true)

    const height = values.height && !isNaN(Number(values.height)) ? Number(values.height) : undefined;
    const weight = values.weight && !isNaN(Number(values.weight)) ? Number(values.weight) : undefined;

    dispatch(upsertTournamentUser({
      tournamentId: info.id,
      name: values.name,
      surname: values.surname,
      middleName: values.middleName,
      birthDate: values.birthDate,
      photo: Array.isArray(values.photo) ? values.photo : undefined,
      height,
      weight,
    })).unwrap().then((data) => {
      navigate(Pages.PLAYERS.builders.view(data.id));
      toast.success('Игрок успешно создан');
    }).finally(() => {
      formikHelpers.setSubmitting(false);
      setIsLoading(false)
    });
  }, [info, dispatch, navigate]);


  return (
    <UDMainLayout headerContent={breadcrumb}>
      <div className={'container pt-6'}>
        <PlayerForm
          onSubmit={onSubmit}
          isLoading={isProcessing || isLoading}
        />
      </div>
    </UDMainLayout>
  );
}

export default PlayerCreatePage;
