/** @jsxImportSource @emotion/react */

import { IconButton, ModalButton, tooltipStyles } from "./styles";
import React, { useCallback, useEffect, useState } from "react";
import { selectActiveModal, selectFabricManager } from "../../../../store/editor/selectors";
import { useDispatch, useSelector } from "react-redux";

import BaseModal from "../../elements/modals/baseModal";
import InteractiveToggleIcon from "./interactiveToggleIcon";
import { ModalState } from "../../../../domain/interface/modalState";
import { Object } from "fabric/fabric-impl";
import SnapToGrid from "../../../../../ud-ui/components/fabric-canvas/addons/snapToGrid";
import SquareIcon from "../../../../../ud-ui/components/icon/square-icon";
import UDIcon from "../../../../../ud-ui/components/icon";
import UDTooltip from "../../../../../ud-form/components/tooltip";
import customization from "../../../../../ud-ui/components/fabric-canvas/addons/customization";
import { fabric } from "fabric";
import history from "../../../../../ud-ui/components/fabric-canvas/addons/history";
import { setActiveModal } from "../../../../store/editor/editor";

type ShapeType = 'rect' | 'circle';

export function AddShape() {
  const dispatch = useDispatch();
  const activeModal = useSelector(selectActiveModal);
  const fabricManager = useSelector(selectFabricManager);
  const [isSelected, setSelected] = useState(false);
  const [modalState, setModalState] = useState<ModalState>({ isModalOpen: false, modalStyle: {} });

  const closeModals = useCallback(() => {
    setSelected(false);
    setModalState(prev => ({ ...prev, isModalOpen: false }));
    dispatch(setActiveModal(undefined));
  }, [dispatch]);

  const openShapeModal = useCallback((e: React.MouseEvent<HTMLButtonElement>) => {
    const target = e.currentTarget;
    if (!target) return;

    const bounding = target.getBoundingClientRect();
    setModalState({
      isModalOpen: true,
      modalStyle: {
        top: `${bounding.top}px`,
        left: `${bounding.left + bounding.width + 15}px`,
        width: 300,
      },
    });
    setSelected(true);
    dispatch(setActiveModal("addShape"));
  }, [dispatch]);

  useEffect(() => {
    const clickListener = (event: MouseEvent) => {
      event.stopPropagation();
      const target = event.target as HTMLElement;
      if (!target) return;

      const isModalButton = target.closest('.modal-button');
      const isModal = target.closest('.base-modal');
      const isIconButton = target.closest('.icon-button-shape');

      if (isSelected && !isModalButton && !isModal && !isIconButton) {
        closeModals();
      }
    };

    const closeModalOnEsc = (event: KeyboardEvent) => {
      if (event.key === 'Escape' && isSelected) closeModals();
    };

    document.addEventListener('keydown', closeModalOnEsc);
    document.addEventListener('click', clickListener);

    return () => {
      document.removeEventListener('keydown', closeModalOnEsc);
      document.removeEventListener('click', clickListener);
    };
  }, [closeModals, isSelected]);

  const handleClick = useCallback((e: React.MouseEvent<HTMLButtonElement>) => {
    if (isSelected) {
      closeModals();
    } else {
      openShapeModal(e);
    }
  }, [closeModals, isSelected, openShapeModal]);

  const addShape = useCallback((shape: ShapeType) => {
    if (!fabricManager) return;

    history.saveState();
    const { left, top } = fabricManager.getLeftTopCurVP();
    let object: Object;

    switch (shape) {
      case 'rect':
        object = new fabric.Rect({
          width: 200,
          height: 100,
          fill: 'red',
          strokeWidth: 0,
          left,
          top,
          ...customization.getAllProperties(),
        });
        break;
      case 'circle':
        object = new fabric.Circle({
          radius: 100,
          fill: 'red',
          strokeWidth: 0,
          left,
          top,
          ...customization.getAllProperties(),
        });
        break;
    }

    fabricManager.canvas.add(object);
    SnapToGrid.snapToGrid([object]);
    closeModals();
  }, [closeModals, fabricManager]);

  return (
    <>
      <UDTooltip show={!activeModal} css={tooltipStyles} text="Фигуры">
        <IconButton className="icon-button-shape" onClick={handleClick}>
          <InteractiveToggleIcon child={SquareIcon} />
        </IconButton>
      </UDTooltip>
      <BaseModal className="base-modal" isOpen={modalState.isModalOpen && isSelected} style={modalState.modalStyle}>
        <ModalButton className="modal-button" onClick={() => addShape('rect')}>
          <UDIcon name="rectangle" />Прямоугольник
        </ModalButton>
        <ModalButton className="modal-button" onClick={() => addShape('circle')}>
          <UDIcon name="circle" />Круг
        </ModalButton>
      </BaseModal>
    </>
  );
}
