import Select, { GroupBase, Props } from 'react-select';

import React from 'react';
import { SelectOption } from './types';
import { getSelectStyles } from './styles';
import overriddenSelectComponents from './override';

export type UDSelectProps<
  isMulti extends boolean = boolean,
  Group extends GroupBase<SelectOption> = GroupBase<SelectOption>
> = Omit<
  Props<SelectOption, isMulti, Group>,
  'hideSelectedOptions' | 'isSearchable' | 'styles'
> & {
  divProps?: React.HTMLAttributes<HTMLDivElement>,
  hasError?: boolean
};

export const UDSelect = <
  isMulti extends boolean = false,
  Group extends GroupBase<SelectOption> = GroupBase<SelectOption>
>(props: UDSelectProps<isMulti, Group>) => {
  const {
    components,
    divProps,
    ...restProps
  } = props;

  const styles = getSelectStyles<isMulti, Group>();
  const selectComponents = components || overriddenSelectComponents;

  return (
    <div {...divProps} onClick={e => e.stopPropagation()}>
      <Select
        {...restProps}
        data-error={restProps.hasError}
        hideSelectedOptions={false}
        placeholder={restProps.placeholder || ''}
        closeMenuOnSelect={!restProps.isMulti}
        isSearchable={false}
        styles={styles}
        components={selectComponents}
      />
    </div>
  );
};
