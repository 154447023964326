import { Tab } from "../../../../ud-ui/components/tabs/interfaces";

type EditFormTabKey = 'COMMON' | 'PLAYERS' | 'PARTICIPANTS' | 'WAITING_LIST' /* | 'ADMINS' */;

export const TeamEditFormPart: Record<EditFormTabKey, Tab> = {
  COMMON: { hash: 'common', label: 'Основные данные' },
  PLAYERS: { hash: 'players', label: 'Игроки' },
  // ADMINS: { hash: 'admins', label: 'Администраторы' },
  PARTICIPANTS: { hash: 'participants', label: 'Участники' },
  WAITING_LIST: { hash: 'waiting-list', label: 'Лист ожидания' },
};

export const defaultTab = TeamEditFormPart.COMMON;
