import { useDispatch, useSelector } from "react-redux";

import { AppDispatch } from 'store/store';
import { fetchTournamentUsers } from "modules/players/store/actions";
import { selectTournamentUserSlice } from "../../store/selectors";
import { setTournamentId } from "modules/players/store";
import { useEffect } from "react";
import { useOrganizer } from "modules/organizer/ui/hooks/useOrganizer";

function UseTournamentUsers() {
  const dispatch = useDispatch<AppDispatch>()
  const { playerLoadedLimit, allDataLoaded, players, tournamentId, isLoaded, isLoading, ...rest } = useSelector(selectTournamentUserSlice)
  const { info } = useOrganizer()

  useEffect(() => {
    if (!info) return
    if (info.id === tournamentId) {
      if (isLoaded) return
      if (isLoading) return;
    }

    dispatch(setTournamentId({ tournamentId: info.id }))
    dispatch(fetchTournamentUsers({ tournamentId: info.id, limit: playerLoadedLimit }))
  }, [dispatch, isLoaded, isLoading, info, playerLoadedLimit, tournamentId]);

  // useEffect(() => {
  //   if (!info) return;
  //   if (isLoading) return;
  //   if (players.length === 0) return;
  //   if (pages >= 10) return;
  //   if (allDataLoaded) return
  //   dispatch(fetchTournamentUsers({ tournamentId: info.id, limit: playerLoadedLimit, offset: players.length }))
  // }, [dispatch, allDataLoaded, info, isLoading, playerLoadedLimit, players.length, pages]);

  return { playerLoadedLimit, allDataLoaded, players, tournamentId, isLoaded, isLoading, ...rest }
}

export default UseTournamentUsers;
