import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
  createDeleteLocationReducer,
  createFetchLocationMapImageReducer,
  createFetchLocationsByFilterReducer,
  createFetchLocationsReducer,
  createUpsertLocationReducer,
} from './reducers';

import { Location } from '../domain/interfaces/location';

export interface LocationsState {
  locations: Location[];

  isLoading: boolean;
  isLoaded: boolean;

  allDataLoaded: boolean;
  locationLoadedLimit: number;

  isProcessing: boolean;
  isProcessed: boolean;
  processName?: string;

  filter: {
    text: string;
    locations: Location[];

    isLoading: boolean;
    isLoaded: boolean;
    allDataLoaded: boolean;
  };

  tournamentId?: number;
}

const initialState: LocationsState = {
  locations: [],

  isLoading: false,
  isLoaded: false,

  allDataLoaded: false,
  locationLoadedLimit: 50,

  isProcessing: false,
  isProcessed: false,

  filter: {
    text: '',
    locations: [],

    isLoading: false,
    isLoaded: false,
    allDataLoaded: false,
  },

  tournamentId: undefined,
};

export const LocationsSlice = createSlice({
  name: 'locationsSlice',
  initialState,
  reducers: {
    setFilter: (state, action: PayloadAction<{ text: string }>) => {
      state.filter.text = action.payload.text
    },
    resetProcess: (state) => {
      state.isProcessing = false
      state.isProcessed = false
      state.processName = undefined
    },
    setTournamentId: (state, action: PayloadAction<{ tournamentId: number }>) => {
      state.tournamentId = action.payload.tournamentId
      state.locations = []
    },
  },
  extraReducers: (builder) => {
    createFetchLocationsReducer(builder);
    createFetchLocationsByFilterReducer(builder);
    createUpsertLocationReducer(builder);
    createDeleteLocationReducer(builder);
    createFetchLocationMapImageReducer(builder);
  },
});

export const {
  setFilter,
  setTournamentId,
  resetProcess,
} = LocationsSlice.actions;

export default LocationsSlice.reducer;
