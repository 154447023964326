import { Player } from 'modules/players/domain/interfaces/player';
import { PlayerApplication } from '../interfaces/PlayerApplication';

export const mapPlayerToPlayerApplication = (
  player: Player,
): Omit<PlayerApplication, 'id' | 'status' | 'createdAt' | 'teamId' | 'championshipId' | 'position' | 'number'> => {
  return {
    player: {
      id: player.id,
      firstName: player.name,
      lastName: player.surname,
      middleName: player.middleName,
      birthDate: player.birthDate,
      logoUrl: player.photo?.externalUrl,
      height: player.height,
      weight: player.weight,
    },
  };
};
