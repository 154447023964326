import { Button } from "../styles";
import { css } from "@emotion/react";
import size from "../../../domain/constants/size";
import styled from "@emotion/styled";

export const TeamIndex = styled.div`
  width: 458px;
  height: 46px;
  border-radius: 8px;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: ${({ theme }) => theme.colors.Blue};

  @media (max-width: ${size.tablet}px) {
    width: 341px;
  }

  @media screen and
  (orientation: landscape) and
  (max-height: ${size.mobile.height}px) and
  (max-width: ${size.tablet}px) {

  }
`

export const TeamName = styled.div`
  background-color: ${({ theme }) => theme.colors.Blue};

  width: 457px;
  height: 140px;
  border-radius: 8px;

  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: ${size.tablet}px) {
   width: 341px;
  }

  @media screen and
  (orientation: landscape) and
  (max-height: ${size.mobile.height}px) and
  (max-width: ${size.tablet}px) {

  }
`

export const Clock = styled.div`
  width: 312px;
  border-radius: 8px;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: #12121F;
  border: 1px solid #7B7B7B;
`

export const StartButton = styled.button<{ isActive: boolean }>`
  width: 312px;
  height: 79px;
  border-radius: 8px;

  display: flex;
  justify-content: center;
  align-items: center;

  font-weight: 900;
  font-size: 42px;

  border: unset;
  background: ${(props) => props.isActive ? '#48D274' : '#D2472F'};
  color: white;

  cursor: pointer;

  transition: background 0.2s ease-in-out;
`

export const SmoothText = css`
  transition: opacity 0.2s ease-in-out;

  &.hide {
    opacity: 0;
  }
`

export const TeamNameInput = styled.input`
  outline: none;
  border-radius: 8px;
  background: ${({ theme }) => theme.colors.Blue};
  color: white;

  width: 95%;

  font-weight: 400 !important;
  text-align: center;

  transition: border 0.2s ease-in-out;
  border: 1px solid transparent;

  &:hover {
    border: 1px solid ${({ theme }) => theme.colors.AccentNaplesYellow};
  }

  &:focus {
    border: 1px solid ${({ theme }) => theme.colors.AccentNaplesYellow};
  }
`

export const ClockInput = styled.input`
  outline: none;
  padding: 0;
  border-radius: 8px;
  background: #12121F;
  font-variant-numeric: tabular-nums;

  font-family: Saira Stencil One, sans-serif;
  color: ${({ theme }) => theme.colors.DarkYellow};
  text-align: center;

  transition: border 0.2s ease-in-out;
  border: 1px solid #7B7B7B;

  &:not(&:disabled):hover {
    border: 1px solid ${({ theme }) => theme.colors.AccentNaplesYellow};
  }

  &:disabled:hover {
    border: 1px solid darkred;
  }

  &:focus {
    border: 1px solid ${({ theme }) => theme.colors.AccentNaplesYellow};
  }
`

export const ReverseButton = styled(Button) <{ isActive: boolean }>`
  position: relative;
  border-radius: 8px;
  background: white;

  width: 312px;
  height: 45px;

  &:after {
    content: '';
    transition: background-color 0.2s ease-in-out;

    width: 20px;
    height: 20px;
    border-radius: 50%;

    background: ${({ isActive, theme }) => isActive ? '#48D274' : theme.colors.WhiteGray};
    border: 1px solid ${({ theme }) => theme.colors.Gray};

    position: absolute;
    top: 50%;
    left: 22px;
    transform: translate(0, -50%);
  }
`

export const Timer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  @media (max-width: ${size.tablet}px) {
    & #header {
      font-size: 32px !important;
    }

    & #reset {
      margin-top: 10px !important;
    }

    & #clock {
      margin-top: 24px !important;
      font-size: 80px !important;
      width: 271px !important;
    }

    & #start-button {
      margin-top: 24px !important;
      width: 271px !important;
    }

    & #reverse-button {
      margin-top: 30px !important;
      width: 271px !important;

      & p {
        font-size: 12px !important;
      }
    }
  }

  @media screen and
  (orientation: landscape) and
  (max-height: ${size.mobile.height}px) and
  (max-width: ${size.tablet}px) {

  }
`

export const TeamNameWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`
