import { useCallback, useState } from 'react';

import { Background } from 'shared/constants/backgrounds';
import BackgroundSelection from "./components/BackgroundSelection";
import { DivProps } from "../../../../../../typings";
import LogoView from "./components/LogoView";
import { MainBlock } from "./styles";
import { SyncLoader } from "react-spinners";

type IProps = DivProps & {
  img?: string
  background?: Background
  backgroundsList: Background[]
  isLoading?: boolean
  onChangeBackground?: (background: Background) => void
}

function BackgroundLogo(props: IProps) {
  const { img, background, isLoading, onChangeBackground, backgroundsList, ...rest } = props
  const [isEdit, setIsEdit] = useState(false)

  const onSubmit = useCallback((background: Background) => {
    setIsEdit(false);
    onChangeBackground?.(background)
  }, [onChangeBackground]);

  const onCancel = () => {
    setIsEdit(false)
  }

  return (
    !isLoading ? (
      img ? (
        <MainBlock {...rest}>
          {isEdit
            ? <BackgroundSelection
              onSubmit={onSubmit}
              onCancel={onCancel}
              backgrounds={backgroundsList} img={img} />
            : <LogoView
              onClick={() => setIsEdit(true)}
              colorName={background?.name ?? ''}
              color={background?.value ?? ''}
              img={img}
            />
          }
        </MainBlock>
      ) : (
        null
      )
    ) : (
      <SyncLoader />
    )
  );
}

export default BackgroundLogo;
