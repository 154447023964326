import * as yup from 'yup';

import { Formik, FormikHelpers } from 'formik';

import { AnnouncedTeam } from '../../../domain/interfaces/AnnouncedTeam';
import { Image } from 'modules/core/domain/interfaces/Image';
import { Pages } from '../../../../navigation/domain/enums/pages';
import { Team } from '../../../domain/interfaces/Team';
import UDButton from '../../../../ud-ui/components/button';
import UDFormDropZone from 'modules/ud-form/components/dropzone/index';
import UDFormInput from '../../../../ud-form/components/input';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

type FormValues = {
  name: string;
  logo?: Image | File[];
};

export type SubmitTeamForm = ({ values, formikHelpers }: { values: FormValues, formikHelpers: FormikHelpers<FormValues> }) => void;

type EditTeamFormProps = {
  onSubmit: SubmitTeamForm;
  initialValues?: Team | AnnouncedTeam;
  isLoading?: boolean;
};

const validateScheme = yup.object().shape({
  name: yup.string().required('Название команды обязательно'),
  logo: yup.mixed().nullable().optional(),
});

const TeamForm = (props: EditTeamFormProps) => {
  const navigate = useNavigate();
  const { initialValues: initialTeam, onSubmit, isLoading } = props;

  const handleCancelClick = useCallback(() => {
    navigate(Pages.TEAMS.INDEX, { replace: true });
  }, [navigate]);

  const initialValues: FormValues = {
    name: initialTeam?.name ?? '',
    logo: initialTeam?.logo,
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values, formikHelpers) => { onSubmit({ values, formikHelpers }) }}
      validationSchema={validateScheme}
    >
      {(formProps) => {
        return (
          <form className="d-flex flex-column" onSubmit={formProps.handleSubmit}>
            <div className="row align-items-start gx-4">
              <UDFormInput
                name="name"
                label="Название команды"
                placeholder="Введите название"
                containerProps={{ className: 'col-6' }}
              />

              <UDFormDropZone
                name="logo"
                label="Логотип команды"
                containerProps={{ className: 'col-6' }}
                formProps={{ className: 'bgc-SurfaceWhite br-8 p-6' }}
              />
            </div>

            <div className="d-flex justify-content-start mb-2 mt-4 col-6 pr-6">
              <UDButton
                type="submit"
                variant="primary"
                className="mr-4 w-50"
                disabled={isLoading || !formProps.isValid || !formProps.dirty}
                loading={isLoading || formProps.isSubmitting}
              >
                Сохранить
              </UDButton>

              <UDButton
                type="button"
                variant="secondary"
                disabled={isLoading || formProps.isSubmitting}
                onClick={handleCancelClick}
                className="w-50"
              >
                Отменить
              </UDButton>
            </div>
          </form>
        )
      }}
    </Formik>
  );
};

export default TeamForm;
