import { UDListItem, UDText } from 'modules/ud-ui/components';

import CategoryListPage from 'shared/components/category/pages/category-list-page';
import HeaderContentCategory from 'shared/components/category/components/list-wrapper/headerContent';
import { Pages } from '../../../navigation/domain/enums/pages';
import { Team } from '../../domain/interfaces/Team';
import UDButton from 'modules/ud-ui/components/button';
import UDMainLayout from '../../../ud-ui/layout/main';
import defaultLogo from 'modules/ud-ui/components/images/default-team-logo.png';
import { useCallback } from 'react';
import useModal from 'modules/ud-ui/components/new-modal/useModal';
import { useNavigate } from 'react-router-dom';
import { useTeamList } from 'modules/main/ui/hooks/useTeamsList';

type Source = 'common' | 'players';
const naming = {
  searchPlaceholder: 'Поиск по командам',
  addButtonText: 'Добавить команду'
};

const TeamsPage = () => {
  const navigate = useNavigate();
  const handleCreateClick = useCallback(() => {
    navigate(Pages.TEAMS.CREATE);
  }, [navigate]);

  const { isLoading, teams: allTeams, changeFilter, filteredTeams } = useTeamList();
  const { defaultOpen: openDelete, close: closeDelete, Modal: DeleteModal } = useModal()

  const handleEditInfoClick = useCallback(
    (team: Team, source: Source) => {
      navigate(Pages.TEAMS.builders.edit(team.id, source));
    },
    [navigate],
  );

  return (
    <UDMainLayout headerContent={<HeaderContentCategory title='Команды' createButtonText="Добавить команду" createButtonOnClick={handleCreateClick} />}>
      <CategoryListPage categoryName='teams' text={naming} isLoading={isLoading} getFiltered={changeFilter} onCreateClick={handleCreateClick}>
        {(filteredTeams !== null ? filteredTeams : allTeams).map((team, index) => {
          return (
            <UDListItem
              key={team.id}
              index={index + 1}
              image={team.logo?.url ?? defaultLogo}
              className="mb-2 white"
              title={team.name}
              subtitle={
                <div className='d-flex justify-content-between w-100' style={{ fontWeight: 400 }}>
                  <p className='mr-1'>{team.createdAt}</p>
                  <p style={{ minWidth: 80 }}>Состав: {team.players.length}</p>
                </div>
              }
              actions={[
                {
                  label: 'Удалить',
                  onClick: openDelete,
                  variant: 'secondary',
                  style: { width: 144 }
                }
              ]}
              onClick={() => handleEditInfoClick(team, 'common')}
            />
          )
        })}
      </CategoryListPage>

      <DeleteModal>
        <div className='flex-center flex-column gap-2 h-100'>
          <UDText type='body-text'>В данный момент функционал в разработке</UDText>
          <UDText type='subhead'>Приносим свои извинения за доставленные неудобства</UDText>
          <UDButton variant='primary' className='mt-8' onClick={closeDelete}>ОК</UDButton>
        </div>
      </DeleteModal>
    </UDMainLayout>
  );
};

export default TeamsPage;
