import { SymbolicTeamFormation } from '../types';
import { TeamPlayersNumber } from 'shared/types/team-players-number';

export const getDefaultFormation = (playersNumber: TeamPlayersNumber): SymbolicTeamFormation => {
  switch (playersNumber) {
    case TeamPlayersNumber.ONE:
      return SymbolicTeamFormation.ONE;
    case TeamPlayersNumber.TWO:
      return SymbolicTeamFormation.TWO;
    case TeamPlayersNumber.THREE:
      return SymbolicTeamFormation.TWO_ONE;
    case TeamPlayersNumber.FOUR:
      return SymbolicTeamFormation.TWO_TWO;
    case TeamPlayersNumber.FIVE:
      return SymbolicTeamFormation.ONE_TWO_TWO;
    case TeamPlayersNumber.SIX:
      return SymbolicTeamFormation.ONE_THREE_TWO;
    case TeamPlayersNumber.SEVEN:
      return SymbolicTeamFormation.ONE_THREE_THREE;
    case TeamPlayersNumber.EIGHT:
      return SymbolicTeamFormation.ONE_THREE_TWO_TWO;
    case TeamPlayersNumber.NINE:
      return SymbolicTeamFormation.ONE_TWO_FOUR_TWO;
    case TeamPlayersNumber.TEN:
      return SymbolicTeamFormation.ONE_THREE_THREE_THREE;
    case TeamPlayersNumber.ELEVEN:
      return SymbolicTeamFormation.ONE_FOUR_FOUR_TWO;
  }
}
