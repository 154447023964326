import { InputWrapper, TextareaInput } from './styles';
import React, { forwardRef } from 'react';

import { UDTextareaInputProps } from './types';
import classNames from 'classnames';

type Props = UDTextareaInputProps;

const UDTextareaInput = forwardRef<HTMLTextAreaElement, Props>((props, ref) => {
  const { hasError, valid, className, wrapperProps, ...othetProps } = props;
  const inputClassName = classNames('input', 'text subhead', props.className, {
    '--error': props.hasError,
  });

  return (
    <InputWrapper
      {...wrapperProps}
      className={classNames(wrapperProps?.className, {
        error: hasError,
        valid,
      })}>
      <TextareaInput {...othetProps} ref={ref} className={inputClassName} />
    </InputWrapper>
  );
});

export default UDTextareaInput;
