import * as S from './styles';

import React, { forwardRef } from 'react';

import { Tab } from "./interfaces";
import { UDTab } from "./UDTab";
import classNames from 'classnames';

type Props = {
  tabs: Tab[];
  beforeChangeTab?: (hash: string) => any;
  className?: string;
  defaultTab: Tab;
};

const UDTabs = forwardRef<HTMLDivElement, Props>((props, ref) => {
  const { tabs, className } = props;

  return (
    <S.TabsContainer
      className={classNames('d-flex flex-row align-items-center', className)}
      ref={ref}
    >
      {tabs.map((tab) => (
        <UDTab key={tab.hash} tab={tab} />
      ))}
    </S.TabsContainer>
  );
});

export default UDTabs;
