import { ValidPhoneCode, getPhonePrefix } from 'modules/ud-form/components/input/domain/formatters/formatPhone';

import { AuthRequestAuthCodePayload } from 'modules/auth/domain/interfaces/AuthRequestAuthCodePayload';
import { Formik } from 'formik';
import React from 'react';
import { SchemaOf } from 'yup';
import UDButton from 'modules/ud-ui/components/button';
import UDInputPhone from 'modules/ud-form/components/input/phone';
import { useTranslation } from 'react-i18next';

type RequestAuthCodeFormProps = {
  initialValues: {
    phone: string;
  };
  isLoading: boolean;
  onSubmit: (values: AuthRequestAuthCodePayload) => void;
  validationSchema?: SchemaOf<AuthRequestAuthCodePayload>;
};

const isPhoneComplete = (phone: string, prefix: ValidPhoneCode): boolean => {
  const digitsOnly = phone.replace(/\D/g, '');

  switch (prefix) {
    case '+7':
      return digitsOnly.slice(0, 11).length === 11;
    case '+375':
      return digitsOnly.slice(0, 12).length === 12;
    case '+374':
      return digitsOnly.slice(0, 11).length === 11;
    default:
      return false;
  }
};

const RequestAuthCodeForm = (props: RequestAuthCodeFormProps) => {
  const { t } = useTranslation();

  return (
    <Formik<AuthRequestAuthCodePayload>
      initialValues={props.initialValues}
      onSubmit={props.onSubmit}
      validateOnBlur={false}
      validateOnChange={false}
      validationSchema={props.validationSchema}
    >
      {(formProps) => {
        const prefix = getPhonePrefix(formProps.values.phone);
        const isComplete = isPhoneComplete(formProps.values.phone, prefix);

        return (
          <form onSubmit={formProps.handleSubmit}>
            <UDInputPhone
              name='phone'
              label={t('signIn.phoneInputLabel')}
              placeholder={t('signIn.phoneInputPlaceholder')}
              wrapperProps={{ className: 'mb-5' }}
              value={formProps.values.phone}
              onChange={(value: string) => {
                formProps.setFieldValue('phone', value);
                if (isComplete) {
                  formProps.validateField('phone');
                }
              }}
            />
            <UDButton
              variant="primary"
              type="submit"
              className="w-100 mb-3"
              style={{ fontFamily: 'SF Pro Display', fontWeight: 400 }}
              loading={props.isLoading}
              disabled={props.isLoading || !isComplete || !formProps.isValid || !formProps.dirty}
            >
              {t('signIn.requestCodeButtonLabel')}
            </UDButton>
          </form>
        );
      }}
    </Formik>
  );
}

export default RequestAuthCodeForm;
