import { Dictionary, createSelector } from '@reduxjs/toolkit';

import { RootState } from '../../../../store/store';
import { StageV2 } from '../../domain/interfaces/Stage';
import { TeamGroup } from '../../domain/interfaces/TeamGroup';
import { TournamentBracket } from '../../domain/interfaces/TournamentBracket';
import bracketsAdapter from './brackets.adapter';
import groupsAdapter from './groups.adapter';
import stagesAdapter from './stages.adapter';
import stagesSlice from './stages.slice';

export { default as bracketsSlice } from './brackets.slice';
export { default as groupsSlice } from './groups.slice';
export { default as stagesSlice } from './stages.slice';

export const stagesActions = stagesSlice.actions;


export const stageSelectors = stagesAdapter.getSelectors((state: RootState) => state.tourneys.stages);

export const groupSelectors = groupsAdapter.getSelectors((state: RootState) => state.tourneys.groups);

export const bracketsSelectors = bracketsAdapter.getSelectors((state: RootState) => state.tourneys.brackets);

export const isStagesLoadingSelector = createSelector(
  (state: RootState) => state.tourneys.stages.status,
  (status) => status === 'loading',
);

export const isStagesLoadedSelector = createSelector(
  (state: RootState) => state.tourneys.stages.status,
  (status) => status === 'loaded',
);

export const getStagesChampionshipIdSelector = createSelector(
  (state: RootState) => state.tourneys.stages,
  (stages) => stages.championshipId,
);

export const isStagesIdleSelector = createSelector(
  (state: RootState) => state.tourneys.stages.status,
  (status) => status === 'idle',
);

export const selectedStageSelector = createSelector(
  stageSelectors.selectEntities,
  (state: RootState) => state.tourneys.stages.selectedId,
  (entities, selectedId) => selectedId ? (entities[selectedId] || null) : null
);

export const selectSelectedStageGroups = createSelector(
  selectedStageSelector,
  (state: RootState) => state.tourneys.groups.entities,
  (stage: StageV2 | null, groups: Dictionary<TeamGroup>) => {

    if (!stage) {
      return [];
    }

    return Object.keys(groups)
      .map((key) => Number(key))
      .filter((key) => stage.teamGroupIds.includes(key))
      .reduce<TeamGroup[]>((acc, key) => {
        const group = groups[key];
        if (!group) {
          return acc;
        }

        return [...acc, group];
      }, []);
  }
);

export const stageRoundsSelector = createSelector(
  selectedStageSelector,
  (stage) => stage?.rounds || [],
);

export const stageBracketsSelector = createSelector(
  bracketsSelectors.selectAll,
  (state: RootState) => state.tourneys.stages.selectedId,
  (entities, stageId) => {
    if (!stageId) {
      return [];
    }

    return entities.filter((bracket) => bracket.stageId === stageId);
  },
);

export const stageBracketsByRoundSelector = createSelector(
  bracketsSelectors.selectAll,
  (state: RootState) => state.tourneys.stages.selectedId,
  (entities, stageId) => {
    if (!stageId) {
      return {};
    }

    const bracketsByRound = entities
      .filter((bracket) => bracket.stageId === stageId)
      .reduce<Record<number, TournamentBracket[]>>((results, bracket) => {
        const { roundId } = bracket;
        const roundBrackets = results[roundId] || [];

        return {
          ...results,
          [roundId]: [...roundBrackets, bracket],
        };
      }, {});

    const bracketPairsByRound: Record<number, Array<TournamentBracket[]>> = {};

    Object.keys(bracketsByRound).forEach((roundId) => {
      const key = Number(roundId);
      const roundBrackets = bracketsByRound[key];

      const pairs = []
      for (let i = 0; i < roundBrackets.length; i += 2) {
        if (i + 1 < roundBrackets.length) {
          pairs.push([roundBrackets[i], roundBrackets[i + 1]]);
        } else {
          pairs.push([roundBrackets[i]]);
        }
      }

      bracketPairsByRound[key] = pairs;
    });

    return bracketPairsByRound;
  },
);

export const stageGameDetailsListSelector = createSelector(
  selectedStageSelector,
  (stage) => stage?.gameDetailsList,
);

export const stageProcessingSelector = createSelector(
  (state: RootState) => state.tourneys.stages,
  (stage) => ({
    isProcessing: stage?.isProcessing,
    processingName: stage?.processingName,
  }),
);
