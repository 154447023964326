import { DivProps } from "typings";
import UDButton from "modules/ud-ui/components/button";
import UDHeaderTitle from "modules/ud-ui/components/header-title";
import UDIcon from "modules/ud-ui/components/icon";
import classNames from "classnames";

interface IProps extends DivProps {
    title: string;
    createButtonText?: string;
    createButtonOnClick?: () => void;
}

function HeaderContentCategory(props: IProps) {
    const { title, createButtonText, createButtonOnClick, className, ...rest } = props;

    return (
        <div className={classNames('d-flex align-items-center', className)} {...rest}>
            <UDHeaderTitle>{title}</UDHeaderTitle>
            <div className='d-flex justify-content-end'>
                {createButtonText && createButtonOnClick && (
                    <UDButton
                        className='d-flex align-items-center ml-6'
                        variant='dark'
                        onClick={createButtonOnClick}
                    >
                        <UDIcon name='plus' componentProps={{ className: 'mr-2' }} />
                        <span>{createButtonText}</span>
                    </UDButton>
                )}
            </div>
        </div>
    );
}

export default HeaderContentCategory;
