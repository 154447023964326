import React, { useCallback } from 'react';

import { Pages } from "../../../navigation/domain/enums/pages";
import UDButton from "../../../ud-ui/components/button";
import UDHeaderTitle from "../../../ud-ui/components/header-title";
import UDIcon from "../../../ud-ui/components/icon";
import { useNavigate } from "react-router-dom";

function HeaderContent() {
    const navigate = useNavigate();

    const handleCreateClick = useCallback(() => {
        navigate(Pages.LOCATIONS.CREATE);
    }, [navigate]);

    return (
        <>
            <UDHeaderTitle>Локации</UDHeaderTitle>
            <div className='d-flex justify-content-end'>
                <UDButton
                    className='d-flex align-items-center ml-6'
                    variant='dark'
                    onClick={handleCreateClick}
                >
                    <UDIcon name='plus' componentProps={{ className: 'mr-2' }} />
                    <span>Добавить локацию</span>
                </UDButton>
            </div>
        </>
    );
}

export default HeaderContent; 