import { TemplateDTO } from "../interfaces/supabase/overlay/dto";
import { Template } from "../interfaces/supabase/overlay/entity";

const templateDataMapper = (template: TemplateDTO): Template => {
  return {
    ...template,
    createdAt: new Date(template.created_at),
    ownerId: template.owner_id,
    sportType: template.sport_type,
  }
};

export default templateDataMapper;
