import React, { useCallback } from 'react';

import { AppDispatch } from 'store/store';
import { OrganizerForm } from 'modules/organizer/ui/components/organizer-form';
import { OrganizerUpsertPayload } from 'modules/organizer/domain/interfaces/OrganizerUpsertPayload';
import { SkeletonOrganizerForm } from 'modules/organizer/ui/components/organizer-form/skeleton-organizer-form';
import UDHeaderTitle from 'modules/ud-ui/components/header-title';
import UDMainLayout from 'modules/ud-ui/layout/main';
import UDText from 'modules/ud-ui/components/text';
import { UpsertOrganizerInput } from 'modules/organizer/domain/interfaces/UpsertOrganizerInput';
import { upsertOrganizer } from 'modules/organizer/store/actions';
import { useAuth } from 'modules/auth/ui/hooks/useAuth';
import { useDispatch } from 'react-redux';
import { useOrganizer } from 'modules/organizer/ui/hooks/useOrganizer';

const SettingsPage = () => {
  const { user } = useAuth();
  const { isLoading, info } = useOrganizer();
  const dispatch = useDispatch<AppDispatch>();

  const values = info || undefined;

  const handleOrganizerSubmit = useCallback((data: OrganizerUpsertPayload) => {
    const { logo, ...restData } = data;

    const params = {
      ...restData,
      adminUserId: user!.id,
      sportType: data.sportType.toString().toUpperCase(),
      logo: logo?.img instanceof File ? logo.img : undefined,
      background: logo?.background,
    } as UpsertOrganizerInput;

    dispatch(upsertOrganizer(params));
  }, [dispatch, user]);

  return (
    <UDMainLayout
      headerContent={
        <>
          <UDHeaderTitle>Настройки</UDHeaderTitle>
        </>
      }
    >
      <div className='container py-6'>
        <UDText type="headline" className="mr-10">
          Информация об организаторе
        </UDText>
      </div>
      <div className="container d-flex align-items-center bb-1" />
      <div className="container py-6">
        {isLoading ? (
          <SkeletonOrganizerForm />
        ) : (
          <OrganizerForm
            isLoading={isLoading}
            initialValues={values}
            onSubmit={handleOrganizerSubmit}
          />
        )}
      </div>
    </UDMainLayout>
  );
};

export default SettingsPage;
