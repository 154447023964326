import { Board, Template } from "../../domain/interfaces/supabase/overlay/entity";
import { BoardDTO, TemplateDTO } from "../../domain/interfaces/supabase/overlay/dto";
import {
  CopyBoard,
  CopyTemplate,
  CreateBoard,
  CreateTemplate,
  GetOrCreateUserTemplate,
  TemplateFilter,
  UpdateBoard,
  UpdateTemplate,
} from "../../domain/interfaces/supabase/overlay/auxiliary-types";
import { PostgrestSingleResponse, SupabaseClient } from "@supabase/supabase-js";

import boardDataMapper from "../../domain/mappers/board-data-mapper";
import templateDataMapper from "../../domain/mappers/template-data-mapper";

export default class Overlay {
  constructor(private supabase: SupabaseClient) { }

  async getOrCreateUserTemplate(props: GetOrCreateUserTemplate): Promise<Template[]> {
    const {
      ownerId,
      visibility = ['all'],
    } = props

    try {
      const template = await this.getTemplates({ ownerId: ownerId, visibility })
      if (template.length > 0) { return template }
      return await this.createDefaultTemplates(ownerId)
    } catch (error) {
      throw error
    }
  }

  private async createDefaultTemplates(ownerId: number): Promise<Template[]> {
    const templates = await this.getTemplates({ access: ['default'], visibility: ['all'] })

    if (!templates) {
      throw Error('Not found default templates')
    }

    const copiedTemplates = []

    if (templates) {
      for (const template of templates) {
        copiedTemplates.push(this.copyTemplate({ ownerId, templateId: template.id }))
      }
    }

    return Promise.all(copiedTemplates)
  }

  async getTemplates(filter?: TemplateFilter): Promise<Template[]> {
    const { name, access, ownerId, visibility, id } = filter ?? {}

    let query = this.supabase
      .from('templates')
      .select(`*`)

    if (name) { query = query.eq('name', name) }
    if (access) { query = query.in('access', access) }
    if (ownerId) { query = query.eq('owner_id', ownerId) }
    if (visibility) { query = query.in('visibility', visibility) }
    if (id) { query = query.eq('id', id) }

    const { data, error } = (await query) as PostgrestSingleResponse<TemplateDTO[]>

    if (error) {
      console.error(error)
      throw error
    }

    return data.map(templateDataMapper)
  }

  async getTemplateById(templateId: number): Promise<Template> {
    const { data, error } = await this.supabase
      .from('templates')
      .select('*')
      .eq('id', templateId)
      .single()

    if (error) {
      console.error(error)
      throw error
    }

    return templateDataMapper(data)
  }

  async getBoardsByTemplateId(templateId: number): Promise<Board[]> {
    const { data, error } = await this.supabase
      .from('boards')
      .select('*')
      .eq('template_id', templateId);

    if (error) {
      console.error(error);
      throw error;
    }

    return data.map(boardDataMapper);
  }

  async createTemplate(props: CreateTemplate): Promise<Template> {
    const { ownerId, name, createStartBoard, resolution, access, visibility, sportType } = props;

    const { data, error } = (await this.supabase
      .from('templates')
      .insert({
        name,
        access,
        visibility,
        resolution,
        owner_id: access === 'default' ? null : ownerId,
        sport_type: sportType,
      })
      .select()
      .single()) as PostgrestSingleResponse<TemplateDTO>

    if (error) {
      console.error(error)
      throw error
    }

    if (createStartBoard) {
      await this.createBoard({ name: 'Новое табло', objects: [], templateId: data.id, version: '5.3.0' })
    }

    return (await this.getTemplates({
      name: name,
      ownerId: access === 'default' ? undefined : ownerId,
      visibility: visibility && [visibility],
      access: access && [access],
    }))[0]
  }

  async createBoard(props: CreateBoard): Promise<Board> {
    const { data, error } = (await this.supabase
      .from('boards')
      .insert({
        name: props.name,
        objects: props.objects,
        version: props.version,
        template_id: props.templateId,
        type: props.type ?? 'static',
        events: props.events ?? [],
        duration: props.duration,
        is_favorite: props.isFavorite ?? false,
        order: props.order,
      })
      .select()
      .single()) as PostgrestSingleResponse<BoardDTO>

    if (error) {
      console.error(error)
      throw error
    }

    return boardDataMapper(data)
  }

  async deleteTemplate(id: number) {
    const { error } = await this.supabase
      .from('templates')
      .delete()
      .eq('id', id)

    if (error) {
      console.error(error)
      throw error
    }
  }

  async updateTemplate(props: UpdateTemplate) {
    const {
      id,
      name,
      access,
      ownerId,
      visibility,
      resolution,
      likes,
      views,
      sportType,
    } = props

    if (!name && !access && !ownerId && !visibility && !resolution && !likes && !views && !sportType) {
      throw new Error('Nothing to update')
    }

    const { data, error } = (await this.supabase
      .from('templates')
      .update({
        name,
        access,
        owner_id: access === 'default' ? null : ownerId,
        visibility,
        resolution,
        likes,
        views,
        sport_type: sportType,
      })
      .eq('id', id)
      .select(`*`)
      .single()) as PostgrestSingleResponse<TemplateDTO>

    if (error) {
      console.error(error)
      throw error
    }

    return templateDataMapper(data)
  }

  async deleteBoard(id: number) {
    const { error } = await this.supabase
      .from('boards')
      .delete()
      .eq('id', id)

    if (error) {
      console.error(error)
      throw error
    }
  }

  async updateBoard(props: UpdateBoard) {
    const {
      id,
      name,
      objects,
      version,
      templateId,
      isFavorite,
      type,
      events,
      duration,
    } = props

    if (!name && !objects && !version && !templateId && isFavorite === undefined && !type && !events && duration === undefined) {
      throw new Error('Nothing to update')
    }

    const { data, error } = (await this.supabase
      .from('boards')
      .update({ name, objects, version, template_id: templateId, is_favorite: isFavorite, type, events, duration })
      .eq('id', id)
      .select()
      .single()) as PostgrestSingleResponse<BoardDTO>

    if (error) {
      console.error(error)
      throw error
    }

    return boardDataMapper(data)
  }

  async copyTemplate(props: CopyTemplate) {
    const { templateId, ownerId, resolution, name } = props
    const initialTemplate = (await this.getTemplates({ id: templateId }))[0]
    if (!initialTemplate) {
      throw new Error('Template not found')
    }

    const template = await this.createTemplate({
      name: name ?? (initialTemplate.name + ' (копия)'),
      ownerId,
      resolution: resolution ?? initialTemplate.resolution,
      createStartBoard: false,
      sportType: initialTemplate.sportType,
    })

    const promises: Promise<Board>[] = []
    const boards = await this.getBoardsByTemplateId(templateId)
    for (let i = 0; i < boards.length; i++) {
      const board = boards[i]
      promises.push(this.createBoard({
        name: board.name,
        objects: board.objects,
        version: board.version,
        templateId: template.id,
        events: board.events,
        duration: board.duration,
        type: board.type,
        order: i + 1,
      }))
    }

    await Promise.all(promises)
    return (await this.getTemplates({ name: template.name, ownerId: ownerId }))[0]
  }

  async addLikeToTemplate(props: { templateId: number, userId: number }) {
    const { templateId, userId } = props
    const template = await this.getTemplates({ id: templateId })
    if (!template) {
      throw new Error('Template not found')
    }

    const likes = template[0].likes
    if (!likes.includes(userId)) {
      return this.updateTemplate({ id: templateId, likes: [...likes, userId] })
    }

    return template[0]
  }

  async addViewToTemplate(props: { templateId: number, userId: number }) {
    const { templateId, userId } = props
    const template = await this.getTemplates({ id: templateId })
    if (!template) {
      throw new Error('Template not found')
    }

    const views = template[0].views
    if (!views.includes(userId)) {
      return this.updateTemplate({ id: templateId, views: [...views, userId] })
    }

    return template[0]
  }

  async getBoard(id: number) {
    const { data, error } = await this.supabase
      .from('boards')
      .select('*')
      .eq('id', id)
      .single()

    if (error) {
      throw error
    }

    return boardDataMapper(data)
  }

  async copyBoard(props: CopyBoard) {
    const {
      name,
      boardId,
      templateId,
      version,
      objects,
      isFavorite,
      type,
      events,
      duration,
      order,
    } = props
    const board = await this.getBoard(boardId)

    return this.createBoard({
      ...board,
      templateId: templateId ?? board.templateId,
      name: name ?? (board.name + ' (копия)'),
      version: version ?? board.version,
      objects: objects ?? board.objects,
      isFavorite: isFavorite ?? board.isFavorite,
      type: type ?? board.type,
      events: events ?? board.events,
      duration: duration ?? board.duration,
      order: order ?? board.order,
    })
  }

  async getTemplateWithBoards(templateId: number): Promise<{ template: Template, boards: Board[] }> {
    const [template, boards] = await Promise.all([
      this.getTemplateById(templateId),
      this.getBoardsByTemplateId(templateId),
    ])
    return { template, boards }
  }
}
