import { NavigateFunction } from 'react-router-dom';
import UDButton from '../../../modules/ud-ui/components/button';
import { UDText } from '../../../modules/ud-ui/components';
import { useCallback } from 'react';
import useModal from '../../../modules/ud-ui/components/new-modal/useModal';

interface PreventNavigationModalProps {
    navigationTx?: {
        id: string;
        status: string;
    };
    nextLocation: string | number | null;
    targetPath: string | null;
    confirmNavigation: () => void;
    navigate: NavigateFunction;
    defaultPath?: string;
    onConfirm?: () => void;
    onCancel?: () => void;
}

export function usePreventNavigationModal(props: PreventNavigationModalProps) {
    const { navigationTx, nextLocation, targetPath, confirmNavigation, navigate, onConfirm, onCancel, defaultPath } = props;
    const { defaultOpen: openLeaveModal, close: closeLeaveModal, Modal: LeaveModal } = useModal();

    const handleCancel = useCallback(() => {
        closeLeaveModal();
        onCancel?.();
    }, [closeLeaveModal, onCancel]);

    const handleConfirmLeave = useCallback(() => {
        closeLeaveModal();
        onConfirm?.();

        setTimeout(() => {
            if (navigationTx) {
                confirmNavigation();
            } else if (nextLocation) {
                if (typeof nextLocation === 'number') {
                    navigate(nextLocation);
                } else {
                    navigate(nextLocation.toString());
                }
            } else if (targetPath) {
                navigate(targetPath);
            } else if (defaultPath) {
                navigate(defaultPath);
            }
        }, 0);
    }, [closeLeaveModal, onConfirm, navigationTx, nextLocation, targetPath, confirmNavigation, navigate, defaultPath]);

    const modal = useCallback(() => {
        return (
            <LeaveModal>
                <div className="p-6">
                    <UDText type="headline" className="mb-4">
                        Несохраненные изменения
                    </UDText>
                    <UDText type="body-text" className="mb-6">
                        У вас есть несохраненные изменения. Вы уверены, что хотите покинуть страницу?
                    </UDText>
                    <div className="d-flex justify-content-end gap-4">
                        <UDButton
                            variant="primary"
                            onClick={handleConfirmLeave}
                        >
                            Покинуть
                        </UDButton>
                        <UDButton
                            variant="secondary"
                            onClick={handleCancel}
                            className="mr-4"
                        >
                            Отмена
                        </UDButton>
                    </div>
                </div>
            </LeaveModal>
        );

    }, [LeaveModal, handleCancel, handleConfirmLeave]);

    return { openLeaveModal, closeLeaveModal, Modal: modal };
}
