import { Route, Routes } from 'react-router-dom';

import CreatePlayer from '../components/form-parts/players/create-player';
import EditTeam from '../components/edit-team';
import { Team } from 'modules/teams/domain/interfaces/Team';
import TeamsComponentsFormParticipantsListPart from '../components/form-parts/participants/participants-list';
import TeamsComponentsFormPlayersListPart from '../components/form-parts/players/players-list';
import WaitingList from '../components/form-parts/waiting-list/waiting-list';
import { getTeamPlayers } from 'modules/teams/store/players/actions';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { useEntryRequests } from '../hooks/useEntryRequests';
import { useOrganizer } from '../../../organizer/ui/hooks/useOrganizer';
import { usePlayers } from '../../../organizer/ui/hooks/usePlayers';

type Props = {
  team: Team;
};

const TeamComponentsEditForm = (props: Props) => {
  const dispatch = useDispatch();
  const { info, isLoading } = useOrganizer();
  const { team } = props;

  useEffect(() => {
    if (info && !isLoading && team) {
      dispatch(getTeamPlayers(team.id));
    }
  }, [dispatch, isLoading, info, team]);

  const { info: players, isGettingPlayers } = usePlayers();
  const { entryRequests, isLoading: isLoadingEntryRequests } = useEntryRequests(team.id);

  if (isLoading || isGettingPlayers || isLoadingEntryRequests) {
    return (
      <div className='d-flex gap-12'>
        <div className='skeleton-block' style={{ width: 502, height: 67 }} />
        <div className='skeleton-block' style={{ width: 502, height: 348 }} />
      </div>
    );
  }


  return (
    <Routes>
      <Route
        path='players'
        element={
          <TeamsComponentsFormPlayersListPart
            teamId={team.id}
            players={players.filter((player) => !player.isReal)}
          />
        }
      />
      <Route
        path='players/create'
        element={<CreatePlayer tournamentId={info!.id} teamId={team.id} />}
      />
      <Route
        path='participants'
        element={
          <TeamsComponentsFormParticipantsListPart
            tournamentId={info!.id}
            teamId={team.id}
            participants={players.filter((player) => player.isReal)}
          />
        }
      />
      <Route
        path='waiting-list'
        element={
          <WaitingList
            tournamentId={info!.id}
            teamId={team.id}
            entryRequests={entryRequests.filter((entryRequest) => entryRequest.status === 'CREATED')}
          />
        }
      />
      {info && (
        <Route
          path='*'
          element={<EditTeam team={team} tournamentId={info!.id} />}
        />
      )}
    </Routes>
  );
};

export default TeamComponentsEditForm;
