/** @jsxImportSource @emotion/react */

import React from 'react';
import classNames from 'classnames';

export type UDTextType =
  | 'title-huge'
  | 'title-large'
  | 'title-1'
  | 'title-2'
  | 'title-3'
  | 'headline'
  | 'body-text'
  | 'callout'
  | 'subhead'
  | 'footnote'
  | 'caption-1'
  | 'caption-2'
  | 'tip';

const componentsMap: Record<UDTextType, any> = {
  'title-huge': 'h1',
  'title-large': 'h1',
  'title-1': 'h1',
  'title-2': 'h2',
  'title-3': 'h3',
  headline: 'h1',
  'body-text': 'p',
  callout: 'p',
  subhead: 'p',
  footnote: 'p',
  'caption-1': 'p',
  'caption-2': 'p',
  tip: 'p',
};

type ComponentProps =
  | React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLHeadingElement>,
    HTMLHeadingElement
  >
  | React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLParagraphElement>,
    HTMLParagraphElement
  >;

export type Props = {
  type: UDTextType;
  maxWidth?: number;
  children: React.ReactNode;
} & Omit<ComponentProps, 'children'>;

const UDText = (props: Props) => {
  const { type, children, className, color, maxWidth, ...otherProps } = props;
  const additionalStyle = maxWidth ? {
    maxWidth,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  } : {};
  const Component = componentsMap[type];
  return (
    <Component
      {...otherProps}
      className={classNames('text', type, className)}
      style={{ color, ...additionalStyle, ...otherProps.style }}
    >
      {children}
    </Component>
  );
};

export default UDText;
