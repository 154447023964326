import { useCallback, useEffect, useState } from 'react';

interface UseMultiSelectProps<T> {
  items: T[];
  getId: (item: T) => number;
}

interface UseMultiSelectReturn {
  selectedIds: number[];
  isDragging: boolean;
  handleMouseDown: (id: number, event: React.MouseEvent) => void;
  handleMouseLeave: (id: number) => void;
  handleMouseEnter: (id: number) => void;
  handleMouseUp: () => void;
  handleSelect: (id: number) => void;
  setSelectedIds: (ids: number[]) => void;
}

export const useMultiSelect = <T>({ items, getId }: UseMultiSelectProps<T>): UseMultiSelectReturn => {
  const [selectedIds, setSelectedIds] = useState<number[]>([]);
  const [isMouseDown, setIsMouseDown] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const [startId, setStartId] = useState<number | null>(null);
  const [action, setAction] = useState<'select' | 'deselect' | null>(null);

  const handleMouseDown = useCallback((id: number, event: React.MouseEvent) => {
    if (event.button === 0) {
      setIsMouseDown(true);
    }
  }, []);

  const setStateForIds = useCallback((ids: number[], state: boolean) => {
    setSelectedIds(prev => {
      if (state) {
        return Array.from(new Set([...prev, ...ids]));
      } else {
        return prev.filter(id => !ids.includes(id));
      }
    });
  }, []);

  const toggleId = useCallback((id: number) => {
    setSelectedIds((prev) => {
      return prev.includes(id)
        ? prev.filter((selectedId) => selectedId !== id)
        : [...prev, id];
    });
  }, []);

  const handleMouseLeave = useCallback((id: number) => {
    if (isMouseDown && !isDragging) {
      const isActive = !selectedIds.includes(id);
      setAction(isActive ? 'select' : 'deselect');
      setIsDragging(true);
      setStartId(id);
      toggleId(id);
    }
  }, [isDragging, toggleId, isMouseDown, selectedIds]);

  const handleMouseEnter = useCallback((id: number) => {
    if(isMouseDown) {
      if (isDragging && startId !== null) {
        const itemIds = items.map(getId);
        const startIndex = itemIds.indexOf(startId);
        const currentIndex = itemIds.indexOf(id);

        const start = Math.min(startIndex, currentIndex);
        const end = Math.max(startIndex, currentIndex);

        const selectedRange = itemIds.slice(start, end + 1);
        setStateForIds(selectedRange, action === 'select');
      }
    }
  }, [isMouseDown, isDragging, startId, items, getId, action, setStateForIds]);

  const handleMouseUp = useCallback(() => {
    setIsMouseDown(false);
    setIsDragging(false);
    setStartId(null);
  }, []);

  const handleSelect = useCallback((id: number) => {
    if (!isDragging) {
      setSelectedIds((prev) => {
        return prev.includes(id)
          ? prev.filter((selectedId) => selectedId !== id)
          : [...prev, id];
      });
    }
  }, [isDragging]);

  useEffect(() => {
    if (isDragging) {
      const handleGlobalMouseUp = () => {
        setIsDragging(false);
        setStartId(null);
      };

      window.addEventListener('mouseup', handleGlobalMouseUp);
      return () => {
        window.removeEventListener('mouseup', handleGlobalMouseUp);
      };
    }
  }, [isDragging]);

  return {
    selectedIds,
    isDragging,
    handleMouseDown,
    handleMouseEnter,
    handleMouseLeave,
    handleMouseUp,
    handleSelect,
    setSelectedIds,
  };
};
