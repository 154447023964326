/** @jsxImportSource @emotion/react */

import { Button, MainBlock, disableButtonCSS, modalContainerCss } from "./styles";
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { fetchActiveTournamentPlan, givePlan } from "../../../../../store/actions";
import { selectActiveOrganizerPlans, selectPlansList } from "../../../../../store/selectors";
import { useDispatch, useSelector } from "react-redux";

import { AdminUser } from "modules/organizers/domain/interfaces/Organizer";
import { AppDispatch } from "../../../../../../../store/store";
import { Plan } from "../../../../../domain/interfaces/plan";
import PlanCard from "../../../../elements/planCard";
import UDButton from "../../../../../../ud-ui/components/button";
import UDText from "../../../../../../ud-ui/components/text";
import { format } from "date-fns";
import { toast } from "react-toastify";
import { useModal } from "../../../../../../ud-ui/components/modal/useModal";

interface IProps {
  tournamentId: number
  owner?: AdminUser
}

function PlansBlock(props: IProps) {
  const dispatch = useDispatch<AppDispatch>()
  const { tournamentId, owner } = props

  const plans = useSelector(selectPlansList)
  const [selectedPlan, setSelectedPlan] = useState<Plan>()
  const [tariff, setTariff] = useState<string>()
  const { open, close, Modal } = useModal({ defaultShow: false })
  const activePlan = useSelector(selectActiveOrganizerPlans(tournamentId))
  const [currentPlanId, setCurrentPlanId] = useState<number>()
  const balance = useMemo(() => {
    return owner?.balance ? Number((owner?.balance / 100).toFixed(2)) : 0
  }, [owner])

  useEffect(() => {
    if (activePlan === undefined) {
      dispatch(fetchActiveTournamentPlan({ tournamentId: tournamentId }))
    } else if (activePlan === null) {
      setCurrentPlanId(0)
    } else {
      setCurrentPlanId(activePlan.id)
    }
  }, [activePlan, dispatch, tournamentId]);

  const closeModal = useCallback(() => {
    setTariff(undefined)
    setSelectedPlan(undefined)
    close()
  }, [close])

  useEffect(() => {
    const onKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        closeModal()
      }
    }

    document.addEventListener('keydown', onKeyDown)

    return () => {
      document.removeEventListener('keydown', onKeyDown)
    }
  }, [closeModal])

  const handleGivePlan = useCallback(() => {
    if (owner === undefined) {
      toast.error('Пользователь не найден')
      return
    }

    const planId = selectedPlan?.id
    if (planId === undefined) {
      toast.error('Не удалось определить план')
      return
    }

    dispatch(givePlan({
      tournamentId: tournamentId,
      recipientId: owner.id,
      tournamentPlanId: planId,
    })).then(() => {
      dispatch(fetchActiveTournamentPlan({ tournamentId: tournamentId }))
      closeModal()
    })
  }, [owner, selectedPlan?.id, dispatch, tournamentId, closeModal]);

  const isCanBuy = Boolean(selectedPlan?.price && (selectedPlan?.price <= balance))

  return (
    <MainBlock>
      <div className={'mb-7'}>
        <UDText type={'subhead'}>Данный пользователь использует план: <span
          style={{ color: '#F5D956', fontWeight: "bold" }}
        >{plans.find(plan => plan.id === currentPlanId)?.name}</span></UDText>
      </div>
      <div className={'d-flex flex-column'} style={{ gap: 10 }}>
        {plans.map((plan, index) => {
          const planLength = plans.length
          const isActive = plan.id === currentPlanId
          const activeFrom = activePlan?.subscription?.activeFrom ? format(new Date(activePlan.subscription.activeFrom), 'dd.MM.yy') : undefined
          const activeTo = activePlan?.subscription?.activeTo ? format(new Date(activePlan.subscription.activeTo), 'dd.MM.yy') : undefined

          const onClick = () => {
            if (plan.id < 0) return
            open()
            setSelectedPlan(plan)
          }

          return (
            <PlanCard
              isDisabled={plan.id < 0}
              onClick={onClick}
              key={plan.id}
              name={plan.name}
              price={plan.price}
              isActive={isActive}
              date={isActive ? {
                from: activeFrom,
                to: activeTo,
              } : undefined}
              widthPercent={100 - (planLength - (index + 1)) * 5}
            />)
        })}
      </div>

      <Modal
        onClose={closeModal}
        overlayProps={{
          id: 'overlay',
          onClick: (event) => {
            event.preventDefault();
            const target = event.target as HTMLElement
            if (target?.id === 'overlay') {
              closeModal()
            }
          },
        }}
        containerProps={{ css: modalContainerCss }}
        closeIconProps={{ style: { paddingInline: 22, paddingBlock: 22 }, className: 'close' }}
      >
        {selectedPlan && selectedPlan?.id === activePlan?.id ? (
          <div className={'d-flex flex-column align-items-start'}>
            <UDText type={'title-3'} style={{ color: 'white', marginBottom: 30 }}>{selectedPlan.name} план</UDText>
            <UDText type={'subhead'} style={{ color: 'white', marginBottom: 26 }}>
              План подключен и будет действовать
              до <span style={{ fontWeight: 'bold' }}>{activePlan?.subscription?.activeTo ? format(new Date(activePlan.subscription.activeTo), 'dd.MM.yy') : "'Неизвестно'"}</span>
            </UDText>
            <UDButton style={{ width: 130 }} onClick={closeModal} variant={'primary'}>Понятно</UDButton>
          </div>
        ) : (
          <div className={'d-flex flex-column align-items-start'}>
            <UDText type={'title-3'} style={{ color: 'white', marginBottom: 30 }}>{selectedPlan?.name} план</UDText>
            <UDText type={'subhead'} style={{ color: 'white', marginBottom: 16 }}>Подключить этот план:</UDText>
            <div style={{ marginBottom: 30, display: 'flex' }}>
              <Button
                onClick={() => setTariff('month')}
                className={'flex-center'}
                style={{
                  marginRight: 16,
                  border: tariff === 'month' ? '2px solid #F5D956' : '2px solid transparent',
                }}
              >
                На 1 месяц
              </Button>
              <Button
                disabled
                onClick={() => setTariff('year')}
                className={'flex-center'}
                style={{
                  border: tariff === 'year' ? '2px solid #F5D956' : '2px solid transparent',
                  cursor: "not-allowed",
                }}
              >
                На 12 месяцев
              </Button>
            </div>
            <div style={{ display: 'flex', alignItems: 'center', gap: 20 }}>
              <UDButton
                css={disableButtonCSS}
                disabled={!tariff || !isCanBuy}
                style={{ width: 186, height: 36 }}
                onClick={handleGivePlan}
                variant={'primary'}
              >
                Подключить
              </UDButton>
              <div className="d-flex flex-column" style={{ gap: 4 }}>
                <UDText type={'caption-1'} style={{ color: '#A8A8A8' }}>один месяц за {selectedPlan?.price} руб.</UDText>
                {!isCanBuy && <UDText type={'caption-1'} style={{ color: '#A8A8A8' }}>недостаточно средств: {(selectedPlan?.price ?? 0) - balance} руб.</UDText>}
              </div>
            </div>
          </div>
        )}
      </Modal>
    </MainBlock>
  );
}

export default PlansBlock;
